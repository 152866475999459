import React from 'react';
import { Container } from 'react-bootstrap';

import './styles.scss';

interface Props {}

const PageBody: React.FunctionComponent<Props> = (props) => {
    return (
        <div className='PageBody mt-n9 pb-3'>
            <Container fluid='md'>{props.children}</Container>
        </div>
    );
};

export default PageBody;
