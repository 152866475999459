import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import { find } from 'lodash';

import { PermitType, ReportStatus } from './../../models';
import { labels } from './../../constants';
import { ApplicationService } from './../../services';
import PageHeader from './../components/PageHeader';
import PageBody from './../components/PageBody';

import './styles.scss';
import commonUtil from '../../services/utils/common.util';

type Props = {};

interface ChartData {
    title: string;
    value: number;
    color: string;
}

const Summary: React.FunctionComponent<Props> = () => {
    const user = useSelector((state: any) => state.user);
    const [isIHCLoading, setIHCLoading] = useState(true);
    const [isSPLoading, setSPLoading] = useState(true);
    const [isLabLoading, setLabLoading] = useState(true);

    const [ihcData, setIHCData] = useState<ChartData[] | undefined>();
    const [spData, setSPData] = useState<ChartData[] | undefined>();
    const [labData, setLabData] = useState<ChartData[] | undefined>();

    let auth = user.user.authorities;

    const reportStat = [
        ReportStatus.FOR_REVIEW,
        ReportStatus.VALIDATED,
        ReportStatus.PAYMENT_SUBMITTED,
        ReportStatus.HEAD_APPROVAL,
        ReportStatus.COMPLETED
    ];

    const labStat = [
        ReportStatus.PENDING_SPECIMEN,
        ReportStatus.PENDING_RESULT,
        ReportStatus.PENDING_APPROVAL
    ];

    let showIHC = commonUtil.allowedValidator(PermitType.IHC, auth) || commonUtil.allowedApprover(PermitType.IHC, auth)
    let showSP = commonUtil.allowedValidator(PermitType.SP, auth) || commonUtil.allowedApprover(PermitType.SP, auth)
    let showLab = commonUtil.allowedLab(auth);

    const checkCount = (count: number): number => {
        return count && count > 0 ? count : 0;
    };

    const proccessData = (report: any): any => {
        let data: any[] = [];
        let reviewCount = checkCount(report.countStatus[ReportStatus.FOR_REVIEW]);
        if (reviewCount > 0) {
            data.push({
                title: labels.FOR_REVIEW,
                value: reviewCount,
                color: '#d93939'
            });
        }

        let validatedCount = checkCount(report.countStatus[ReportStatus.VALIDATED]);
        if (validatedCount > 0) {
            data.push({
                title: labels.VALIDATED,
                value: validatedCount,
                color: '#117262'
            });
        }

        let submittedCount = checkCount(report.countStatus[ReportStatus.PAYMENT_SUBMITTED]);
        if (submittedCount > 0) {
            data.push({
                title: labels.PAYMENT_VALIDATION,
                value: submittedCount,
                color: '#a0bac7'
            });
        }

        let waitingCount = checkCount(report.countStatus[ReportStatus.HEAD_APPROVAL]);
        if (waitingCount > 0) {
            data.push({
                title: labels.WAITING_APPROVAL,
                value: waitingCount,
                color: '#115272'
            });
        }

        if (data.length === 0) {
            data.push({
                title: labels.COMPLETED,
                value: checkCount(report.countStatus[ReportStatus.COMPLETED]),
                color: '#117262'
            });
        }

        return data;
    };

    const loadReport = () => {
        if (showIHC) loadIHC();
        if (showSP) loadSP();
        if (showLab) loadLab();
    };

    const loadIHC = () => {
        setIHCLoading(true);

        ApplicationService.getReportStat(PermitType.IHC, reportStat)
            .then((response: any) => {
                let ihc = find(response.body.countReport, { name: PermitType.IHC });

                if (ihc) {
                    setIHCData(proccessData(ihc));
                }

                setIHCLoading(false);
            })
            .catch((err) => {
                setIHCLoading(false);
                console.error(err);
            });
    };

    const loadSP = () => {
        setSPLoading(true);

        ApplicationService.getReportStat(PermitType.SP, reportStat)
            .then((response: any) => {
                let sp = find(response.body.countReport, { name: PermitType.SP });

                if (sp) {
                    setSPData(proccessData(sp));
                }

                setSPLoading(false);
            })
            .catch((err) => {
                setSPLoading(false);
                console.error(err);
            });
    };

    const loadLab = () => {
        setLabLoading(true);

        ApplicationService.getReportStat(PermitType.IHC, labStat)
            .then((response: any) => {
                let lab = find(response.body.countReport, { name: PermitType.IHC });

                if (lab) {
                    setLabData([
                        {
                            title: labels.PENDING_SPECIMEN,
                            value: checkCount(lab.countStatus[ReportStatus.PENDING_SPECIMEN]),
                            color: '#d93939'
                        },
                        {
                            title: labels.PENDING_RESULT,
                            value: checkCount(lab.countStatus[ReportStatus.PENDING_RESULT]),
                            color: '#a0bac7'
                        },
                        {
                            title: labels.PENDING_APPROVAL,
                            value: checkCount(lab.countStatus[ReportStatus.PENDING_APPROVAL]),
                            color: '#115272'
                        }
                    ]);
                }

                setLabLoading(false);
            })
            .catch((err) => {
                setSPLoading(false);
                console.error(err);
            });
    };

    useEffect(loadReport, []);

    return (
        <div className='Summary'>
            <PageHeader title={labels.DASHBOARD} />
            <PageBody>
                {showIHC && (
                    <Row>
                        <Col>
                            <Card className='summary'>
                                <Card.Header>
                                    <h4>{labels.HEALTH}</h4>
                                </Card.Header>
                                <Card.Body>
                                    {isIHCLoading && (
                                        <div className='center-content'>
                                            <Spinner animation='border' variant='primary' />
                                        </div>
                                    )}
                                    {!isIHCLoading && ihcData && (
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <PieChart
                                                        data={ihcData}
                                                        style={{ height: '320px' }}
                                                        animate
                                                        label={({ dataEntry }) => dataEntry.value + ' ' + dataEntry.title}
                                                        labelStyle={(index) => ({
                                                            fill: ihcData[index].color,
                                                            fontSize: '5px',
                                                            fontFamily: 'sans-serif'
                                                        })}
                                                        radius={42}
                                                        labelPosition={112}
                                                        segmentsShift={0.5}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <Card className='summary'>
                                <Card.Header>
                                    <h4>Progress</h4>
                                </Card.Header>
                                <Card.Body>
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                )}

                {showSP && (
                    <Row>
                        <Col>
                            <Card className='summary'>
                                <Card.Header>
                                    <h4>{labels.SANITARY}</h4>
                                </Card.Header>
                                <Card.Body>
                                    {isSPLoading && (
                                        <div className='center-content'>
                                            <Spinner animation='border' variant='primary' />
                                        </div>
                                    )}
                                    {!isSPLoading && spData && (
                                        <PieChart
                                            data={spData}
                                            style={{ height: '320px' }}
                                            animate
                                            label={({ dataEntry }) => dataEntry.value + ' ' + dataEntry.title}
                                            labelStyle={(index) => ({
                                                fill: spData[index].color,
                                                fontSize: '5px',
                                                fontFamily: 'sans-serif'
                                            })}
                                            radius={42}
                                            labelPosition={112}
                                            segmentsShift={0.5}
                                        />
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <Card className='summary'>
                                <Card.Header>
                                    <h4>Progress</h4>
                                </Card.Header>
                                <Card.Body>
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                )}

                {showLab && (
                    <Row>
                        <Col>
                            <Card className='summary'>
                                <Card.Header>
                                    <h4>{labels.LABORATORY}</h4>
                                </Card.Header>
                                <Card.Body>
                                    {isLabLoading && (
                                        <div className='center-content'>
                                            <Spinner animation='border' variant='primary' />
                                        </div>
                                    )}
                                    {!isLabLoading && labData && (
                                        <PieChart
                                            data={labData}
                                            style={{ height: '320px' }}
                                            animate
                                            label={({ dataEntry }) => dataEntry.value + ' ' + dataEntry.title}
                                            labelStyle={(index) => ({
                                                fill: labData[index].color,
                                                fontSize: '5px',
                                                fontFamily: 'sans-serif'
                                            })}
                                            radius={42}
                                            labelPosition={112}
                                            segmentsShift={0.5}
                                        />
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <Card className='summary'>
                                <Card.Header>
                                    <h4>Progress</h4>
                                </Card.Header>
                                <Card.Body>
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                )}
            </PageBody>
        </div>
    );
};

export default Summary;
