import React from 'react';

import { labels } from '../../../constants';
import { PermitType, Status } from '../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import ApplicationTable from '../../components/ApplicationTable';

import './styles.scss';

type Props = {};

const SanitaryForReview: React.FunctionComponent<Props> = () => {
    return (
        <div className='SanitaryForReview'>
            <PageHeader title={labels.SANITARY} subTitle={labels.ON_HOLD} />
            <PageBody>
                <ApplicationTable permitType={PermitType.SP} status={Status.ON_HOLD} />
            </PageBody>
        </div>
    );
};

export default SanitaryForReview;
