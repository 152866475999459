import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { usePage } from 'react-page-states';
import { Card, Row, Col, Form, Button, Spinner, Table } from 'react-bootstrap';
import { find } from 'lodash';

import { labels } from '../../../constants';
import { ApplicationFilter, Payment, PaymentStatus, Permit, PermitType, SortBy } from './../../../models';
import { ApplicationService, common } from '../../../services';
import Paginator from '../../components/Paginator';
import SearchFilter, { SearchRef } from '../SearchFilter';
import PaymentModal from '../../components/PaymentModal';
// import BulkTooltip from '../BulkTooltip';
import Sorter from '../Sorter';

import './styles.scss';

type Props = {
    status: PaymentStatus;
    permitType: PermitType;
};

const PaymentTable: React.FunctionComponent<Props> = (props) => {
    const { status, permitType } = props;
    const searchRef = useRef<SearchRef>(null);
    const { pageNo, pageSize, total, ...page } = usePage();
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const isPaymentSubmitted = status === PaymentStatus.SUBMITTED;
    const [isLoading, setLoading] = useState(true);
    const [paymentList, setPaymentList] = useState<Payment[]>([]);
    const [sortBy, setSortBy] = useState(SortBy.UPDATED_DATE);
    const [sortAsc, setSortAsc] = useState(true);
    const [paymentData, setPaymentData] = useState<Payment | undefined>();
    const hasBulk = paymentList.filter((payment) => payment.bulkApplicationId).length > 0;

    const searchColumns: string[] = ['applicationId'];

    if (permitType === PermitType.IHC) {
        searchColumns.push('lastName');
        searchColumns.push('firstName');
    } else if (permitType === PermitType.SP) {
        searchColumns.push('establishmentName');
        searchColumns.push('mayorPermitNo');
        searchColumns.push('establishmentBarangay');
        searchColumns.push('district');
    }

    const loadData = () => {
        setLoading(true);

        if (permitList.length > 0) {
            let permitId = find(permitList, { type: permitType }).permitId;
            let filter: ApplicationFilter = common.createFilter(searchRef?.current?.getValues());

            ApplicationService.getPayments(pageSize, pageNo, permitId, status, filter, sortBy, sortAsc)
                .then((response) => {
                    let data = response.body.data;
                    page.setTotal(data.totalCount);
                    setPaymentList(data.detailResponseDtos);
                })
                .catch((err) => console.error(err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(loadData, [pageSize, pageNo, permitList, sortBy, sortAsc]);

    const _handleFormChange = () => {
        page.setPageNo(1);
        loadData();
    };

    const _handleLimitChange = (limit: number) => {
        page.setPageSize(limit);
        page.setPageNo(1);
    };

    const _handlePaymentModalClose = (hasUpdate: boolean) => {
        setPaymentData(undefined);

        if (hasUpdate) loadData();
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        paymentList.forEach((payment, index) => {
            // let isBulk = payment.bulkApplicationId ? true : false;

            rows.push(
                <tr key={index}>
                    <td>{common.parseNullable(payment.applicationId)}</td>
                    {hasBulk && <td>{common.parseNullable(payment.bulkApplicationId)}</td>}
                    <td>
                        {common.parseFullname(payment)}
                        {/* {isBulk && <BulkTooltip />} */}
                    </td>
                    <td>{payment.referenceNumber}</td>
                    {isPaymentSubmitted && <td>{common.parseNullable(payment.officialReceiptNumber)}</td>}
                    <td>{common.parseCurrency(payment.total)}</td>
                    <td>{payment.updatedDate}</td>
                    {isPaymentSubmitted && (
                        <>
                            <td>{payment.paymentDate}</td>
                            <td className='action-group'>
                                <Button variant='primary' onClick={() => setPaymentData(payment)}>
                                    {labels.VIEW_RECEIPT}
                                </Button>
                            </td>
                        </>
                    )}
                </tr>
            );
        });

        return rows;
    };

    return (
        <div className='PaymentTable'>
            <SearchFilter ref={searchRef} columns={searchColumns} onSubmit={_handleFormChange} />
            <Card className='mt-3'>
                <Card.Body className='p-3'>
                    <Row>
                        <Col xs={{ span: 12, order: 'last' }} md={{ span: 4, order: 'first' }} className='mt-3 mt-md-0'>
                            <span>Show </span>
                            <Form.Group className='count-select'>
                                <Form.Control
                                    as='select'
                                    size='sm'
                                    value={pageSize}
                                    onChange={(e: any) => _handleLimitChange(e.target.value)}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className='fit'>{labels.ID}</th>
                                        {hasBulk && <th className='fit'>{labels.BULK_ID}</th>}
                                        {permitType === PermitType.IHC && (
                                            <th>{labels.APPLICANT + '/' + labels.REPRESENTATIVE}</th>
                                        )}
                                        {permitType === PermitType.SP && (
                                            <th>{labels.ESTABLISHMENT_NAME}</th>
                                        )}
                                        <th>{labels.REFERENCE_NO}</th>
                                        {isPaymentSubmitted && <th>{labels.OR_NO}</th>}
                                        <th>{labels.AMOUNT}</th>
                                        <th>
                                            {labels.LAST_UPDATE}
                                            <Sorter
                                                sortBy={SortBy.UPDATED_DATE}
                                                currentSortBy={sortBy}
                                                currentSortAsc={sortAsc}
                                                setSortBy={setSortBy}
                                                setSortAsc={setSortAsc}
                                            />
                                        </th>
                                        {isPaymentSubmitted && (
                                            <>
                                                <th>
                                                    {labels.PAYMENT_DATE}
                                                    <Sorter
                                                        sortBy={SortBy.PAYMENT_DATE}
                                                        currentSortBy={sortBy}
                                                        currentSortAsc={sortAsc}
                                                        setSortBy={setSortBy}
                                                        setSortAsc={setSortAsc}
                                                    />
                                                </th>
                                                <th>{labels.RECEIPT}</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading && (
                                        <tr key={1}>
                                            <td colSpan={100}>
                                                <div className='center-content'>
                                                    <Spinner animation='border' variant='primary' />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {!isLoading && total === 0 && (
                                        <tr key={1}>
                                            <td colSpan={100}>
                                                <div className='center-content'>
                                                    <span>{labels.NO_DATA}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {!isLoading && total > 0 && <>{renderRows()}</>}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {total > 0 && (
                                <span>
                                    Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                    {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                </span>
                            )}
                        </Col>
                        <Col md='auto'>
                            <Paginator
                                currentPage={pageNo}
                                maxPage={page.maxPage}
                                _handlePageChange={(pageNumber) => page.setPageNo(pageNumber)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {paymentData && (
                <PaymentModal
                    permitType={permitType}
                    payment={paymentData}
                    onClose={(updated) => _handlePaymentModalClose(updated)}
                />
            )}
        </div>
    );
};

export default PaymentTable;
