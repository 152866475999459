import React from 'react';

import { labels } from '../../../constants';
import { PermitType, PaymentStatus } from '../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import PaymentTable from '../../components/PaymentTable';

import './styles.scss';

type Props = {};

const HealthPaymentCreated: React.FunctionComponent<Props> = () => {
    return (
        <div className='HealthPaymentCreated'>
            <PageHeader title={labels.HEALTH} subTitle={labels.PAYMENT_CREATED} />
            <PageBody>
                <PaymentTable permitType={PermitType.IHC} status={PaymentStatus.FOR_REVIEW} />
            </PageBody>
        </div>
    );
};

export default HealthPaymentCreated;
