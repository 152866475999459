import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Spinner, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { usePage } from 'react-page-states';
import { IoSearchSharp } from 'react-icons/io5';
import { find } from 'lodash';

import { labels, routes } from '../../../constants';
import { Application, SanitaryAppData, Permit, PermitType } from '../../../models';
import { ApplicationService, common } from '../../../services';

import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import Paginator from '../../components/Paginator';
import SanitaryReportItem from '../../components/SanitaryReportItem';

import './styles.scss';

type FormValues = {
    establishmentName: string;
};

type Props = {};

const establishmentParam = 'establishmentName';

const SearchApplication: React.FunctionComponent<Props> = () => {
    const navigate = useNavigate();
    const { pageNo, pageSize, total, ...page } = usePage();
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const [isLoading, setLoading] = useState(false);
    const [applicationList, setApplicationList] = useState<Application[]>([]);
    const permitId = find(permitList, { type: PermitType.SP })?.permitId;
    const [searchParams, setSearchParams] = useSearchParams();
    const initSearch = searchParams.get(establishmentParam);
    const [formValues, setFormValues] = useState<FormValues>({
        establishmentName: initSearch ? initSearch : ''
    });

    const { handleSubmit, register } = useForm<FormValues>({
        defaultValues: {
            establishmentName: initSearch ? initSearch : ''
        }
    });

    const loadData = () => {
        if (formValues && formValues.establishmentName && permitId) {
            setSearchParams({ [establishmentParam]: formValues.establishmentName }, { replace: true });
            setLoading(true);
            let filter = common.createFilter(formValues);

            ApplicationService.getApplications(pageSize, pageNo, permitId, filter)
                .then((response: any) => {
                    let data = response.body.data;
                    page.setTotal(data.totalCount);
                    setApplicationList(data.details);
                })
                .catch((err: any) => console.error(err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(loadData, [pageSize, pageNo, formValues, permitId]);

    const _handleFormChange = (values: FormValues) => {
        setFormValues(values);
        page.setPageNo(1);
    };

    // const renderHeader = () => {
    //     return (
    //         <Row className='row-header'>
    //             <span>{labels.MAYOR_PERMIT}</span>
    //             <span>{labels.INDUSTRY}</span>
    //             <span>{labels.DATE}</span>
    //         </Row>
    //     )
    // }

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        // const getStatusClass = (status: Status) => {
        //     let className = 'default';

        //     if (status === Status.COMPLETED || status === Status.RELEASED) {
        //         className = 'done';
        //     } else if (status === Status.REJECTED) {
        //         className = 'reject';
        //     }

        //     return className;
        // }

        applicationList.forEach((application, index) => {
            let formData = application.formData as SanitaryAppData;
            rows.push(
                <SanitaryReportItem
                    key={index}
                    establishmentName={formData.establishmentName}
                    ownerName={formData.ownerName}
                    permitNumber={formData.mayorPermitNo}
                    industry={formData.industry}
                    createdDate={application.createdDate}
                    address={formData.address}
                    onClick={() =>
                        navigate(
                            routes.dashboard.SANITARY_INSPECTION.SANITARY_REPORT_FORM.path.replace(
                                ':appId',
                                application.applicationId.toString()
                            )
                        )
                    }
                />
            );
        });

        return rows;
    };

    return (
        <div className='SearchApplication'>
            <PageHeader title={labels.CREATE_SANITARY_REPORT} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Form onSubmit={handleSubmit((values) => _handleFormChange(values))}>
                            <Row>
                                <Col className='mb-4'>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            name='establishmentName'
                                            placeholder={labels.ESTABLISHMENT_NAME}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                        />
                                        <Button type='submit'>
                                            <IoSearchSharp />
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>

                        {isLoading && (
                            <Row>
                                <Col>
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {!isLoading && !formValues.establishmentName && (
                            <Row>
                                <Col>
                                    <div className='center-content'>
                                        <span>{labels.SEARCH_ESTABLISHMENT}</span>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {!isLoading && formValues.establishmentName && total === 0 && (
                            <Row>
                                <Col>
                                    <div className='center-content'>
                                        <div dangerouslySetInnerHTML={{ __html: labels.NONE_ESTABLISHMENT }}></div>
                                        <div className='divider'>Or</div>
                                        <Link to={routes.dashboard.SANITARY_INSPECTION.SANITARY_REPORT_STANDALONE.path}>
                                            <div>{labels.CREATE_STANDALONE_REPORT}</div>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {!isLoading && total > 0 && (
                            <>
                                <Row>
                                    <Col>
                                        <h4>{total + ' ' + (total > 1 ? labels.APPLICATIONS : labels.APPLICATION)}</h4>
                                    </Col>
                                </Row>
                                {/* <div>
                                    {renderHeader()}
                                </div> */}
                                <div>{renderRows()}</div>
                                <Row className='page-row'>
                                    <Col md='auto'>
                                        <Paginator
                                            currentPage={pageNo}
                                            maxPage={page.maxPage}
                                            _handlePageChange={(pageNumber) => page.setPageNo(pageNumber)}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </PageBody>
        </div>
    );
};

export default SearchApplication;
