import { useState, useLayoutEffect } from 'react';
import { config } from './../../constants';

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', updateSize);

        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
};

export const useMobileCheck = () => {
    const [width] = useWindowSize();

    return width <= config.MOBILE_WIDTH;
};

export default {
    useWindowSize,
    useMobileCheck
};
