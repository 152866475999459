import { actions } from './../constants';
import { DialogInputOption, DialogResponse } from './../models';

export const openLoader = (message?: string) => {
    return {
        type: actions.modal.preloader.OPEN,
        payload: {
            message: message
        }
    };
};

export const closeLoader = () => {
    return {
        type: actions.modal.preloader.CLOSE
    };
};

export const openDialog = (
    body: string | JSX.Element,
    header?: string | JSX.Element,
    positiveText?: string,
    negativeText?: string,
    secondaryText?: string,
    inputs?: DialogInputOption[],
    negativeModal: boolean = false
) => {
    return {
        type: actions.modal.dialog.OPEN,
        payload: {
            header,
            body,
            positiveText,
            negativeText,
            secondaryText,
            inputs,
            negativeModal
        }
    };
};

export const closeDialog = (response: DialogResponse, values?: Object) => {
    return {
        type: actions.modal.dialog.CLOSE,
        payload: {
            response: response,
            values: values
        }
    };
};
