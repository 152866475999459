enum ROLES {
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ADMIN = 'ROLE_ADMIN',
    INSPECTOR = 'ROLE_INSPECTOR',
    IHC_VALIDATOR = 'ROLE_IHC_VALIDATOR',
    IHC_APPROVER = 'ROLE_IHC_APPROVER',
    IHC_AUDITOR = 'ROLE_IHC_AUDITOR',
    SP_VALIDATOR = 'ROLE_SP_VALIDATOR',
    SP_APPROVER = 'ROLE_SP_APPROVER',
    SP_AUDITOR = 'ROLE_SP_AUDITOR',
    SP_VIEWER = 'ROLE_SP_VIEWER',
    LAB_REP = 'ROLE_LAB_REP',
    MICROSCOPIST = 'ROLE_MICROSCOPIST',
    PATHOLOGIST = 'ROLE_PATHOLOGIST',
    LAB_VIEWER = 'ROLE_LAB_VIEWER',
    MASSAGIST_TRAINER = 'ROLE_MASSAGIST_TRAINER',
    LAB_AIDE = 'ROLE_LAB_AIDE',
}

export default ROLES;
