import { find, filter } from 'lodash';

import { actions } from '../constants';
import { Industry, Permit, LookUp, LookUpInfo, LookUpType, Requirement } from '../models';
import { ConfigService } from '../services/api';

export const getPermits = () => {
    return (dispatch: any, getState: any) => {
        const { permitList } = getState().config;

        if (permitList.length === 0) {
            ConfigService.getPermits()
                .then((response: any) => {
                    dispatch(setPermits(response.body.data));
                })
                .catch((error) => {
                    console.error('Error calling getPermit service:', error);
                });
        }
    };
};

export const getIndustries = (update?: boolean) => {
    return (dispatch: any, getState: any) => {
        const { industry } = getState().config;

        if (industry.list.length === 0 || update) {
            dispatch(setIndustriesLoading());

            ConfigService.getIndustries()
                .then((response: any) => {
                    let industryList = response.body.data;
                    industryList = industryList.filter(industry => industry.industryName.toLowerCase().includes('food'))
                    dispatch(setIndustries(industryList));
                })
                .catch((error) => {
                    console.error('Error calling getIndustries service:', error);
                });
        }
    };
};

export const getRequirements = (update?: boolean) => {
    return (dispatch: any, getState: any) => {
        const { requirement } = getState().config;

        if (requirement.list.length === 0 || update) {
            dispatch(setRequirementsLoading());

            ConfigService.getRequirements()
                .then((response: any) => {
                    dispatch(setRequirements(response.body.data));
                })
                .catch((error) => {
                    console.error('Error calling getRequirements service:', error);
                });
        }
    };
};

export const getApplicationTypes = (update?: boolean) => {
    return (dispatch: any, getState: any) => {
        const { applicationType } = getState().config;

        if (applicationType.list.length === 0 || update) {
            dispatch(setApplicationTypesLoading());

            ConfigService.getApplicationTypes()
                .then((response: any) => {
                    dispatch(setApplicationTypes(response.body.data));
                })
                .catch((error) => {
                    console.error('Error calling getApplicationTypes service:', error);
                });
        }
    };
};

export const getLookUps = (update?: boolean) => {
    return (dispatch: any, getState: any) => {
        const lookUps = getState().config.lookUps.list;

        if (lookUps.length === 0 || update) {
            dispatch(setLookUpsLoading());

            return ConfigService.getLookUps()
                .then((response: any) => {
                    dispatch(setLookUps(response.body.data));
                    return Promise.resolve(response.body.data);
                })
                .catch((error) => {
                    console.error('Error calling getLookUps service:', error);
                    return Promise.reject(error);
                });
        }
    };
};

export const getLookUpInfos = (id: number, update?: boolean) => {
    return (dispatch: any, getState: any) => {
        const lookUpInfos = getState().config.lookUpInfos.list;

        if (update || filter(lookUpInfos, { lookUpId: id }).length === 0) {
            dispatch(setLookUpInfosLoading());

            return ConfigService.getLookUpInfos(id)
                .then((response: any) => {
                    dispatch(setLookUpInfos(response.body.data));
                    return Promise.resolve(response.body.data);
                })
                .catch((error) => {
                    console.error('Error calling getLookUpInfos service:', error);
                    return Promise.reject(error);
                });
        }
    };
};

export const getLookUpInfoByType = (type: LookUpType) => {
    return (dispatch: any, getState: any) => {
        const lookUps = getState().config.lookUps.list;

        let lookUpByType = find(lookUps, { name: type });

        if (lookUpByType) {
            dispatch(getLookUpInfos(lookUpByType.lookUpId));
        } else {
            dispatch(getLookUps())
                .then((data) => {
                    let newLookUpByType = find(data, { name: type });

                    if (newLookUpByType) dispatch(getLookUpInfos(newLookUpByType.lookUpId));
                })
                .catch((error: any) => {
                    console.error('Error calling getLookUpInfoByType service:', error);
                });
        }
    };
};

export const setPermits = (permits: Array<Permit>) => {
    return {
        type: actions.config.SET_PERMITS,
        payload: permits
    };
};

export const setIndustries = (industries: Array<Industry>) => {
    return {
        type: actions.config.SET_INDUSTRIES,
        payload: industries
    };
};

export const setIndustriesLoading = () => {
    return {
        type: actions.config.SET_INDUSTRIES_LOADING
    };
};

export const setRequirements = (requirements: Array<Requirement>) => {
    return {
        type: actions.config.SET_REQUIREMENTS,
        payload: requirements
    };
};

export const setRequirementsLoading = () => {
    return {
        type: actions.config.SET_REQUIREMENTS_LOADING
    };
};

export const setApplicationTypes = (industries: Array<Industry>) => {
    return {
        type: actions.config.SET_APPLICATIONTYPES,
        payload: industries
    };
};

export const setApplicationTypesLoading = () => {
    return {
        type: actions.config.SET_APPLICATIONTYPES_LOADING
    };
};

export const setLookUps = (lookups: Array<LookUp>) => {
    return {
        type: actions.config.SET_LOOKUPS,
        payload: lookups
    };
};

export const setLookUpsLoading = () => {
    return {
        type: actions.config.SET_LOOKUPS_LOADING
    };
};

export const setLookUpInfos = (lookupInfos: Array<LookUpInfo>) => {
    return {
        type: actions.config.SET_LOOKUPINFOS,
        payload: lookupInfos
    };
};

export const setLookUpInfosLoading = () => {
    return {
        type: actions.config.SET_LOOKUPINFOS_LOADING
    };
};
