import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Row, Col, Image, Container, Form, Table } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { find } from 'lodash';

import { labels, images } from '../../../constants';
import {
    File,
    User,
    Roles,
    Status,
    LabFormUser,
    LabFormStatus,
    LabFormType,
    Gender,
    StoolColor,
    StoolConsistency,
    CreateLabForm,
    LabForm,
    Requirement,
    Application,
    HealthAppData,
    SanitaryAppData,
    WAFormEnum
} from '../../../models';
import { ApplicationService, modal, common } from '../../../services';
import { getImageDetails } from '../../../actions/content.action';

import './styles.scss';
import { parseDateTimeAPIToDateTime } from '../../../services/utils/common.util';
import LabType from '../../../models/enums/LabType';

type Props = {
    show: boolean;
    status: Status;
    application: Application;
    requirement?: Requirement;
    labForm?: LabForm;
    labType?: LabType | null;
    viewOnly?: boolean;
    onClose: (updated?: boolean) => void;
};

const LabFormModal: React.FunctionComponent<Props> = (props) => {
    const { show, status, requirement, application, labForm, viewOnly, onClose, labType } = props;
    const user: User = useSelector((state: any) => state.user.user);
    const imageList: File[] = useSelector((state: any) => state.content.imageList);
    const dispatch = useDispatch();
    const isPendingResult = status === Status.PENDING_RESULT;

    const initFormValues = () => {
        let formOptions: any = {
            mode: 'onBlur'
        };

        let formValues;

        //Initialize with defaults
        if (labType === LabType.WATER_ANALYSIS) {
            let formData = application.formData as SanitaryAppData;
            formValues = {
                establishmentName: formData.establishmentName,
                owner: formData.ownerName,
                address: formData.address,
                contactNumber: formData.contactNumber,
                officialReceiptNumber: application.paymentDetailResponseDto?.[0]?.officialReceiptNumber,
                dateExamined: common.parseDateTimeToString(),
                dateReported: common.parseDateTimeToString()
            };
        } else {
            let formData = application.formData as HealthAppData;
            formValues = {
                name: common.parseFullname(formData),
                date: common.parseDateTimeToString(),
                age: common.getAge(formData.birthDate),
                gender: formData.gender,
                labNo: formData?.labNo,
                facility: formData?.facility,
            };
        }

        //Overwrite with previous form
        if (labForm) {
            let formData = labForm.formData;

            formValues = {
                ...formValues,
                ...formData
            }

            //Handler for old data without time
            formValues.date = !formValues.date || formValues.date.includes('T') ? formValues.date : formValues.date + 'T00:00'
        }

        formOptions.defaultValues = formValues;

        return formOptions;
    };

    const initType = () => {
        let type = LabFormType.STOOL;

        if (requirement) {
            if (requirement.requirementName.toLowerCase().includes(LabFormType.STOOL.toLowerCase())) {
                type = LabFormType.STOOL;
            } else if (requirement.requirementName.toLowerCase().includes(LabFormType.SPUTUM.toLowerCase())) {
                type = LabFormType.SPUTUM;
            } else if (requirement.requirementName.toLowerCase() === LabFormType.VD.toLowerCase()) {
                type = LabFormType.VD;
            } else if (requirement.requirementName.toLowerCase().includes(LabFormType.MICRO_WATER.toLowerCase())) {
                type = LabFormType.MICRO_WATER;
            }
        } else if (labForm) {
            type = labForm.formData.formType;
            if (labForm.formData.color) {
                type = LabFormType.STOOL;
            } else if (labForm.formData.sputumAFB) {
                type = LabFormType.SPUTUM;
            } else if (labForm.formData.koh) {
                type = LabFormType.VD
            } else if (labForm.formData.totalColiformResult) {
                type = LabFormType.MICRO_WATER;
            }
        }

        return type;
    };

    const type = initType();
    const { handleSubmit, register, control, errors } = useForm(initFormValues());
    const formValues = control.defaultValuesRef.current;

    const totalColiformMethod: any = useWatch({ control, name: 'totalColiformMethod' });
    const thermoColiformMethod: any = useWatch({ control, name: 'thermoColiformMethod' });
    const heteroPlateMethod: any = useWatch({ control, name: 'heteroPlateMethod' });

    const _handleSave = (values) => {
        modal.displayConfirmation(labels.dialog.message.LAB_RESULTS_CONFIRM, () => {
            let method;
            let successMessage;

            const appFormData = application.formData as HealthAppData;
            values['labNo'] = appFormData.labNo ?? values['labNo'];
            values['facility'] = appFormData.facility ?? values['facility'];

            //Remove unneeded spaces
            Object.keys(values).forEach((key) => {
                values[key] = values[key] ? values[key].trim() : values[key];
            });

            if (labForm) {
                let form: LabForm = {
                    ...labForm,
                    laboratoryStatus: LabFormStatus.SUBMITTED,
                    formData: {
                        ...labForm.formData,
                        ...values
                    }
                };

                //Set date to current time if unchanged else prioritize input
                if (labForm.formData.date === form.formData.date) {
                    form.formData.date = common.parseDateTimeToString();
                }

                method = ApplicationService.updateLaboratory(form);
                successMessage = labels.dialog.message.LAB_UPDATE_SUCCESS;
            } else if (requirement) {
                let form: CreateLabForm = {
                    applicationId: application.applicationId,
                    requirementId: requirement.requirementId,
                    laboratoryStatus: LabFormStatus.SUBMITTED,
                    formData: values
                };
                method = ApplicationService.createLaboratory(form);
                successMessage = labels.dialog.message.LAB_CONFIRM_SUCCESS;
            }

            method
                .then(() => {
                    modal.displaySuccess(successMessage);
                    onClose(true);
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const _handleApprove = () => {
        if (labForm) {
            modal.displayConfirmation(labels.dialog.message.LAB_APPROVE_CONFIRM.replace('{type}', type), () => {
                ApplicationService.approveLaboratoryResult(labForm.laboratoryId)
                    .then(() => {
                        onClose(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    const _handleReject = () => {
        if (labForm) {
            modal.displayRejConfirmation(labels.dialog.message.LAB_REJECT_CONFIRM.replace('{type}', type), (values) => {
                ApplicationService.rejectLaboratoryResult(labForm.laboratoryId, values.remarks)
                    .then(() => {
                        onClose(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    const renderGenderOptions = () => {
        let options: JSX.Element[] = [];
        options.push(
            <option key={0} value={''} hidden>
                Select
            </option>
        );

        Object.values(Gender).forEach((gender, index) => {
            options.push(<option key={index + 1}>{gender}</option>);
        });

        return options;
    };

    const renderColorOptions = () => {
        let options: JSX.Element[] = [];

        Object.values(StoolColor).forEach((color, index) => {
            options.push(
                <Form.Check
                    key={index}
                    type='radio'
                    label={color}
                    value={color}
                    name='color'
                    required
                    disabled={!isPendingResult}
                    ref={register()}
                />
            );
        });

        return options;
    };

    const renderConsistencyOptions = () => {
        let options: JSX.Element[] = [];

        Object.values(StoolConsistency).forEach((consistency, index) => {
            options.push(
                <Form.Check
                    key={index}
                    type='radio'
                    label={consistency}
                    value={consistency}
                    name='consistency'
                    required
                    disabled={!isPendingResult}
                    ref={register()}
                />
            );
        });

        return options;
    };

    const renderWalUnit = (method: any) => {
        if (method === WAFormEnum.MethodType.ENZYME) {
            return labels.UNIT_ENZYME;
        } else if (method === WAFormEnum.MethodType.TUBE) {
            return labels.UNIT_TUBE;
        } else if (method === WAFormEnum.MethodType.PLATE) {
            return labels.UNIT_PLATE;
        } else {
            return;
        }
    };

    const renderWalMethod = (isColiform: boolean) => {
        let options: JSX.Element[] = [];

        options.push(<option key={0} value={undefined} hidden></option>);

        if (isColiform) {
            options.push(
                <option key={1} value={WAFormEnum.MethodType.ENZYME}>
                    {WAFormEnum.MethodValue.ENZYME}
                </option>
            );
            options.push(
                <option key={2} value={WAFormEnum.MethodType.TUBE}>
                    {WAFormEnum.MethodValue.TUBE}
                </option>
            );
        } else {
            options.push(
                <option key={1} value={WAFormEnum.MethodType.PLATE}>
                    {WAFormEnum.MethodValue.PLATE}
                </option>
            );
        }

        return options;
    };

    const renderWalStandard = (method: any) => {
        if (method === WAFormEnum.MethodType.ENZYME) {
            return labels.STANDARD_ENZYME;
        } else if (method === WAFormEnum.MethodType.TUBE) {
            return labels.STANDARD_TUBE;
        } else if (method === WAFormEnum.MethodType.PLATE) {
            return labels.STANDARD_PLATE;
        } else {
            return;
        }
    };

    const renderWalResult = (method: any) => {
        let options: JSX.Element[] = [];
        let resultType;

        if (method === WAFormEnum.MethodType.ENZYME) {
            resultType = WAFormEnum.EnzymeResult;
        } else if (method === WAFormEnum.MethodType.TUBE) {
            resultType = WAFormEnum.TubeResult;
        } else {
            return options;
        }

        options.push(<option key={0} value={undefined} hidden></option>);

        Object.values(resultType).forEach((result: any, index) => {
            options.push(
                <option key={result} value={result}>
                    {result}
                </option>);
        });

        return options;
    };

    const renderWalRemarks = () => {
        let options: JSX.Element[] = [];
        options.push(<option key={0} value={undefined} hidden></option>);

        Object.values(WAFormEnum.Remarks).forEach((remark: any, index) => {
            options.push(
                <option key={remark} value={remark}>
                    {remark}
                </option>);
        });

        return options;
    };

    const renderMedTechDetails = () => {
        let details: JSX.Element;

        if (!labForm?.formData?.medTechDetails && labForm?.formData?.medTech) {
            details = (
                <Form.Group className='sig-group'>
                    <Form.Control
                        className='signature'
                        as='textarea'
                        rows={2}
                        name='medTech'
                        value={labForm.formData.medTech}
                        disabled={!isPendingResult}
                    />
                    <Form.Label className='label-name'>{labels.MED_TECH}</Form.Label>
                </Form.Group>
            );
        } else {
            let submittedBy = labForm?.submittedBy;
            let approvedBy = labForm?.approvedBy;
            let sig;
            let info;

            const getLabel = (labelUser: LabFormUser | User) => {
                let label = labels.UPLOADER;

                if (labelUser.authorities?.includes(Roles.LAB_REP) || labelUser.authorities?.includes(Roles.PATHOLOGIST)) {
                    label = labels.MED_TECH;
                } else if (labelUser.authorities?.includes(Roles.MICROSCOPIST)) {
                    label = labels.MICROSCOPIST;
                }

                if (approvedBy && submittedBy && approvedBy.userId === submittedBy.userId) {
                    if (approvedBy.authorities?.includes(Roles.PATHOLOGIST)) {
                        label = label + ' / ' + labels.PATHOLOGIST;
                    } else {
                        label = label + ' / ' + labels.APPROVER;
                    }
                } else if (!approvedBy && !isPendingResult && submittedBy && user.login === submittedBy.userId) {
                    if (user?.authorities?.includes(Roles.PATHOLOGIST)) {
                        label = label + ' / ' + labels.PATHOLOGIST;
                    } else {
                        label = label + ' / ' + labels.APPROVER;
                    }
                }

                return label;
            };

            if (submittedBy) {
                info = submittedBy;

                if (submittedBy.signatureId) {
                    sig = find(imageList, { id: submittedBy.signatureId });

                    if (!sig) {
                        dispatch(getImageDetails(submittedBy.signatureId));
                    }
                }
            } else {
                info = user;

                if (user.signatureId) {
                    sig = find(imageList, { id: user.signatureId });

                    if (!sig) {
                        dispatch(getImageDetails(user.signatureId));
                    }
                }
            }

            details = (
                <Col xs={12} md={6}>
                    <div className='rep-signature'>
                        {sig && sig.imageUrl && <Image src={sig.imageUrl} />}
                        {info && (
                            <>
                                <label className='name'>{common.parseRepName(info)}</label>
                                {info.licence && <label className='license'>{common.parseRepLicense(info.licence)}</label>}
                            </>
                        )}
                        <label className='role'>{getLabel(info)}</label>
                    </div>
                </Col>
            );
        }

        return details;
    };

    const renderPathoDetails = () => {
        let details: JSX.Element = <></>;

        if (!labForm?.approvedBy && labForm?.formData?.pathologist) {
            details = (
                <Form.Group className='sig-group'>
                    <Form.Control className='signature' as='textarea' rows={2} name='pathologist' disabled={true} />
                    <Form.Label className='label-name'>{labels.PATHOLOGIST}</Form.Label>
                </Form.Group>
            );
        } else {
            let submittedBy = labForm?.submittedBy;
            let approvedBy = labForm?.approvedBy;
            let showPatho = true;
            let sig;
            let info;

            const getLabel = (user: LabFormUser | User) => {
                let label = labels.APPROVER;

                if (user) {
                    if (user?.authorities?.includes(Roles.PATHOLOGIST)) {
                        label = labels.PATHOLOGIST;
                    } else if (user?.authorities?.includes(Roles.LAB_REP)) {
                        label = labels.MED_TECH + ' ' + labels.APPROVER;
                    } else if (user?.authorities?.includes(Roles.MICROSCOPIST)) {
                        label = labels.MICROSCOPIST + ' ' + labels.APPROVER;
                    }
                } else {
                    label = labels.PATHOLOGIST;
                }

                return label;
            };

            if (approvedBy) {
                if (submittedBy && approvedBy.userId !== submittedBy.userId) {
                    info = approvedBy;

                    if (approvedBy.signatureId) {
                        sig = find(imageList, { id: approvedBy.signatureId });

                        if (!sig) {
                            dispatch(getImageDetails(approvedBy.signatureId));
                        }
                    }
                } else {
                    showPatho = false;
                }
            } else if (!isPendingResult) {
                if (user && (!submittedBy || submittedBy.userId !== user.login)) {
                    info = user;

                    if (user.signatureId) {
                        sig = find(imageList, { id: user.signatureId });

                        if (!sig) {
                            dispatch(getImageDetails(user.signatureId));
                        }
                    }
                } else {
                    showPatho = false;
                }
            }

            if (showPatho) {
                details = (
                    <Col xs={12} md={6}>
                        <div className='rep-signature'>
                            {sig && sig.imageUrl && <Image src={sig.imageUrl} />}
                            {info && (
                                <>
                                    <label className='name'>{common.parseRepName(info)}</label>
                                    {info?.licence && <label className='license'>{common.parseRepLicense(info.licence)}</label>}
                                </>
                            )}
                            <label className='role'>{getLabel(info)}</label>
                        </div>
                    </Col>
                );
            }
        }

        return details;
    };

    const renderMicroWaterForm = () => {
        return (
            <>
                <hr />
                <Row className='title centered'>
                    <Col xs={12}>
                        <span>{labels.MICRO_WATER_RESULT}</span>
                    </Col>
                </Row>
                <Table responsive bordered hover>
                    <thead>
                        <tr>
                            <th>{labels.TEST}</th>
                            <th>{labels.UNIT}</th>
                            <th>{labels.METHOD}</th>
                            <th>{labels.PNSDW_STANDARD}</th>
                            <th>{labels.RESULTS}</th>
                            <th>{labels.REMARKS}</th>
                        </tr>
                    </thead>
                    {!isPendingResult && (
                        <tbody>
                            <tr>
                                <td>{labels.TOTAL_COLIFORM}</td>
                                <td>{renderWalUnit(totalColiformMethod)}</td>
                                <td>{WAFormEnum.MethodValue[formValues.totalColiformMethod]}</td>
                                <td>{renderWalStandard(totalColiformMethod)}</td>
                                <td>{formValues.totalColiformResult}</td>
                                <td>{formValues.totalColiformRemarks}</td>
                            </tr>
                            <tr>
                                <td>{labels.THERMO_COLIFORM}</td>
                                <td>{renderWalUnit(thermoColiformMethod)}</td>
                                <td>{WAFormEnum.MethodValue[formValues.thermoColiformMethod]}</td>
                                <td>{renderWalStandard(thermoColiformMethod)}</td>
                                <td>{formValues.thermoColiformResult}</td>
                                <td>{formValues.thermoColiformRemarks}</td>
                            </tr>
                            <tr>
                                <td>{labels.HETERO_COUNT}</td>
                                <td>{renderWalUnit(heteroPlateMethod)}</td>
                                <td>{WAFormEnum.MethodValue[formValues.heteroPlateMethod]}</td>
                                <td>{renderWalStandard(heteroPlateMethod)}</td>
                                <td>{formValues.heteroPlateResult + ' CFU/ml'}</td>
                                <td>{formValues.heteroPlateRemarks}</td>
                            </tr>
                            <tr>
                                <td colSpan={100}>
                                    <span className='end'>{labels.TABLE_END}</span>
                                </td>
                            </tr>
                        </tbody>
                    )
                    }
                    {isPendingResult && (
                        <tbody>
                            <tr>
                                <td>{labels.TOTAL_COLIFORM}</td>
                                <td>{renderWalUnit(totalColiformMethod)}</td>
                                <td>
                                    <Form.Group>
                                        <Form.Control
                                            as='select'
                                            name='totalColiformMethod'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.totalColiformMethod}>
                                            {renderWalMethod(true)}
                                        </Form.Control>
                                        {!!errors.totalColiformMethod && (
                                            <Form.Control.Feedback type='invalid'>{errors.totalColiformMethod.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </td>
                                <td>{renderWalStandard(totalColiformMethod)}</td>
                                <td>
                                    {totalColiformMethod &&
                                        <Form.Group>
                                            <Form.Control
                                                as='select'
                                                name='totalColiformResult'
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.totalColiformResult}>
                                                {renderWalResult(totalColiformMethod)}
                                            </Form.Control>
                                            {!!errors.totalColiformResult && (
                                                <Form.Control.Feedback type='invalid'>{errors.totalColiformResult.message}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    }
                                </td>
                                <td>
                                    {totalColiformMethod &&
                                        <Form.Group>
                                            <Form.Control
                                                as='select'
                                                name='totalColiformRemarks'
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.totalColiformRemarks}>
                                                {renderWalRemarks()}
                                            </Form.Control>
                                            {!!errors.totalColiformRemarks && (
                                                <Form.Control.Feedback type='invalid'>{errors.totalColiformRemarks.message}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{labels.THERMO_COLIFORM}</td>
                                <td>{renderWalUnit(thermoColiformMethod)}</td>
                                <td>
                                    <Form.Group>
                                        <Form.Control
                                            as='select'
                                            name='thermoColiformMethod'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.thermoColiformMethod}>
                                            {renderWalMethod(true)}
                                        </Form.Control>
                                        {!!errors.thermoColiformMethod && (
                                            <Form.Control.Feedback type='invalid'>{errors.thermoColiformMethod.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </td>
                                <td>{renderWalStandard(thermoColiformMethod)}</td>
                                <td>
                                    {thermoColiformMethod &&
                                        <Form.Group>
                                            <Form.Control
                                                as='select'
                                                name='thermoColiformResult'
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.thermoColiformResult}>
                                                {renderWalResult(thermoColiformMethod)}
                                            </Form.Control>
                                            {!!errors.thermoColiformResult && (
                                                <Form.Control.Feedback type='invalid'>{errors.thermoColiformResult.message}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    }
                                </td>
                                <td>
                                    {thermoColiformMethod &&
                                        <Form.Group>
                                            <Form.Control
                                                as='select'
                                                name='thermoColiformRemarks'
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.thermoColiformRemarks}>
                                                {renderWalRemarks()}
                                            </Form.Control>
                                            {!!errors.thermoColiformRemarks && (
                                                <Form.Control.Feedback type='invalid'>{errors.thermoColiformRemarks.message}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{labels.HETERO_COUNT}</td>
                                <td>{renderWalUnit(heteroPlateMethod)}</td>
                                <td>
                                    <Form.Group>
                                        <Form.Control
                                            as='select'
                                            name='heteroPlateMethod'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.heteroPlateMethod}>
                                            {renderWalMethod(false)}
                                        </Form.Control>
                                        {!!errors.heteroPlateMethod && (
                                            <Form.Control.Feedback type='invalid'>{errors.heteroPlateMethod.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </td>
                                <td>{renderWalStandard(heteroPlateMethod)}</td>
                                <td>
                                    {heteroPlateMethod &&
                                        <Form.Group>
                                            <Form.Control
                                                type='number'
                                                name='heteroPlateResult'
                                                min={0}
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.heteroPlateResult}
                                            />
                                            {!!errors.heteroPlateResult && (
                                                <Form.Control.Feedback type='invalid'>{errors.heteroPlateResult.message}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    }
                                </td>
                                <td>
                                    {heteroPlateMethod &&
                                        <Form.Group>
                                            <Form.Control
                                                as='select'
                                                name='heteroPlateRemarks'
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.heteroPlateRemarks}>
                                                {renderWalRemarks()}
                                            </Form.Control>
                                            {!!errors.heteroPlateRemarks && (
                                                <Form.Control.Feedback type='invalid'>{errors.heteroPlateRemarks.message}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={100}>
                                    <span className='end'>{labels.TABLE_END}</span>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table >
                <hr />
                <Row className='footer'>
                    <Col xs={2}>
                        <span>Reference:</span>
                    </Col>
                    <Col>
                        <div>Philippine National Standards for Drinking Water - DOH 2017</div>
                        <div>Standard Methods for the Examination of Drinking Water and Wastewater, American Public Health Association</div>
                        <div>Water works Association, 22nd ed. 2012</div>
                    </Col>
                </Row>
                <br />
                <Row className='footer'>
                    <Col xs={2}>
                        <span>Note:</span>
                    </Col>
                    <Col>
                        <span>The test result given refers only to the particular sample collected.</span>
                    </Col>

                </Row>
            </>
        )
    }

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='LabFormModal'>
            <Modal.Header closeButton>
                <Row className='header__row'>
                    <Col>
                        <Image src={images.QC_LOGO.src} alt={images.QC_LOGO.alt} className='logo' />
                    </Col>
                    <Col xs={8} md={8} className='col__wrap'>
                        <h5>{labels.REPUBLIC_OF_THE_PHILIPPINES}</h5>
                        <h4>{labels.TITLE}</h4>
                        <h6>{labels.QC_ADDRESS_LINE1}</h6>
                        <h6>{labels.QC_ADDRESS_LINE2}</h6>
                        {labType === LabType.WATER_ANALYSIS &&
                            <h6>{common.parseDetailLabel(labels.EMAIL_ADDRESS) + labels.WATER_EMAIL}</h6>
                        }
                    </Col>
                    <Col>
                        <Image src={images.QC_HEALTH_LOGO.src} alt={images.QC_HEALTH_LOGO.alt} className='health__logo' />
                    </Col>
                </Row>
            </Modal.Header>
            <Form spellCheck={false} onSubmit={handleSubmit(_handleSave)}>
                <Modal.Body>
                    {labType === LabType.WATER_ANALYSIS &&
                        <>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_NO}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='labNo'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.labNo}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <fieldset disabled>
                                        <Form.Group>
                                            <Form.Label>{labels.OR_NO}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='officialReceiptNumber'
                                                ref={register}
                                                isInvalid={!!errors.officialReceiptNumber}
                                            />
                                        </Form.Group>
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{labels.ESTABLISHMENT_NAME}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='establishmentName'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.establishmentName}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{labels.ADDRESS}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='address'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.establishmentName}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.OWNER}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='owner'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.owner}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.CONTACT_NUMBER}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='contactNumber'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.contactNumber}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_COLLECTED}</Form.Label>
                                        <Form.Control
                                            type='datetime-local'
                                            name='dateCollected'
                                            max={common.parseDateTimeToString()}
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.dateCollected}
                                        />
                                        {!!errors.dateCollected && (
                                            <Form.Control.Feedback type='invalid'>{errors.dateCollected.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_RECEIVED}</Form.Label>
                                        <Form.Control
                                            type='datetime-local'
                                            name='dateReceived'
                                            max={common.parseDateTimeToString()}
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.dateReceived}
                                        />
                                        {!!errors.dateReceived && (
                                            <Form.Control.Feedback type='invalid'>{errors.dateReceived.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_EXAMINED}</Form.Label>
                                        <Form.Control
                                            type='datetime-local'
                                            name='dateExamined'
                                            max={common.parseDateTimeToString()}
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.dateExamined}
                                        />
                                        {!!errors.dateExamined && (
                                            <Form.Control.Feedback type='invalid'>{errors.dateExamined.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_REPORTED}</Form.Label>
                                        <Form.Control
                                            type='datetime-local'
                                            name='dateReported'
                                            max={common.parseDateTimeToString()}
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.dateReported}
                                        />
                                        {!!errors.dateReported && (
                                            <Form.Control.Feedback type='invalid'>{errors.dateReported.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LOCATION}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='location'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.location}
                                        />
                                        {!!errors.location && (
                                            <Form.Control.Feedback type='invalid'>{errors.location.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.COLLECTED_BY}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='collectedBy'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.collectedBy}
                                        />
                                        {!!errors.collectedBy && (
                                            <Form.Control.Feedback type='invalid'>{errors.collectedBy.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }
                    {labType !== LabType.WATER_ANALYSIS &&
                        <>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.NAME}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='name'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.name}
                                        />
                                        {!!errors.name && (
                                            <Form.Control.Feedback type='invalid'>{errors.name.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_CREATED}</Form.Label>
                                        <Form.Control
                                            type='datetime-local'
                                            name='date'
                                            max={common.parseDateTimeToString()}
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.date}
                                        />
                                        {!!errors.date && (
                                            <Form.Control.Feedback type='invalid'>{errors.date.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.AGE}</Form.Label>
                                                <Form.Control
                                                    type='number'
                                                    name='age'
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.age}
                                                />
                                                {!!errors.age && (
                                                    <Form.Control.Feedback type='invalid'>{errors.age.message}</Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.GENDER}</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='gender'
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.gender}>
                                                    {renderGenderOptions()}
                                                </Form.Control>
                                                {!!errors.gender && (
                                                    <Form.Control.Feedback type='invalid'>{errors.gender.message}</Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.FACILITY}&nbsp;&amp;&nbsp;{labels.LAB_NO}</Form.Label>
                                        {
                                            (labType && labType === LabType.SOCIAL_HYGIENE) &&
                                            <Form.Control
                                                type='text'
                                                name='labNo'
                                                disabled={!isPendingResult}
                                                ref={register({
                                                    required: labels.FIELD_REQUIRED
                                                })}
                                                isInvalid={!!errors.labNo}
                                            />
                                        }
                                        {
                                            (labType && labType === LabType.REGULAR_LABORATORY) &&
                                            <Row>
                                                <Col xs={6}>
                                                    <Form.Control
                                                        type='text'
                                                        name='facility'
                                                        disabled={true}
                                                        ref={register()}
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control
                                                        type='text'
                                                        name='labNo'
                                                        disabled={true}
                                                        ref={register()}
                                                    />
                                                </Col>
                                            </Row>
                                        }

                                        {!!errors.labNo && (
                                            <Form.Control.Feedback type='invalid'>{errors.labNo.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_SUBMIT}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            disabled={true}
                                            value={labForm?.submittedBy?.logDate ? parseDateTimeAPIToDateTime(labForm?.submittedBy?.logDate) : ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_RELEASE}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            disabled={true}
                                            value={labForm?.approvedBy?.logDate ? parseDateTimeAPIToDateTime(labForm?.approvedBy?.logDate) : ''}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }

                    {type === LabFormType.MICRO_WATER && (renderMicroWaterForm())
                    }
                    {type === LabFormType.STOOL && (
                        <>
                            <hr />
                            <Row className='title'>
                                <Col xs={12}>
                                    <span>{labels.FECALYSIS}</span>
                                </Col>
                            </Row>
                            <Container>
                                <Row className='lab-form'>
                                    <Col xs={12} md={6}>
                                        <span className='form-category'>{labels.PHYSICAL}:</span>
                                        <Form.Group as={Row} tag='fieldset'>
                                            <Form.Label as='legend' column xs={4}>
                                                {labels.COLOR}:
                                            </Form.Label>
                                            <Col className='radio-wrap' xs={8}>
                                                {renderColorOptions()}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label as='legend' column xs={4}>
                                                {labels.CONSISTENCY}:
                                            </Form.Label>
                                            <Col className='radio-wrap' xs={8}>
                                                {renderConsistencyOptions()}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='micro-group'>
                                        <span className='form-category'>{labels.MICROSCOPIC}:</span>

                                        <Form.Group as={Row} tag='fieldset'>
                                            <Form.Label column xs={5} md={4}>
                                                {labels.PUS_CELLS}:
                                            </Form.Label>
                                            <Col xs={5} md={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='pusCells'
                                                    min={0}
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.pusCells}
                                                />
                                                {!!errors.pusCells && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.pusCells.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            <Form.Label column xs={2} md={2}>
                                                {labels.HPF}
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column xs={5} md={4}>
                                                {labels.RED_CELLS}:
                                            </Form.Label>
                                            <Col xs={5} md={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='redCells'
                                                    min={0}
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.redCells}
                                                />
                                                {!!errors.redCells && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.redCells.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            <Form.Label column xs={2} md={2}>
                                                {labels.HPF}
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column xs={5} md={4}>
                                                {labels.CYST}:
                                            </Form.Label>
                                            <Col xs={5} md={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='cryst'
                                                    min={0}
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.cryst}
                                                />
                                                {!!errors.cryst && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.cryst.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            <Form.Label column xs={2} md={2}>
                                                {labels.HPF}
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column xs={5} md={4}>
                                                {labels.TROPHOZIOTE}:
                                            </Form.Label>
                                            <Col xs={5} md={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='trophoziote'
                                                    min={0}
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.trophoziote}
                                                />
                                                {!!errors.trophoziote && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.trophoziote.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            <Form.Label column xs={2} md={2}>
                                                {labels.HPF}
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column xs={5} md={4}>
                                                {labels.OVA_PARASITE}:
                                            </Form.Label>
                                            <Col xs={5} md={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='ovaParasite'
                                                    min={0}
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.ovaParasite}
                                                />
                                                {!!errors.ovaParasite && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.ovaParasite.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            <Form.Label column xs={2} md={2}>
                                                {labels.HPF}
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column xs={5} md={4}>
                                                {labels.BACTERIA}:
                                            </Form.Label>
                                            <Col xs={5} md={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='bacteria'
                                                    min={0}
                                                    disabled={!isPendingResult}
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.bacteria}
                                                />
                                                {!!errors.bacteria && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.bacteria.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            <Form.Label column xs={2} md={2}>
                                                {labels.HPF}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )}

                    {type === LabFormType.SPUTUM && (
                        <>
                            <hr />
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className='label-title'>{labels.SPUTUM}</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={3}
                                            name='sputumAFB'
                                            disabled={!isPendingResult}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.sputumAFB}
                                        />
                                        {!!errors.sputumAFB && (
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.sputumAFB.message}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}

                    {type === LabFormType.VD && (
                        <>
                            <hr />
                            <Row className='row-spacing'>
                                <Col xs={4}>
                                    <span className='label-title'>METHOD USED</span>
                                </Col>
                                <Col xs={8}>
                                    <span className='label-title'>RESULT</span>
                                </Col>
                            </Row>
                            <Row className='row-spacing'>
                                <Col xs={2}>GRAM STAIN:</Col>
                                <Col xs={2}>Vaginal</Col>
                                <Col xs={8}>
                                    <Form.Control
                                        type='text'
                                        name='gramVaginal'
                                        disabled={!isPendingResult}
                                        ref={register({
                                            required: labels.FIELD_REQUIRED
                                        })}
                                        isInvalid={!!errors.gramVaginal}
                                    />
                                    {!!errors.gramVaginal && (
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.gramVaginal.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                            </Row>
                            <Row className='row-spacing'>
                                <Col xs={{ span: 2, offset: 2 }}>Penile</Col>
                                <Col xs={8}>
                                    <Form.Control
                                        type='text'
                                        name='gramPenile'
                                        disabled={!isPendingResult}
                                        ref={register({
                                            required: labels.FIELD_REQUIRED
                                        })}
                                        isInvalid={!!errors.gramPenile}
                                    />
                                    {!!errors.gramPenile && (
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.gramPenile.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                            </Row>
                            <Row className='row-spacing'>
                                <Col xs={{ span: 2, offset: 2 }}>Throat</Col>
                                <Col xs={8}>
                                    <Form.Control
                                        type='text'
                                        name='gramThroat'
                                        disabled={!isPendingResult}
                                        ref={register({
                                            required: labels.FIELD_REQUIRED
                                        })}
                                        isInvalid={!!errors.gramThroat}
                                    />
                                    {!!errors.gramThroat && (
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.gramThroat.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                            </Row>
                            <Row className='row-spacing'>
                                <Col xs={{ span: 2, offset: 2 }}>Rectal</Col>
                                <Col xs={8}>
                                    <Form.Control
                                        type='text'
                                        name='gramRectal'
                                        disabled={!isPendingResult}
                                        ref={register({
                                            required: labels.FIELD_REQUIRED
                                        })}
                                        isInvalid={!!errors.gramRectal}
                                    />
                                    {!!errors.gramRectal && (
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.gramRectal.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                            </Row>
                            <Row className='row-spacing'>
                                <Col xs={4}>WET MOUNT</Col>
                                <Col xs={8}>
                                    <select
                                        defaultValue='Negative'
                                        name='wetMount'
                                        ref={register()}
                                        disabled={!isPendingResult}
                                    >
                                        <option value='Negative'>Negative</option>
                                        <option value='Positive'>Positive</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row className='row-spacing-2'>
                                <Col xs={4}>KOH</Col>
                                <Col xs={8}>
                                    <select
                                        defaultValue='Negative'
                                        name='koh'
                                        ref={register()}
                                        disabled={!isPendingResult}
                                    >
                                        <option value='Negative'>Negative</option>
                                        <option value='Positive'>Positive</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <span>REMARKS: Return Visit:</span>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control
                                        type='text'
                                        name='vdRemarks'
                                        disabled={!isPendingResult}
                                        ref={register()}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    <hr />

                    {labType !== LabType.WATER_ANALYSIS &&
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className='label-title'>{labels.ADD_FINDINGS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={3}
                                        name='addFindings'
                                        disabled={!isPendingResult}
                                        ref={register()}
                                        isInvalid={!!errors.addFindings}
                                    />
                                    {!!errors.addFindings && (
                                        <Form.Control.Feedback type='invalid'>{errors.addFindings.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    }

                    <hr />

                    <Row className='rep-row'>
                        {renderMedTechDetails()}
                        {renderPathoDetails()}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {!viewOnly && isPendingResult &&
                        common.allowedLab(user.authorities) && (
                            <Button variant='primary' type='submit'>
                                {labForm && labForm.status !== LabFormStatus.PENDING ? labels.UPDATE_RESULT : labels.UPLOAD_RESULT}
                            </Button>
                        )}
                    {status === Status.PENDING_APPROVAL &&
                        common.allowedLab(user.authorities) && (
                            <>
                                <Button
                                    variant='outline-secondary'
                                    disabled={labForm?.status === LabFormStatus.REJECTED}
                                    onClick={_handleReject}>
                                    {labels.REJECT}
                                </Button>
                                <Button
                                    variant='primary'
                                    disabled={labForm?.status === LabFormStatus.APPROVED}
                                    onClick={_handleApprove}>
                                    {labels.APPROVE}
                                </Button>
                            </>
                        )}
                </Modal.Footer>
            </Form>
        </Modal >
    );
};

export default LabFormModal;
