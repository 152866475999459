import React from 'react';
import { Pagination } from 'react-bootstrap';

interface Props {
    currentPage: number;
    maxPage: number;
    _handlePageChange: (page: number) => void;
}

const Header: React.FunctionComponent<Props> = (props) => {
    const { currentPage, maxPage, _handlePageChange } = props;

    const renderPages = (): JSX.Element[] => {
        let items: JSX.Element[] = [];

        //Show only max of 5 pagination
        for (let i = 1; i <= (maxPage < 5 ? maxPage : 5); i++) {
            //Adjust pagination view based on current page
            let number;
            if (currentPage < 3 || maxPage < 5) {
                number = i;
            } else if (currentPage + 4 >= maxPage) {
                number = maxPage - (5 - i);
            } else {
                number = currentPage - (3 - i);
            }

            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => _handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Pagination size='sm'>
            {currentPage > 2 && <Pagination.First onClick={() => _handlePageChange(1)} />}
            {currentPage > 1 && <Pagination.Prev onClick={() => _handlePageChange(currentPage - 1)} />}
            {renderPages()}
            {currentPage < maxPage && <Pagination.Next onClick={() => _handlePageChange(currentPage + 1)} />}
            {currentPage < maxPage - 1 && <Pagination.Last onClick={() => _handlePageChange(maxPage)} />}
        </Pagination>
    );
};

export default Header;
