import store from './../../stores/store';
import { openLoader, closeLoader, openDialog, closeDialog as closeDialogAction } from './../../actions/modal.action';
import { DialogResponse, DialogInputOption } from './../../models';
import { labels } from './../../constants';
import { common } from '.';

//PRELOADER
/////////////////////////////////////////////////////////////////////////
const displayPreloader = (message?: string): void => {
    store.dispatch(openLoader(message));
};

const closePreloader = (): void => {
    store.dispatch(closeLoader());
};

//DIALOG
/////////////////////////////////////////////////////////////////////////
const displayDialog = (
    body: string | JSX.Element,
    header?: string | JSX.Element,
    positiveText: string = labels.OK,
    negativeText?: string,
    positiveHandler?: any,
    negativeHandler?: any,
    secondaryText?: string,
    secondaryHandler?: any,
    inputs?: DialogInputOption[],
    negativeModal: boolean = false
): void => {
    store.dispatch(openDialog(body, header, positiveText, negativeText, secondaryText, inputs, negativeModal));

    if (positiveHandler || negativeHandler || secondaryHandler) {
        const unsubscribe = store.subscribe(() => {
            let response = store.getState().modal.dialog.response;
            let values = store.getState().modal.dialog.values;
            if (response) {
                unsubscribe();
                if (response === DialogResponse.POSITIVE) {
                    if (positiveHandler) positiveHandler(values);
                } else if (response === DialogResponse.NEGATIVE) {
                    if (negativeHandler) negativeHandler();
                } else if (response === DialogResponse.SECONDARY) {
                    if (secondaryHandler) secondaryHandler();
                }
            }
        });
    }
};

const closeDialog = (response: DialogResponse = DialogResponse.NEGATIVE, values?: Object) => {
    store.dispatch(closeDialogAction(response, values));
};

const displaySuccess = (
    body: string | JSX.Element = labels.dialog.message.SUCCESS,
    header: string | JSX.Element = labels.dialog.header.SUCCESS,
    positiveHandler?: any,
    positiveText?: string
) => {
    displayDialog(body, header, positiveText, undefined, positiveHandler);
};

const displaySuccessOptions = (
    body: string | JSX.Element = labels.dialog.message.SUCCESS,
    header: string | JSX.Element = labels.dialog.header.SUCCESS,
    positiveHandler?: any,
    positiveText?: string,
    secondaryHandler?: any,
    secondaryText?: string
) => {
    displayDialog(body, header, positiveText, undefined, positiveHandler, undefined, secondaryText, secondaryHandler);
};

const displayError = (
    body: string | JSX.Element = labels.dialog.message.FAILED,
    header: string | JSX.Element = labels.dialog.header.SORRY,
    positiveHandler?: any,
    positiveText?: string
) => {
    displayDialog(body, header, positiveText, undefined, positiveHandler);
};

const displayConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    header: string | JSX.Element = labels.dialog.header.CONFIRM,
    negativeHandler?: any,
    positiveText?: string,
    negativeText: string = labels.CANCEL,
    negativeModal?: boolean
) => {
    displayDialog(
        body,
        header,
        positiveText,
        negativeText,
        positiveHandler,
        negativeHandler,
        undefined,
        undefined,
        undefined,
        negativeModal
    );
};

const displayInputConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    inputs: DialogInputOption[],
    positiveText?: string,
    positiveHandler?: any,
    header: string | JSX.Element = labels.dialog.header.CONFIRM,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL,
    negativeModal?: boolean
) => {
    displayDialog(
        body,
        header,
        positiveText,
        negativeText,
        positiveHandler,
        negativeHandler,
        undefined,
        undefined,
        inputs,
        negativeModal
    );
};

const displayApproveConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    positiveText: string = labels.APPROVE,
    header: string | JSX.Element = labels.APPROVE,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL
) => {
    let inputs = [
        {
            label: common.parseOptional(labels.REMARKS),
            name: 'remarks',
            as: 'textarea',
            placeholder: labels.OPTIONAL
        }
    ];

    displayInputConfirmation(body, inputs, positiveText, positiveHandler, header, negativeHandler, negativeText);
};

const displayReqUpdConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    positiveText: string = labels.REQUIRES_UPDATE,
    header: string | JSX.Element = labels.REQUIRES_UPDATE,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL
) => {
    let inputs = [
        {
            label: common.parseRequired(labels.REMARKS),
            name: 'remarks',
            as: 'textarea',
            reg: {
                required: labels.FIELD_REQUIRED
            }
        }
    ];

    displayInputConfirmation(body, inputs, positiveText, positiveHandler, header, negativeHandler, negativeText, true);
};

const displayOnHoldConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    positiveText: string = labels.ON_HOLD,
    header: string | JSX.Element = labels.ON_HOLD,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL
) => {
    let inputs = [
        {
            label: labels.REMARKS,
            name: 'remarks',
            as: 'textarea',
            placeholder: labels.OPTIONAL
        }
    ];

    displayInputConfirmation(body, inputs, positiveText, positiveHandler, header, negativeHandler, negativeText, true);
};

const displayRejConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    positiveText: string = labels.REJECT,
    header: string | JSX.Element = labels.REJECT,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL
) => {
    let inputs = [
        {
            label: labels.REMARKS,
            name: 'remarks',
            as: 'textarea',
            reg: {
                required: labels.FIELD_REQUIRED
            }
        }
    ];

    displayInputConfirmation(body, inputs, positiveText, positiveHandler, header, negativeHandler, negativeText, true);
};

const displayPickupConfirmation = (
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    positiveText: string = labels.APPROVE,
    header: string | JSX.Element = labels.APPROVE,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL
) => {
    let inputs = [
        {
            label: labels.PICKUP_DATE,
            name: 'pickUpDate',
            type: 'date',
            min: common.parseDateToString(),
            reg: {
                required: labels.FIELD_REQUIRED,
                validate: (value) => (common.isWeekend(value) ? labels.NOT_WEEKEND : undefined)
            }
        },
        {
            label: labels.REMARKS,
            name: 'remarks',
            as: 'textarea'
        }
    ];

    displayInputConfirmation(body, inputs, positiveText, positiveHandler, header, negativeHandler, negativeText);
};

const displayReleaseConfirmation = (
    initDate: string,
    body: string | JSX.Element = labels.dialog.message.SURE,
    positiveHandler?: any,
    positiveText: string = labels.RELEASE_APP,
    header: string | JSX.Element = labels.RELEASE_APP,
    negativeHandler?: any,
    negativeText: string = labels.CANCEL
) => {
    let inputs = [
        {
            label: labels.PICKUP_DATE,
            name: 'pickUpDate',
            type: 'date',
            max: common.parseDateToString(),
            defaultValue: initDate,
            reg: {
                required: labels.FIELD_REQUIRED
            }
        }
    ];

    displayInputConfirmation(body, inputs, positiveText, positiveHandler, header, negativeHandler, negativeText);
};

export default {
    displayPreloader,
    closePreloader,
    displayDialog,
    closeDialog,
    displaySuccess,
    displaySuccessOptions,
    displayError,
    displayConfirmation,
    displayInputConfirmation,
    displayApproveConfirmation,
    displayReqUpdConfirmation,
    displayOnHoldConfirmation,
    displayRejConfirmation,
    displayPickupConfirmation,
    displayReleaseConfirmation
};
