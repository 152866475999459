import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

import { modal } from './../../../services';
import { DialogResponse } from './../../../models';

import './styles.scss';

type Props = {};

const Dialog: React.FunctionComponent<Props> = () => {
    const dialog = useSelector((state: any) => state.modal.dialog);

    const { handleSubmit, register, errors } = useForm({
        mode: 'onBlur'
    });

    const renderForm = () => {
        let forms: JSX.Element[] = [];

        if (dialog.inputs && dialog.inputs.length > 0) {
            dialog.inputs.forEach((input, index) => {
                forms.push(
                    <Form.Group key={index} className={index === 0 ? 'initial' : ''}>
                        <Form.Label>{input.label}</Form.Label>
                        {input.as === 'select' && input.options && input.options.length > 0 && (
                            <Form.Control
                                as={input.as ? input.as : undefined}
                                type={input.type ? input.type : undefined}
                                name={input.name}
                                min={input.min}
                                max={input.max}
                                defaultValue={input.defaultValue}
                                ref={register(input.reg)}
                                isInvalid={!!errors[input.name]}>
                                {renderOptions(input.options)}
                            </Form.Control>
                        )}
                        {input.as !== 'select' && (
                            <Form.Control
                                as={input.as ? input.as : undefined}
                                type={input.type ? input.type : undefined}
                                name={input.name}
                                min={input.min}
                                max={input.max}
                                placeholder={input.placeholder}
                                defaultValue={input.defaultValue}
                                ref={register(input.reg)}
                                isInvalid={!!errors[input.name]}
                            />
                        )}
                        {!!errors[input.name] && (
                            <Form.Control.Feedback type='invalid'>{errors[input.name].message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                );
            });
        }

        return forms;
    };

    const renderOptions = (selectOptions: any[]) => {
        let options: JSX.Element[] = [];

        selectOptions.forEach((option, index) => {
            options.push(
                <option key={index} value={option.value} hidden={option.hidden}>
                    {option.label}
                </option>
            );
        });

        return options;
    };

    const _onSubmit = (values) => {
        modal.closeDialog(DialogResponse.POSITIVE, values);
    };

    return (
        <Modal
            show={dialog.show}
            onHide={() => modal.closeDialog()}
            centered
            dialogClassName={'Dialog' + (dialog.negativeModal ? ' negative' : '')}>
            {dialog.header && (
                <Modal.Header closeButton>
                    <Modal.Title>{dialog.header}</Modal.Title>
                </Modal.Header>
            )}
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <div className='message' dangerouslySetInnerHTML={{ __html: dialog.body }}></div>
                    {dialog.inputs && dialog.inputs.length > 0 && renderForm()}
                </Modal.Body>
                <Modal.Footer>
                    {dialog.negativeText && (
                        <Button block variant='outline-secondary' onClick={() => modal.closeDialog()}>
                            {dialog.negativeText}
                        </Button>
                    )}
                    {dialog.secondaryText && (
                        <Button block variant='outline-primary' onClick={() => modal.closeDialog(DialogResponse.SECONDARY)}>
                            {dialog.secondaryText}
                        </Button>
                    )}
                    {dialog.positiveText && (
                        <Button block variant='primary' type='submit'>
                            {dialog.positiveText}
                        </Button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default Dialog;
