import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from './../reducers';
import { env } from './../services';

const middlewares: any = [thunk];

if (env.isDev()) {
    middlewares.push(createLogger());
}

const middleware = applyMiddleware(...middlewares);

const store = createStore(reducer, middleware);

export default store;
