import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Table, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { usePage } from 'react-page-states';
import { find } from 'lodash';

import { labels, config, routes } from '../../../constants';
import { Application, Status, Permit, PermitType, Payment, SortBy, ApplicationFilter } from '../../../models';
import { ApplicationService, common } from '../../../services';
import Paginator from '../Paginator';
import PaymentModal from '../PaymentModal';
// import BulkTooltip from '../BulkTooltip';
import SearchFilter, { SearchRef } from '../SearchFilter';
import Sorter from '../Sorter';

import './styles.scss';

interface Props {
    permitType: PermitType;
    status?: Status;
}

const ApplicationTable: React.FunctionComponent<Props> = (props) => {
    const { permitType, status } = props;
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const { pageNo, pageSize, total, ...page } = usePage();
    const navigate = useNavigate();
    const searchRef = useRef<SearchRef>(null);

    const [isLoading, setLoading] = useState(true);
    const [applicationList, setApplications] = useState<Application[]>([]);
    const [paymentData, setPaymentData] = useState<Payment | undefined>();
    const [sortBy, setSortBy] = useState(SortBy.UPDATED_DATE);
    const [sortAsc, setSortAsc] = useState(!(status === Status.COMPLETED || status === Status.REJECTED));
    const hasBulk = applicationList.filter((app) => app.bulkApplicationId).length > 0;

    const searchColumns: string[] = ['applicationId'];
    if (permitType === PermitType.IHC) {
        searchColumns.push('lastName');
        searchColumns.push('firstName');
    } else if (permitType === PermitType.SP) {
        searchColumns.push('establishmentName');
        searchColumns.push('mayorPermitNo');
        searchColumns.push('establishmentBarangay');
        searchColumns.push('district');
    }

    const loadData = () => {
        setLoading(true);

        if (permitList.length > 0) {
            let permitId = find(permitList, { type: permitType }).permitId;
            let method: any;

            let filter: ApplicationFilter = common.createFilter(searchRef?.current?.getValues());

            if (status) {
                method = ApplicationService.getApplicationByStatus(pageSize, pageNo, permitId, status, filter, sortBy, sortAsc);
            } else {
                method = ApplicationService.getApplications(pageSize, pageNo, permitId, filter, sortBy, sortAsc);
            }

            method
                .then((response: any) => {
                    let data = response.body.data;
                    setLoading(false);
                    page.setTotal(data.totalCount);
                    setApplications(data.details);
                })
                .catch((err: any) => {
                    setLoading(false);
                    console.error(err);
                });
        }
    };

    useEffect(() => {
        const refreshTimer = setInterval(() => {
            loadData();
        }, config.REFRESH_SECONDS * 1000);

        return () => clearInterval(refreshTimer);
    });

    useEffect(loadData, [pageSize, pageNo, permitList, sortBy, sortAsc]);

    const _handleLimitChange = (limit: number) => {
        page.setPageSize(limit);
        page.setPageNo(1);
    };

    const _handleFormChange = () => {
        page.setPageNo(1);
        loadData();
    };

    const _handlePaymentModalClose = (hasUpdate: boolean) => {
        setPaymentData(undefined);

        if (hasUpdate) loadData();
    };

    const renderName = () => {
        if (permitType === PermitType.IHC) {
            return (
                <>
                    {labels.APPLICANT}
                    <Sorter
                        sortBy={SortBy.LAST_NAME}
                        currentSortBy={sortBy}
                        currentSortAsc={sortAsc}
                        setSortBy={setSortBy}
                        setSortAsc={setSortAsc}
                    />
                </>
            );
        } else {
            return (
                <>
                    {labels.ESTABLISHMENT}
                    <Sorter
                        sortBy={SortBy.ESTABLISHMENT}
                        currentSortBy={sortBy}
                        currentSortAsc={sortAsc}
                        setSortBy={setSortBy}
                        setSortAsc={setSortAsc}
                    />
                </>
            );
        }
    };

    const renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];

        applicationList.forEach((application, index) => {
            let formData: any = application.formData;
            let payment = application.paymentDetailResponseDto?.[0];

            if (status === Status.PAYMENT_SUBMITTED || status === Status.PAYMENT_CREATED) {
                rows.push(
                    <tr key={index}>
                        <td>{common.parseNullable(application.applicationId)}</td>
                        {hasBulk && <td>{common.parseNullable(application.bulkApplicationId)}</td>}
                        <td>{permitType === PermitType.IHC ? common.parseFullname(formData) : formData.establishmentName}</td>
                        <td>{common.parseNullable(payment?.officialReceiptNumber)}</td>
                        <td>{common.parseCurrency(payment?.total)}</td>
                        <td>{payment?.referenceNumber}</td>
                        {status === Status.PAYMENT_SUBMITTED && (
                            <>
                                <td>{payment?.paymentDate}</td>
                                <td className='action-group'>
                                    <Button variant='primary' onClick={() => setPaymentData(payment)}>
                                        {labels.VIEW_RECEIPT}
                                    </Button>
                                </td>
                            </>
                        )}
                    </tr>
                );
            } else {
                if (permitType === PermitType.IHC) {
                    // const isBulk = application.bulkApplicationId ? true : false;

                    rows.push(
                        <tr
                            key={index}
                            className='clickable'
                            onClick={() => {
                                navigate(
                                    routes.APPLICATION.path
                                        .replace(':type', permitType)
                                        .replace(':appId', application.applicationId.toString())
                                );
                            }}>
                            <td>{application.applicationId}</td>
                            {hasBulk && <td>{common.parseNullable(application.bulkApplicationId)}</td>}
                            <td>
                                {common.parseFullname(formData)}
                                {/* {isBulk && <BulkTooltip />} */}
                            </td>
                            <td>{formData.industry}</td>
                            {!status && <td>{application.status}</td>}
                            {status === Status.COMPLETED && (
                                <td>{common.parseNullable(application.certificateNumber)}</td>
                            )}
                            <td>{application.updatedDate}</td>
                            <td>{application.createdDate}</td>
                        </tr>
                    );
                } else {
                    rows.push(
                        <tr
                            key={index}
                            className='clickable'
                            onClick={() => {
                                navigate(
                                    routes.APPLICATION.path
                                        .replace(':type', permitType)
                                        .replace(':appId', application.applicationId.toString())
                                );
                            }}>
                            <td>{application.applicationId}</td>
                            {hasBulk && <td>{common.parseNullable(application.bulkApplicationId)}</td>}
                            <td>{formData.establishmentName}</td>
                            <td>{formData.industry}</td>
                            {permitType === PermitType.SP && (
                                <td>{formData.mayorPermitNo}</td>
                            )}
                            {!status && <td>{application.status}</td>}
                            {status === Status.COMPLETED && (
                                <td>{common.parseNullable(application.certificateNumber)}</td>
                            )}
                            <td>{application.updatedDate}</td>
                            <td>{application.createdDate}</td>
                        </tr>
                    );
                }
            }
        });

        return rows;
    };

    return (
        <div className='ApplicationTable'>
            <SearchFilter ref={searchRef} columns={searchColumns} onSubmit={_handleFormChange} />
            <Card className='mt-3'>
                <Card.Body className='px-3 py-4'>
                    <Row>
                        <Col>
                            <span>Show </span>
                            <Form.Group className='count-select'>
                                <Form.Control
                                    as='select'
                                    size='sm'
                                    value={pageSize}
                                    onChange={(e: any) => _handleLimitChange(e.target.value)}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isLoading && (
                                <div className='center-content'>
                                    <Spinner animation='border' variant='primary' />
                                </div>
                            )}
                            {!isLoading && total === 0 && (
                                <div className='center-content'>
                                    <span>No data available</span>
                                </div>
                            )}
                            {!isLoading && total > 0 && (
                                <Table responsive striped bordered hover>
                                    <thead>
                                        {(status === Status.PAYMENT_SUBMITTED || status === Status.PAYMENT_CREATED) && (
                                            <tr>
                                                <th className='fit'>{labels.ID}</th>
                                                {hasBulk && <th className='fit'>{labels.BULK_ID}</th>}
                                                <th>{renderName()}</th>
                                                <th>{labels.OR_NO}</th>
                                                <th>{labels.AMOUNT}</th>
                                                <th>{labels.REFERENCE_NO}</th>
                                                {status === Status.PAYMENT_SUBMITTED && (
                                                    <>
                                                        <th>{labels.PAYMENT_DATE}</th>
                                                        <th>{labels.RECEIPT}</th>
                                                    </>
                                                )}
                                            </tr>
                                        )}
                                        {!(status === Status.PAYMENT_SUBMITTED || status === Status.PAYMENT_CREATED) && (
                                            <tr>
                                                <th className='fit'>{labels.ID}</th>
                                                {hasBulk && <th className='fit'>{labels.BULK_ID}</th>}
                                                <th>{renderName()}</th>
                                                <th>{labels.INDUSTRY}</th>
                                                {permitType === PermitType.SP && (
                                                    <th>{labels.MAYOR_PERMIT}</th>
                                                )}
                                                {!status && <th>{labels.STATUS}</th>}
                                                {status === Status.COMPLETED && (
                                                    <th>{labels.CERTIFICATE_NO}</th>
                                                )}
                                                <th>
                                                    {labels.LAST_UPDATE}
                                                    <Sorter
                                                        sortBy={SortBy.UPDATED_DATE}
                                                        currentSortBy={sortBy}
                                                        currentSortAsc={sortAsc}
                                                        setSortBy={setSortBy}
                                                        setSortAsc={setSortAsc}
                                                    />
                                                </th>
                                                <th>
                                                    {labels.APPLICATION_DATE}
                                                    <Sorter
                                                        sortBy={SortBy.CREATED_DATE}
                                                        currentSortBy={sortBy}
                                                        currentSortAsc={sortAsc}
                                                        setSortBy={setSortBy}
                                                        setSortAsc={setSortAsc}
                                                    />
                                                </th>
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody>{renderRows()}</tbody>
                                </Table>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {total > 0 && (
                                <span>
                                    Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                    {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                </span>
                            )}
                        </Col>
                        <Col md='auto'>
                            <Paginator
                                currentPage={pageNo}
                                maxPage={page.maxPage}
                                _handlePageChange={(pageNumber) => page.setPageNo(pageNumber)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {paymentData && (
                <PaymentModal
                    permitType={permitType}
                    payment={paymentData}
                    onClose={(updated) => _handlePaymentModalClose(updated)}
                />
            )}
        </div>
    );
};

export default ApplicationTable;
