import 'whatwg-fetch';

import store from './../../stores/store';
import { modal } from './';
import { httpCodes, labels } from './../../constants';
import { logout } from './../../actions/user.action';

const JSON_DATA = 'application/json';

export const defaultHeaders = (withJsonContent: boolean = true): Headers => {
    const { user } = store.getState();

    let headers = new Headers({
        Accept: JSON_DATA
    });

    if (user.token) headers.append('Authorization', 'Bearer ' + user.token);
    if (withJsonContent) headers.append('Content-Type', JSON_DATA);

    return headers;
};

export const defaultExternalHeaders = (): Headers => {
    let headers;

    headers = new Headers({
        Accept: JSON_DATA,
        'Content-Type': JSON_DATA
    });

    return headers;
};

class APIUtilClass {
    _checkStatus(response: any): any {
        if (response.ok) {
            return response;
        } else {
            let error = new ResponseError(response.statusText, response);
            throw error;
        }
    }

    async _handleError(error: ResponseError, handleBadRequest: boolean = true, handleError: boolean = true): Promise<Error> {
        let response = error.response;
        let responseBody;

        if (response) {
            try {
                await response.json().then((responseJSON) => {
                    responseBody = responseJSON;
                });
            } catch {
                console.error('Response body is null');
            }

            if (response.status === httpCodes.UNAUTHORIZED || response.status === httpCodes.FORBIDDEN) {
                if (handleBadRequest) {
                    store.dispatch(logout());
                    modal.displayError(labels.dialog.message.UNAUTHORIZED, labels.dialog.header.SECURITY);
                }
            } else if (response.status === httpCodes.BAD_REQUEST) {
                if (handleBadRequest) {
                    let errorMessage = responseBody?.title
                        ? responseBody.title
                        : responseBody?.message
                        ? responseBody.message
                        : undefined;

                    modal.displayError(errorMessage);
                }
            } else if (handleError) {
                modal.displayError();
            }

            return Promise.reject(responseBody ? responseBody : response);
        } else {
            if (handleError) modal.displayError(labels.dialog.message.OFFLINE, labels.dialog.header.FAILED);

            return Promise.reject(error);
        }
    }

    async _parseResponse(response: any): Promise<any> {
        const headers = [...response.headers].reduce((acc, header) => {
            return { ...acc, [header[0]]: header[1] };
        }, {});

        const contentType = headers['content-type'];

        const body = await (contentType && contentType.includes('json') ? response.json() : response.text());

        return (response = {
            headers,
            status: response.status,
            body
        });
    }

    _fetch(
        url: string,
        options: any,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean
    ) {
        if (preLoaderMessage) modal.displayPreloader(typeof preLoaderMessage === 'string' ? preLoaderMessage : undefined);

        try {
            let apiUrl = encodeURI(url);

            return fetch(apiUrl, options)
                .then(this._checkStatus)
                .then(this._parseResponse)
                .then((response) => {
                    return Promise.resolve(response);
                })
                .catch((error) => this._handleError(error, handleBadRequest, handleError))
                .finally(() => {
                    if (preLoaderMessage) {
                        modal.closePreloader();
                    }
                });
        } catch (e) {
            return this._handleError(e as ResponseError, handleBadRequest, handleError);
        }
    }

    _fetchExternal(
        url: string,
        options: any,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean
    ) {
        if (preLoaderMessage) {
            modal.displayPreloader(typeof preLoaderMessage === 'string' ? preLoaderMessage : undefined);
        }

        try {
            return fetch(encodeURI(url), options)
                .then(this._checkStatus)
                .then(this._parseResponse)
                .then((response) => Promise.resolve(response))
                .catch((error) => this._handleError(error, handleBadRequest, handleError))
                .finally(() => {
                    if (preLoaderMessage) {
                        modal.closePreloader();
                    }
                });
        } catch (e) {
            return this._handleError(e as ResponseError, handleBadRequest, handleError);
        }
    }

    get(
        url: string,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultHeaders()
    ): any {
        const options = new Options('GET', headers);

        return this._fetch(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    delete(
        url: string,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultHeaders()
    ): any {
        const options = new Options('DELETE', headers);

        return this._fetch(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    post(
        url: string,
        data: any,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultHeaders()
    ): any {
        const options = new Options('POST', headers, JSON.stringify(data));

        return this._fetch(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    postMultiForm(
        url: string,
        data: any,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultHeaders(false)
    ): Promise<any> {
        const formData = this.convertToForm(data);
        const request = { method: 'POST', headers, body: formData };

        return this._fetch(url, request, preLoaderMessage, handleBadRequest, handleError);
    }

    put(
        url: string,
        data: any,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultHeaders()
    ): any {
        const options = new Options('PUT', headers, JSON.stringify(data));

        return this._fetch(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    getExternal(
        url: string,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultExternalHeaders()
    ): any {
        const options = new Options('GET', headers);

        return this._fetchExternal(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    getExternalAuthorized(
        url: string,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultHeaders()
    ): any {
        const options = new Options('GET', headers);

        return this._fetchExternal(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    postExternal(
        url: string,
        data: any,
        preLoaderMessage: boolean | string = true,
        handleBadRequest?: boolean,
        handleError?: boolean,
        headers: Headers = defaultExternalHeaders()
    ): any {
        const options = new Options('POST', headers, JSON.stringify(data));

        return this._fetchExternal(url, options, preLoaderMessage, handleBadRequest, handleError);
    }

    convertToForm(data: any): FormData {
        let formData = new FormData();

        for (const key in data) {
            formData.append(key, data[key]);
        }

        return formData;
    }
}

const APIUtil = new APIUtilClass();

export default APIUtil;

export class Options {
    constructor(public method: string, public headers: Headers, public body?: string) {}
}

class ExtendableError extends Error {
    constructor(message: string) {
        super();
        this.message = message;
        this.stack = new Error().stack;
        this.name = this.constructor.name;
    }
}

export class ResponseError extends ExtendableError {
    constructor(message: string, public response?: any) {
        super(message);
    }
}
