import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Form, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { labels, httpCodes } from './../../constants';
import Header from './../components/Header';
import { login } from '../../actions/user.action';

import './styles.scss';

type Props = {};

type FormValues = {
    username: string;
    password: string;
    rememberMe: boolean;
};

const Login: React.FunctionComponent<Props> = () => {
    const [isHidden, setHidden] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const dispatch: any = useDispatch();

    const { handleSubmit, register, errors } = useForm<FormValues>({
        mode: 'onBlur'
    });

    const _onSubmit = (values: FormValues) => {
        dispatch(login(values))
            .then(() => {
                setError('');
            })
            .catch((error: any) => {
                if (error.status === httpCodes.UNAUTHORIZED) {
                    if (error.detail.includes('Bad credentials')) {
                        setError(labels.INVALID_AUTH);
                    } else if (error.detail.includes('not activated')) {
                        setError('Account disabled\nPlease contact your system admin');
                    } else {
                        setError(error.detail);
                    }
                }
            });
    };

    return (
        <div className='Login'>
            <Header />
            <div className='login-body'>
                <div className='login-form shadow border rounded'>
                    {error && <div className='error-msg' dangerouslySetInnerHTML={{ __html: error }}></div>}
                    <Form onSubmit={handleSubmit(_onSubmit)}>
                        <Form.Group>
                            <Form.Label>{labels.USERNAME}</Form.Label>
                            <Form.Control
                                type='text'
                                name='username'
                                ref={register({
                                    required: labels.USERNAME_REQUIRED
                                })}
                                isInvalid={!!errors.username}
                            />
                            {!!errors.username && (
                                <Form.Control.Feedback type='invalid'>{errors.username.message}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{labels.PASSWORD}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type={isHidden ? 'password' : 'text'}
                                    name='password'
                                    ref={register({
                                        required: labels.PASSWORD_REQUIRED
                                    })}
                                    isInvalid={!!errors.password}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text className='clickable' onClick={() => setHidden(!isHidden)}>
                                        {isHidden ? <FaEyeSlash /> : <FaEye />}
                                    </InputGroup.Text>
                                </InputGroup.Append>
                                {!!errors.password && (
                                    <Form.Control.Feedback type='invalid'>{errors.password.message}</Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type='checkbox' name='rememberMe' label={labels.REMEMBER_ME} ref={register()} />
                        </Form.Group>
                        <div className='button-row'>
                            <Button block type='submit'>
                                {labels.LOGIN}
                            </Button>
                            <Form.Text muted>{labels.caveats.PROBLEM}</Form.Text>
                        </div>
                    </Form>
                </div>
                <div className='security-caveat'>
                    <span>{labels.caveats.SECURITY}</span>
                </div>
            </div>
        </div>
    );
};

export default Login;
