enum ReportStatus {
    TOTAL = 'TOTAL',
    DRAFT = 'DRAFT',
    PENDING_SPECIMEN = 'PENDING_SPECIMEN',
    FOR_REVIEW = 'FOR_REVIEW',
    PENDING_RESULT = 'PENDING_RESULT',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    PAYMENT_CREATED = 'PAYMENT_CREATED',
    UPDATE_REQUIRED = 'UPDATE_REQUIRED',
    COMPLETED = 'COMPLETED',
    HIV_SEMINAR = 'HIV_SEMINAR',
    RELEASED = 'RELEASED',
    PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED',
    VALIDATED = 'VALIDATED',
    HEAD_APPROVAL = 'HEAD_APPROVAL'
}

export default ReportStatus;
