import React from 'react';

import { labels } from '../../constants';
import PageHeader from '../components/PageHeader';
import PageBody from '../components/PageBody';
import AccountDetails from '../components/AccountDetails';

import './styles.scss';

type Props = {
    createMode?: boolean;
};

const User: React.FunctionComponent<Props> = () => {
    return (
        <div className='User'>
            <PageHeader title={labels.ACCOUNT_DETAILS} allowBack={true} />
            <PageBody>
                <AccountDetails />
            </PageBody>
        </div>
    );
};

export default User;
