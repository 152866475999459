import { defaultExternalHeaders } from '../utils/api.util';
import { api, env } from './../utils';

class EPaymentServiceClass {
    private serviceURL: string;
    private legacyApiUrl: string = env.legacyApiURL();

    constructor() {
        this.serviceURL = this.legacyApiUrl + '/epayment';
    }

    getEPaymentData(paymentReferenceNo: String) {
        return api.post(this.serviceURL + '/epayment_request/transaction_data_via_epaymentrefno', { epayment_reference_number: paymentReferenceNo }, false, true, true, defaultExternalHeaders());
    }
}

const EPaymentService = new EPaymentServiceClass();

export default EPaymentService;
