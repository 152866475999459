import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { usePage } from 'react-page-states';

import { labels, routes } from './../../../constants';
import { Roles, User } from './../../../models';
import { AuthService, common } from './../../../services';
import Paginator from './../../components/Paginator';
import SearchFilter, { SearchRef } from '../../components/SearchFilter';

import './styles.scss';

type Props = {
    roles?: Roles[];
};

const UsersTable: React.FunctionComponent<Props> = (props) => {
    const { roles } = props;
    const navigate = useNavigate();
    const searchRef = useRef<SearchRef>(null);
    const { pageSize, pageNo, total, ...page } = usePage();
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const searchColumns = ['login', 'lastName', 'firstName'];

    const loadData = () => {
        setLoading(true);
        let formValues = searchRef?.current?.getValues();

        AuthService.getUsers(pageSize, pageNo - 1, roles, formValues?.login, formValues?.firstName, formValues?.lastName)
            .then((response: any) => {
                setUsers(response.body);
                let totalCount = response.headers['x-total-count'];
                page.setTotal(parseInt(totalCount !== null ? totalCount : 0));
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(loadData, [pageSize, pageNo]);

    const _handleFormChange = () => {
        page.setPageNo(1);
        loadData();
    };

    const _handleLimitChange = (limit: number) => {
        page.setPageNo(1);
        page.setPageSize(limit);
    };

    const renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];

        users.forEach((user, index) => {
            rows.push(
                <tr key={index} onClick={() => navigate(routes.USER.path.replace(':userId', user.login))}>
                    <td>{user.login}</td>
                    <td>{user.lastName + ', ' + user.firstName}</td>
                    <td>{user.position}</td>
                    <td>{user.department}</td>
                    <td>{user.lastModifiedBy || user.createdBy}</td>
                    <td>{user.lastModifiedDate ? common.parseDateTimeAPIToDateTime(user.lastModifiedDate) :
                        user.createdDate ? common.parseDateTimeAPIToDateTime(user.createdDate)
                            : ""}</td>
                    {user.activated && <td className='active'>{labels.ACTIVE}</td>}
                    {!user.activated && <td className='deactivated'>{labels.DEACTIVATED}</td>}
                </tr>
            );
        });

        return rows;
    };

    return (
        <div className='UsersTable'>
            <SearchFilter ref={searchRef} columns={searchColumns} onSubmit={_handleFormChange} />
            <Card className='mt-3'>
                <Card.Body className='p-3'>
                    <Row>
                        <Col>
                            <span>Show </span>
                            <Form.Group className='count-select'>
                                <Form.Control
                                    as='select'
                                    size='sm'
                                    value={pageSize}
                                    onChange={(e: any) => _handleLimitChange(e.target.value)}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button className='float-right' variant='primary' onClick={() => navigate(routes.CREATE_USER.path, {state: {roles}})}>
                                {labels.CREATE_USER}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isLoading && (
                                <div className='center'>
                                    <Spinner animation='border' variant='primary' />
                                </div>
                            )}
                            {!isLoading && total === 0 && (
                                <div className='center'>
                                    <span>No data available</span>
                                </div>
                            )}
                            {!isLoading && total > 0 && (
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>{labels.USERNAME}</th>
                                            <th>{labels.NAME}</th>
                                            <th>{labels.POSITION}</th>
                                            <th>{labels.DEPARTMENT}</th>
                                            <th>{labels.UPDATED_BY}</th>
                                            <th>{labels.UPDATED_DATE}</th>
                                            <th>{labels.STATUS}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderRows()}</tbody>
                                </Table>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {total > 0 && (
                                <span>
                                    Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                    {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                </span>
                            )}
                        </Col>
                        <Col md='auto'>
                            <Paginator
                                currentPage={pageNo}
                                maxPage={page.maxPage}
                                _handlePageChange={(pageNumber) => page.setPageNo(pageNumber)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default UsersTable;
