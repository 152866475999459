import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { remove } from 'lodash';

import { config, labels } from '../../../constants';
import { Application, Payment, BulkPayment, PermitType } from '../../../models';
import { ApplicationService, common } from '../../../services';
import CollapsibleCard from '../CollapsibleCard';
import PaymentModal from '../PaymentModal';

import './styles.scss';
import InfoTooltip from '../InfoTooltip';

interface Props {
    permitType: PermitType;
    application?: Application;
    viewOnly?: boolean;
    onUpdate?: () => void;
}

const PaymentDetailsCard: React.FunctionComponent<Props> = (props) => {
    const { permitType, application, viewOnly, onUpdate } = props;
    const [paymentData, setPaymentData] = useState<Payment>();
    const [bulkParts, setBulkParts] = useState<Payment[]>([]);
    const [bulkDetails, setBulkDetails] = useState<BulkPayment>();
    const [bulkShowCount, setBulkShowCount] = useState(config.SHOW_LIMIT);
    const count = bulkParts.length;

    const checkBulk = () => {
        let bulkId = application?.bulkApplicationId;

        if (application && bulkId) {
            let calls: any = [ApplicationService.getBulkPaymentParts(bulkId), ApplicationService.getBulkPaymentDetails(bulkId)];

            Promise.all(calls)
                .then((responses) => {
                    let parts = responses[0].body.data.detailResponseDtos;
                    let details = responses[1].body.data;
                    let ownApp = remove(parts, (part) => part.applicationId === application.applicationId);

                    setBulkParts(ownApp.concat(parts));
                    setBulkDetails(details);
                })
                .catch((err: any) => console.error(err));
        }
    };

    useEffect(checkBulk, [application]);

    const _handlePaymentModalClose = (hasUpdate: boolean) => {
        setPaymentData(undefined);
        if (hasUpdate && onUpdate) onUpdate();
    };

    const renderBulkParts = () => {
        let rows: JSX.Element[] = [];

        bulkParts.forEach((part, index) => {
            if (index < bulkShowCount) {
                rows.push(
                    <tr key={index}>
                        <td>
                            {part.referenceNumber}
                            {index === 0 && <InfoTooltip message={'This application'} />}
                        </td>
                        <td>{part.total}</td>
                    </tr>
                );
            }
        });

        return rows;
    };

    return (
        <>
            {/* TODO: FIX revisit because payment now list */}
            {application && !application.bulkApplicationId && application.paymentDetailResponseDto?.length > 0 && (
                <CollapsibleCard className='PaymentDetailsCard' defaultCollapsed={true}>
                    <CollapsibleCard.Header>
                        <span>
                            <b>{labels.PAYMENT_DETAILS}</b>
                        </span>
                    </CollapsibleCard.Header>
                    <CollapsibleCard.Body>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>{labels.REFERENCE_NO}</th>
                                    <th>{labels.OR_NO}</th>
                                    <th>{labels.AMOUNT}</th>
                                    <th>{labels.PAYMENT_DATE}</th>
                                    <th>{labels.DATE_UPLOADED}</th>
                                    <th>{labels.RECEIPT}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{application.paymentDetailResponseDto[0]?.referenceNumber}</td>
                                    <td>{common.parseNullable(application.paymentDetailResponseDto[0]?.officialReceiptNumber)}</td>
                                    <td>{common.parseCurrency(application.paymentDetailResponseDto[0]?.total)}</td>
                                    <td>{common.parseNullable(application.paymentDetailResponseDto[0]?.paymentDate)}</td>
                                    <td>{common.parseNullable(application.paymentDetailResponseDto[0]?.updatedDate)}</td>
                                    <td className='text-center'>
                                        {application.paymentDetailResponseDto[0]?.imageId && (
                                            <span>
                                                <Button
                                                    variant='primary'
                                                    onClick={() => setPaymentData(application.paymentDetailResponseDto[0] ?? {})}>
                                                    {labels.VIEW_RECEIPT}
                                                </Button>
                                            </span>
                                        )}
                                        {!application.paymentDetailResponseDto[0]?.imageId && <>{common.parseNullable()}</>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </CollapsibleCard.Body>
                </CollapsibleCard>
            )}
            {application && bulkDetails && (
                <CollapsibleCard className='PaymentDetailsCard' defaultCollapsed={true}>
                    <CollapsibleCard.Header>
                        <span>
                            <b>{labels.BULK + ' ' + labels.PAYMENT_DETAILS}</b>
                        </span>
                    </CollapsibleCard.Header>
                    <CollapsibleCard.Body>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>{labels.BULK + ' ' + labels.REFERENCE_NO}</th>
                                    <th>{labels.BULK + ' ' + labels.OR_NO}</th>
                                    <th>{labels.AMOUNT}</th>
                                    <th>{labels.PAYMENT_DATE}</th>
                                    <th>{labels.RECEIPT}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{bulkDetails.referenceNumber}</td>
                                    <td>{common.parseNullable(bulkDetails.officialReceiptNumber)}</td>
                                    <td>{common.parseCurrency(bulkDetails.total)}</td>
                                    <td>{common.parseNullable(bulkDetails.paymentDate)}</td>
                                    <td className='text-center'>
                                        {bulkDetails.imageId && (
                                            <span>
                                                <Button
                                                    variant='primary'
                                                    onClick={() => {
                                                        let payment = {
                                                            ...(application.paymentDetailResponseDto[0] ?? {}),
                                                            ...bulkDetails
                                                        };
                                                        setPaymentData(payment);
                                                    }}>
                                                    {labels.VIEW_RECEIPT}
                                                </Button>
                                            </span>
                                        )}
                                        {!bulkDetails.imageId && <>{common.parseNullable()}</>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>{labels.APPLICATION + ' ' + labels.REFERENCE_NO}</th>
                                    <th>{labels.AMOUNT}</th>
                                </tr>
                            </thead>
                            <tbody>{renderBulkParts()}</tbody>
                        </Table>
                        {count > bulkShowCount && (
                            <label className='action-text' onClick={() => setBulkShowCount(bulkShowCount + config.SHOW_LIMIT)}>
                                {'Show ' +
                                    (count > bulkShowCount + config.SHOW_LIMIT ? config.SHOW_LIMIT : count - bulkShowCount) +
                                    ' more'}
                            </label>
                        )}
                    </CollapsibleCard.Body>
                </CollapsibleCard>
            )}
            {paymentData && application &&(
                <PaymentModal
                    permitType={permitType}
                    payment={paymentData}
                    viewOnly={viewOnly}
                    onClose={(updated) => _handlePaymentModalClose(updated)}
                />
            )}
        </>
    );
};

export default PaymentDetailsCard;
