import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardBackspace } from 'react-icons/md';

import './styles.scss';

interface Props {
    title: string;
    subTitle?: string;
    allowBack?: boolean;
    backConfirm?: () => void;
}

const PageHeader: React.FunctionComponent<Props> = (props) => {
    const { title, subTitle, allowBack, backConfirm, children } = props;
    const navigate = useNavigate();

    return (
        <div className='PageHeader bg-gradient-primary pb-7'>
            <Container fluid='md' className='pt-4 pb-5'>
                <div className='title'>
                    {allowBack && <MdKeyboardBackspace onClick={() => (backConfirm ? backConfirm() : navigate(-1))} />} {title}
                </div>
                {subTitle && <label className={'sub-title' + (allowBack ? ' padded' : '')}>{subTitle}</label>}
                {children}
            </Container>
        </div>
    );
};

export default PageHeader;
