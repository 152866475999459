import {
    CreateBusinessLine,
    CreateIndustry,
    CreateSubIndustry,
    SubIndustry,
    UpdateIndustry,
    AddRequirement,
    BusinessLine,
    Requirement,
    CreateLookUp,
    CreateLookUpInfo,
    LookUp,
    LookUpInfo,
    OrderOfPayment,
    CreateLink,
    Link,
    CreateOrderOfPayment
} from '../../models';
import Maintenance from '../../models/configs/Maintenance';
import { api, env } from '../utils';

class ConfigServiceClass {
    private permitService: string;
    private applicationTypeService: string;
    private requirementService: string;
    private orderOfPaymentService: string;
    private privacyPolicyService: string;
    private industriesService: string;
    private subIndustriesService: string;
    private businessLineService: string;
    private lookUpService: string;
    private lookUpInfoService: string;
    private linksService: string;
    private maintenanceService: string;
    private apiUrl: string = env.apiURL();

    constructor() {
        this.permitService = this.apiUrl + '/qch/permit';
        this.applicationTypeService = this.apiUrl + '/qch/application/type';
        this.requirementService = this.apiUrl + '/qch/requirements';
        this.orderOfPaymentService = this.apiUrl + '/qch/order-of-payment';
        this.privacyPolicyService = this.apiUrl + '/qch/privacy-policy';
        this.industriesService = this.apiUrl + '/qch/industries';
        this.subIndustriesService = this.apiUrl + '/qch/sub-industries';
        this.businessLineService = this.apiUrl + '/qch/business/line';
        this.lookUpService = this.apiUrl + '/qch/lookup';
        this.lookUpInfoService = this.apiUrl + '/qch/lookup/info';
        this.linksService = this.apiUrl + '/qch/links';
        this.maintenanceService = this.apiUrl + '/qch/maintenance';
    }

    getPermits() {
        return api.get(this.permitService, false);
    }

    getApplicationTypes() {
        return api.get(this.applicationTypeService, false);
    }

    getRequirements(permitId?: number, applicationTypeId?: number, industryId?: number, subIndustryId?: number) {
        let url = this.requirementService + '?';
        if (permitId) url = url + '&permitId=' + permitId;
        if (applicationTypeId) url = url + '&applicationTypeId=' + applicationTypeId;
        if (industryId) url = url + '&industryId=' + industryId;
        if (subIndustryId) url = url + '&subIndustryId=' + subIndustryId;
        return api.get(url, false);
    }

    activateRequirement(id: number) {
        let url = this.requirementService + '/' + id;
        return api.post(url, { requiremendId: id });
    }

    deactivateRequirement(id: number) {
        let url = this.requirementService + '/' + id;
        return api.delete(url);
    }

    saveRequirement(data: AddRequirement) {
        return api.post(this.requirementService, [data]);
    }

    updateRequirement(data: Requirement) {
        return api.put(this.requirementService, data);
    }

    getOrderOfPayments(permitId: number) {
        let url = this.orderOfPaymentService + '?permitId=' + permitId;

        return api.get(url, false);
    }

    createOrderPayment(data: CreateOrderOfPayment) {
        return api.post(this.orderOfPaymentService, data);
    }

    updateOrderPayment(data: OrderOfPayment) {
        return api.put(this.orderOfPaymentService, data);
    }

    deleteOrderPayment(id: number) {
        return api.delete(this.orderOfPaymentService + '/' + id);
    }

    getPrivacy() {
        return api.get(this.privacyPolicyService, false);
    }

    savePrivacy(content: string) {
        return api.post(this.privacyPolicyService, { content }, true);
    }

    getIndustries() {
        return api.get(this.industriesService, false);
    }

    createIndustry(data: CreateIndustry) {
        return api.post(this.industriesService, data);
    }

    updateIndustry(data: UpdateIndustry) {
        return api.put(this.industriesService, data);
    }

    deleteIndustry(id: number) {
        return api.delete(this.industriesService + '/' + id);
    }

    createSubIndustry(data: CreateSubIndustry) {
        return api.post(this.subIndustriesService, data);
    }

    updateSubIndustry(data: SubIndustry) {
        return api.put(this.subIndustriesService, data);
    }

    disableSubIndustry(id: number) {
        return api.delete(this.subIndustriesService + '/' + id);
    }

    enableSubIndustry(subIndustryId: number) {
        return api.post(this.subIndustriesService + '/active/' + subIndustryId, {});
    }

    getBusinessLine() {
        return api.get(this.businessLineService, false);
    }

    createBusinessLine(data: CreateBusinessLine) {
        return api.post(this.businessLineService, data);
    }

    updateBusinessLine(data: BusinessLine) {
        return api.put(this.businessLineService, data);
    }

    deleteBusinessLine(id: number) {
        return api.delete(this.businessLineService + '/' + id);
    }

    getLookUps() {
        return api.get(this.lookUpService, false);
    }

    getLookUpInfos(lookUpId: number) {
        return api.get(this.lookUpInfoService + '/' + lookUpId, false);
    }

    createLookUp(data: CreateLookUp) {
        return api.post(this.lookUpService, data);
    }

    updateLookUp(data: LookUp) {
        return api.put(this.lookUpService, data);
    }

    createLookUpInfo(data: CreateLookUpInfo) {
        return api.post(this.lookUpInfoService, data);
    }

    updateLookUpInfo(data: LookUpInfo) {
        return api.put(this.lookUpInfoService, data);
    }

    deleteLookupInfo(id: number) {
        return api.delete(this.lookUpInfoService + '/' + id);
    }

    getLinks() {
        return api.get(this.linksService, false);
    }

    createLink(data: CreateLink) {
        return api.post(this.linksService, data);
    }

    updateLink(data: Link) {
        return api.put(this.linksService, data);
    }
    
    setMaintenance(data: Maintenance) {
        return api.post(this.maintenanceService, data);
    }

    getMaintenance() {
        return api.get(this.maintenanceService, false);
    }
}

const ConfigService = new ConfigServiceClass();

export default ConfigService;
