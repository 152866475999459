import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Col, Spinner, Table, Button } from 'react-bootstrap';
import { FiEdit3 } from 'react-icons/fi';
import { AiOutlineStop, AiOutlineCheck } from 'react-icons/ai';
import { find, filter, sortBy } from 'lodash';

import { labels } from '../../../constants';
import { ApplicationType, Industry, SubIndustry, Permit, PermitType, Requirement } from './../../../models';
import { ConfigService, modal, common } from './../../../services';
import { getIndustries, getApplicationTypes } from './../../../actions/config.action';
import RequirementModal from './../../components/RequirementModal';

import './styles.scss';

interface Props {
    permitType: PermitType;
}

let permitId: number = 0;

const Requirements: React.FunctionComponent<Props> = (props) => {
    const { permitType } = props;
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const applicationType = useSelector((state: any) => state.config.applicationType);
    const industry = useSelector((state: any) => state.config.industry);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [requirementList, setRequirementList] = useState<Requirement[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [requirement, setRequirement] = useState<Requirement | undefined>();

    useEffect(() => {
        dispatch(getIndustries());
        dispatch(getApplicationTypes());
    }, [dispatch]);

    const loadData = () => {
        setLoading(true);

        if (permitList.length > 0 && !industry.isLoading) {
            permitId = find(permitList, { type: permitType }).permitId;

            ConfigService.getRequirements(permitId)
                .then((response: any) => {
                    let requirementList = sortBy(response.body.data, ['requirementName']);

                    setRequirementList(requirementList);
                    setLoading(false);
                })
                .catch((err: any) => {
                    setLoading(false);
                    console.error(err);
                });
        }
    };

    useEffect(loadData, [permitList]);

    const getAppType = (ids: number[]) => {
        let appTypes: string = labels.ALL;

        if (ids.length > 0) {
            let currentAppTypes: ApplicationType[] = applicationType.list.filter(({ applicationTypeId }) =>
                ids.includes(applicationTypeId)
            );
            appTypes = currentAppTypes.map((e) => e.value).join(';\n');
        }

        return appTypes;
    };

    const getAppTypeList = () => {
        let list: ApplicationType[] = [];

        if (permitType === PermitType.IHC) {
            list = filter(applicationType.list, { applicableToIHC: true });
        } else if (permitType === PermitType.SP) {
            list = filter(applicationType.list, { applicableToSP: true });
        }

        return list;
    };

    const getIndustry = (id: number) => {
        let currentIndusty: Industry = find(industry.list, { industryId: id });

        return currentIndusty ? currentIndusty.industryDesc : '';
    };

    const getSubIndustry = (id: number, subIds: number[]) => {
        let subIndustries: string = labels.ALL;

        if (subIds.length > 0) {
            let currentIndusty: Industry = find(industry.list, { industryId: id });

            if (currentIndusty) {
                let currentSubIndustries: SubIndustry[] = currentIndusty.subIndustryResponseDtos.filter(({ subIndustryId }) =>
                    subIds.includes(subIndustryId)
                );
                subIndustries = currentSubIndustries.map((e) => e.subIndustryDesc).join(';\n');
            }
        }

        return subIndustries;
    };

    const disableRequirement = (id: number, name: string) => {
        modal.displayConfirmation(labels.dialog.message.DISABLE_REQ_CONFIRM.replace('{name}', name), () => {
            ConfigService.deactivateRequirement(id).then(() => loadData());
        });
    };

    const enableRequirement = (id: number, name: string) => {
        modal.displayConfirmation(labels.dialog.message.ENABLE_REQ_CONFIRM.replace('{name}', name), () => {
            ConfigService.activateRequirement(id).then(() => loadData());
        });
    };

    const setEditing = (req: Requirement) => {
        setRequirement(Object.assign({}, req));
        setShowModal(true);
    };

    const setAdding = () => {
        setRequirement(undefined);
        setShowModal(true);
    };

    const _handleModalClose = (data: any) => {
        setShowModal(false);

        if (data) {
            if (data.requirementId) {
                ConfigService.updateRequirement(data).then(() => {
                    loadData();
                });
            } else {
                data.permitId = permitId;
                ConfigService.saveRequirement(data).then(() => {
                    loadData();
                });
            }
        }
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        requirementList.forEach((req, index) => {
            rows.push(
                <tr key={index}>
                    <td>{req.requirementName}</td>
                    <td>{req.requirementDesc}</td>
                    <td className='multiline'>{getAppType(req.applicationTypeIds)}</td>
                    <td>{req.industryId ? getIndustry(req.industryId) : labels.ALL}</td>
                    <td className='multiline'>
                        {req.subIndustryIds.length > 0 ? getSubIndustry(req.industryId, req.subIndustryIds) : labels.ALL}
                    </td>
                    <td>{req.inactive ? labels.DISABLED : labels.ENABLED}</td>
                    <td>{common.parseBooleanLabel(req.required)}</td>
                    {permitType === PermitType.IHC && (
                        <>
                            <td>{common.parseBooleanLabel(req.internal)}</td>
                            <td>{common.parseBooleanLabel(req.socialHygiene)}</td>
                        </>
                    )}
                    {permitType === PermitType.SP && (
                        <>
                            <td>{common.parseBooleanLabel(req.waterAnalysis)}</td>
                        </>
                    )}
                    <td>
                        <div className='action-group'>
                            {req.inactive && (
                                <Button
                                    variant='outline-success'
                                    onClick={() => enableRequirement(req.requirementId, req.requirementName)}>
                                    <AiOutlineCheck />
                                </Button>
                            )}
                            {!req.inactive && (
                                <>
                                    <Button variant='outline-primary' disabled={req.inactive} onClick={() => setEditing(req)}>
                                        <FiEdit3 />
                                    </Button>
                                    <Button
                                        variant='outline-secondary'
                                        onClick={() => disableRequirement(req.requirementId, req.requirementName)}>
                                        <AiOutlineStop />
                                    </Button>
                                </>
                            )}
                        </div>
                    </td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <div className='Requirements'>
            <Card>
                <Card.Body className='p-3'>
                    <Row>
                        <Col>
                            {isLoading && (
                                <div className='center-content'>
                                    <Spinner animation='border' variant='primary' />
                                </div>
                            )}
                            {!isLoading && (
                                <>
                                    <Row>
                                        <Col>
                                            <Button className='add' variant='primary' onClick={() => setAdding()}>
                                                {labels.CREATE_REQUIREMENT}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table responsive bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>{labels.REQUIREMENT}</th>
                                                        <th>{labels.DESCRIPTION}</th>
                                                        <th>{labels.APP_TYPE}</th>
                                                        <th>{labels.INDUSTRY}</th>
                                                        <th>{labels.SUB_INDUSTRY}</th>
                                                        <th>{labels.STATUS}</th>
                                                        <th>{labels.REQUIRED}</th>
                                                        {permitType === PermitType.IHC && (
                                                            <>
                                                                <th>{labels.CLINICAL_LAB}</th>
                                                                <th>{labels.SOCIAL_HYGIENE}</th>
                                                            </>
                                                        )}
                                                        {permitType === PermitType.SP && (
                                                            <>
                                                                <th>{labels.WATER_ANALYSIS}</th>
                                                            </>
                                                        )}
                                                        <th>{labels.ACTION}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{renderRows()}</tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <RequirementModal
                show={showModal}
                permitType={permitType}
                requirement={requirement}
                appTypeList={getAppTypeList()}
                industryList={industry.list}
                onClose={(data) => _handleModalClose(data)}
            />
        </div>
    );
};

export default Requirements;
