import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Spinner, Form, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FiEdit3 } from 'react-icons/fi';
import { FaCheck, FaClipboardCheck, FaRegTrashAlt } from 'react-icons/fa';
import { BsClipboardX } from 'react-icons/bs';

import { labels } from '../../../constants';
import { ApplicationService, common, device, modal } from './../../../services';
import { TrainingSession, TrainingParticipant, Roles } from '../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import TrainingParticipantModal from '../../components/TrainingParticipantModal';
import TrainingSessionModal from '../../components/TrainingSessionModal';

import './styles.scss';

type Props = {};

interface IActionBttn {
    id: string;
    message: string;
    variant?: string;
    handleClick: any;
    disabled?: boolean;
}

const ActionBttn: React.FunctionComponent<IActionBttn> = ({ children, id, message, variant, handleClick, disabled }) => {
    const tooltip = (
        <Tooltip id={id}>
            <span>{message}</span>
        </Tooltip>
    )

    if (disabled) {
        return <Button disabled={disabled} variant='outline-dark' onClick={handleClick}>
            {children}
        </Button>
    }

    return <OverlayTrigger overlay={tooltip}>
        <Button disabled={disabled} variant={variant ?? 'outline-primary'} onClick={handleClick}>
            {children}
        </Button>
    </OverlayTrigger>
}

const TrainingDetails: React.FunctionComponent<Props> = () => {
    const isMobile = device.useMobileCheck();
    const params: any = useParams();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user.user);
    const [isLoading, setLoading] = useState(true);
    const [session, setSession] = useState<TrainingSession>();
    const [editSession, setEditSession] = useState<TrainingSession>();
    const [showAdd, setShowAdd] = useState(false);
    const [activeParticipant, setActiveParticipant] = useState<TrainingParticipant>();
    const isTrainer = common.allowedRoute([Roles.MASSAGIST_TRAINER], user.authorities);

    const loadDetails = () => {
        setLoading(true);

        ApplicationService.getTrainingSessionDetails(params.sessionId).then((res) => {
            setSession(res.body.data);
            setLoading(false);
        });
    };

    useEffect(loadDetails, [params.sessionId]);

    const _handleAddClose = (participant?: TrainingParticipant) => {
        if (participant) {
            participant.trainingSessionId = params.sessionId;

            if (participant.trainingParticipantId) {
                modal.displayConfirmation(labels.dialog.message.PARTICIPANT_UPD_CONFIRM, () => {
                    ApplicationService.updateTrainingParticipant(participant).then(() => {
                        loadDetails();
                        setShowAdd(false);
                        setActiveParticipant(undefined);
                    });
                });
            } else {
                modal.displayConfirmation(
                    labels.dialog.message.PARTICIPANT_ADD_CONFIRM.replace('{name}', common.parseFullname(participant)),
                    () => {
                        ApplicationService.createTrainingParticipant(participant).then(() => {
                            loadDetails();
                            setShowAdd(false);
                            setActiveParticipant(undefined);
                        });
                    }
                );
            }
        } else {
            setShowAdd(false);
            setActiveParticipant(undefined);
        }
    };

    const _handleEdit = (session?: any) => {
        if (session) {
            modal.displayConfirmation(labels.dialog.message.SESSION_UPD_CONFIRM, () => {
                ApplicationService.updateTrainingSession(session).then(() => {
                    setEditSession(undefined);
                    loadDetails();
                });
            });
        } else {
            setEditSession(undefined);
        }
    };

    const _handleDeleteSession = () => {
        if (session) {
            let message = labels.dialog.message.SESSION_DEL_CONFIRM;

            if (session && session.trainingParticipants && session.trainingParticipants.length > 0) {
                message = labels.dialog.message.SESSION_DEL_PARTICIPANTS;
            }

            modal.displayConfirmation(message.replace('{name}', session.trainingName), () => {
                ApplicationService.deleteTrainingSession(session.trainingSessionId).then(() => {
                    navigate(-1);
                });
            });
        }
    };

    const _handleDeleteParticipant = (participant: TrainingParticipant) => {
        modal.displayConfirmation(
            labels.dialog.message.PARTICIPANT_DEL_CONFIRM.replace('{name}', common.parseFullname(participant)),
            () => {
                ApplicationService.deleteTrainingParticipant(participant.trainingParticipantId).then(() => {
                    if (participant.applicationId) {
                        ApplicationService.getApplicationById(participant.applicationId).then((response: any) => {
                            const applicantApplication = response.body.data

                            const updatedApplication = {
                                ...applicantApplication,
                                formData: {
                                    ...applicantApplication.formData,
                                    trainingParticipantId: undefined,
                                    trainingSessionId: undefined,
                                }
                            }

                            ApplicationService.updateApplication(updatedApplication).then(() => {
                                loadDetails();
                            });
                        });
                    }
                });
            }
        );
    };

    const _handleCompletedConfirmation = (participant: TrainingParticipant) => {
        modal.displayConfirmation(
            labels.dialog.message.PARTICIPANT_COMPLETED_CONFIRM.replace('{name}', common.parseFullname(participant)),
            () => {
                ApplicationService.updateParticipantSeminarAttendance(participant.trainingParticipantId, 'complete').then(() => {
                    loadDetails();
                })
            }
        )
    }

    const _handleNoShowConfirmation = (participant: TrainingParticipant) => {
        modal.displayConfirmation(
            labels.dialog.message.PARTICIPANT_NO_SHOW_CONFIRM.replace('{name}', common.parseFullname(participant)),
            () => {
                ApplicationService.updateParticipantSeminarAttendance(participant.trainingParticipantId, 'no-show').then(() => {
                    loadDetails();
                })
            }
        )
    }

    const _handleConfirmParticipant = (participant: TrainingParticipant) => {
        modal.displayConfirmation(
            labels.dialog.message.PARTICIPANT_CONFIRM_CONFIRM.replace('{name}', common.parseFullname(participant)),
            () => {
                ApplicationService.updateParticipantSeminarAttendance(participant.trainingParticipantId, 'session-confirmed').then(() => {
                    loadDetails();
                })
            }
        )
    }

    const renderRows = () => {
        let rows: JSX.Element[] = [];
        if (session && session.trainingParticipants && session.trainingParticipants.length > 0) {
            session.trainingParticipants.forEach((participant, index) => {
                rows.push(
                    <tr key={index}>
                        <td>{common.parseFullname(participant)}</td>
                        <td>{participant.gender}</td>
                        <td>{participant.birthDate}</td>
                        <td>{participant.emailAddress}</td>
                        {isTrainer && (
                            <td>
                                <div className='action-group'>
                                    {
                                        (!participant.trainingParticipantSessionConfirmed) ?
                                            <ActionBttn
                                                id='confirm'
                                                message={labels.seminar.CONFIRM}
                                                variant='outline-success'
                                                handleClick={() => _handleConfirmParticipant(participant)}
                                                disabled={participant.trainingSessionCompleted}
                                            >
                                                <FaCheck />
                                            </ActionBttn>
                                            :
                                            <>
                                                <ActionBttn
                                                    id='complete'
                                                    message={labels.seminar.COMPLETED}
                                                    handleClick={() => _handleCompletedConfirmation(participant)}
                                                    disabled={["COMPLETE", "NO-SHOW"].includes(participant.status ?? '')}
                                                >
                                                    <FaClipboardCheck />
                                                </ActionBttn>
                                                <ActionBttn
                                                    id='no-show'
                                                    message={labels.seminar.NO_SHOW}
                                                    variant='outline-secondary'
                                                    disabled={["COMPLETE", "NO-SHOW"].includes(participant.status ?? '')}
                                                    handleClick={() => _handleNoShowConfirmation(participant)}
                                                >
                                                    <BsClipboardX />
                                                </ActionBttn>
                                            </>
                                    }
                                    <ActionBttn
                                        id='remove'
                                        message={labels.seminar.REMOVE}
                                        variant='outline-secondary'
                                        handleClick={() => _handleDeleteParticipant(participant)}
                                        disabled={["COMPLETE", "NO-SHOW"].includes(participant.status ?? '')}
                                    >
                                        <FaRegTrashAlt />
                                    </ActionBttn>
                                </div>
                            </td>
                        )}
                    </tr>
                );
            });
        } else {
            rows.push(
                <tr key={1}>
                    <td colSpan={100}>
                        <span>{labels.NO_DATA}</span>
                    </td>
                </tr>
            );
        }

        return rows;
    };

    return (
        <div className='TrainingDetails'>
            <PageHeader title={labels.TRAINING_DETAILS} allowBack={true} />
            <PageBody>
                <Card>
                    <Card.Body className='py-3 px-5'>
                        {isLoading && (
                            <div className='center-content'>
                                <Spinner animation='border' variant='primary' />
                            </div>
                        )}
                        {!isLoading && session && (
                            <>
                                {isTrainer && (
                                    <Row>
                                        <Col className='button-group'>
                                            <Button variant='primary' onClick={() => setEditSession(session)}>
                                                <FiEdit3 />
                                                {!isMobile && labels.TRAINING_UPDATE}
                                            </Button>
                                            <Button variant='outline-secondary' onClick={_handleDeleteSession}>
                                                <FaRegTrashAlt />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.TRAINING_NAME}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='trainingName'
                                                defaultValue={session.trainingName}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.TRAINING_DESC}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='trainingDesc'
                                                defaultValue={session.trainingDesc}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.START_DATE}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='startDate'
                                                defaultValue={`${common.parseDateTimeToDate(common.parseDateTimeToString(session.startDate))} ${common.formatTimeToAMPM(session.startTime)}`}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.END_DATE}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='endDate'
                                                defaultValue={`${common.parseDateTimeToDate(common.parseDateTimeToString(session.endDate))} ${common.formatTimeToAMPM(session.endTime)}`}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.PARTICIPANT_LIMIT}</Form.Label>
                                            <Form.Control type='text' name='maxAllowedParticipants' defaultValue={session.maxAllowedParticipants} readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.TRAINER}</Form.Label>
                                            <Form.Control type='text' name='trainer' defaultValue={session.trainer} readOnly />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.LOCATION}</Form.Label>
                                            <Form.Control type='text' name='location' defaultValue={session.location} readOnly />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='title-row'>
                                    <Col xs={6}>
                                        <h5>Participants</h5>
                                    </Col>
                                    {/* {isTrainer && (
                                        <Col xs={6}>
                                            <Button className='add' variant='primary' onClick={() => setShowAdd(true)}>
                                                <IoMdAdd />
                                                {!isMobile && labels.ADD_PARTICIPANT}
                                            </Button>
                                        </Col>
                                    )} */}
                                </Row>
                                <Row>
                                    <Col>
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>{labels.NAME}</th>
                                                    <th>{labels.GENDER}</th>
                                                    <th>{labels.BIRTH_DATE}</th>
                                                    <th>{labels.EMAIL_ADDRESS}</th>
                                                    {isTrainer && <th>{labels.ACTION}</th>}
                                                </tr>
                                            </thead>
                                            <tbody>{renderRows()}</tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </PageBody>
            {showAdd && <TrainingParticipantModal show={showAdd} participant={activeParticipant} onClose={_handleAddClose} />}
            {editSession && (
                <TrainingSessionModal show={editSession ? true : false} session={editSession} onClose={_handleEdit} />
            )}
        </div>
    );
};

export default TrainingDetails;
