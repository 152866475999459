import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Table, Row, Col, Spinner, Button, Form } from 'react-bootstrap';
import { findIndex, find, filter, maxBy } from 'lodash';
import { FiEdit3 } from 'react-icons/fi';
import { FaRegTrashAlt } from 'react-icons/fa';

import { labels, config } from '../../../constants';
import { OrderOfPayment, Permit, NestedOrderOfPayment, OrderOfPaymentData } from '../../../models';
import { ConfigService, common, modal } from './../../../services';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import OrderOfPaymentModal from '../../components/OrderOfPaymentModal';

import './styles.scss';

type Props = {};

let orderOfPayment: OrderOfPayment | undefined;
let isParent: boolean;
let isNew: boolean;

const OrderPayment: React.FunctionComponent<Props> = () => {
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const [isLoading, setLoading] = useState(true);
    const [orderOfPayments, setOrderOfPayments] = useState<OrderOfPayment[]>([]);
    const [pageData, setPageData] = useState<OrderOfPaymentData>();
    const [showModal, setShowModal] = useState(false);
    const [permitId, setPermitId] = useState(permitList.length > 0 ? permitList[0].permitId : 1);

    const loadData = () => {
        setLoading(true);

        if (permitList.length > 0) {
            let calls: any = [];

            //Set order payment call per permit type
            permitList.forEach((permit) => {
                calls.push(ConfigService.getOrderOfPayments(permit.permitId));
            });

            Promise.all(calls)
                .then((responses) => {
                    let orderPayments: OrderOfPayment[] = [];

                    responses.forEach((response: any) => {
                        orderPayments = orderPayments.concat(response.body.data);
                    });

                    setOrderOfPayments(orderPayments);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    const setData = () => {
        setPageData(common.createOrderOfPaymentData(orderOfPayments));
    };

    useEffect(loadData, [permitList]);
    useEffect(setData, [orderOfPayments]);

    const getChildFees = (children: NestedOrderOfPayment[], result: number = 0) => {
        let r = result;

        children.forEach((child) => {
            if (child.child && child.child.length > 0) {
                getChildFees(child.child, r);
            } else {
                r = r + (child.amount ? child.amount : 0);
            }
        });

        return r;
    };

    const createOrderOfPayment = () => {
        isParent = false;
        isNew = true;
        orderOfPayment = undefined;
        setShowModal(true);
    }

    const setEditing = (id: number, isParentData: boolean) => {
        isParent = isParentData;
        isNew = false;
        orderOfPayment = Object.assign({}, find(orderOfPayments, { orderOfPaymentId: id }));
        setShowModal(true);
    };

    const deleteOrderOfPayment = (id: number, name: string) => {
        modal.displayConfirmation(labels.dialog.message.DEL_ORDER_PAYMENT.replace('{name}', name), () => {
            ConfigService.deleteOrderPayment(id)
                .then(() => {
                    loadData();
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    }

    const _handleModalClose = (orderOfPayment) => {
        if (orderOfPayment) {
            if (orderOfPayment.orderOfPaymentId) {
                ConfigService.updateOrderPayment(orderOfPayment)
                    .then(() => {
                        let index = findIndex(orderOfPayments, { orderOfPaymentId: orderOfPayment.orderOfPaymentId });

                        let newOrderOfPayments = [
                            ...orderOfPayments.slice(0, index),
                            orderOfPayment,
                            ...orderOfPayments.slice(index + 1)
                        ];

                        setOrderOfPayments(newOrderOfPayments);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                let maxOrder = maxBy(filter(orderOfPayments, { permitId: permitId }), 'order');
                orderOfPayment.order = maxOrder.order + 1;

                ConfigService.createOrderPayment(orderOfPayment)
                    .then(() => {
                        loadData();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }

        setShowModal(false);
    };

    const renderHeadColumns = () => {
        let columns: JSX.Element[] = [];
        let key = 0;

        if (pageData) {
            for (let i = 0; i <= pageData.totalDepth; i++) {
                if (i === pageData.totalDepth) {
                    columns.push(<th key={key++}>{labels.ACCOUNT_NAME}</th>);
                    columns.push(<th key={key++}>{labels.ACCOUNT_CODE}</th>);
                    columns.push(<th key={key++}>{labels.AMOUNT}</th>);
                } else {
                    columns.push(<th key={key++}></th>);
                }
            }

            columns.push(<th key={key++}>{labels.ACTION}</th>);
        }

        return columns;
    };

    const renderRows = (rowDatas: NestedOrderOfPayment[], result: JSX.Element[], index: number = 0): JSX.Element[] => {
        let r = result;

        rowDatas.forEach((item, i) => {
            if (item.permitId === permitId) {
                r.push(<tr key={index + '_' + i}>{renderColumns(item)}</tr>);

                if (item.child && item.child.length > 0) {
                    renderRows(item.child, r, i + 1);
                }
            }
        });

        return r;
    };

    const renderColumns = (rowData: NestedOrderOfPayment) => {
        let columns: JSX.Element[] = [];
        let key = 0;

        if (pageData) {
            for (let i = 1; i <= pageData.totalDepth; i++) {
                if (i === rowData.depth) {
                    columns.push(<td key={key++}>{rowData.orderOfPaymentName}</td>);
                } else {
                    columns.push(<td key={key++}></td>);
                }
            }

            if (rowData && rowData.child && rowData.child.length > 0) {
                let amount = getChildFees(rowData.child);

                columns.push(<td key={key++}></td>);
                columns.push(<td key={key++}></td>);

                columns.push(
                    <td key={key++}>
                        <span>
                            <b>{labels.TOTAL + ': '}</b>
                            {config.CURRENCY + ' ' + amount}
                        </span>
                    </td>
                );

                columns.push(
                    <td key={key}>
                        <Button variant='outline-primary' onClick={() => setEditing(rowData.orderOfPaymentId, true)}>
                            <FiEdit3 />
                        </Button>
                    </td>
                );
            } else {
                columns.push(<td key={key++}>{rowData.accountName}</td>);
                columns.push(<td key={key++}>{rowData.accountCode}</td>);
                columns.push(<td key={key++}>{(rowData.amount ? config.CURRENCY + ' ' + rowData.amount : '')}</td>);

                columns.push(
                    <td className='action-group' key={key}>
                        <Button variant='outline-primary' onClick={() => setEditing(rowData.orderOfPaymentId, false)}>
                            <FiEdit3 />
                        </Button>
                        <Button
                            variant='outline-secondary'
                            onClick={() => deleteOrderOfPayment(rowData.orderOfPaymentId, rowData.orderOfPaymentName)}>
                            <FaRegTrashAlt />
                        </Button>
                    </td>
                );
            }
        }

        return columns;
    };

    const renderList = () => {
        let options: JSX.Element[] = [];

        permitList.forEach((item, index) => {
            options.push(
                <option key={index} value={item.permitId}>
                    {item.permitDesc}
                </option>
            );
        });

        return options;
    };

    return (
        <div className='OrderOfPayment'>
            <PageHeader title={labels.ORDER_PAYMENT} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Group className='count-select'>
                                            <Form.Control
                                                as='select'
                                                value={permitId}
                                                onChange={(e: any) => setPermitId(parseInt(e.target.value))}>
                                                {renderList()}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button className='add' variant='primary' onClick={() => createOrderOfPayment()}>
                                    {'Add New'}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && pageData !== undefined && pageData.list.length === 0 && (
                                    <div className='center-content'>
                                        <span>No data available</span>
                                    </div>
                                )}
                                {!isLoading && pageData !== undefined && pageData.list.length > 0 && (
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>{renderHeadColumns()}</tr>
                                        </thead>
                                        <tbody>{renderRows(pageData.list, [])}</tbody>
                                    </Table>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {showModal &&
                    <OrderOfPaymentModal
                        show={showModal}
                        permitId={permitId}
                        orderOfPayment={orderOfPayment}
                        orderOfPayments={orderOfPayments}
                        isParent={isParent}
                        isNew={isNew}
                        onClose={(o) => _handleModalClose(o)}
                    />
                }
            </PageBody>
        </div>
    );
};

export default OrderPayment;
