import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { labels } from '../../../constants';
import {
    User,
    CreateLabForm,
    LabForm,
    Requirement,
    Application,
    LabType,
    LabFormStatus,
} from '../../../models';
import { ApplicationService, common } from '../../../services';

import './styles.scss';

type Props = {
    show: boolean;
    application: Application;
    labType: LabType;
    requirement?: Requirement;
    labForm?: LabForm;
    onClose: (updated?: boolean) => void;
};

const LabSpecimenFormModal: React.FunctionComponent<Props> = (props) => {
    const { show, requirement, application, labType, labForm, onClose } = props;
    const user: User = useSelector((state: any) => state.user.user);

    const initFormValues = () => {
        let formOptions: any = {
            mode: 'onBlur'
        };

        formOptions.defaultValues = {
            location: labForm?.formData?.location,
            collectedBy: labForm?.formData?.collectedBy,
            dateCollected: labForm?.formData?.dateCollected,
            dateReceived: labForm?.formData?.dateReceived ?
                labForm.formData.dateReceived : common.parseDateTimeToString()
        }

        return formOptions;
    };

    const { handleSubmit, register, errors } = useForm(initFormValues());

    const _handleSave = (values) => {
        let method;

        if (labForm) {
            let form: LabForm = {
                ...labForm,
                laboratoryStatus: LabFormStatus.PENDING,
                formData: {
                    ...labForm.formData,
                    ...values,
                }
            };

            method = ApplicationService.updateLaboratory(form);
        } else if (requirement) {
            let form: CreateLabForm = {
                applicationId: application.applicationId,
                requirementId: requirement.requirementId,
                laboratoryStatus: LabFormStatus.PENDING,
                formData: {
                    formType: common.getFormType(requirement),
                    ...values
                }
            };
            method = ApplicationService.createLaboratory(form);
        }

        method
            .then(() => {
                onClose(true);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='LabSpecimenFormModal'>
            <Modal.Header closeButton>
                <h4>{labels.SPECIMEN}</h4>
            </Modal.Header>
            <Form spellCheck={false} onSubmit={handleSubmit(_handleSave)}>
                <Modal.Body>
                    {labType === LabType.WATER_ANALYSIS &&
                        <>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{labels.LOCATION}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='location'
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.location}
                                        />
                                        {!!errors.location && (
                                            <Form.Control.Feedback type='invalid'>{errors.location.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAB_DATE_COLLECTED}</Form.Label>
                                        <Form.Control
                                            type='datetime-local'
                                            name='dateCollected'
                                            max={common.parseDateTimeToString()}
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.dateCollected}
                                        />
                                        {!!errors.dateCollected && (
                                            <Form.Control.Feedback type='invalid'>{errors.dateCollected.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{labels.COLLECTED_BY}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='collectedBy'
                                            ref={register({
                                                required: labels.FIELD_REQUIRED
                                            })}
                                            isInvalid={!!errors.collectedBy}
                                        />
                                        {!!errors.collectedBy && (
                                            <Form.Control.Feedback type='invalid'>{errors.collectedBy.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{labels.LAB_DATE_RECEIVED}</Form.Label>
                                <Form.Control
                                    type='datetime-local'
                                    name='dateReceived'
                                    max={common.parseDateTimeToString()}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.dateReceived}
                                />
                                {!!errors.dateReceived && (
                                    <Form.Control.Feedback type='invalid'>{errors.dateReceived.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {common.allowedDispatch(user.authorities) && (
                        <Button variant='primary' type='submit'>
                            {labels.SAVE}
                        </Button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal >
    );
};

export default LabSpecimenFormModal;
