import React from 'react';
import { Provider } from 'react-redux';

import store from './stores/store';
import Preloader from './pages/components/Preloader';
import Dialog from './pages/components/Dialog';
import Navigator from './Navigator';

import './styles/custom-bootstrap.scss';
import './App.scss';

type Props = {};

const App: React.FunctionComponent<Props> = () => {
    return (
        <div className='App'>
            <Provider store={store}>
                <Navigator />
                <Preloader />
                <Dialog />
            </Provider>
        </div>
    );
};

export default App;
