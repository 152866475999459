import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

import { labels } from '../../../constants';
import { LookUpInfo } from '../../../models';

import './styles.scss';

type FormValues = {
    value: string;
    desc: string;
};

type Props = {
    show: boolean;
    onClose: (lookup: any) => void;
    lookUpInfo?: LookUpInfo;
};

const LookUpInfoModal: React.FunctionComponent<Props> = (props) => {
    const { show, onClose, lookUpInfo } = props;

    const { handleSubmit, register, errors } = useForm<FormValues>({
        mode: 'onBlur'
    });

    const _onSubmit = (values: FormValues) => {
        if (lookUpInfo) {
            lookUpInfo.value = values.value;
            lookUpInfo.desc = values.desc;

            onClose(lookUpInfo);
        } else {
            let newLookUpInfo = {
                value: values.value,
                desc: values.desc
            };

            onClose(newLookUpInfo);
        }
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='LookUpInfoModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.LOOKUP_VALUE}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{labels.NAME}</Form.Label>
                        <Form.Control
                            type='text'
                            name='value'
                            defaultValue={lookUpInfo ? lookUpInfo.value : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.value}
                        />
                        {!!errors.value && <Form.Control.Feedback type='invalid'>{errors.value.message}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.DESCRIPTION}</Form.Label>
                        <Form.Control
                            type='text'
                            name='desc'
                            defaultValue={lookUpInfo ? lookUpInfo.desc : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.desc}
                        />
                        {!!errors.desc && <Form.Control.Feedback type='invalid'>{errors.desc.message}</Form.Control.Feedback>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default LookUpInfoModal;
