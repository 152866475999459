enum Position {
    SUPER_ADMIN = 'Super Admin',
    ADMIN = 'Admin',
    VALIDATOR = 'Validator',
    APPROVER = 'Head Approver',
    INSPECTOR = 'Sanitary Inspector',
    SP_VIEWER = 'Sanitary Permit Viewer',
    AUDITOR = 'Auditor',
    LAB_REP = 'Medical Technologist',
    MICROSCOPIST = 'Microscopist',
    PATHOLOGIST = 'Pathologist',
    LAB_VIEWER = 'Lab Viewer',
    MASSAGIST_TRAINER = 'Massagist Trainer',
    LAB_AIDE = 'Lab Aide',
}

export default Position;
