import React from 'react';
import { OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { IoWarningOutline } from 'react-icons/io5';

import './styles.scss';

interface Props {
    size?: string;
    trigger?: OverlayTriggerType | OverlayTriggerType[];
    placement?: Placement;
    message: string;
}

const WarningTooltip: React.FunctionComponent<Props> = (props) => {
    const { size = '1.25rem', trigger = ['hover', 'focus'], placement = 'right', message } = props;

    return (
        <span className='WarningTooltip'>
            <OverlayTrigger
                trigger={trigger}
                placement={placement}
                overlay={
                    <Popover id='popover-basic'>
                        <PopoverContent>{message}</PopoverContent>
                    </Popover>
                }>
                <IoWarningOutline size={size} />
            </OverlayTrigger>
        </span>
    );
};

export default WarningTooltip;
