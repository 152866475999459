import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import { find } from 'lodash';

import { labels } from '../../../constants';
import { ApplicationType, Industry, Requirement, SubIndustry, PermitType } from '../../../models';

import './styles.scss';

type FormValues = {
    requirementName: string;
    requirementDesc: string;
    applicationTypeIds: string[];
    industryId: string;
    subIndustryIds?: string[];
    required: boolean;
    laboratory: boolean;
    internal: boolean;
    socialHygiene: boolean;
    waterAnalysis: boolean;
};

type Props = {
    show: boolean;
    permitType: PermitType;
    appTypeList: ApplicationType[];
    industryList: Industry[];
    onClose: (requirement: any) => void;
    requirement?: Requirement;
};

const RequirementModal: React.FunctionComponent<Props> = (props) => {
    const { show, permitType, appTypeList, industryList, onClose, requirement } = props;

    const { handleSubmit, register, errors, watch, setValue } = useForm({
        mode: 'onBlur'
    });

    const watchIndustry = watch('industryId', requirement && requirement.industryId ? requirement.industryId.toString() : '');

    const renderApplicationTypeOptions = () => {
        let options: JSX.Element[] = [];

        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        appTypeList.forEach((appType, index) => {
            options.push(
                <option key={index + 1} value={appType.applicationTypeId}>
                    {appType.value}
                </option>
            );
        });

        return options;
    };

    const renderIndustryOptions = () => {
        let options: JSX.Element[] = [];

        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        industryList.forEach((industry, index) => {
            options.push(
                <option key={index + 1} value={industry.industryId}>
                    {industry.industryDesc}
                </option>
            );
        });

        return options;
    };

    const renderSubIndustryOptions = () => {
        let options: JSX.Element[] = [];
        let industry: Industry = find(industryList, { industryId: parseInt(watchIndustry) });

        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        if (industry) {
            industry.subIndustryResponseDtos.forEach((subIndustry: SubIndustry, index) => {
                if (!subIndustry.inactive) {
                    options.push(
                        <option key={index + 1} value={subIndustry.subIndustryId}>
                            {subIndustry.subIndustryDesc}
                        </option>
                    );
                }
            });
        }

        return options;
    };

    const _handleAllCheck = (e) => {
        const values = [...e.target.selectedOptions].map((opt) => opt.value);

        //Remove other selection if All is selected or if all others are selected
        if (values.includes('') || e.target.options.length - 1 === values.length) {
            setValue(e.target.name, ['']);
        }
    };

    const _onSubmit = (values: FormValues) => {
        if (requirement) {
            requirement.requirementName = values.requirementName;
            requirement.requirementDesc = values.requirementDesc;
            requirement.applicationTypeIds =
                values.applicationTypeIds && !values.applicationTypeIds.includes('') ? values.applicationTypeIds.map(Number) : [];
            requirement.industryId = parseInt(values.industryId);
            requirement.subIndustryIds =
                values.subIndustryIds && !values.subIndustryIds.includes('') ? values.subIndustryIds.map(Number) : [];
            requirement.required = values.required;
            requirement.internal = values.internal;
            requirement.socialHygiene = values.socialHygiene;
            requirement.waterAnalysis = values.waterAnalysis;

            onClose(requirement);
        } else {
            let newRequirement = {
                requirementName: values.requirementName,
                requirementDesc: values.requirementDesc,
                applicationTypeIds:
                    values.applicationTypeIds && !values.applicationTypeIds.includes('')
                        ? values.applicationTypeIds.map(Number)
                        : [],
                industryId: parseInt(values.industryId),
                subIndustryIds:
                    values.subIndustryIds && !values.subIndustryIds.includes('') ? values.subIndustryIds.map(Number) : [],
                required: values.required,
                internal: values.internal,
                socialHygiene: values.socialHygiene,
                waterAnalysis: values.waterAnalysis
            };

            onClose(newRequirement);
        }
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='RequirementModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.REQUIREMENT}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{labels.REQUIREMENT_NAME}</Form.Label>
                        <Form.Control
                            type='text'
                            name='requirementName'
                            defaultValue={requirement ? requirement.requirementName : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.requirementName}
                        />
                        {!!errors.requirementName && (
                            <Form.Control.Feedback type='invalid'>{errors.requirementName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.REQUIREMENT_DESC}</Form.Label>
                        <Form.Control
                            type='text'
                            name='requirementDesc'
                            defaultValue={requirement ? requirement.requirementDesc : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.requirementDesc}
                        />
                        {!!errors.requirementDesc && (
                            <Form.Control.Feedback type='invalid'>{errors.requirementDesc.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.APP_TYPE}</Form.Label>
                        <Form.Control
                            as='select'
                            name='applicationTypeIds'
                            multiple
                            defaultValue={
                                requirement && requirement.applicationTypeIds && requirement.applicationTypeIds.length > 0
                                    ? requirement.applicationTypeIds.map(String)
                                    : ['']
                            }
                            onChange={_handleAllCheck}
                            ref={register()}
                            isInvalid={!!errors.applicationTypeIds}>
                            {renderApplicationTypeOptions()}
                        </Form.Control>
                        {!!errors.applicationTypeIds && (
                            <Form.Control.Feedback type='invalid'>{errors.applicationTypeIds.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.INDUSTRY}</Form.Label>
                        <Form.Control
                            as='select'
                            name='industryId'
                            defaultValue={requirement ? requirement.industryId : undefined}
                            ref={register()}
                            isInvalid={!!errors.industryId}>
                            {renderIndustryOptions()}
                        </Form.Control>
                        {!!errors.industryId && (
                            <Form.Control.Feedback type='invalid'>{errors.industryId.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {watchIndustry && (
                        <Form.Group>
                            <Form.Label>{labels.SUB_INDUSTRY}</Form.Label>
                            <Form.Control
                                as='select'
                                name='subIndustryIds'
                                multiple
                                defaultValue={
                                    requirement && requirement.subIndustryIds && requirement.subIndustryIds.length > 0
                                        ? requirement.subIndustryIds.map(String)
                                        : ['']
                                }
                                onChange={_handleAllCheck}
                                ref={register()}
                                isInvalid={!!errors.subIndustryIds}>
                                {renderSubIndustryOptions()}
                            </Form.Control>
                            {!!errors.subIndustryIds && (
                                <Form.Control.Feedback type='invalid'>{errors.subIndustryIds.message}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Check
                            type='checkbox'
                            name='required'
                            label={labels.MAND_REQ}
                            defaultChecked={requirement ? requirement.required : false}
                            ref={register()}>
                        </Form.Check>
                    </Form.Group>
                    {permitType === PermitType.IHC && (
                        <>
                            <Form.Group>
                                <Form.Check
                                    type='checkbox'
                                    name='internal'
                                    label={labels.AVAILABLE_INHOUSE}
                                    defaultChecked={requirement ? requirement.internal : false}
                                    ref={register()}></Form.Check>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    type='checkbox'
                                    name='socialHygiene'
                                    label={labels.AVAILABLE_SOCIAL_HYGIENE}
                                    defaultChecked={requirement ? requirement.socialHygiene : false}
                                    ref={register()}>
                                </Form.Check>
                            </Form.Group>
                        </>
                    )}
                    {permitType === PermitType.SP && (
                        <>
                            <Form.Group>
                                <Form.Check
                                    type='checkbox'
                                    name='waterAnalysis'
                                    label={labels.AVAILABLE_WATER_ANALYSIS}
                                    defaultChecked={requirement ? requirement.waterAnalysis : false}
                                    ref={register()}>
                                </Form.Check>
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RequirementModal;
