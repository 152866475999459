import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Card, Row, Col, Form, Button, Spinner, Image } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { find } from 'lodash';

import { labels } from '../../../constants';
import { SanitaryReportStatus, Roles } from '../../../models';
import { ApplicationService, common, modal } from '../../../services';
import { getIndustries } from '../../../actions/config.action';
import { getImageDetails } from '../../../actions/content.action';
import CollapsibleCard from '../CollapsibleCard';

import './styles.scss';

type Props = {
    sanitaryReportData?: any;
    readOnly?: boolean;
    onSubmit: (formData: any) => void;
};

const SanitaryReportForm: React.FunctionComponent<Props> = (props) => {
    const { sanitaryReportData, readOnly, onSubmit } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user.user);
    const { loading: isIndustryLoading, list: industryList } = useSelector((state: any) => state.config.industry);
    const imageList: File[] = useSelector((state: any) => state.content.imageList);
    const standAlone = !sanitaryReportData;
    const isExisting = sanitaryReportData?.sanitaryInspectionReportNo;
    const allowedAction = !readOnly && common.allowedAction([Roles.INSPECTOR], user.authorities);

    const { handleSubmit, register, errors, watch } = useForm({
        mode: 'onBlur'
    });

    const watchIndustryId = watch(
        'industryId',
        sanitaryReportData?.industryId ? sanitaryReportData?.industryId : industryList.length > 0 ? industryList[0].industryId : 0
    );
    const isFood =
        find(industryList, { industryId: parseInt(watchIndustryId) })?.industryName.toLowerCase() === 'food' ? true : false;

    useEffect(() => {
        dispatch(getIndustries());
    }, [dispatch]);

    const _onSubmit = (values: any) => {
        values.industryId = parseInt(values.industryId);
        values.employeeNum = parseInt(values.employeeNum);
        values.employeeNumWithHC = parseInt(values.employeeNumWithHC);
        values.employeeNumWithoutHC = parseInt(values.employeeNumWithoutHC);
        values.ppePersonnel = parseInt(values.ppePersonnel);
        if (values.toiletNo) values.toiletNo = parseInt(values.toiletNo);
        if (values.lavatoryNo) values.lavatoryNo = parseInt(values.lavatoryNo);
        if (values.tapMicroDate) values.tapMicroDate = common.parseDateStringAPI(values.tapMicroDate);
        if (values.tapPhysDate) values.tapPhysDate = common.parseDateStringAPI(values.tapPhysDate);
        if (values.filteredMicroDate) values.filteredMicroDate = common.parseDateStringAPI(values.filteredMicroDate);
        if (values.filteredPhysDate) values.filteredPhysDate = common.parseDateStringAPI(values.filteredPhysDate);
        if (values.iceMicroDate) values.iceMicroDate = common.parseDateStringAPI(values.iceMicroDate);
        if (values.icePhysDate) values.icePhysDate = common.parseDateStringAPI(values.icePhysDate);
        if (values.firstSODate) values.firstSODate = common.parseDateStringAPI(values.firstSODate);
        if (values.secondSODate) values.secondSODate = common.parseDateStringAPI(values.secondSODate);
        if (values.noticeHearingDate) values.noticeHearingDate = common.parseDateStringAPI(values.noticeHearingDate);

        onSubmit(values);
    };

    const renderClassification = () => {
        const renderIndustryOptions = () => {
            let options: JSX.Element[] = [];

            industryList.forEach((industry, index) => {
                options.push(
                    <option key={index} value={industry.industryId}>
                        {industry.industryDesc}
                    </option>
                );
            });

            return options;
        };

        return (
            <CollapsibleCard defaultCollapsed={true}>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.CLASSIFICATION}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{labels.ESTABLISHMENT_NAME}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='establishmentName'
                                    readOnly={!standAlone}
                                    disabled={!allowedAction}
                                    defaultValue={sanitaryReportData?.establishmentName}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.establishmentName}
                                />
                                {!!errors.establishmentName && (
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.establishmentName.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6}>
                            <Form.Group>
                                <Form.Label>{labels.MAYOR_PERMIT}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='permitNumber'
                                    defaultValue={sanitaryReportData?.permitNumber}
                                    readOnly={!standAlone}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.permitNumber}
                                />
                                {!!errors.permitNumber && (
                                    <Form.Control.Feedback type='invalid'>{errors.permitNumber.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6}>
                            <Form.Group>
                                <Form.Label>{labels.OWNER}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='ownerName'
                                    defaultValue={sanitaryReportData?.ownerName}
                                    readOnly={!standAlone}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.ownerName}
                                />
                                {!!errors.ownerName && (
                                    <Form.Control.Feedback type='invalid'>{errors.ownerName.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <fieldset disabled={!standAlone}>
                                <Form.Group>
                                    <Form.Label>{labels.INDUSTRY}</Form.Label>
                                    <Form.Control
                                        as='select'
                                        name='industryId'
                                        defaultValue={sanitaryReportData?.industryId}
                                        disabled={!allowedAction}
                                        ref={register({
                                            required: labels.FIELD_REQUIRED
                                        })}
                                        isInvalid={!!errors.industryId}>
                                        {renderIndustryOptions()}
                                    </Form.Control>
                                    {!!errors.industryId && (
                                        <Form.Control.Feedback type='invalid'>{errors.industryId.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </fieldset>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>{labels.CONTACT_NUMBER}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='contactNumber'
                                    defaultValue={sanitaryReportData?.contactNumber}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.contactNumber}
                                />
                                {!!errors.contactNumber && (
                                    <Form.Control.Feedback type='invalid'>{errors.contactNumber.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{labels.ADDRESS}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='address'
                                    defaultValue={sanitaryReportData?.address}
                                    readOnly={!standAlone}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.address}
                                />
                                {!!errors.address && (
                                    <Form.Control.Feedback type='invalid'>{errors.address.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderEmployees = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.EMPLOYEES}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <Row>
                        <Col xs={12} sm={4}>
                            <Form.Group>
                                <Form.Label>{labels.TOTAL_EMPLOYEES}</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='employeeNum'
                                    defaultValue={sanitaryReportData?.employeeNum}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.employeeNum}
                                />
                                {!!errors.employeeNum && (
                                    <Form.Control.Feedback type='invalid'>{errors.employeeNum.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={8} className='row-group'>
                            <Form.Group>
                                <Form.Label>{labels.WITH_HC}</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='employeeNumWithHC'
                                    defaultValue={sanitaryReportData?.employeeNumWithHC}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.employeeNumWithHC}
                                />
                                {!!errors.employeeNumWithHC && (
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.employeeNumWithHC.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{labels.WO_HC}</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='employeeNumWithoutHC'
                                    defaultValue={sanitaryReportData?.employeeNumWithoutHC}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.employeeNumWithoutHC}
                                />
                                {!!errors.employeeNumWithoutHC && (
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.employeeNumWithoutHC.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{labels.USING_PPE}</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='ppePersonnel'
                                    defaultValue={sanitaryReportData?.ppePersonnel}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.ppePersonnel}
                                />
                                {!!errors.ppePersonnel && (
                                    <Form.Control.Feedback type='invalid'>{errors.ppePersonnel.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderWaterSupply = () => {
        const tapMicro = watch('tapMicro', common.parseBooleanString(sanitaryReportData?.tapMicro));
        const tapPhys = watch('tapPhys', common.parseBooleanString(sanitaryReportData?.tapPhys));
        const filteredMicro = watch('filteredMicro', common.parseBooleanString(sanitaryReportData?.filteredMicro));
        const filteredPhys = watch('filteredPhys', common.parseBooleanString(sanitaryReportData?.filteredPhys));
        const iceMicro = watch('iceMicro', common.parseBooleanString(sanitaryReportData?.iceMicro));
        const icePhys = watch('icePhys', common.parseBooleanString(sanitaryReportData?.icePhys));

        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.WATER_SUPPLY}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.TAP}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='tapMicro'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.tapMicro)}
                                                label={labels.MICROBIOLOGICAL}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={8} className='row-group'>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE_EXAMINATION}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='tapMicroDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.tapMicroDate)}
                                                disabled={!tapMicro || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: tapMicro ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={tapMicro && errors.tapMicroDate}
                                            />
                                            {tapMicro && errors.tapMicroDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.tapMicroDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{labels.QUALITY}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='tapMicroQuality'
                                                defaultValue={sanitaryReportData?.tapMicroQuality}
                                                disabled={!tapMicro || !allowedAction}
                                                ref={register({
                                                    required: tapMicro ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={tapMicro && errors.tapMicroQuality}
                                            />
                                            {tapMicro && errors.tapMicroQuality && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.tapMicroQuality.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='tapPhys'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.tapPhys)}
                                                label={labels.PHYSICAL_CHEMICAL}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={8} className='row-group'>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE_EXAMINATION}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='tapPhysDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.tapPhysDate)}
                                                disabled={!tapPhys || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: tapPhys ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={tapPhys && errors.tapPhysDate}
                                            />
                                            {tapPhys && errors.tapPhysDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.tapPhysDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{labels.QUALITY}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='tapPhysQuality'
                                                defaultValue={sanitaryReportData?.tapPhysQuality}
                                                disabled={!tapPhys || !allowedAction}
                                                ref={register({
                                                    required: tapPhys ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={tapPhys && errors.tapPhysQuality}
                                            />
                                            {tapPhys && errors.tapPhysQuality && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.tapPhysQuality.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='tapRemarks'
                                        defaultValue={sanitaryReportData?.tapRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.FILTERED}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='filteredMicro'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.filteredMicro)}
                                                label={labels.MICROBIOLOGICAL}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={8} className='row-group'>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE_EXAMINATION}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='filteredMicroDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.filteredMicroDate)}
                                                disabled={!filteredMicro || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: filteredMicro ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={filteredMicro && errors.filteredMicroDate}
                                            />
                                            {filteredMicro && errors.filteredMicroDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.filteredMicroDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{labels.QUALITY}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='filteredMicroQuality'
                                                defaultValue={sanitaryReportData?.filteredMicroQuality}
                                                disabled={!filteredMicro || !allowedAction}
                                                ref={register({
                                                    required: filteredMicro ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={filteredMicro && errors.filteredMicroQuality}
                                            />
                                            {filteredMicro && errors.filteredMicroQuality && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.filteredMicroQuality.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='filteredPhys'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.filteredPhys)}
                                                label={labels.PHYSICAL_CHEMICAL}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={8} className='row-group'>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE_EXAMINATION}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='filteredPhysDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.filteredPhysDate)}
                                                disabled={!filteredPhys || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: filteredPhys ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={filteredPhys && errors.filteredPhysDate}
                                            />
                                            {filteredPhys && errors.filteredPhysDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.filteredPhysDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{labels.QUALITY}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='filteredPhysQuality'
                                                defaultValue={sanitaryReportData?.filteredPhysQuality}
                                                disabled={!filteredPhys || !allowedAction}
                                                ref={register({
                                                    required: filteredPhys ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={filteredPhys && errors.filteredPhysQuality}
                                            />
                                            {filteredPhys && errors.filteredPhysQuality && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.filteredPhysQuality.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='filteredRemarks'
                                        defaultValue={sanitaryReportData?.filteredRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.ICE}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='iceMicro'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.iceMicro)}
                                                label={labels.MICROBIOLOGICAL}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={8} className='row-group'>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE_EXAMINATION}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='iceMicroDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.iceMicroDate)}
                                                disabled={!iceMicro || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: iceMicro ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={iceMicro && errors.iceMicroDate}
                                            />
                                            {iceMicro && errors.iceMicroDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.iceMicroDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{labels.QUALITY}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='iceMicroQuality'
                                                defaultValue={sanitaryReportData?.iceMicroQuality}
                                                disabled={!iceMicro || !allowedAction}
                                                ref={register({
                                                    required: iceMicro ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={iceMicro && errors.iceMicroQuality}
                                            />
                                            {iceMicro && errors.iceMicroQuality && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.iceMicroQuality.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='icePhys'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.icePhys)}
                                                label={labels.PHYSICAL_CHEMICAL}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={8} className='row-group'>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE_EXAMINATION}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='icePhysDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.icePhysDate)}
                                                disabled={!icePhys || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: icePhys ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={icePhys && errors.icePhysDate}
                                            />
                                            {icePhys && errors.icePhysDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.icePhysDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{labels.QUALITY}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='icePhysQuality'
                                                defaultValue={sanitaryReportData?.icePhysQuality}
                                                disabled={!icePhys || !allowedAction}
                                                ref={register({
                                                    required: icePhys ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={icePhys && errors.icePhysQuality}
                                            />
                                            {icePhys && errors.icePhysQuality && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.icePhysQuality.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='iceRemarks'
                                        defaultValue={sanitaryReportData?.iceRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderToiletFacilities = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.TOILET_FACILITIES}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.TOILET}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={12} lg={4}>
                                        <Form.Group>
                                            <Form.Label>{labels.NUM_FACILITY}</Form.Label>
                                            <Form.Control
                                                type='number'
                                                name='toiletNo'
                                                defaultValue={sanitaryReportData?.toiletNo}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='toiletSoap'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.toiletSoap)}
                                                label={labels.SOAP}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='toiletPaper'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.toiletPaper)}
                                                label={labels.TOILET_PAPER}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='toiletRemarks'
                                        defaultValue={sanitaryReportData?.toiletRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.LAVATORY}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col lg={12} xl={4}>
                                        <Form.Group>
                                            <Form.Label>{labels.NUM_FACILITY}</Form.Label>
                                            <Form.Control
                                                type='number'
                                                name='lavatoryNo'
                                                defaultValue={sanitaryReportData?.lavatoryNo}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='lavatorySoap'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.lavatorySoap)}
                                                label={labels.SOAP}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='lavatoryPaper'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.lavatoryPaper)}
                                                label={labels.TOILET_PAPER}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='lavatoryDryer'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.lavatoryDryer)}
                                                label={labels.HAND_DRYER}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='lavatoryGarbage'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.lavatoryGarbage)}
                                                label={labels.GARBAGE}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='lavatoryCleanliness'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.lavatoryCleanliness
                                                )}
                                                label={labels.CLEANLINESS}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='lavatoryAccess'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.lavatoryAccess)}
                                                label={labels.W_ACCESS}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} xl={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Label>{labels.DISTANCE_M}</Form.Label>
                                            <Form.Control
                                                type='number'
                                                name='lavatoryDistance'
                                                defaultValue={sanitaryReportData?.lavatoryDistance}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='lavatoryRemarks'
                                        defaultValue={sanitaryReportData?.lavatoryRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderFood = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.FOOD}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.STORAGE}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col sm={12} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='foodWholesome'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.foodWholesome)}
                                                label='Wholesomeness of Food'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='foodPerishable'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.foodPerishable)}
                                                label='Perishable food packed, labeled and stored properly'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='foodStacked'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.foodStacked)}
                                                label='Food stuff stacked at least 20 cm above floor line including dry goods'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='foodOdor'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.foodOdor)}
                                                label='Absence of foul odor'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='foodRemarks'
                                        defaultValue={sanitaryReportData?.foodRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderEquipment = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.EQUIPMENT}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.EQUIPMENT_MAINTENANCE}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentDetergent'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentDetergent)}
                                                label='Washing w/ detergent'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentRinsing'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentRinsing)}
                                                label='Rinsing'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentSanitizing'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.equipmentSanitizing
                                                )}
                                                label='Sanitizing'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentStorage'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentStorage)}
                                                label='Proper Storage'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentChips'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentChips)}
                                                label='Chips & Cracks'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentSingleUse'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentSingleUse)}
                                                label='Single Use'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentClean'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentClean)}
                                                label='Table & Cloth always clean'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentSanitized'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.equipmentSanitized)}
                                                label='Spoon & Forks Sanitized'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='equipmentMaintained'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.equipmentMaintained
                                                )}
                                                label='Kitchen equipment cleaned and well maintained'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='equipmentRemarks'
                                        defaultValue={sanitaryReportData?.equipmentRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderWaste = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.WASTE_DISPOSAL}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.SOLID}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteSolidSegregated'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.wasteSolidSegregated
                                                )}
                                                label='Segregated'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteSolidCovered'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.wasteSolidCovered)}
                                                label='w/ tightly covered receptacle'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteSolidAdequate'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.wasteSolidAdequate)}
                                                label='Adequate'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='wasteSolidRemarks'
                                        defaultValue={sanitaryReportData?.wasteSolidRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.LIQUID}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidGreaseTrap'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.wasteLiquidGreaseTrap
                                                )}
                                                label='Grease Trap'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidTreatment'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.wasteLiquidTreatment
                                                )}
                                                label='Sewage Treatment Plant '
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidDrainage'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.wasteLiquidDrainage
                                                )}
                                                label='Blind Drainage'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidTapped'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.wasteLiquidTapped)}
                                                label='Tapped in Public Sewer Line'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidSeptic'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.wasteLiquidSeptic)}
                                                label='Septic Tank'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidGreaseInterceptor'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.wasteLiquidGreaseInterceptor
                                                )}
                                                label='Grease Interceptor'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='wasteLiquidMaintentance'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.wasteLiquidMaintentance
                                                )}
                                                label='Cleaning & Maintenance'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='wasteLiquidRemarks'
                                        defaultValue={sanitaryReportData?.wasteLiquidRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderVermins = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.INSECTS}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.PRESENCE}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col sm={12} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='verminsPresence'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.verminsPresence)}
                                                label='Presence of Vermins/Insects'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {watch('verminsPresence') && (
                                        <>
                                            <Col xs={6} lg={3} className='checkbox'>
                                                <Form.Group>
                                                    <Form.Check
                                                        type='checkbox'
                                                        name='verminsRats'
                                                        defaultChecked={common.parseBooleanString(
                                                            sanitaryReportData?.verminsRats
                                                        )}
                                                        label='Rats/Mouse'
                                                        disabled={!allowedAction}
                                                        ref={register}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} lg={3} className='checkbox'>
                                                <Form.Group>
                                                    <Form.Check
                                                        type='checkbox'
                                                        name='verminsFlies'
                                                        defaultChecked={common.parseBooleanString(
                                                            sanitaryReportData?.verminsFlies
                                                        )}
                                                        label='Flies'
                                                        disabled={!allowedAction}
                                                        ref={register}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} lg={3} className='checkbox'>
                                                <Form.Group>
                                                    <Form.Check
                                                        type='checkbox'
                                                        name='verminsAnts'
                                                        defaultChecked={common.parseBooleanString(
                                                            sanitaryReportData?.verminsAnts
                                                        )}
                                                        label='Ants'
                                                        disabled={!allowedAction}
                                                        ref={register}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} lg={3} className='checkbox'>
                                                <Form.Group>
                                                    <Form.Check
                                                        type='checkbox'
                                                        name='verminsTermites'
                                                        defaultChecked={common.parseBooleanString(
                                                            sanitaryReportData?.verminsTermites
                                                        )}
                                                        label='Termites'
                                                        disabled={!allowedAction}
                                                        ref={register}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Group>
                                                    <Form.Label>{labels.SPECIFY_LOCATION}</Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name='verminsLocation'
                                                        defaultValue={sanitaryReportData?.verminsLocation}
                                                        disabled={!allowedAction}
                                                        ref={register({
                                                            required: labels.FIELD_REQUIRED
                                                        })}
                                                        isInvalid={!!errors.verminsLocation}
                                                    />
                                                    {!!errors.verminsLocation && (
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.verminsLocation.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='verminsRemarks'
                                        defaultValue={sanitaryReportData?.verminsRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.SERVICE_PROVIDER}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='providerUpdatedPermit'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.providerUpdatedPermit
                                                )}
                                                label='Updated Permit'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={4} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='providerServiceReport'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.providerServiceReport
                                                )}
                                                label='Service Report'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12} sm={4}>
                                                <label>
                                                    <b>Frequency</b>
                                                </label>
                                            </Col>
                                            <Col xs={6} sm={4} className='checkbox'>
                                                <Form.Group>
                                                    <Form.Check
                                                        type='checkbox'
                                                        name='providerFrquencyMonthly'
                                                        defaultChecked={common.parseBooleanString(
                                                            sanitaryReportData?.providerFrquencyMonthly
                                                        )}
                                                        label='Monthly'
                                                        disabled={!allowedAction}
                                                        ref={register}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6} sm={4} className='checkbox'>
                                                <Form.Group>
                                                    <Form.Check
                                                        type='checkbox'
                                                        name='providerFrquencyQuarterly'
                                                        defaultChecked={common.parseBooleanString(
                                                            sanitaryReportData?.providerFrquencyQuarterly
                                                        )}
                                                        label='Quarterly'
                                                        disabled={!allowedAction}
                                                        ref={register}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='providerRemarks'
                                        defaultValue={sanitaryReportData?.providerRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderMaintenance = () => {
        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.GENERAL_MAINTENANCE}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.KITCHEN}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='kitchenRepair'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.kitchenRepair)}
                                                label={labels.NEEDS_REPAIR}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='kitchenUntidy'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.kitchenUntidy)}
                                                label={labels.UNTIDY}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='kitchenPoorLighting'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.kitchenPoorLighting
                                                )}
                                                label={labels.POOR_LIGHTING}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='kitchenPoorVentilation'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.kitchenPoorVentilation
                                                )}
                                                label={labels.POOR_VENTILATION}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='kitchenRemarks'
                                        defaultValue={sanitaryReportData?.kitchenRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.DINING}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='diningRepair'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.diningRepair)}
                                                label={labels.NEEDS_REPAIR}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='diningUntidy'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.diningUntidy)}
                                                label={labels.UNTIDY}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='diningPoorLighting'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.diningPoorLighting)}
                                                label={labels.POOR_LIGHTING}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='diningPoorVentilation'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.diningPoorVentilation
                                                )}
                                                label={labels.POOR_VENTILATION}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='diningRemarks'
                                        defaultValue={sanitaryReportData?.diningRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.TOILET}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='toiletRepair'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.toiletRepair)}
                                                label={labels.NEEDS_REPAIR}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='toiletUntidy'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.toiletUntidy)}
                                                label={labels.UNTIDY}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='toiletPoorLighting'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.toiletPoorLighting)}
                                                label={labels.POOR_LIGHTING}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='toiletPoorVentilation'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.toiletPoorVentilation
                                                )}
                                                label={labels.POOR_VENTILATION}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='toiletRemarks'
                                        defaultValue={sanitaryReportData?.toiletRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.OUTSIDE_PREMISES}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='outsideRepair'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.outsideRepair)}
                                                label={labels.NEEDS_REPAIR}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='outsideUntidy'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.outsideUntidy)}
                                                label={labels.UNTIDY}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='outsidePoorLighting'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.outsidePoorLighting
                                                )}
                                                label={labels.POOR_LIGHTING}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='outsidePoorVentilation'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.outsidePoorVentilation
                                                )}
                                                label={labels.POOR_VENTILATION}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='outsideRemarks'
                                        defaultValue={sanitaryReportData?.outsideRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.OTHER_LOCATION}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='otherRepair'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.otherRepair)}
                                                label={labels.NEEDS_REPAIR}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='otherUntidy'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.otherUntidy)}
                                                label={labels.UNTIDY}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='otherPoorLighting'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.otherPoorLighting)}
                                                label={labels.POOR_LIGHTING}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={3} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='otherPoorVentilation'
                                                defaultChecked={common.parseBooleanString(
                                                    sanitaryReportData?.otherPoorVentilation
                                                )}
                                                label={labels.POOR_VENTILATION}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='otherRemarks'
                                        defaultValue={sanitaryReportData?.otherRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderSanitaryOrder = () => {
        const firstSONo = watch('firstSONo', sanitaryReportData?.firstSONo);
        const secondSONo = watch('secondSONo', sanitaryReportData?.secondSONo);
        const noticeHearing = watch('noticeHearing', common.parseBooleanString(sanitaryReportData?.noticeHearing));

        return (
            <CollapsibleCard>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.SANITARY_ORDER}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.FIRST_ORDER}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.SANITARY_ORDER_NO}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='firstSONo'
                                                defaultValue={sanitaryReportData?.firstSONo}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='firstSODate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.firstSODate)}
                                                disabled={!firstSONo || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: firstSONo ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={firstSONo && errors.firstSODate}
                                            />
                                            {firstSONo && errors.firstSODate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.firstSODate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='firstSORemarks'
                                        defaultValue={sanitaryReportData?.firstSORemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.SECOND_ORDER}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.SANITARY_ORDER_NO}</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='secondSONo'
                                                defaultValue={sanitaryReportData?.secondSONo}
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='secondSODate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.secondSODate)}
                                                disabled={!secondSONo || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: secondSONo ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={secondSONo && errors.secondSODate}
                                            />
                                            {secondSONo && errors.secondSODate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.secondSODate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='secondSORemarks'
                                        defaultValue={sanitaryReportData?.secondSORemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='sub-section-row'>
                        <Row>
                            <Col xs={12}>
                                <label className='section-label'>{labels.NOTICE_HEARING}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12} sm={6} className='checkbox'>
                                        <Form.Group>
                                            <Form.Check
                                                type='checkbox'
                                                name='noticeHearing'
                                                defaultChecked={common.parseBooleanString(sanitaryReportData?.noticeHearing)}
                                                label='For Notice of Hearing'
                                                disabled={!allowedAction}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Label>{labels.DATE}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='noticeHearingDate'
                                                defaultValue={common.parseDateString(sanitaryReportData?.noticeHearingDate)}
                                                disabled={!noticeHearing || !allowedAction}
                                                max={common.parseDateToString()}
                                                ref={register({
                                                    required: noticeHearing ? labels.FIELD_REQUIRED : false
                                                })}
                                                isInvalid={noticeHearing && errors.noticeHearingDate}
                                            />
                                            {noticeHearing && errors.noticeHearingDate && (
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.noticeHearingDate.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={5}>
                                <Form.Group>
                                    <Form.Label>{labels.REMARKS}</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        name='noticeHearingRemarks'
                                        defaultValue={sanitaryReportData?.noticeHearingRemarks}
                                        disabled={!allowedAction}
                                        ref={register}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderComments = () => {
        const renderStatusOptions = () => {
            let options: JSX.Element[] = [];

            Object.keys(SanitaryReportStatus).forEach((key, index) => {
                options.push(
                    <option key={index} value={key}>
                        {SanitaryReportStatus[key]}
                    </option>
                );
            });

            return options;
        };

        return (
            <CollapsibleCard defaultCollapsed={true}>
                <CollapsibleCard.Header className='section-header'>
                    <h5>{labels.COMMENTS_RECOM}</h5>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12} className='checkbox'>
                                    <Form.Group>
                                        <Form.Check
                                            type='checkbox'
                                            name='commentCompliance'
                                            disabled={!allowedAction}
                                            defaultChecked={common.parseBooleanString(sanitaryReportData?.commentCompliance)}
                                            label='May issue Sanitary Permit'
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className='checkbox'>
                                    <Form.Group>
                                        <Form.Check
                                            type='checkbox'
                                            name='commentReinspection'
                                            disabled={!allowedAction}
                                            defaultChecked={common.parseBooleanString(sanitaryReportData?.commentReinspection)}
                                            label='For Re-inspection subject to compliance to Sanitary Order issued'
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className='checkbox'>
                                    <Form.Group>
                                        <Form.Check
                                            type='checkbox'
                                            name='commentRevocation'
                                            disabled={!allowedAction}
                                            defaultChecked={common.parseBooleanString(sanitaryReportData?.commentRevocation)}
                                            label='For revocation of Sanitary Permit due to non - compliance of Sanitary Rules and Regulation'
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className='checkbox'>
                                    <Form.Group>
                                        <Form.Check
                                            type='checkbox'
                                            name='commentIssuance'
                                            disabled={!allowedAction}
                                            defaultChecked={common.parseBooleanString(sanitaryReportData?.commentIssuance)}
                                            label='For Issuance of Cease and Desist'
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={5}>
                            <Form.Group>
                                <Form.Label>{labels.STATUS}</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='sanitaryInspectionReportStatus'
                                    defaultValue={sanitaryReportData?.sanitaryInspectionReportStatus}
                                    disabled={!allowedAction}
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.sanitaryInspectionReportStatus}>
                                    {renderStatusOptions()}
                                </Form.Control>
                                {!!errors.sanitaryInspectionReportStatus && (
                                    <Form.Control.Feedback type='invalid'>{errors.sanitaryInspectionReportStatus.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        );
    };

    const renderSignature = () => {
        let submittedBy = sanitaryReportData?.submittedBy;
        let sig;
        let info;

        if (submittedBy) {
            info = submittedBy;
        } else {
            info = user;
        }

        if (info.signatureId) {
            sig = find(imageList, { id: info.signatureId });

            if (!sig) {
                dispatch(getImageDetails(info.signatureId));
            }
        }

        return (
            <div className='section-signature'>
                <div className='inspector-signature'>
                    {sig && sig.imageUrl && <Image src={sig.imageUrl} />}
                    {info && <label className='name'>{common.parseUserFullname(info)}</label>}
                    <label className='role'>{labels.SANITARY_INSPECTOR}</label>
                </div>
            </div>
        );
    };

    const _handleDelete = () => {
        if (isExisting && sanitaryReportData) {
            modal.displayConfirmation(
                labels.dialog.message.SR_DELETE_CONFIRM.replace('{SR}', sanitaryReportData.sanitaryInspectionReportNo),
                () => {
                    ApplicationService.deleteSanitaryReport(sanitaryReportData.sanitaryInspectionReportId)
                        .then(() => {
                            navigate(-1);
                        })
                        .catch((err) => console.error(err));
                }
            );
        }
    };

    return (
        <div className='SanitaryReportForm'>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Card>
                    {isIndustryLoading && (
                        <Row>
                            <Col>
                                <div className='center-content'>
                                    <Spinner animation='border' variant='primary' />
                                </div>
                            </Col>
                        </Row>
                    )}
                    {!isIndustryLoading && (
                        <>
                            <Card.Body className='p-0'>
                                <>
                                    {renderClassification()}
                                    {renderEmployees()}
                                    {renderWaterSupply()}
                                    {renderToiletFacilities()}
                                    {isFood && renderFood()}
                                    {isFood && renderEquipment()}
                                    {renderWaste()}
                                    {renderVermins()}
                                    {renderMaintenance()}
                                    {renderSanitaryOrder()}
                                    {renderComments()}
                                    {renderSignature()}
                                </>
                            </Card.Body>

                            {common.allowedAction([Roles.INSPECTOR], user.authorities) && (
                                <Card.Footer>
                                    {isExisting && (
                                        <Button variant='outline-secondary' onClick={_handleDelete}>
                                            <FaRegTrashAlt />
                                        </Button>
                                    )}
                                    <Button className='submit' type='submit'>
                                        {isExisting ? labels.SAVE_CHANGES : labels.SUBMIT}
                                    </Button>
                                </Card.Footer>
                            )}
                        </>
                    )}
                </Card>
            </Form>
        </div>
    );
};

export default SanitaryReportForm;
