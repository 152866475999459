import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Table, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { find } from 'lodash';

import { labels, config } from '../../../constants';
import {
    Application,
    PermitType,
    Requirement,
    Upload,
    OrderOfPaymentData,
    NestedOrderOfPayment,
    CreatePayment,
    PaymentDetail
} from '../../../models';
import { ConfigService, common, modal } from '../../../services';

import './styles.scss';

type Props = {
    show: boolean;
    requirements: Requirement[];
    uploads: Upload[];
    application: Application;
    permitType: PermitType;
    isYellow?: boolean;
    onClose: (paymentData?: CreatePayment) => void;
};

const CreatePaymentModal: React.FunctionComponent<Props> = (props) => {
    const { show, requirements, uploads, application, permitType, isYellow, onClose } = props;
    const [isLoading, setLoading] = useState(true);
    const [isPESO, setIsPESO] = useState(false);
    const [orderOfPaymentData, setOrderOfPaymentData] = useState<OrderOfPaymentData>();

    const getOrderOfPayments = () => {
        if (show) {
            //Check if peso application
            let formData: any = application.formData;

            setIsPESO(common.parseBooleanString(formData?.isPESO));

            setLoading(true);
            ConfigService.getOrderOfPayments(application.permitId)
                .then((response: any) => {
                    if (response.body.data && response.body.data.length > 0) {
                        setOrderOfPaymentData(common.createOrderOfPaymentData(response.body.data));
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        }
    };

    useEffect(getOrderOfPayments, [show]);

    const getChildFees = (children: NestedOrderOfPayment[], result: number = 0) => {
        let r = result;
        children.forEach((child) => {
            if (child.child && child.child.length > 0) {
                r = r + getChildFees(child.child, result);
            } else {
                r = r + (child.selected && child.amount ? child.amount : 0);
            }
        });

        return r;
    };

    const itemSelect = (item) => {
        item.selected = !item.selected;
        let newOrderOfPaymentData = Object.assign({}, orderOfPaymentData);
        setOrderOfPaymentData(newOrderOfPaymentData);
    };

    const itemAmount = (item, value) => {
        item.amount = parseFloat(value);
        let newOrderOfPaymentData = Object.assign({}, orderOfPaymentData);
        setOrderOfPaymentData(newOrderOfPaymentData);
    };

    const createPaymentDetails = (order: NestedOrderOfPayment[], paymentDetails: PaymentDetail[] = []) => {
        let result = paymentDetails;

        order.forEach((item) => {
            let hasChild = item.child && item.child.length > 0;

            if (item.child && hasChild) {
                createPaymentDetails(item.child, result);
            } else if (item.selected) {
                let requirement: Requirement = find(requirements, (req: Requirement) => { return req.inactive === false && (req.requirementId === item.requirementId || item.requirementIds?.includes(req.requirementId))});
                result.push({
                    orderOfPaymentId: item.orderOfPaymentId,
                    price: item.amount ? item.amount : 0,
                    laboratory: requirement && requirement.internal ? true : false,
                    socialHygiene: requirement && requirement.socialHygiene ? true : false,
                    waterAnalysis: requirement && requirement.waterAnalysis ? true : false
                });
            }
        });

        return result;
    };

    const _handleCreatePayment = () => {
        if (orderOfPaymentData) {
            let totalAmount = config.CURRENCY + ' ' + (isPESO ? 0 : getChildFees(orderOfPaymentData.list));

            modal.displayConfirmation(labels.dialog.message.CREATE_PAYMENT_CONFIRM.replace('{amount}', totalAmount), () => {
                let details = createPaymentDetails(orderOfPaymentData.list);
                let payment: CreatePayment = {
                    applicationId: application.applicationId,
                    peso: isPESO,
                    detailRequestDtos: details
                };

                onClose(payment);
            });
        }
    };

    const renderRows = (rowDatas: NestedOrderOfPayment[]) => {
        let rows = renderPaymentRows(rowDatas);

        //Space
        rows.push(
            <tr key={999}>
                <td colSpan={3}></td>
            </tr>
        );

        //Total amount row
        rows.push(
            <tr key={1000}>
                <td colSpan={2}>
                    <div className='peso-group'>
                        <span>
                            {permitType === PermitType.IHC && (
                                <Form.Check
                                    type='checkbox'
                                    label={labels.PESO_APPLICANT}
                                    checked={isPESO}
                                    onChange={() => setIsPESO(!isPESO)}
                                />
                            )}
                        </span>
                        <span>
                            <b>{labels.TOTAL}</b>
                        </span>
                    </div>
                </td>
                <td colSpan={1}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>{config.CURRENCY}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type='number' disabled={true} value={isPESO ? 0 : getChildFees(rowDatas)} />
                    </InputGroup>
                </td>
            </tr>
        );

        return rows;
    };

    const renderPaymentRows = (rowDatas: NestedOrderOfPayment[], rows: JSX.Element[] = [], index: number = 0) => {
        let r = rows;

        rowDatas.forEach((item, i) => {
            let hasChild = item.child && item.child.length > 0;
            let formData: any = application.formData;

            //Init selected if inHouse applicant
            if (!hasChild && common.parseBooleanString(formData?.isInternal) && item.selected === undefined) {
                let requirementUpload = find(uploads, (upload: Upload) => { return upload.requirementId === item.requirementId || item.requirementIds?.includes(upload.requirementId) });
                
                let clReq = find(requirements, { requirementId: item.requirementId, internal: true, inactive: false });
                // let shlReq = find(requirements, { requirementId: item.requirementId, socialHygiene: true, inactive: false });
                let walReq = find(requirements, { requirementId: item.requirementId, waterAnalysis: true, inactive: false });

                //Order if payment required for lab requirement with missing upload
                if ((clReq || walReq) && !requirementUpload) {
                    item.selected = true;
                    item.disabled = true;
                } else {
                    item.selected = false;
                }
            }

            //Disable HIV order of payment for renewal
            if (
                !hasChild &&
                item.orderOfPaymentName?.toLowerCase().includes('hiv') &&
                application.formData.type?.toLowerCase().includes('renew')
            ) {
                item.disabled = true;
            }

            if (!hasChild && isYellow && item.orderOfPaymentName?.toLowerCase().includes('vd clearance')) {
                item.selected = true;
            }

            r.push(
                <tr key={index + '_' + i}>
                    <td>
                        {!hasChild && (
                            <Form.Check
                                type='checkbox'
                                checked={item.selected ? true : false}
                                disabled={item.disabled}
                                onChange={() => itemSelect(item)}
                            />
                        )}
                    </td>
                    <td
                        className={
                            'pl-' + (item.depth ? Math.ceil(item.depth * 2.5) : 3) + (hasChild ? ' font-weight-bold' : '')
                        }>
                        {item.orderOfPaymentName}
                    </td>
                    <td>
                        {!hasChild && (
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{config.CURRENCY}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type='number'
                                    min={0}
                                    disabled={!item.selected}
                                    defaultValue={item.amount ? item.amount : 0}
                                    onChange={(e) => itemAmount(item, e.target.value)}
                                />
                            </InputGroup>
                        )}
                        {item.child && (
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <b>{labels.TOTAL}</b>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{config.CURRENCY}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type='number' disabled={true} value={getChildFees(item.child)} />
                            </InputGroup>
                        )}
                    </td>
                </tr>
            );

            if (item.child && hasChild) {
                renderPaymentRows(item.child, r, i + 1);
            }
        });

        return r;
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='CreatePaymentModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.ORDER_PAYMENT}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='detail-row'>
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.APPLICATION_TYPE)}
                            <span>{application.formData.type}</span>
                        </span>
                    </Col>
                </Row>
                {isLoading && (
                    <div className='center-content'>
                        <Spinner animation='border' variant='primary' />
                    </div>
                )}
                {!isLoading && (!orderOfPaymentData || orderOfPaymentData.list.length === 0) && (
                    <div className='center-content'>
                        <span>No data available</span>
                    </div>
                )}
                {!isLoading && orderOfPaymentData && orderOfPaymentData.list.length > 0 && (
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th>{labels.SELECT}</th>
                                <th>{labels.REQUIREMENTS}</th>
                                <th>{labels.AMOUNT}</th>
                            </tr>
                        </thead>
                        <tbody>{renderRows(orderOfPaymentData.list)}</tbody>
                    </Table>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={() => _handleCreatePayment()}>
                    {labels.CREATE_PAYMENT}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreatePaymentModal;
