import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Spinner, Table } from 'react-bootstrap';
import { find, filter } from 'lodash';

import { labels } from '../../../constants';
import { Upload, Status, Application, PermitType, Employee, Requirement } from '../../../models';
import { ApplicationService, common } from '../../../services';
import Paginator from '../Paginator';
import ViewRequirementModal from '../ViewRequirementModal';
import InfoTooltip from '../InfoTooltip';
import CertificateModal from '../CertificateModal';
import UploadHistoryModal from '../UploadHistoryModal';
import WarningTooltip from '../WarningTooltip';

import './styles.scss';

type Props = {
    show: boolean;
    application: Application;
    permitType: PermitType;
    viewOnly?: boolean;
    onClose: (hasUpdate: boolean) => void;
};

type UploadData = {
    upload: Upload;
    requirementName: string;
};

const EmployeeRequirementModal: React.FunctionComponent<Props> = (props) => {
    const { show, application, permitType, viewOnly, onClose } = props;
    const requirementList = useSelector((state: any) => state.config.requirement.list);
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [uploads, setUploads] = useState<Upload[]>([]);
    const [uploadData, setUploadData] = useState<UploadData>();
    const [employeeApp, setEmployeeApp] = useState<Application>();
    const [showCertificate, setShowCertificate] = useState(false);
    const [activeUploadHistory, setActiveUploadHistory] = useState<{ requirementName: string; uploadHistory: Upload[] }>();
    const [hasUpdate, setHasUpdate] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const [total, setTotal] = useState(0);
    const limit = 10;

    const getEmployees = () => {
        setLoading(true);
        ApplicationService.getBulkEmployeesHC(application.applicationId, pageNo, limit)
            .then((response) => {
                let data = response.body.data;
                setEmployees(data.details);
                setTotal(data.totalCount);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const getUploads = () => {
        if (employees.length > 0) {
            let manualUploadEmployeeIds: number[] = [];
            employees.forEach((employee) => {
                if (!employee.healthCertNumber) {
                    manualUploadEmployeeIds.push(employee.employeeId);
                }
            });

            if (manualUploadEmployeeIds.length > 0) {
                ApplicationService.getBulkEmployeesUploads(manualUploadEmployeeIds)
                    .then((response) => {
                        setUploads(response.body.data);
                    })
                    .catch((err) => console.error(err))
                    .finally(() => setLoading(false));
            } else {
                setLoading(false);
            }
        }
    };

    const getUploadHistory = (employeeId: number, requirement: Requirement) => {
        ApplicationService.getEmployeeUploadHistory(employeeId)
        .then((response) => {
            let uploadHistory: Upload[] = filter(response.body.data, { requirementId: requirement.requirementId });
            uploadHistory.reverse();
            setActiveUploadHistory({requirementName: requirement.requirementName, uploadHistory});
        })
        .catch((err) => console.error(err))
    };

    useEffect(getEmployees, [pageNo]);
    useEffect(getUploads, [employees]);

    const _handleUploadClose = (updated: boolean) => {
        setUploadData(undefined);
        if (updated) {
            setHasUpdate(true);
            getUploads();
        }
    };

    const _handleViewCertificate = (certifcateNo: string) => {
        ApplicationService.getApplicationByCertificateNo(certifcateNo)
            .then((res) => {
                setEmployeeApp(res.body.data);
                setShowCertificate(true);
            })
            .catch((err) => console.error(err));
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        if (isLoading) {
            rows.push(
                <tr key={1}>
                    <td colSpan={100}>
                        <div className='center-content'>
                            <Spinner animation='border' variant='primary' />
                        </div>
                    </td>
                </tr>
            );
        } else {
            employees.forEach((employee, index) => {
                let certNumber = employee.healthCertNumber;
                let employeeIndex = index;
                if (certNumber) {

                    rows.push(
                        <tr key={employeeIndex}>
                            <td>
                                {common.parseFullname(employee)}
                                {employee.employeeExist && <WarningTooltip message='Employee exist on other applications.' />}
                            </td>
                            <td className='passed'>{labels.COMPLETED}</td>
                            <td>
                                <span className='link' onClick={() => _handleViewCertificate(certNumber)}>
                                    {certNumber}
                                </span>
                            </td>
                        </tr>
                    );
                } else {
                    let employeeUploads = filter(uploads, { employeeId: employee.employeeId });

                    if (employeeUploads.length > 0) {
                        employeeUploads.forEach((upload, index) => {
                            let employeeUploadIndex = index;
                            let className = '';

                            if (upload.status === Status.FOR_REVIEW) {
                                className = 'status';
                            } else if (upload.status === Status.REQUIRES_UPDATE) {
                                className = 'failed';
                            } else if (upload.status === Status.COMPLETED) {
                                className = 'passed';
                            }

                            let requirement = find(requirementList, { requirementId: upload.requirementId });

                            rows.push(
                                <tr key={employeeIndex + '-' + employeeUploadIndex}>
                                    {employeeUploadIndex === 0 && (
                                        <td rowSpan={employeeUploads.length}>
                                            {common.parseFullname(employee)}
                                            {employee.employeeExist && (
                                                <WarningTooltip message='Employee exist on other applications.' />
                                            )}
                                        </td>
                                    )}
                                    <td className='text-center'>
                                        <span className={className}>
                                            {upload.status}
                                            {upload.remarks && <InfoTooltip message={upload.remarks} />}

                                        </span>
                                        <span
                                            className='link history'
                                            onClick={() => getUploadHistory(employee.employeeId, requirement)}>
                                            View History
                                        </span>
                                    </td>
                                    <td
                                        className='action'
                                        onClick={() => setUploadData({ upload, requirementName: requirement.requirementName })}>
                                        {requirement.requirementName}
                                    </td>
                                </tr>
                            );
                        });
                    } else {
                        rows.push(
                            <tr key={employeeIndex}>
                                <td>
                                    {common.parseFullname(employee)}
                                    {employee.employeeExist && <WarningTooltip message='Employee exist on other applications.' />}
                                </td>
                                <td>No Upload</td>
                                <td></td>
                            </tr>
                        );
                    }


                }
            });
        }

        return rows;
    };

    return (
        <Modal show={show} onHide={() => onClose(hasUpdate)} centered dialogClassName='EmployeeRequirementModal'>
            <Modal.Header closeButton>
                <Modal.Title>{isLoading ? labels.EMPLOYEES : total + ' ' + labels.EMPLOYEES}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive bordered hover>
                    <thead>
                        <tr>
                            <th>{labels.NAME}</th>
                            <th>{labels.STATUS}</th>
                            <th>{labels.HEALTH}</th>
                        </tr>
                    </thead>
                    <tbody>{renderRows()}</tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Paginator
                    currentPage={pageNo}
                    maxPage={Math.ceil(total / limit)}
                    _handlePageChange={(pageNumber) => setPageNo(pageNumber)}
                />
            </Modal.Footer>
            {uploadData && application && (
                <ViewRequirementModal
                    application={application}
                    permitType={permitType}
                    upload={uploadData.upload}
                    requirementName={uploadData?.requirementName}
                    viewOnly={viewOnly}
                    onClose={(updated) => _handleUploadClose(updated)}
                />
            )}
            {employeeApp && (
                <CertificateModal
                    show={showCertificate}
                    application={employeeApp}
                    viewOnly={true}
                    onClose={() => setShowCertificate(false)}
                />
            )}
            {application && activeUploadHistory && (
                <UploadHistoryModal
                    application={application}
                    permitType={permitType}
                    requirementName={activeUploadHistory.requirementName}
                    uploadHistory={activeUploadHistory.uploadHistory}
                    onClose={() => setActiveUploadHistory(undefined)}
                />
            )}
        </Modal>
    );
};

export default EmployeeRequirementModal;
