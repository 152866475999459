import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { find, filter } from 'lodash';

import { labels } from '../../../constants';
import { Status, PermitType, LookUpType, ApplicationType, SanitaryReportStatus } from '../../../models';
import { getIndustries, getApplicationTypes, getLookUpInfoByType } from '../../../actions/config.action';
import { common, device } from '../../../services';
import CollapsibleCard from '../CollapsibleCard';

import './styles.scss';

interface Props {
    permitType?: PermitType;
    isLaboratory?: boolean;
    columns?: string[];
    defaultCollapsed?: boolean;
    initForm?: SearchForm;
    onSubmit: (formData: SearchForm) => void;
}

export interface SearchRef {
    getValues: () => SearchForm;
}

const searchObj = {
    applicationId: null,
    bulkApplicationId: null,
    dateFrom: null,
    dateTo: null,
    login: null,
    lastName: null,
    firstName: null,
    establishmentName: null,
    permitNumber: null, //For SP report
    mayorPermitNo: null, //For SP
    applicationTypeId: null,
    isPESO: null,
    industryId: null,
    occupation: null,
    barangay: null, //Client input
    establishmentBarangay: null, //For integrated data
    district: null,
    contactNumber: null,
    officialReceipt: null,
    status: null,
    approvedDateFrom: null,
    approvedDateTo: null
};

type TKeys = keyof typeof searchObj;

export type SearchForm = {
    [key in TKeys]?: string;
};

const SearchFilter = forwardRef<SearchRef, Props>((props, ref) => {
    const { permitType, isLaboratory, columns, defaultCollapsed, initForm, onSubmit } = props;
    const [searchParams, setSearchParams] = useSearchParams();

    //handle form initialization
    const form: SearchForm = {};
    searchParams.forEach((value, key) => {
        if (searchObj.hasOwnProperty(key)) {
            form[key] = value;
        }
    });
    const defaultForm = {
        ...initForm,
        ...form
    };

    const dispatch = useDispatch();
    const isMobile = device.useMobileCheck();
    const applicationType = useSelector((state: any) => state.config.applicationType);
    const industryList = useSelector((state: any) => state.config.industry.list);
    const lookUps = useSelector((state: any) => state.config.lookUps.list);
    const lookUpInfos = useSelector((state: any) => state.config.lookUpInfos.list);
    const [searchCollapsed, setSearchCollapsed] = useState(Object.keys(defaultForm).length > 0 ? true : defaultCollapsed);
    const currentDate = new Date();

    const { handleSubmit, register, watch, reset, errors, getValues } = useForm<SearchForm>({
        defaultValues: defaultForm
    });

    const isApprovedFilter = watch('status') === Status.COMPLETED;

    useImperativeHandle(ref, () => ({
        getValues: () => {
            return getValues();
        }
    }));

    useEffect(() => {
        dispatch(getIndustries());
        dispatch(getApplicationTypes());
        dispatch(getLookUpInfoByType(LookUpType.OCCUPATION));
    }, [dispatch]);

    const setParams = (values: SearchForm) => {
        let params = {};

        Object.keys(values).forEach((key) => {
            if (values[key] && values[key].length > 0) {
                params[key] = values[key];
            }
        });

        setSearchParams(params, { replace: true });
    };

    const _handleSearch = (values: SearchForm) => {
        onSubmit(values);
        setParams(values);
        if (isMobile) setSearchCollapsed(false);
    };

    const renderApplicationTypeOptions = () => {
        let options: JSX.Element[] = [];
        let list: ApplicationType[] = [];

        if (permitType === PermitType.IHC) {
            list = filter(applicationType.list, { applicableToIHC: true });
        } else if (permitType === PermitType.SP) {
            list = filter(applicationType.list, { applicableToSP: true });
        }

        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        list.forEach((appType, index) => {
            options.push(
                <option key={index + 1} value={appType.applicationTypeId}>
                    {appType.value}
                </option>
            );
        });

        return options;
    };

    const renderIndustryOptions = () => {
        let options: JSX.Element[] = [];

        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        industryList.forEach((industry, index) => {
            options.push(
                <option key={index + 1} value={industry.industryId}>
                    {industry.industryDesc}
                </option>
            );
        });

        return options;
    };

    const renderOccupationOptions = (): JSX.Element[] => {
        let options: JSX.Element[] = [];
        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        let occupationLookUp = find(lookUps, { name: LookUpType.OCCUPATION });

        if (occupationLookUp) {
            let occpuationLookUpInfos = filter(lookUpInfos, { lookUpId: occupationLookUp.lookUpId });

            occpuationLookUpInfos.forEach((occupation, index) => {
                options.push(<option key={index + 1}>{occupation.value}</option>);
            });
        }

        return options;
    };

    const renderStatusOptions = (): JSX.Element[] => {
        let options: JSX.Element[] = [];

        if (!isLaboratory) {
            options.push(
                <option key={0} value={''}>
                    All
                </option>
            );
        }

        if (isLaboratory) {
            options.push(<option key={1}>{Status.PENDING_RESULT}</option>);
            options.push(<option key={2}>{Status.PENDING_SPECIMEN}</option>);
            options.push(<option key={3}>{Status.PENDING_APPROVAL}</option>);
            options.push(<option key={4}>{Status.COMPLETED_RESULT}</option>);
        } else {
            options.push(<option key={1}>{Status.FOR_REVIEW}</option>);
            options.push(<option key={2}>{Status.ON_HOLD}</option>);
            options.push(<option key={3}>{Status.REQUIRES_UPDATE}</option>);
            options.push(<option key={4}>{Status.REJECTED}</option>);
            options.push(<option key={5}>{Status.VALIDATED}</option>);
            options.push(<option key={6}>{Status.PAYMENT_CREATED}</option>);
            options.push(
                <option key={7} value={Status.PAYMENT_SUBMITTED}>
                    {labels.PAYMENT_VALIDATION}
                </option>
            );
            options.push(
                <option key={8} value={Status.HEAD_APPROVAL}>
                    {labels.WAITING_APPROVAL}
                </option>
            );
            options.push(
                <option key={9} value={Status.COMPLETED}>
                    {labels.COMPLETED}
                </option>
            );
        }

        return options;
    };

    const renderSRStatusOptions = () => {
        let options: JSX.Element[] = [];

        options.push(
            <option key={0} value={''}>
                All
            </option>
        );

        Object.keys(SanitaryReportStatus).forEach((key, index) => {
            options.push(
                <option key={index + 1} value={key}>
                    {SanitaryReportStatus[key]}
                </option>
            );
        });

        return options;
    };

    return (
        <div className='SearchFilter'>
            <CollapsibleCard collapsed={searchCollapsed} onClick={() => setSearchCollapsed(!searchCollapsed)}>
                <CollapsibleCard.Header>
                    <span>{labels.SEARCH_FILTER}</span>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <Form onSubmit={handleSubmit(_handleSearch)}>
                        <Row>
                            {(columns?.includes('dateFrom') || permitType) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.FROM_DATE_APPLIED}</Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='dateFrom'
                                            defaultValue={common.parseDateToString(
                                                new Date(new Date().setDate(currentDate.getDate() - 7))
                                            )}
                                            max={watch('dateTo', common.parseDateToString())}
                                            ref={register({
                                                validate: (value) =>
                                                    watch('dateTo')
                                                        ? common.isPastDate(value, watch('dateTo'))
                                                        : true || 'Past date'
                                            })}
                                            isInvalid={!!errors.dateFrom}
                                        />
                                        {!!errors.dateFrom && (
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.dateFrom.message}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('dateTo') || permitType) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.TO_DATE_APPLIED}</Form.Label>
                                        <Form.Control
                                            type='date'
                                            name='dateTo'
                                            defaultValue={common.parseDateToString()}
                                            max={common.parseDateToString()}
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('applicationTypeId') || permitType) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.APPLICATION_TYPE}</Form.Label>
                                        <Form.Control as='select' name='applicationTypeId' ref={register}>
                                            {renderApplicationTypeOptions()}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('isPESO') || permitType === PermitType.IHC) && (
                                <Col xs={12} sm={6} lg={4} xl={3} className='checkbox'>
                                    <Form.Group>
                                        <Form.Check type='checkbox' name='isPESO' label={labels.PESO_APPLICANT} ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('industryId') || permitType) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.INDUSTRY}</Form.Label>
                                        <Form.Control as='select' name='industryId' ref={register}>
                                            {renderIndustryOptions()}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('applicationId') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.APPLICATION_ID}</Form.Label>
                                        <Form.Control type='number' name='applicationId' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('bulkApplicationId') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.BULK_APP_ID}</Form.Label>
                                        <Form.Control type='number' name='bulkApplicationId' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('login') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.USERNAME}</Form.Label>
                                        <Form.Control type='text' name='login' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('lastName') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.LAST_NAME}</Form.Label>
                                        <Form.Control type='text' name='lastName' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('firstName') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.FIRST_NAME}</Form.Label>
                                        <Form.Control type='text' name='firstName' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('establishmentName') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.ESTABLISHMENT_NAME}</Form.Label>
                                        <Form.Control type='text' name='establishmentName' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('permitNumber') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.MAYOR_PERMIT}</Form.Label>
                                        <Form.Control type='text' name='permitNumber' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('mayorPermitNo') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.MAYOR_PERMIT}</Form.Label>
                                        <Form.Control type='text' name='mayorPermitNo' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('sanitaryInspectionReportStatus') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.STATUS}</Form.Label>
                                        <Form.Control as='select' name='sanitaryInspectionReportStatus' ref={register}>
                                            {renderSRStatusOptions()}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('occupation') || permitType === PermitType.IHC) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.OCCUPATION}</Form.Label>
                                        <Form.Control as='select' name='occupation' ref={register}>
                                            {renderOccupationOptions()}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('barangay') || permitType) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.BARANGAY}</Form.Label>
                                        <Form.Control type='text' name='barangay' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('establishmentBarangay')) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.BARANGAY}</Form.Label>
                                        <Form.Control type='text' name='establishmentBarangay' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('district')) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.DISTRICT}</Form.Label>
                                        <Form.Control type='text' name='district' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('contactNumber') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.CONTACT_NUMBER}</Form.Label>
                                        <Form.Control type='text' name='contactNumber' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {columns?.includes('officialReceipt') && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.OR_NO}</Form.Label>
                                        <Form.Control type='text' name='officialReceipt' ref={register} />
                                    </Form.Group>
                                </Col>
                            )}
                            {(columns?.includes('status') || permitType) && (
                                <Col xs={12} sm={6} lg={4} xl={3}>
                                    <Form.Group>
                                        <Form.Label>{labels.APPLICATION_STATUS}</Form.Label>
                                        <Form.Control
                                            as='select'
                                            name='status'
                                            ref={register({
                                                required: isLaboratory ? labels.FIELD_REQUIRED : undefined
                                            })}
                                            isInvalid={!!errors.status}>
                                            {renderStatusOptions()}
                                        </Form.Control>
                                        {!!errors.status && (
                                            <Form.Control.Feedback type='invalid'>{errors.status.message}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            )}

                            {isApprovedFilter && (
                                <>
                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                        <Form.Group>
                                            <Form.Label>{labels.FROM_DATE_APPROVED}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='approvedDateFrom'
                                                max={common.parseDateToString()}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                        <Form.Group>
                                            <Form.Label>{labels.TO_DATE_APPROVED}</Form.Label>
                                            <Form.Control
                                                type='date'
                                                name='approvedDateTo'
                                                max={common.parseDateToString()}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant='outline-secondary'
                                    className='float-left filter-button'
                                    onClick={() => reset({})}>
                                    {labels.CLEAR}
                                </Button>
                            </Col>
                            <Col>
                                <Button type='submit' variant='primary' className='float-right filter-button'>
                                    {labels.SEARCH}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        </div>
    );
});

export default SearchFilter;
