import React from 'react';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import { PermitType } from '../../../models';
import LabType from '../../../models/enums/LabType';
import ApplicationSearch from '../../components/ApplicationSearch';

import './styles.scss';

type Props = {};

const WaterAnalysisLabGenerateReport: React.FunctionComponent<Props> = () => {
    return (
        <div className='SHCGenerateReport'>
            <PageHeader title={labels.WATER_ANALYSIS} subTitle={labels.GENERATE_REPORT} />
            <PageBody>
                <ApplicationSearch permitType={PermitType.SP} isLaboratory={true} labType={LabType.WATER_ANALYSIS} />
            </PageBody>
        </div>
    );
};

export default WaterAnalysisLabGenerateReport;
