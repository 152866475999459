import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Card, Row, Col, Form, Button, Image, Spinner, InputGroup } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { GiFountainPen } from 'react-icons/gi';
import { FaSignature, FaEye, FaEyeSlash } from 'react-icons/fa';
import { find } from 'lodash';

import { labels, regexp } from './../../../constants';
import { Position, Permit, CreateUser, Roles, User, Departments } from '../../../models';
import { common, modal, AuthService, ApplicationService } from './../../../services';
import { logout, setLogin } from './../../../actions/user.action';
import SignatureModal from './../../components/SignatureModal';
import InfoTooltip from './../../components/InfoTooltip';

import './styles.scss';

type Props = {
    createMode?: boolean;
    roles?: Roles[];
};

const AccountDetails: React.FunctionComponent<Props> = (props) => {
    const { createMode } = props;
    const params: any = useParams();
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user.user);
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const [isLoading, setLoading] = useState(true);
    const [isOwnAccount, setIsOwnAccount] = useState(false);
    const [currentUser, setCurrentUser] = useState<User>();
    const [isPasswordHidden, setPasswordHidden] = useState(true);
    const [isConfirmHidden, setConfirmHidden] = useState(true);
    const [viewSignature, setViewSignature] = useState(false);

    let superAdmin = user?.authorities.includes(Roles.SUPER_ADMIN);
    let admin = user?.authorities.includes(Roles.ADMIN);
    let adminUser = superAdmin || admin;

    const { control, setValue, handleSubmit, register, watch, reset, trigger, errors } = useForm({
        mode: 'onBlur'
    });

    const watchPositions = watch('positions', []);

    const loadAccount = () => {
        let isOwn = params.userId === user.login;

        if (!isOwn && !adminUser) {
            dispatch(logout());
            modal.displayError(labels.dialog.message.UNAUTHORIZED, labels.dialog.header.SECURITY);
        }

        setIsOwnAccount(isOwn);

        if (createMode) {
            setLoading(false);
        } else {
            AuthService.getUserDetail(params.userId).then((res) => {
                let userDetails: User = res.body;
                setCurrentUser(userDetails);
                setLoading(false);
                setValue('activated', userDetails.activated);
                setValue('login', userDetails.login);
                setValue('email', userDetails.email);
                setValue('firstName', userDetails.firstName);
                setValue('lastName', userDetails.lastName);
                setValue('degreeSuffix', userDetails.degreeSuffix);
                setValue('license', userDetails.licence);
                setValue('createdBy', userDetails.createdBy);
                setValue('createdDate', userDetails.createdDate ? common.parseDateTimeAPIToDateTime(userDetails.createdDate) : "");
                setValue('updatedBy', userDetails.lastModifiedBy || userDetails.createdBy);
                setValue('updatedDate', userDetails.lastModifiedDate ? common.parseDateTimeAPIToDateTime(userDetails.lastModifiedDate) :
                    userDetails.createdDate ? common.parseDateTimeAPIToDateTime(userDetails.createdDate)
                        : "");

                setAuthorities(userDetails.authorities);

                if (userDetails.signatureId) {
                    ApplicationService.getFileDetails(userDetails.signatureId).then((res) => {
                        setValue('eSignature', res.body.imageUrl);
                        trigger();
                    });
                } else {
                    trigger();
                }
            });
        }
    };

    useEffect(loadAccount, [params.userId]);

    const _onSubmit = (values: any) => {
        if (createMode) {
            modal.displayConfirmation(labels.dialog.message.CREATE_CONFIRM, () => {
                uploadSignature(values.eSignature, values.login)
                    .then((id) => {
                        let newUser: CreateUser = {
                            activated: values.activated,
                            login: values.login,
                            password: values.password,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            position: values.positions.join(', '),
                            department: values.departments ? values.departments.join(', ') : '',
                            authorities: getRole(values.departments, values.positions),
                            degreeSuffix: values.degreeSuffix,
                            licence: values.license,
                            signatureId: id
                        };

                        AuthService.createUser(newUser).then(() => {
                            modal.displaySuccess(labels.dialog.message.CREATE_SUCCESS.replace('{user}', values.login));
                            reset();
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        } else if (currentUser) {
            modal.displayConfirmation(labels.dialog.message.UPDATE_CONFIRM.replace('{user}', values.login), () => {
                uploadSignature(values.eSignature, values.login)
                    .then((id) => {
                        let user: User = {
                            id: currentUser.id,
                            activated: adminUser ? values.activated : currentUser.activated,
                            login: values.login,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            position: values.positions.join(', '),
                            department: values.departments ? values.departments.join(', ') : '',
                            authorities: getRole(values.departments, values.positions),
                            degreeSuffix: values.degreeSuffix,
                            licence: values.license,
                            signatureId: id
                        };

                        AuthService.updateUser(user).then((res) => {
                            if (isOwnAccount) dispatch(setLogin(res.body));
                            setCurrentUser(res.body);
                            modal.displaySuccess(
                                labels.dialog.message.UPDATE_SUCCESS.replace('{user}', values.login),
                                undefined,
                                () => {
                                    navigate(-1);
                                }
                            );
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    const uploadSignature = (signature: string, login: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (signature) {
                //Still url based and no change in signature
                if (currentUser && signature.includes('/files/view')) {
                    resolve(currentUser.signatureId);
                } else {
                    let file = common.dataToFile(signature, login + '_signature');
                    ApplicationService.uploadFile(file, login, user.login)
                        .then((res) => {
                            let data = res.body.data;
                            setValue('eSignature', data.imageUrl);
                            resolve(data.id);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            } else {
                resolve(null);
            }
        });
    };

    const checkDepartmentDisabled = (adminUser: boolean) => {
        return !adminUser || !(
            watchPositions.includes(Position.VALIDATOR) ||
            watchPositions.includes(Position.APPROVER) ||
            watchPositions.includes(Position.AUDITOR) ||
            watchPositions.includes(Position.LAB_AIDE) ||
            watchPositions.includes(Position.LAB_REP) ||
            watchPositions.includes(Position.MICROSCOPIST) ||
            watchPositions.includes(Position.PATHOLOGIST) ||
            watchPositions.includes(Position.LAB_VIEWER)
        );
    };

    const getRole = (departments: string[], positions: string[]) => {
        let role: string[] = [];

        const positionValues = Object.entries(Position);

        positions.forEach((position) => {
            const matchingPosition = positionValues.find(posEntry => posEntry[1] === position)
            if (matchingPosition) {
                role.push(Roles[matchingPosition[0]])
            } else {
                departments.forEach((department) => {
                    role.push(
                        'ROLE_' + find(permitList, { permitDesc: department }).type + '_' + common.getEnumKey(Position, position)
                    );
                });
            }
        })

        return role;
    };

    const setAuthorities = (auths: string[]): void => {
        let positions: string[] = [];
        let departments: string[] = [];

        const roleValues = Object.entries(Roles);

        auths.forEach((auth) => {
            const matchingRole = roleValues.find(roleEntry => roleEntry[1] === auth);
            if (matchingRole) {
                positions.push(Position[matchingRole[0]])
            } else {
                permitList.forEach((permit) => {
                    Object.keys(Position).forEach((key) => {
                        let role = 'ROLE_' + permit.type + '_' + key;
                        if (auth === role) {
                            if (!positions.includes(Position[key])) positions.push(Position[key]);
                            if (!departments.includes(permit.permitDesc)) departments.push(permit.permitDesc);
                        }
                    });
                });
            }
        });

        setValue('positions', positions);
        setValue('departments', departments);
    };

    // const _handleActivate = (activate: boolean) => {
    //     if (currentUser) {
    //         let message = activate ? labels.dialog.message.ACT_ACC : labels.dialog.message.DEAC_ACC;

    //         modal.displayConfirmation(message.replace('{name}', currentUser.login), () => {
    //             AuthService.activateUser(currentUser.id, activate)
    //                 .then((res) => {
    //                     setActiveAccount(res.body.activated);
    //                     setCurrentUser(res.body);
    //                 })
    //                 .catch((err) => {
    //                     console.error(err);
    //                 });
    //         });
    //     }
    // };

    const renderPositions = (): JSX.Element[] => {
        let positions: JSX.Element[] = [];

        Object.values(Position).forEach((position, index) => {
            if (position === Position.SUPER_ADMIN) {
                if (superAdmin) {
                    positions.push(<option key={index}>{position}</option>);
                }
            } else if (position === Position.ADMIN) {
                let currentAdmin = currentUser?.authorities.includes(Roles.ADMIN);

                if (currentAdmin || superAdmin) {
                    positions.push(<option key={index}>{position}</option>);
                }
            } else {
                positions.push(<option key={index}>{position}</option>);
            }
        });

        return positions;
    };

    const renderDepartments = (): JSX.Element[] => {
        let departments: JSX.Element[] = [];
        let index = 0;

        if (watchPositions.includes(Position.VALIDATOR) ||
            watchPositions.includes(Position.APPROVER) ||
            watchPositions.includes(Position.AUDITOR)) {
            departments.push(<option key={index++}>{Departments.IHC}</option>);
            departments.push(<option key={index++}>{Departments.SP}</option>);
        }

        if (watchPositions.includes(Position.LAB_AIDE) ||
            watchPositions.includes(Position.LAB_REP) ||
            watchPositions.includes(Position.MICROSCOPIST) ||
            watchPositions.includes(Position.PATHOLOGIST) ||
            watchPositions.includes(Position.LAB_VIEWER)) {
            departments.push(<option key={index++}>{Departments.CL}</option>);
            departments.push(<option key={index++}>{Departments.SHL}</option>);
            departments.push(<option key={index++}>{Departments.WAL}</option>);
        }

        return departments;
    };

    return (
        <div className='AccountDetails'>
            <Card>
                <Card.Body className='py-3 px-5'>
                    {isLoading && (
                        <div className='center-content'>
                            <Spinner animation='border' variant='primary' />
                        </div>
                    )}
                    {!isLoading && (
                        <Form onSubmit={handleSubmit(_onSubmit)}>
                            <Row>
                                <Col>
                                    {adminUser && (
                                        <Row>
                                            <Col xs={12} sm={{ span: 6, offset: 6 }}>
                                                <Form.Group>
                                                    <Controller
                                                        name='activated'
                                                        control={control}
                                                        defaultValue={true}
                                                        render={({ onChange, value }) => (
                                                            <BootstrapSwitchButton
                                                                checked={value}
                                                                width={175}
                                                                height={50}
                                                                onlabel={labels.ACTIVE}
                                                                offlabel={labels.DEACTIVATED}
                                                                offstyle='secondary'
                                                                onChange={(active) => {
                                                                    onChange(active);
                                                                }}
                                                            />
                                                        )}></Controller>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.USERNAME}</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='login'
                                                    readOnly={!createMode}
                                                    ref={register({
                                                        required: labels.USERNAME_REQUIRED,
                                                        pattern: {
                                                            value: regexp.USERNAME,
                                                            message: labels.USERNAME_FORMAT
                                                        }
                                                    })}
                                                    isInvalid={!!errors.login}
                                                />
                                                {!!errors.login && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.login.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.EMAIL_ADDRESS}</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='email'
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED,
                                                        pattern: {
                                                            value: regexp.EMAIL_ADDRESS,
                                                            message: labels.INVALID_FORMAT_EMAIL
                                                        }
                                                    })}
                                                    isInvalid={!!errors.email}
                                                />
                                                {!!errors.email && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.email.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {createMode && (
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <Form.Group>
                                                    <Form.Label>{labels.PASSWORD}</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type={isPasswordHidden ? 'password' : 'text'}
                                                            name='password'
                                                            ref={register({
                                                                required: labels.PASSWORD_REQUIRED,
                                                                pattern: {
                                                                    value: regexp.PASSWORD,
                                                                    message: labels.PASSWORD_FORMAT
                                                                }
                                                            })}
                                                            isInvalid={!!errors.password}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text
                                                                className='clickable'
                                                                onClick={() => setPasswordHidden(!isPasswordHidden)}>
                                                                {isPasswordHidden ? <FaEyeSlash /> : <FaEye />}
                                                            </InputGroup.Text>
                                                        </InputGroup.Append>
                                                        {!!errors.password && (
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.password.message}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Group>
                                                    <Form.Label>{labels.CONFIRM_PASSWORD}</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type={isConfirmHidden ? 'password' : 'text'}
                                                            name='confirmPassword'
                                                            ref={register({
                                                                required: labels.FIELD_REQUIRED,
                                                                validate: (value) =>
                                                                    value === watch('password') || labels.PASSWORD_MISMATCH
                                                            })}
                                                            isInvalid={!!errors.confirmPassword}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text
                                                                className='clickable'
                                                                onClick={() => setConfirmHidden(!isConfirmHidden)}>
                                                                {isConfirmHidden ? <FaEyeSlash /> : <FaEye />}
                                                            </InputGroup.Text>
                                                        </InputGroup.Append>
                                                        {!!errors.confirmPassword && (
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.confirmPassword.message}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.FIRST_NAME}</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='firstName'
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.firstName}
                                                />
                                                {!!errors.firstName && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.firstName.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.LAST_NAME}</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='lastName'
                                                    ref={register({
                                                        required: labels.FIELD_REQUIRED
                                                    })}
                                                    isInvalid={!!errors.lastName}
                                                />
                                                {!!errors.lastName && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.lastName.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.POSITION}</Form.Label>
                                                <fieldset disabled={!adminUser}>
                                                    <Form.Control
                                                        as='select'
                                                        multiple
                                                        name='positions'
                                                        ref={register({
                                                            required: labels.FIELD_REQUIRED
                                                        })}
                                                        isInvalid={!!errors.positions}>
                                                        {renderPositions()}
                                                    </Form.Control>
                                                </fieldset>
                                                {!!errors.positions && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.positions.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.DEPARTMENT}</Form.Label>
                                                <fieldset disabled={checkDepartmentDisabled(adminUser)}>
                                                    <Form.Control
                                                        as='select'
                                                        multiple
                                                        name='departments'
                                                        ref={register({
                                                            required: checkDepartmentDisabled(adminUser) ? false : labels.FIELD_REQUIRED
                                                        })}
                                                        isInvalid={!!errors.departments}>
                                                        {renderDepartments()}
                                                    </Form.Control>
                                                </fieldset>
                                                {!!errors.departments && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.departments.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {labels.DEGREE_SUFFIX}
                                                    <InfoTooltip
                                                        message={`${labels.tooltip.COMMA_MULTIPLE
                                                            } ${labels.DEGREE_SUFFIX.toLowerCase()}`}
                                                    />
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='degreeSuffix'
                                                    placeholder='MD, FPSP, RMT II'
                                                    ref={register({})}
                                                    isInvalid={!!errors.degreeSuffix}
                                                />
                                                {!!errors.degreeSuffix && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.degreeSuffix.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {labels.LICENSE_NO}
                                                    <InfoTooltip
                                                        message={`${labels.tooltip.COMMA_MULTIPLE
                                                            } ${labels.LICENSE_NO.toLowerCase()}`}
                                                    />
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='license'
                                                    placeholder='0000000'
                                                    ref={register({})}
                                                    isInvalid={!!errors.license}
                                                />
                                                {!!errors.license && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.license.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.E_SIGNATURE}</Form.Label>
                                                <Controller
                                                    name='eSignature'
                                                    control={control}
                                                    defaultValue=''
                                                    render={({ onChange, onBlur, value }) => (
                                                        <div
                                                            className={
                                                                'form-control form-image' +
                                                                (errors.eSignature ? ' is-invalid' : '')
                                                            }>
                                                            {value && (
                                                                <div className='image-container'>
                                                                    <Image src={value} />
                                                                </div>
                                                            )}
                                                            {!value && <FaSignature />}
                                                            <Button variant='primary' onClick={() => setViewSignature(true)}>
                                                                <GiFountainPen />
                                                            </Button>
                                                            <SignatureModal
                                                                show={viewSignature}
                                                                onClose={(data) => {
                                                                    setViewSignature(false);
                                                                    if (data) {
                                                                        onChange(data);
                                                                        onBlur();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    rules={{
                                                        required: createMode || !isOwnAccount ? false : labels.FIELD_REQUIRED
                                                    }}></Controller>
                                                {!!errors.eSignature && (
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.eSignature.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {!createMode && adminUser &&
                                        <>
                                            <Row>
                                                <Col xs={12} sm={6}>
                                                    <Form.Group>
                                                        <Form.Label>{labels.CREATED_BY}</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            name='createdBy'
                                                            disabled
                                                            ref={register({})}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <Form.Group>
                                                        <Form.Label>{labels.CREATED_DATE}</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            name='createdDate'
                                                            disabled
                                                            ref={register({})}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={6}>
                                                    <Form.Group>
                                                        <Form.Label>{labels.UPDATED_BY}</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            name='updatedBy'
                                                            disabled
                                                            ref={register({})}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <Form.Group>
                                                        <Form.Label>{labels.UPDATED_DATE}</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            name='updatedDate'
                                                            disabled
                                                            ref={register({})}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    <Row>
                                        <Col>
                                            <Button className='save' type='submit' variant='primary'>
                                                {createMode && labels.CREATE_USER.toUpperCase()}
                                                {!createMode && labels.SAVE_CHANGES.toUpperCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default AccountDetails;
