import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';

import { labels } from '../../../constants';
import {
    User,
    CreateLabForm,
    LabForm,
    Requirement,
    Application,
    LabFormStatus,
    SanitaryAppData,
} from '../../../models';
import { ApplicationService, common } from '../../../services';

import './styles.scss';

type Props = {
    show: boolean;
    application: Application;
    requirement?: Requirement;
    labForm?: LabForm;
    onClose: (updated?: boolean) => void;
};

const LabDispatchFormModal: React.FunctionComponent<Props> = (props) => {
    const { show, requirement, application, labForm, onClose } = props;
    const user: User = useSelector((state: any) => state.user.user);
    const [form, setForm] = useState(labForm);
    const isMounted = useRef(false);
    const printRef: any = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page {
                margin: 0mm;
            }
          }`,
        content: () => printRef.current,
        onAfterPrint: () => onClose(true)
    });



    const initFormValues = () => {
        let formOptions: any = {
            mode: 'onBlur'
        };

        let formValues;
        let formData = application.formData as SanitaryAppData;
        formValues = {
            formType: common.getFormType(requirement),
            establishmentName: formData.establishmentName,
            address: formData.address,
            ...form?.formData,

        }

        formOptions.defaultValues = formValues;

        return formOptions;
    };

    const { handleSubmit, register, errors } = useForm(initFormValues());

    const _handleSave = (values) => {
        let method;

        if (labForm) {
            let form: LabForm = {
                ...labForm,
                laboratoryStatus: LabFormStatus.PENDING,
                formData: {
                    ...labForm.formData,
                    ...values,
                }
            };

            method = ApplicationService.updateLaboratory(form);
        } else if (requirement) {
            let form: CreateLabForm = {
                applicationId: application.applicationId,
                requirementId: requirement.requirementId,
                laboratoryStatus: LabFormStatus.PENDING,
                formData: {
                    ...values
                }
            };
            method = ApplicationService.createLaboratory(form);
        }

        method
            .then((response) => {
                setForm(response.body.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        if (isMounted.current) {
            handlePrint();
        } else {
            isMounted.current = true;
        }
    }, [form, handlePrint]);

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='LabDispatchFormModal'>
            <Modal.Header closeButton>
                <h4>{labels.DISPATCH_SAMPLER}</h4>
            </Modal.Header>
            <Form spellCheck={false} onSubmit={handleSubmit(_handleSave)}>
                <Modal.Body>
                    {form && form.laboratoryId &&
                        <Row className='center'>
                            <Barcode height={30} width={1} fontSize={10} value={'A' + form.applicationId + 'L' + form.laboratoryId} />
                        </Row>
                    }
                    <Form.Group as={Row}>
                        <Form.Label column='sm' xs={3}>{common.parseDetailLabel(labels.SPECIMEN)}</Form.Label>
                        <Col xs={9}>
                            <Form.Control
                                size='sm'
                                type='text'
                                name='formType'
                                readOnly
                                ref={register}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column='sm' xs={3}>{common.parseDetailLabel(labels.ESTABLISHMENT)}</Form.Label>
                        <Col xs={9}>
                            <Form.Control
                                size='sm'
                                type='text'
                                name='establishmentName'
                                readOnly
                                ref={register}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column='sm' xs={3}>{common.parseDetailLabel(labels.ADDRESS)}</Form.Label>
                        <Col xs={9}>
                            <Form.Control
                                size='sm'
                                type='text'
                                name='address'
                                readOnly
                                ref={register}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column='sm' xs={3}>{common.parseDetailLabel(labels.SAMPLER)}</Form.Label>
                        <Col xs={9}>
                            <Form.Control
                                size='sm'
                                type='text'
                                name='collectedBy'
                                ref={register({
                                    required: labels.FIELD_REQUIRED
                                })}
                                isInvalid={!!errors.collectedBy}
                            />
                            {!!errors.collectedBy && (
                                <Form.Control.Feedback type='invalid'>{errors.collectedBy.message}</Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <div style={{ overflow: 'hidden', height: 0 }}>
                        <div ref={printRef}>
                            <div style={{ width: '220px', height: '80px', margin: '1px', padding: '1px', border: '1px solid', fontSize: '8px' }}>
                                {form && form.laboratoryId &&
                                    <div style={{ textAlign: 'center' }}>
                                        <Barcode height={15} width={0.5} fontSize={7} margin={2} value={'A' + form.applicationId + 'L' + form.laboratoryId} />
                                    </div>
                                }
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span>{common.parseDetailLabel(labels.SPECIMEN)}</span>
                                    <span>{form?.formData.formType}</span>
                                </div>
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span>{common.parseDetailLabel(labels.ESTABLISHMENT)}</span>
                                    <span>{form?.formData.establishmentName}</span>
                                </div>
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span>{common.parseDetailLabel(labels.ADDRESS)}</span>
                                    <span>{form?.formData.address}</span>
                                </div>
                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {common.parseDetailLabel(labels.SAMPLER)}
                                    <span>{form?.formData.collectedBy}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {common.allowedDispatch(user.authorities) && (
                        <Button variant='primary' type='submit'>
                            {labels.PRINT_LABEL}
                        </Button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal >
    );
};

export default LabDispatchFormModal;
