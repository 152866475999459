import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Col, Spinner, Table, Button } from 'react-bootstrap';
import { FiEdit3 } from 'react-icons/fi';
import { BiBookAdd } from 'react-icons/bi';
import { RiMenuAddLine } from 'react-icons/ri';
import { AiOutlineStop, AiOutlineCheck } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { sortBy } from 'lodash';

import { labels } from '../../../constants';
import { Industry, UpdateSubIndustry, BusinessLine, UpdateIndustry, SubIndustry } from '../../../models';
import { getIndustries } from './../../../actions/config.action';
import { ConfigService, modal } from '../../../services';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import IndustryModal from '../../components/IndustryModal';
import SubIndustryModal from '../../components/SubIndustryModal';
import BusinessLineModal from '../../components/BusinessLineModal';

import './styles.scss';

type Props = {};

let selectedIndustry: any;
let activeIndustry: UpdateIndustry;
let selectedSubIndustry: any;
let activeSubIndustry: UpdateSubIndustry;
let selectedBusinessLine: any;

const Industries: React.FunctionComponent<Props> = () => {
    const industry = useSelector((state: any) => state.config.industry);
    const dispatch = useDispatch();
    const [showIndustry, setShowIndustry] = useState(false);
    const [showSubIndustry, setShowSubIndustry] = useState(false);
    const [showBusinessLine, setShowBusinessLine] = useState(false);

    const loadData = () => {
        dispatch(getIndustries(true));
    };

    useEffect(loadData, []);

    // const _handleAddIndustry = () => {
    //     selectedIndustry = undefined;
    //     setShowIndustry(true);
    // }

    const _handleEditIndustry = (industry: Industry) => {
        selectedIndustry = {
            industryId: industry.industryId,
            industryName: industry.industryName,
            industryDesc: industry.industryDesc
        };
        setShowIndustry(true);
    };

    // const _handleDeleteIndustry = (name: string, id: number) => {
    //     modal.displayConfirmation(labels.dialog.message.DEL_IND.replace('{name}', name), () => {
    //         ConfigService.deleteIndustry(id).then(() => {
    //             loadData();
    //         });
    //     });
    // };

    const _handleIndustryClose = (industry: any) => {
        setShowIndustry(false);
        selectedIndustry = undefined;

        if (industry) {
            if (industry.industryId) {
                ConfigService.updateIndustry(industry).then(() => {
                    loadData();
                });
            } else {
                ConfigService.createIndustry(industry).then(() => {
                    loadData();
                });
            }
        }
    };

    const _handleAddSubIndustry = (industry: Industry) => {
        activeIndustry = {
            industryId: industry.industryId,
            industryName: industry.industryName,
            industryDesc: industry.industryDesc
        };
        selectedSubIndustry = undefined;
        setShowSubIndustry(true);
    };

    const _handleEditSubIndustry = (industry: Industry, subIndustry: SubIndustry) => {
        activeIndustry = {
            industryId: industry.industryId,
            industryName: industry.industryName,
            industryDesc: industry.industryDesc
        };
        selectedSubIndustry = {
            industryId: subIndustry.industryId,
            subIndustryId: subIndustry.subIndustryId,
            subIndustryName: subIndustry.subIndustryName,
            subIndustryDesc: subIndustry.subIndustryDesc
        };
        setShowSubIndustry(true);
    };

    const _handleDisableSubIndustry = (name: string, id: number) => {
        modal.displayConfirmation(
            labels.dialog.message.DEL_SUB.replace('{name}', name),
            () => {
                ConfigService.disableSubIndustry(id).then(() => {
                    loadData();
                });
            },
            labels.dialog.header.CONFIRM_DISABLE
        );
    };

    const _handleEnableSubIndustry = (name: string, id: number) => {
        modal.displayConfirmation(labels.dialog.message.ENABLE_SUB.replace('{name}', name), () => {
            ConfigService.enableSubIndustry(id).then(() => {
                loadData();
            });
        });
    };

    const _handleSubIndustryClose = (subIndustry: any) => {
        setShowSubIndustry(false);
        selectedSubIndustry = undefined;

        if (subIndustry) {
            if (subIndustry.subIndustryId) {
                ConfigService.updateSubIndustry(subIndustry).then(() => {
                    loadData();
                });
            } else {
                ConfigService.createSubIndustry(subIndustry).then(() => {
                    loadData();
                });
            }
        }
    };

    const _handleAddBusinessLine = (subIndustry: SubIndustry) => {
        activeSubIndustry = {
            industryId: subIndustry.industryId,
            subIndustryId: subIndustry.subIndustryId,
            subIndustryName: subIndustry.subIndustryName,
            subIndustryDesc: subIndustry.subIndustryDesc,
            inactive: subIndustry.inactive
        };
        selectedBusinessLine = undefined;
        setShowBusinessLine(true);
    };

    const _handleEditBusinessLine = (subIndustry: SubIndustry, businessLine: BusinessLine) => {
        activeSubIndustry = {
            industryId: subIndustry.industryId,
            subIndustryId: subIndustry.subIndustryId,
            subIndustryName: subIndustry.subIndustryName,
            subIndustryDesc: subIndustry.subIndustryDesc,
            inactive: subIndustry.inactive
        };
        selectedBusinessLine = {
            subIndustryId: businessLine.subIndustryId,
            businessLineId: businessLine.businessLineId,
            businessLineName: businessLine.businessLineName,
            businessLineDesc: businessLine.businessLineDesc
        };
        setShowBusinessLine(true);
    };

    const _handleDeleteBusinessLine = (name: string, id: number) => {
        modal.displayConfirmation(labels.dialog.message.DEL_NAME.replace('{name}', name), () => {
            ConfigService.deleteBusinessLine(id).then(() => {
                loadData();
            });
        });
    };

    const _handleBusinessLineClose = (businessLine: any) => {
        setShowBusinessLine(false);
        selectedBusinessLine = undefined;

        if (businessLine) {
            if (businessLine.businessLineId) {
                ConfigService.updateBusinessLine(businessLine).then(() => {
                    loadData();
                });
            } else {
                ConfigService.createBusinessLine(businessLine).then(() => {
                    loadData();
                });
            }
        }
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        if (industry && industry.list && industry.list.length > 0) {
            industry.list.forEach((industry: Industry, index) => {
                rows.push(
                    <tr key={index}>
                        <td>{industry.industryDesc}</td>
                        <td></td>
                        <td></td>
                        <td>
                            <div className='action-group'>
                                <Button
                                    className='mr-1'
                                    variant='outline-primary'
                                    onClick={() => _handleAddSubIndustry(industry)}>
                                    <RiMenuAddLine />
                                </Button>
                                <Button className='mr-1' variant='outline-primary' onClick={() => _handleEditIndustry(industry)}>
                                    <FiEdit3 />
                                </Button>
                                {/* <Button
                                    variant='outline-secondary'
                                    onClick={() => _handleDeleteIndustry(industry.industryDesc, industry.industryId)}>
                                    <MdDelete />
                                </Button> */}
                            </div>
                        </td>
                    </tr>
                );

                let subIndustries = industry.subIndustryResponseDtos;

                if (subIndustries && subIndustries.length > 0) {
                    subIndustries.sort((a, b) => {
                        if (a.inactive !== b.inactive) {
                            return a.inactive ? -1 : 1;
                        } else {
                            return a.subIndustryDesc.localeCompare(b.subIndustryDesc);
                        }
                    });

                    subIndustries.forEach((subIndustry: SubIndustry, subIndex) => {
                        if (subIndustry.subIndustryDesc) {
                            rows.push(
                                <tr className={subIndustry.inactive ? 'disabled' : ''} key={index + '_' + subIndex}>
                                    <td></td>
                                    <td>{subIndustry.subIndustryDesc}</td>
                                    <td></td>
                                    <td>
                                        <div className='action-group'>
                                            <Button
                                                className='mr-1'
                                                variant='outline-primary'
                                                onClick={() => _handleAddBusinessLine(subIndustry)}>
                                                <BiBookAdd />
                                            </Button>
                                            <Button
                                                className='mr-1'
                                                variant='outline-primary'
                                                onClick={() => _handleEditSubIndustry(industry, subIndustry)}>
                                                <FiEdit3 />
                                            </Button>
                                            {subIndustry.inactive && (
                                                <Button
                                                    variant='outline-success'
                                                    onClick={() =>
                                                        _handleEnableSubIndustry(
                                                            subIndustry.subIndustryDesc,
                                                            subIndustry.subIndustryId
                                                        )
                                                    }>
                                                    <AiOutlineCheck />
                                                </Button>
                                            )}
                                            {!subIndustry.inactive && (
                                                <Button
                                                    variant='outline-secondary'
                                                    onClick={() =>
                                                        _handleDisableSubIndustry(
                                                            subIndustry.subIndustryDesc,
                                                            subIndustry.subIndustryId
                                                        )
                                                    }>
                                                    <AiOutlineStop />
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        }

                        let businessLines = subIndustry.businessLines;

                        if (businessLines && businessLines.length > 0) {
                            businessLines = sortBy(businessLines, ['businessLineDesc']);

                            businessLines.forEach((businessLine: BusinessLine, busIndex) => {
                                if (businessLine.businessLineDesc) {
                                    rows.push(
                                        <tr
                                            className={subIndustry.inactive ? 'disabled' : ''}
                                            key={index + '_' + subIndex + '_' + busIndex}>
                                            <td></td>
                                            <td></td>
                                            <td>{businessLine.businessLineDesc}</td>
                                            <td>
                                                <div className='action-group'>
                                                    <Button
                                                        className='mr-1'
                                                        variant='outline-primary'
                                                        onClick={() => _handleEditBusinessLine(subIndustry, businessLine)}>
                                                        <FiEdit3 />
                                                    </Button>
                                                    <Button
                                                        variant='outline-secondary'
                                                        onClick={() =>
                                                            _handleDeleteBusinessLine(
                                                                businessLine.businessLineName,
                                                                businessLine.businessLineId
                                                            )
                                                        }>
                                                        <MdDelete />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }
                            });
                        }
                    });
                }
            });
        }

        return rows;
    };

    return (
        <div className='Industries'>
            <PageHeader title={labels.INDUSTRIES} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Row>
                            <Col>
                                {industry.loading && (
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!industry.loading && (
                                    <Row>
                                        <Col>
                                            <Table responsive bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>{labels.INDUSTRIES}</th>
                                                        <th>{labels.SUB_INDUSTRIES}</th>
                                                        <th>{labels.BUSINESS_LINE}</th>
                                                        <th>{labels.ACTION}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{renderRows()}</tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </PageBody>
            <IndustryModal show={showIndustry} industry={selectedIndustry} onClose={_handleIndustryClose} />
            <SubIndustryModal
                show={showSubIndustry}
                industry={activeIndustry}
                subIndustry={selectedSubIndustry}
                onClose={_handleSubIndustryClose}
            />
            <BusinessLineModal
                show={showBusinessLine}
                subIndustry={activeSubIndustry}
                businessLine={selectedBusinessLine}
                onClose={_handleBusinessLineClose}
            />
        </div>
    );
};

export default Industries;
