import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { labels, httpCodes, regexp } from '../../../constants';
import { AuthService, modal } from '../../../services';

import './styles.scss';

type FormValues = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

type Props = {
    show: boolean;
    onClose: () => void;
};

const Dialog: React.FunctionComponent<Props> = (props) => {
    const { show, onClose } = props;
    const [isCurrentHidden, setCurrentHidden] = useState<boolean>(true);
    const [isNewHidden, setNewHidden] = useState<boolean>(true);
    const [isConfirmHidden, setConfirmHidden] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const { handleSubmit, register, watch, errors } = useForm<FormValues>({
        mode: 'onBlur'
    });

    const _onSubmit = (values: FormValues) => {
        AuthService.changePassword({
            currentPassword: values.currentPassword,
            newPassword: values.newPassword
        })
            .then(() => {
                setError('');
                onClose();
                modal.displaySuccess(labels.dialog.message.PASSWORD_SUCCCESS);
            })
            .catch((error: any) => {
                if (error.status === httpCodes.BAD_REQUEST) {
                    setError(labels.INVALID_PASSWORD);
                }
            });
    };

    return (
        <Modal show={show} onHide={() => onClose()} centered dialogClassName='ChangePassword'>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    {error && <p className='error-msg'>{error}</p>}
                    <Form.Group>
                        <Form.Label>{labels.CURRENT_PASSWORD}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={isCurrentHidden ? 'password' : 'text'}
                                name='currentPassword'
                                ref={register({
                                    required: labels.FIELD_REQUIRED
                                })}
                                isInvalid={!!errors.currentPassword}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text className='clickable' onClick={() => setCurrentHidden(!isCurrentHidden)}>
                                    {isCurrentHidden ? <FaEyeSlash /> : <FaEye />}
                                </InputGroup.Text>
                            </InputGroup.Append>
                            {!!errors.currentPassword && (
                                <Form.Control.Feedback type='invalid'>{errors.currentPassword.message}</Form.Control.Feedback>
                            )}
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.NEW_PASSWORD}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={isNewHidden ? 'password' : 'text'}
                                name='newPassword'
                                ref={register({
                                    required: labels.FIELD_REQUIRED,
                                    pattern: {
                                        value: regexp.PASSWORD,
                                        message: labels.PASSWORD_FORMAT
                                    }
                                })}
                                isInvalid={!!errors.newPassword}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text className='clickable' onClick={() => setNewHidden(!isNewHidden)}>
                                    {isNewHidden ? <FaEyeSlash /> : <FaEye />}
                                </InputGroup.Text>
                            </InputGroup.Append>
                            {!!errors.newPassword && (
                                <Form.Control.Feedback type='invalid'>{errors.newPassword.message}</Form.Control.Feedback>
                            )}
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.CONFIRM_PASSWORD}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={isConfirmHidden ? 'password' : 'text'}
                                name='confirmPassword'
                                ref={register({
                                    required: labels.FIELD_REQUIRED,
                                    validate: (value) => value === watch('newPassword') || labels.PASSWORD_MISMATCH
                                })}
                                isInvalid={!!errors.confirmPassword}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text className='clickable' onClick={() => setConfirmHidden(!isConfirmHidden)}>
                                    {isConfirmHidden ? <FaEyeSlash /> : <FaEye />}
                                </InputGroup.Text>
                            </InputGroup.Append>
                            {!!errors.confirmPassword && (
                                <Form.Control.Feedback type='invalid'>{errors.confirmPassword.message}</Form.Control.Feedback>
                            )}
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {labels.SUBMIT}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default Dialog;
