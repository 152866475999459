enum MethodType {
    TUBE = 'TUBE',
    ENZYME = 'ENZYME',
    PLATE = 'PLATE',
}

enum MethodValue {
    TUBE = 'Multiple Tube Fermentation Technique',
    ENZYME = 'Enzyme Substrate Coliform Test',
    PLATE = 'Pour plate method'
}

enum TubeResult {
    LESS1 = '<1.1',
    ONE = '1.1',
    TWO = '2.6',
    FOUR = '4.6',
    EIGHT = '8.0',
    GREAT8 = '>8.0'
}

enum EnzymeResult {
    ABSENT = 'ABSENT',
    PRESENT = 'PRESENT',
}

enum Remarks {
    PASSED = 'PASSED',
    FAILED = 'FAILED'
}

export default { MethodType, MethodValue, TubeResult, EnzymeResult, Remarks };
