import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

import { labels, regexp } from '../../../constants';
import { common } from '../../../services';
import { CreateTrainingParticipant, TrainingParticipant, Gender } from '../../../models';

import './styles.scss';

type Props = {
    show: boolean;
    participant?: TrainingParticipant;
    onClose: (participant?: any) => void;
};

const TrainingParticipantModal: React.FunctionComponent<Props> = (props) => {
    const { show, participant, onClose } = props;
    let formOptions: any = { mode: 'onBlur' };
    formOptions.defaultValues = participant
        ? {
              firstName: participant.firstName,
              lastName: participant.lastName,
              middleName: participant.middleName,
              gender: participant.gender,
              birthDate: common.parseDateString(participant.birthDate),
              emailAddress: participant.emailAddress
          }
        : undefined;

    const { handleSubmit, register, errors } = useForm<CreateTrainingParticipant>(formOptions);

    const _onSubmit = (values: CreateTrainingParticipant) => {
        values.birthDate = common.parseDateStringAPI(values.birthDate);

        if (participant) {
            let updParticipant = {
                trainingParticipantId: participant.trainingParticipantId,
                ...values
            };

            onClose(updParticipant);
        } else {
            onClose(values);
        }
    };

    const renderGenderOptions = () => {
        let options: JSX.Element[] = [];
        options.push(
            <option key={0} value={''} hidden>
                Select
            </option>
        );

        Object.values(Gender).forEach((gender, index) => {
            options.push(<option key={index + 1}>{gender}</option>);
        });

        return options;
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='TrainingParticipantModal'>
            <Modal.Header closeButton>
                <Modal.Title>{participant ? labels.TRAINING_UPDATE : labels.ADD_PARTICIPANT}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.FIRST_NAME)}</Form.Label>
                        <Form.Control
                            type='text'
                            name='firstName'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.firstName}
                        />
                        {!!errors.firstName && (
                            <Form.Control.Feedback type='invalid'>{errors.firstName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.LAST_NAME)}</Form.Label>
                        <Form.Control
                            type='text'
                            name='lastName'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.lastName}
                        />
                        {!!errors.lastName && (
                            <Form.Control.Feedback type='invalid'>{errors.lastName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.MIDDLE_NAME}</Form.Label>
                        <Form.Control type='text' name='middleName' ref={register()} isInvalid={!!errors.middleName} />
                        {!!errors.middleName && (
                            <Form.Control.Feedback type='invalid'>{errors.middleName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.GENDER}</Form.Label>
                        <Form.Control
                            as='select'
                            name='gender'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.gender}>
                            {renderGenderOptions()}
                        </Form.Control>
                        {!!errors.gender && <Form.Control.Feedback type='invalid'>{errors.gender.message}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.BIRTH_DATE)}</Form.Label>
                        <Form.Control
                            type='date'
                            name='birthDate'
                            max={common.parseDateToString()}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.birthDate}
                        />
                        {!!errors.birthDate && (
                            <Form.Control.Feedback type='invalid'>{errors.birthDate.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.EMAIL_ADDRESS)}</Form.Label>
                        <Form.Control
                            type='text'
                            name='emailAddress'
                            ref={register({
                                required: labels.FIELD_REQUIRED,
                                pattern: {
                                    value: regexp.EMAIL_ADDRESS,
                                    message: labels.INVALID_FORMAT_EMAIL
                                }
                            })}
                            isInvalid={!!errors.emailAddress}
                        />
                        {!!errors.emailAddress && (
                            <Form.Control.Feedback type='invalid'>{errors.emailAddress.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {participant ? labels.UPDATE : labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TrainingParticipantModal;
