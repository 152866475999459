import React from 'react';
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

import { SortBy } from '../../../models';

import './styles.scss';

interface Props {
    sortBy: SortBy;
    currentSortBy: SortBy;
    currentSortAsc?: boolean;
    setSortBy: (sortBy: SortBy) => void;
    setSortAsc: (sortAsc: boolean) => void;
}

const Sorter: React.FunctionComponent<Props> = (props) => {
    const { sortBy, currentSortBy, currentSortAsc = true, setSortBy, setSortAsc } = props;

    const _handleSort = (sort: SortBy) => {
        if (sort === currentSortBy) {
            setSortAsc(!currentSortAsc);
        } else {
            setSortBy(sort);
            setSortAsc(true);
        }
    };

    return (
        <span className='Sorter'>
            {sortBy === currentSortBy && (
                <>
                    {currentSortAsc && <TiArrowSortedUp className='float-right' onClick={() => setSortAsc(false)} />}
                    {!currentSortAsc && <TiArrowSortedDown className='float-right' onClick={() => setSortAsc(true)} />}
                </>
            )}
            {sortBy !== currentSortBy && <TiArrowUnsorted className='float-right' onClick={() => _handleSort(sortBy)} />}
        </span>
    );
};

export default Sorter;
