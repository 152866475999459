import React, { useState, useEffect } from 'react';
import { Container, Row, Nav, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { RiDashboardLine, RiArrowRightSLine } from 'react-icons/ri';
import { GiHealthNormal } from 'react-icons/gi';
import { FaClinicMedical, FaWater } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { GrConfigure } from 'react-icons/gr';
import { ImLab } from 'react-icons/im';
import { MdOutlineWavingHand } from 'react-icons/md';
import { BsPencilSquare } from 'react-icons/bs';
import { IoBeaker } from 'react-icons/io5';

import { labels, routes } from './../../constants';
import { common } from './../../services';
import { PermitType } from '../../models';

import './styles.scss';

type Props = {
    toggled: boolean;
};

type State = {
    isHealthCollapsed: boolean;
    isSanitaryCollapsed: boolean;
    isLabCollapsed: boolean;
    isSanitaryInspectionCollapsed: boolean;
    isMassagistCollapsed: boolean;
    isUsersCollapsed: boolean;
    isConfigCollapsed: boolean;
    isSHCCollapsed: boolean;
    isWALCollapsed: boolean;
};

const initialState: State = {
    isHealthCollapsed: false,
    isSanitaryCollapsed: false,
    isLabCollapsed: false,
    isSanitaryInspectionCollapsed: false,
    isMassagistCollapsed: false,
    isUsersCollapsed: false,
    isConfigCollapsed: false,
    isSHCCollapsed: false,
    isWALCollapsed: false,
};

const Sidebar: React.FunctionComponent<Props> = (props) => {
    const { toggled } = props;
    const location = useLocation();
    const user = useSelector((state: any) => state.user.user);
    const [state, setState] = useState(initialState);

    const setStateCollapse = (key?: string) => {
        let newState = Object.assign({}, state);

        Object.keys(newState).forEach((stateKey) => {
            newState[stateKey] = stateKey === key ? !newState[stateKey] : false;
        });

        setState(newState);
    };

    useEffect(() => {
        let path = location.pathname;
        let newState = Object.assign({}, initialState);

        if (path.includes('health') || path.includes(PermitType.IHC)) {
            newState.isHealthCollapsed = true;
        } else if (path.includes('sanitary') || path.includes(PermitType.SP)) {
            newState.isSanitaryCollapsed = true;
        } else if (path.includes('laboratory')) {
            newState.isLabCollapsed = true;
        } else if (path.includes('inspection')) {
            newState.isSanitaryInspectionCollapsed = true;
        } else if (path.includes('massagist')) {
            newState.isMassagistCollapsed = true;
        } else if (path.includes('users')) {
            newState.isUsersCollapsed = true;
        } else if (path.includes('config')) {
            newState.isConfigCollapsed = true;
        } else if (path.includes('shc')) {
            newState.isSHCCollapsed = true;
        } else if (path.includes('water-analysis')) {
            newState.isWALCollapsed = true;
        }

        setState(newState);
    }, [location]);

    const renderHealth = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.HEALTH).forEach((key, index) => {
            let route = routes.dashboard.HEALTH[key];

            if (common.allowedRoute(route.auth, user.authorities)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isHealthCollapsed')}
                        aria-controls='health-collapse'
                        aria-expanded={state.isHealthCollapsed}>
                        <span className='label-group'>
                            <GiHealthNormal />
                            <span>{labels.HEALTH}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isHealthCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isHealthCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderSanitary = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.SANITARY).forEach((key, index) => {
            let route = routes.dashboard.SANITARY[key];

            if (!route.hideSidebar && common.allowedRoute(route.auth, user.authorities)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isSanitaryCollapsed')}
                        aria-controls='sanitary-collapse'
                        aria-expanded={state.isSanitaryCollapsed}>
                        <span className='label-group'>
                            <FaWater />
                            <span>{labels.SANITARY}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isSanitaryCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isSanitaryCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderLaboratory = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.LABORATORY).forEach((key, index) => {
            let route = routes.dashboard.LABORATORY[key];

            if (common.allowedRoute(route.auth, user.authorities, route.department, user.department)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isLabCollapsed')}
                        aria-controls='lab-collapse'
                        aria-expanded={state.isLabCollapsed}>
                        <span className='label-group'>
                            <ImLab />
                            <span>{labels.LABORATORY}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isLabCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isLabCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderSHC = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.SOCIAL_HYGIENE).forEach((key, index) => {
            let route = routes.dashboard.SOCIAL_HYGIENE[key];

            if (common.allowedRoute(route.auth, user.authorities, route.department, user.department)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isSHCCollapsed')}
                        aria-controls='shc-collapse'
                        aria-expanded={state.isSHCCollapsed}>
                        <span className='label-group'>
                            <FaClinicMedical />
                            <span>{labels.SOCIAL_HYGIENE}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isSHCCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isSHCCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderWAL = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.WATER_ANALYSIS).forEach((key, index) => {
            let route = routes.dashboard.WATER_ANALYSIS[key];

            if (common.allowedRoute(route.auth, user.authorities, route.department, user.department)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isWALCollapsed')}
                        aria-controls='wal-collapse'
                        aria-expanded={state.isWALCollapsed}>
                        <span className='label-group'>
                            <IoBeaker />
                            <span>{labels.WATER_ANALYSIS}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isWALCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isWALCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    }

    const renderSanitaryInspection = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.SANITARY_INSPECTION).forEach((key, index) => {
            let route = routes.dashboard.SANITARY_INSPECTION[key];

            if (!route.hideSidebar && common.allowedRoute(route.auth, user.authorities)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isSanitaryInspectionCollapsed')}
                        aria-controls='inspection-collapse'
                        aria-expanded={state.isSanitaryInspectionCollapsed}>
                        <span className='label-group'>
                            <BsPencilSquare />
                            <span>{labels.SANITARY_INSPECTION}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isSanitaryInspectionCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isSanitaryInspectionCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderMassagist = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.MASSAGIST).forEach((key, index) => {
            let route = routes.dashboard.MASSAGIST[key];

            if (!route.hideSidebar && common.allowedRoute(route.auth, user.authorities)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isMassagistCollapsed')}
                        aria-controls='massagist-collapse'
                        aria-expanded={state.isMassagistCollapsed}>
                        <span className='label-group'>
                            <MdOutlineWavingHand />
                            <span>{labels.MASSAGIST_TRAINING}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isMassagistCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isMassagistCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderUsers = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.USERS).forEach((key, index) => {
            let route = routes.dashboard.USERS[key];

            if (common.allowedRoute(route.auth, user.authorities)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isUsersCollapsed')}
                        aria-controls='users-collapse'
                        aria-expanded={state.isUsersCollapsed}>
                        <span className='label-group'>
                            <FiUsers />
                            <span>{labels.USER_MANAGEMENT}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isUsersCollapsed ? ' active' : '')} />
                    </Row>

                    <Row>
                        <Collapse in={state.isUsersCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    const renderConfig = (): JSX.Element => {
        let navItems: JSX.Element[] = [];

        Object.keys(routes.dashboard.CONFIG).forEach((key, index) => {
            let route = routes.dashboard.CONFIG[key];

            if (common.allowedRoute(route.auth, user.authorities)) {
                navItems.push(
                    <Nav.Item key={index}>
                        <NavLink to={route.path} className='nav-link link-container'>
                            <span>{route.label}</span>
                        </NavLink>
                    </Nav.Item>
                );
            }
        });

        if (navItems.length > 0) {
            return (
                <>
                    <Row
                        className='menu-group'
                        onClick={() => setStateCollapse('isConfigCollapsed')}
                        aria-controls='config-collapse'
                        aria-expanded={state.isConfigCollapsed}>
                        <span className='label-group'>
                            <GrConfigure />
                            <span>{labels.CONFIG_MANAGEMENT}</span>
                        </span>
                        <RiArrowRightSLine className={'icon' + (state.isConfigCollapsed ? ' active' : '')} />
                    </Row>
                    <Row>
                        <Collapse in={state.isConfigCollapsed}>
                            <Nav>{navItems}</Nav>
                        </Collapse>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div className={'shadow-lg sidebar-wrapper' + (toggled ? ' toggled' : '')}>
            <div className='menu'>
                <Container>
                    {common.allowedRoute(routes.dashboard.SUMMARY.auth, user.authorities) &&
                        !common.blockedRoute(routes.dashboard.SUMMARY.blockedAuth, user.authorities) && (
                            <Row onClick={() => setStateCollapse()}>
                                <NavLink to={routes.dashboard.SUMMARY.path} className='menu-group nav-link'>
                                    <span className='label-group'>
                                        <RiDashboardLine />
                                        <span>{labels.DASHBOARD}</span>
                                    </span>
                                </NavLink>
                            </Row>
                        )}
                    {renderHealth()}
                    {renderSanitary()}
                    {renderLaboratory()}
                    {renderSHC()}
                    {renderWAL()}
                    {renderSanitaryInspection()}
                    {renderMassagist()}
                    {renderUsers()}
                    {renderConfig()}
                </Container>
            </div>
            {/* <div className='footer'>
                <Container>
                    <Row>
                        <span className='label'>{labels.LOGGED_IN}</span>
                    </Row>
                    <Row>
                        <span className='font-weight-bold'>{user.firstName + ' ' + user.lastName}</span>
                    </Row>
                </Container>
            </div> */}
        </div>
    );
};

export default Sidebar;
