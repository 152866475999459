import React, { useState, useEffect } from 'react';
import { usePage } from 'react-page-states';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Card, Spinner, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { IoSearchSharp } from 'react-icons/io5';

import { labels, routes } from '../../../constants';
import { SanitaryReport } from '../../../models';
import { ApplicationService } from '../../../services';

import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import SanitaryReportItem from '../../components/SanitaryReportItem';
import Paginator from '../../components/Paginator';

import './styles.scss';

type FormValues = {
    establishmentName: string;
};

type Props = {};

const establishmentParam = 'establishmentName';

const SanitaryReports: React.FunctionComponent<Props> = () => {
    const navigate = useNavigate();
    const { pageNo, pageSize, total, ...page } = usePage();
    const [sanitaryReportList, setSanitaryReportList] = useState<SanitaryReport[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const initSearch = searchParams.get(establishmentParam);
    const [formValues, setFormValues] = useState<FormValues>({
        establishmentName: initSearch ? initSearch : ''
    });

    const { handleSubmit, register } = useForm<FormValues>({
        defaultValues: {
            establishmentName: initSearch ? initSearch : ''
        }
    });

    const loadData = () => {
        setLoading(true);

        ApplicationService.getSanitaryReports(pageNo, pageSize, { establishmentName: formValues.establishmentName })
            .then((response) => {
                let data = response.body.data;
                setSanitaryReportList(data.details);
                page.setTotal(data.totalCount);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(loadData, [pageNo, formValues]);

    const _handleFormChange = (values: FormValues) => {
        if (values.establishmentName) {
            setSearchParams({ [establishmentParam]: values.establishmentName }, { replace: true });
        } else {
            setSearchParams({}, { replace: true });
        }

        setFormValues(values);
        page.setPageNo(1);
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        sanitaryReportList.forEach((sanitaryReport, index) => {
            rows.push(
                <SanitaryReportItem
                    key={index}
                    establishmentName={sanitaryReport.establishmentName}
                    ownerName={sanitaryReport.formData.ownerName}
                    permitNumber={sanitaryReport.permitNumber}
                    industry={sanitaryReport.industry.industryName}
                    createdDate={sanitaryReport.updatedDate}
                    address={sanitaryReport.formData.address}
                    reportNo={sanitaryReport.sanitaryInspectionReportNo}
                    status={sanitaryReport.sanitaryInspectionReportStatus}
                    onClick={() =>
                        navigate(
                            routes.dashboard.SANITARY_INSPECTION.SANITARY_DETAILS.path.replace(
                                ':id',
                                sanitaryReport.sanitaryInspectionReportId.toString()
                            )
                        )
                    }
                />
            );
        });

        return rows;
    };

    return (
        <div className='SanitaryReports'>
            <PageHeader title={labels.SANITARY_REPORTS} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Form onSubmit={handleSubmit((values) => _handleFormChange(values))}>
                            <Row>
                                <Col className='mb-4'>
                                    <InputGroup>
                                        <Form.Control
                                            type='text'
                                            name='establishmentName'
                                            placeholder={labels.ESTABLISHMENT_NAME}
                                            ref={register}
                                        />
                                        <Button type='submit'>
                                            <IoSearchSharp />
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && total === 0 && (
                                    <div className='center-content'>
                                        <span>No data available</span>
                                    </div>
                                )}
                                {!isLoading && total > 0 && (
                                    <>
                                        <Row>
                                            <Col>
                                                <h4>
                                                    {total + ' ' + (total > 1 ? labels.SANITARY_REPORTS : labels.SANITARY_REPORT)}
                                                </h4>
                                            </Col>
                                        </Row>
                                        <div>{renderRows()}</div>
                                        <Row className='page-row'>
                                            <Col md='auto'>
                                                <Paginator
                                                    currentPage={pageNo}
                                                    maxPage={page.maxPage}
                                                    _handlePageChange={(pageNo) => page.setPageNo(pageNo)}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </PageBody>
        </div>
    );
};

export default SanitaryReports;
