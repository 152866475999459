import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Spinner, Table, Button, Form } from 'react-bootstrap';
import { FaRegFileImage } from 'react-icons/fa';
import { FaFilePrescription } from 'react-icons/fa';
import { IoWarningOutline } from 'react-icons/io5';
import { find, filter, reject, sortBy, difference } from 'lodash';

import { labels, routes, config } from '../../constants';
import { ApplicationService, ConfigService, common, modal } from '../../services';
import { generateAddress, parseDateToStringAPI } from '../../services/utils/common.util';
import {
    Application,
    PermitType,
    HealthAppData,
    SanitaryAppData,
    Status,
    Upload,
    LabForm,
    Requirement,
    CreatePayment,
    HistoryStatus,
    LabFormStatus,
    TrainingParticipant,
    SanitaryReport,
    SanitaryReportStatus,
    TrainingSession,
    LabType,
    LabFormType
} from './../../models';
import CheckMassagistHistory from '../../models/applications/CheckMassagistHistory';
import PageHeader from '../components/PageHeader';
import PageBody from '../components/PageBody';
import CreatePaymentModal from './../components/CreatePaymentModal';
import ViewRequirementModal from './../components/ViewRequirementModal';
import LabFormModal from './../components/LabFormModal';
import InfoTooltip from './../components/InfoTooltip';
import RequiredToolTip from '../components/RequiredToolTip';
import CollapsibleCard from '../components/CollapsibleCard';
import DuplicateApplicationCard from '../components/DuplicateApplicationCard';
import PaymentDetailsCard from '../components/PaymentDetailsCard';
import EmployeeRequirementModal from '../components/EmployeeRequirementModal';
import CertificateModal from '../components/CertificateModal';
import UploadHistoryModal from '../components/UploadHistoryModal';

import './styles.scss';

type Props = {};

type UploadData = {
    upload: Upload;
    requirementName: string;
    viewOnly?: boolean;
};

const ApplicationDetails: React.FunctionComponent<Props> = () => {
    const navigate = useNavigate();
    const params: any = useParams();
    const permitType: PermitType = params.type;
    const user = useSelector((state: any) => state.user.user);
    const [isLoading, setLoading] = useState(true);
    const [application, setApplication] = useState<Application>();
    const [requirements, setRequirements] = useState<Requirement[]>([]);
    const [uploads, setUploads] = useState<Upload[]>([]);
    const [uploadsHistory, setUploadsHistory] = useState<Upload[]>([]);
    const [labResults, setLabResults] = useState<LabForm[]>([]);
    const [uploadData, setUploadData] = useState<UploadData>();
    const [activeUploadHistory, setActiveUploadHistory] = useState<{ requirementName: string; uploadHistory: Upload[] }>();
    const [labResultData, setLabResultData] = useState<LabForm>();
    const [sanitaryReportList, setSanitaryReportList] = useState<SanitaryReport[]>([]);
    const [bulkApplicationList, setBulkApplicationList] = useState<Application[]>([]);
    const [bulkApplicationCount, setBulkApplicationCount] = useState<number>(0);
    const [bulkShowCount, setBulkShowCount] = useState(config.SHOW_LIMIT);
    const [showPayment, setShowPayment] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);

    const [isYellow, setIsYellow] = useState(false);
    let initialYellowState = false;

    const [isRequirementsLoading, setRequirementsLoading] = useState(false);
    const [isLabLoading, setLabLoading] = useState(true);
    const [isBulkHCLoading, setBulkHCLoading] = useState(true);

    const [showBulkHC, setShowBulkHC] = useState(false);
    const [hasBulkHC, setHasBulkHc] = useState(false);
    const [bulkHCStatus, setBulkHCStatus] = useState<Status>();
    const [massagistTrainingDetails, setMassagistTrainingDetails] = useState<TrainingParticipant>();
    const [trainingSessionDetails, setTrainingSessionDetails] = useState<TrainingSession>();

    useEffect(() => {
        if (!isLabLoading && !isBulkHCLoading) {
            setRequirementsLoading(false);
        } else {
            setRequirementsLoading(true);
        }
    }, [uploads, isLabLoading, isBulkHCLoading]);

    const loadDetails = () => {
        setLoading(true);
        ApplicationService.getApplicationById(params.appId)
            .then((response: any) => {
                let data: Application = response.body.data;
                setApplication(data);
                setIsYellow(data.yellowCard);
                initialYellowState = data.yellowCard;
            })
            .catch((err: any) => console.error(err))
            .finally(() => setLoading(false));
    };

    const getUploads = () => {
        if (application) {
            let calls: any = [];
            calls.push(ApplicationService.getUploads(application.applicationId));
            calls.push(
                ConfigService.getRequirements(
                    application.permitId,
                    application.formData.applicationTypeId,
                    application.formData.industryId,
                    application.formData.subIndustryId
                )
            );
            calls.push(ApplicationService.getUploadHistory(application.applicationId));

            setRequirementsLoading(true);
            Promise.all(calls)
                .then((responses: any[]) => {
                    let requirementList = sortBy(responses[1].body.data, ['requirementName']);
                    setUploads(responses[0].body.data);
                    setRequirements(requirementList);
                    setUploadsHistory(responses[2].body.data);
                })
                .catch((err) => {
                    setRequirementsLoading(false);
                    console.error(err);
                });
        }
    };

    const checkLab = () => {
        if (application) {
            if (application.labCompleted || application.socialHygieneCompleted || application.waterAnalysisCompleted) {
                setLabLoading(true);

                ApplicationService.getAllLaboratories(application.applicationId)
                    .then((response) => {
                        setLabResults(response.body.data);
                    })
                    .catch((err) => console.error(err))
                    .finally(() => setLabLoading(false));
            } else {
                setLabLoading(false);
            }
        }
    };

    const checkSO = () => {
        if (application) {
            if (permitType === PermitType.SP) {
                let formData = application.formData as SanitaryAppData;
                ApplicationService.getSanitaryReports(1, 999, { establishmentName: formData.establishmentName, permitNumber: formData.mayorPermitNo })
                    .then((response) => {
                        setSanitaryReportList(response.body.data.details);
                    })
                    .catch((err) => console.error(err));
            }
        }
    };

    const checkBulk = () => {
        let bulkId = application?.bulkApplicationId;

        if (bulkId) {
            let currentPage = bulkShowCount / config.SHOW_LIMIT + 1;
            ApplicationService.getBulkApplications(bulkId, currentPage, config.SHOW_LIMIT)
                .then((response) => {
                    let data = response.body.data;
                    setBulkApplicationList(bulkApplicationList.concat(data.details));
                    setBulkApplicationCount(data.totalCount - 1);
                    setBulkShowCount(bulkShowCount + config.SHOW_LIMIT);

                })
                .catch((err) => console.error(err));
        }
    };

    const checkHCBulk = () => {
        if (application) {
            if (application.spBulk) {
                setBulkHCLoading(true);
                ApplicationService.getBulkEmployeesHC(application.applicationId, 1, 1)
                    .then((response) => {
                        let data = response.body.data;
                        setHasBulkHc(data.totalCount > 0);
                        setBulkHCStatus(data.status);
                    })
                    .catch((err) => console.error(err))
                    .finally(() => setBulkHCLoading(false));
            } else {
                setBulkHCLoading(false);
            }
        }
    };

    const checkMassagistHistory = () => {
        const appForm = application?.formData as HealthAppData;
        if (application && application.yellowCard && appForm?.occupation === "Masseur") {
            const applicationFormData = application.formData as unknown as HealthAppData;
            const detailParams: CheckMassagistHistory = {
                firstName: applicationFormData.firstName,
                middleName: applicationFormData.middleName,
                lastName: applicationFormData.lastName,
                dob: parseDateToStringAPI(new Date(applicationFormData.birthDate))
            }

            ApplicationService.checkMassagistTraining(detailParams)
                .then((response: any) => {
                    const trainingHistory = response?.body?.data?.details ? response?.body?.data?.details[0] : {};
                    setMassagistTrainingDetails(trainingHistory);

                    if (trainingHistory.trainingSessionId) {
                        ApplicationService.getTrainingSessionDetails(trainingHistory.trainingSessionId)
                            .then((response: any) => {
                                const trainingSessionDetails = response.body.data;
                                setTrainingSessionDetails(trainingSessionDetails)
                            })

                    }
                })
        }
    }

    useEffect(loadDetails, [params]);
    useEffect(getUploads, [application]);
    useEffect(checkLab, [application]);
    useEffect(checkSO, [application]);
    useEffect(checkBulk, [application]);
    useEffect(checkHCBulk, [application]);
    useEffect(checkMassagistHistory, [application]);

    const createPayment = (paymentData: CreatePayment) => {
        ApplicationService.createPayment(paymentData)
            .then((response) => {
                modal.displaySuccessOptions(
                    labels.dialog.message.CREATE_PAYMENT_SUCCESS.replace('{ref}', response.body.data.referenceNumber),
                    undefined,
                    () => {
                        loadDetails();
                    },
                    undefined,
                    () => {
                        navigate(
                            permitType === PermitType.IHC
                                ? routes.dashboard.HEALTH.FOR_REVIEW.path
                                : routes.dashboard.SANITARY.FOR_REVIEW.path,
                            { replace: true }
                        );
                    },
                    labels.BACK_LIST
                );
                setLoading(false);
            })
            .catch((err) => {
                navigate(-1);
                setLoading(false);
                console.error(err);
            });
    };

    const checkApproveDisabled = () => {
        let disabled = true;

        if (!isLoading && uploads.length === 0) {
            disabled = false;
        } else {
            let employeeUploadsNotCompleted = false;

            //Get pending uploads
            let notCompleted: Upload[] = reject(uploads, { status: Status.COMPLETED });
            let notCompletedIds = notCompleted.map((a) => a.requirementId);

            //Check if upload is applicable to a requirement
            let pendingRequirements: Requirement[] = requirements.filter(({ requirementId }) =>
                notCompletedIds.includes(requirementId)
            );

            if (hasBulkHC) {
                //Ignore health certificate pending requirement
                pendingRequirements = pendingRequirements.filter(
                    (requirement) => !requirement.requirementName.toLowerCase().includes('health certificate')
                );

                employeeUploadsNotCompleted = bulkHCStatus ? bulkHCStatus !== Status.COMPLETED : false;
            }

            disabled = pendingRequirements.length > 0 || employeeUploadsNotCompleted;
        }

        return disabled;
    };

    const checkReqUpdateDisabled = () => {
        let disabled = true;

        if (!isLoading) {
            if (uploads.length === 0 || (isYellow && initialYellowState !== isYellow)) {
                disabled = false;
            } else {
                let disabledReqUpdate = true;
                let disabledBulk = true;

                //Check for uploads in requires update status
                let reqUpdate = find(uploads, { status: Status.REQUIRES_UPDATE });

                disabledReqUpdate = reqUpdate ? false : true;

                //If still disabled, check for missing requirement
                if (disabledReqUpdate) {
                    let requiredRequirement: Requirement[] = filter(requirements, { required: true, inactive: false });
                    //Exclude SP health certs
                    requiredRequirement = requiredRequirement.filter(req => !req.requirementName.toLowerCase().includes("health certificates"));
                    let reqIds = requiredRequirement.map(req => req.requirementId);
                    let uploadReqIds = uploads.map(upload => upload.requirementId);

                    let missingRequirements = difference(reqIds, uploadReqIds);

                    disabledReqUpdate = missingRequirements.length > 0 ? false : true;
                }

                disabledBulk = hasBulkHC ? bulkHCStatus !== Status.REQUIRES_UPDATE : false;

                disabled = disabledReqUpdate && disabledBulk;
            }
        }

        return disabled;
    };

    const _handleClose = () => {
        let reqUpdate = find(uploads, { status: Status.REQUIRES_UPDATE });
        if (reqUpdate) {
            modal.displayConfirmation(labels.dialog.message.CLOSE_CONFIRM, () => {
                navigate(-1);
            });
        } else {
            navigate(-1);
        }
    };

    const _handleUploadClose = (updated: boolean) => {
        setUploadData(undefined);
        if (updated) getUploads();
    };

    const _handlePaymentClose = (paymentData: CreatePayment | undefined) => {
        setShowPayment(false);
        if (paymentData && application) {
            let id = application.applicationId;
            setLoading(true);

            if (application.status === Status.FOR_REVIEW || application.status === Status.ON_HOLD) {
                ApplicationService.validateApplication(id, isYellow)
                    .then(() => {
                        createPayment(paymentData);
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.error(err);
                    });
            } else if (application.status === Status.VALIDATED) {
                createPayment(paymentData);
            }
        }
    };

    // const _handleRelease = (id: number, initDate: string) => {
    //     modal.displayReleaseConfirmation(
    //         common.parseDateString(initDate),
    //         labels.dialog.message.RELEASE_APP_CONFIRM,
    //         (values) => {
    //             ApplicationService.releaseApplication(id, common.parseDateStringAPI(values.pickUpDate))
    //                 .then(() => {
    //                     modal.displaySuccessOptions(
    //                         labels.dialog.message.RELEASE_APP_SUCCESS,
    //                         undefined,
    //                         () => {
    //                             loadDetails();
    //                         },
    //                         undefined,
    //                         () => {
    //                             navigate(
    //                                 permitType === PermitType.IHC
    //                                     ? routes.dashboard.HEALTH.APPROVED.path
    //                                     : routes.dashboard.SANITARY.APPROVED.path,
    //                                 { replace: true }
    //                             );
    //                         },
    //                         labels.BACK_LIST
    //                     );
    //                 })
    //                 .catch((err) => {
    //                     console.error(err);
    //                 });
    //         }
    //     );
    // };

    const _handleValidateApp = (id: number) => {
        modal.displayConfirmation(labels.dialog.message.VALIDATE_APP_CONFIRM, () => {
            ApplicationService.validateApplication(id, isYellow)
                .then(() => {
                    modal.displaySuccessOptions(
                        labels.dialog.message.VALIDATE_APP_SUCCESS,
                        undefined,
                        () => {
                            loadDetails();
                        },
                        undefined,
                        () => {
                            navigate(
                                permitType === PermitType.IHC
                                    ? routes.dashboard.HEALTH.FOR_REVIEW.path
                                    : routes.dashboard.SANITARY.FOR_REVIEW.path,
                                { replace: true }
                            );
                        },
                        labels.BACK_LIST
                    );
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const _handleApproveApp = (id: number) => {
        modal.displayApproveConfirmation(labels.dialog.message.APPROVE_APP_CONFIRM, (values) => {
            ApplicationService.approveApplication(id, values.remarks, common.parseDateToStringAPI())
                .then(() => {
                    modal.displaySuccessOptions(
                        labels.dialog.message.APPROVE_APP_SUCCESS,
                        undefined,
                        () => {
                            loadDetails();
                        },
                        undefined,
                        () => {
                            navigate(
                                permitType === PermitType.IHC
                                    ? routes.dashboard.HEALTH.WAITING_APPROVAL.path
                                    : routes.dashboard.SANITARY.WAITING_APPROVAL.path,
                                { replace: true }
                            );
                        },
                        labels.BACK_LIST
                    );
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const _handleRequireUpdateApp = (id: number) => {
        modal.displayReqUpdConfirmation(labels.dialog.message.REQUIRE_UPDATE_CONFIRM, (values) => {
            ApplicationService.requireUpdateApplication(id, values.remarks, isYellow)
                .then(() => {
                    navigate(-1);
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const _handleRequireUpdateHead = () => {
        if (application) {
            let inputOptions = [
                {
                    label: 'Select update',
                    value: '',
                    hidden: true
                },
                {
                    label: 'Requirements',
                    value: 'requirement'
                }
            ];

            if (application.paymentDetailResponseDto?.[0]?.total) {
                inputOptions.push({ label: 'Receipt', value: 'receipt' });
            }

            let inputs = [
                {
                    label: labels.REQUIRE_UPDATE_FOR,
                    name: 'update',
                    as: 'select',
                    options: inputOptions,
                    reg: {
                        required: labels.FIELD_REQUIRED
                    }
                },
                {
                    label: labels.REMARKS,
                    name: 'remarks',
                    as: 'textarea',
                    reg: {
                        required: labels.FIELD_REQUIRED
                    }
                }
            ];

            modal.displayInputConfirmation(
                labels.dialog.message.REQUIRE_UPDATE_CONFIRM,
                inputs,
                labels.REQUIRES_UPDATE,
                (values) => {
                    ApplicationService.requireUpdateHead(application.applicationId, values.update + '- ' + values.remarks)
                        .then(() => {
                            navigate(-1);
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                },
                labels.REQUIRES_UPDATE,
                undefined,
                undefined,
                true
            );
        }
    };

    const _handleOnHold = (id: number) => {
        modal.displayOnHoldConfirmation(
            labels.dialog.message.ON_HOLD_CONFIRM,
            (values) => {
                ApplicationService.onHoldApplication(id, values.remarks)
                    .then(() => {
                        navigate(-1);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        );
    };

    const _handleRejectApp = (id: number) => {
        modal.displayReqUpdConfirmation(
            labels.dialog.message.REJECT_APP_CONFIRM,
            (values) => {
                ApplicationService.rejectApplication(id, values.remarks)
                    .then(() => {
                        navigate(-1);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },
            labels.REJECT_APP,
            labels.REJECT
        );
    };

    const _handleDiscardApp = (id: number) => {
        modal.displayConfirmation(
            labels.dialog.message.DISCARD_APP_CONFIRM,
            () => {
                ApplicationService.discardApplication(id)
                    .then(() => navigate(-1))
                    .catch((err) => console.error(err));
            },
            labels.DISCARD,
            undefined,
            undefined,
            undefined,
            true
        );
    };

    const renderApplicationDetails = () => {
        if (application) {
            if (permitType === PermitType.IHC) {
                let formData = application.formData as HealthAppData;
                const generatedAddress = generateAddress(formData);

                return (
                    <Row className='detail-row'>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.APPLICATION_DATE)}
                                <span>{application.createdDate}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.APPLICATION_TYPE)}
                                <span>{formData.type}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.INDUSTRY)}
                                <span>{formData.industry}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.SUB_INDUSTRY)}
                                <span>{formData.subIndustry}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.FULL_NAME)}
                                <span>{common.parseFullname(formData)}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.GENDER)}
                                <span>{formData.gender}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.EMAIL_ADDRESS)}
                                <span>{formData.email}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.CONTACT_NUMBER)}
                                <span>{formData.contactNumber}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.BIRTH_DATE)}
                                <span>{formData.birthDate}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.NATIONALITY)}
                                <span>{formData.nationality}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.OCCUPATION)}
                                <span>{formData.occupation}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.COMPANY)}
                                <span>{common.parseNullable(formData.companyName)}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.PESO_APPLICANT)}
                                <span>{common.parseBooleanLabel(formData.isPESO)}</span>
                            </span>
                        </Col>

                        {application.yellowCard !== null && (
                            <Col xs={12} md={6}>
                                <span>
                                    {common.parseDetailLabel(labels.YELLOW_CARD)}
                                    <span>{common.parseBooleanLabel(application.yellowCard)}</span>
                                </span>
                            </Col>
                        )}

                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.FOR_INHOUSE)}
                                <span>{common.parseBooleanLabel(formData.isInternal)}</span>
                            </span>
                        </Col>
                        {application.certificateNumber && (
                            <Col xs={12} lg={6}>
                                <span>
                                    {common.parseDetailLabel(labels.CERTIFICATE_NO)}
                                    <span className='link' onClick={() => setShowCertificate(true)}>
                                        {application.certificateNumber}
                                    </span>
                                </span>
                            </Col>
                        )}
                        {application.expirationDate && (
                            <Col xs={12} lg={6}>
                                <span>
                                    {common.parseDetailLabel(labels.DATE_EXPIRY)}
                                    <span>{application.expirationDate}</span>
                                </span>
                            </Col>
                        )}
                        {application.issuanceDate && (
                            <Col xs={12} lg={6}>
                                <span>
                                    {common.parseDetailLabel(labels.DATE_RELEASED)}
                                    <span>{application.issuanceDate}</span>
                                </span>
                            </Col>
                        )}
                        {application.remarks && (
                            <Col xs={12}>
                                <span>
                                    {common.parseDetailLabel(labels.REMARKS)}
                                    <span>{application.remarks}</span>
                                </span>
                            </Col>
                        )}
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.digitalHealthCert.OCCUPATIONAL_PERMIT)}
                                <span>{formData.occupationalPermit}</span>
                            </span>
                        </Col>
                        {generatedAddress && (
                            <Col xs={12}>
                                <span>
                                    {common.parseDetailLabel(labels.HOME_ADDRESS)}
                                    <span>{generatedAddress}</span>
                                </span>
                            </Col>
                        )}
                    </Row>
                );
            } else if (permitType === PermitType.SP) {
                let formData = application.formData as SanitaryAppData;
                return (
                    <Row className='detail-row'>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.APPLICATION_DATE)}
                                <span>{application.createdDate}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.ESTABLISHMENT)}
                                <span>{formData.establishmentName}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.MAYOR_PERMIT)}
                                <span>{common.parseNullable(formData.mayorPermitNo)}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.OWNER)}
                                <span>{formData.ownerName}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.EMAIL_ADDRESS)}
                                <span>{formData.email}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.ADDRESS)}
                                <span>{formData.address}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.APPLICATION_TYPE)}
                                <span>{formData.type}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.INDUSTRY)}
                                <span>{formData.industry}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.SUB_INDUSTRY)}
                                <span>{formData.subIndustry}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.NUM_EMPLOYEES)}
                                <span>{formData.employeeNum}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.NUM_EMPLOYEES_HC)}
                                <span>{formData.employeeNumWithHC}</span>
                            </span>
                        </Col>
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.NUM_EMPLOYEES_NOHC)}
                                <span>{formData.employeeNumWithoutHC}</span>
                            </span>
                        </Col>
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.FOR_INHOUSE)}
                                <span>{common.parseBooleanLabel(formData.isInternal)}</span>
                            </span>
                        </Col>
                        {application.certificateNumber && (
                            <Col xs={12} lg={6}>
                                <span>
                                    {common.parseDetailLabel(labels.CERTIFICATE_NO)}
                                    <span>{application.certificateNumber}</span>
                                </span>
                            </Col>
                        )}
                        {application.expirationDate && (
                            <Col xs={12} lg={6}>
                                <span>
                                    {common.parseDetailLabel(labels.DATE_EXPIRY)}
                                    <span>{application.expirationDate}</span>
                                </span>
                            </Col>
                        )}
                        {application.remarks && (
                            <Col xs={12}>
                                <span>
                                    {common.parseDetailLabel(labels.REMARKS)}
                                    <span>{application.remarks}</span>
                                </span>
                            </Col>
                        )}
                        {application.approvedDate && (
                            <Col xs={12} lg={6}>
                                <span>
                                    {common.parseDetailLabel(labels.DATE_RELEASED)}
                                    <span>{application.approvedDate}</span>
                                </span>
                            </Col>
                        )}
                    </Row>
                );
            }
        } else {
            return <></>;
        }
    };

    const renderSO = () => {
        let total = sanitaryReportList.length;
        if (total > 0) {
            let rows: JSX.Element[] = [];

            sanitaryReportList.forEach((report, index) => {
                let className = 'status';
                let statusKey = SanitaryReportStatus[report.sanitaryInspectionReportStatus];

                if (statusKey === SanitaryReportStatus.RESOLVED) {
                    className = 'passed';
                } else if (statusKey === SanitaryReportStatus.FOR_ISSUANCE) {
                    className = 'failed';
                }

                rows.push(
                    <tr
                        key={index}
                        onClick={() =>
                            navigate(
                                routes.dashboard.SANITARY.SR_VIEW.path
                                    .replace(':type', 'view')
                                    .replace(':id', report.sanitaryInspectionReportId.toString())
                            )
                        }>
                        <td>{report.sanitaryInspectionReportNo}</td>
                        <td className={className}>{SanitaryReportStatus[report.sanitaryInspectionReportStatus]}</td>
                        <td>{report.createdDate}</td>
                    </tr>
                );
            });

            return (
                <CollapsibleCard className='content-card'>
                    <CollapsibleCard.Header className='warning'>
                        <IoWarningOutline />
                        <span>{total + ' ' + (total > 1 ? labels.SANITARY_REPORTS : labels.SANITARY_REPORT)}</span>
                    </CollapsibleCard.Header>
                    <CollapsibleCard.Body>
                        <Table responsive bordered hover className='link'>
                            <thead>
                                <tr>
                                    <th>{labels.SANITARY_ORDER_NO}</th>
                                    <th>{labels.STATUS}</th>
                                    <th>{labels.CREATED_DATE}</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </CollapsibleCard.Body>
                </CollapsibleCard>
            );
        } else {
            return <></>;
        }
    };

    const renderRequirements = () => {
        if (application) {
            return (
                <Row>
                    <Col>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>{labels.REQUIREMENTS}</th>
                                    <th>{labels.STATUS}</th>
                                    <th>{labels.VIEW}</th>
                                </tr>
                            </thead>
                            <tbody>{renderRows()}</tbody>
                        </Table>
                    </Col>
                </Row>
            );
        } else {
            return <></>;
        }
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        if (application) {
            if (isRequirementsLoading) {
                rows.push(
                    <tr key={1}>
                        <td colSpan={100}>
                            <div className='center-content'>
                                <Spinner animation='border' variant='primary' />
                            </div>
                        </td>
                    </tr>
                );
            } else if (requirements.length === 0) {
                rows.push(
                    <tr key={1}>
                        <td colSpan={100}>
                            <span>{labels.NO_DATA}</span>
                        </td>
                    </tr>
                );
            } else {
                const getRequirementStatus = (
                    application: Application,
                    requirement: Requirement,
                    upload?: Upload,
                    labResult?: LabForm
                ) => {
                    let className = '';
                    let status: string;
                    let formData: any = application.formData;

                    if (upload) {
                        if (upload.status === Status.FOR_REVIEW) {
                            className = 'status';
                        } else if (upload.status === Status.REQUIRES_UPDATE) {
                            className = 'failed';
                        } else if (upload.status === Status.COMPLETED) {
                            className = 'passed';
                        }
                    } else if (labResult) {
                        if (labResult.status === LabFormStatus.APPROVED) {
                            className = 'passed';
                        } else if (labResult.status === LabFormStatus.REJECTED) {
                            className = 'failed';
                        }
                    }

                    status = labels.NO_RESULT;

                    if (upload) {
                        status = upload.status;
                    } else if (labResult) {
                        status = labels.COMPLETED_RESULT;
                    } else {
                        if ((requirement.internal || requirement.socialHygiene || requirement.waterAnalysis) 
                            && common.parseBooleanString(formData.isInternal)) {
                            status = labels.PENDING_LAB;
                            if ((requirement.internal && application.labCompleted) || 
                            (requirement.socialHygiene && (application.socialHygieneCompleted || !application.yellowCard)) ||
                            (requirement.waterAnalysis && application.waterAnalysisCompleted)) {
                                status = labels.NO_RESULT;
                            }
                        }
                    }

                    return (
                        <>
                            <span className={className}>{status}</span>
                            {upload && upload.remarks && <InfoTooltip message={upload.remarks} />}
                        </>
                    );
                };

                const getHCRequirementStatus = () => {
                    let className = 'status';

                    if (bulkHCStatus === Status.REQUIRES_UPDATE) {
                        className = 'failed';
                    } else if (bulkHCStatus === Status.COMPLETED) {
                        className = 'passed';
                    }

                    return <span className={className}>{bulkHCStatus}</span>;
                };

                requirements.forEach((requirement, index) => {
                    let upload: Upload = find(uploads, { requirementId: requirement.requirementId });
                    let uploadHistory: Upload[] = filter(uploadsHistory, { requirementId: requirement.requirementId });
                    uploadHistory.reverse();
                    let labResult: LabForm = (application.labCompleted || (application.yellowCard && application.socialHygieneCompleted) || application.waterAnalysisCompleted)
                        ? find(labResults, { requirementId: requirement.requirementId })
                        : undefined;

                    if (!requirement.inactive || upload) {
                        let showHC = requirement.requirementName.toLowerCase().includes('health certificate') && hasBulkHC;

                        rows.push(
                            <tr key={index}>
                                <td>
                                    {requirement.requirementName}
                                    {requirement.required && <RequiredToolTip />}
                                </td>
                                {showHC && (
                                    <>
                                        <td className='text-center'>{getHCRequirementStatus()}</td>
                                        <td className='text-center'>
                                            <Button variant='primary' onClick={() => setShowBulkHC(true)}>
                                                {labels.VIEW_CERTIFICATES}
                                            </Button>
                                        </td>
                                    </>
                                )}
                                {!showHC && (
                                    <>
                                        <td className='text-center'>
                                            {getRequirementStatus(application, requirement, upload, labResult)}
                                            {uploadHistory.length > 0 && (
                                                <span
                                                    className='link history'
                                                    onClick={() =>
                                                        setActiveUploadHistory({
                                                            requirementName: requirement.requirementName,
                                                            uploadHistory: uploadHistory
                                                        })
                                                    }>
                                                    View History
                                                </span>
                                            )}
                                        </td>
                                        <td className='text-center'>
                                            {upload && (
                                                <Button
                                                    variant='primary'
                                                    onClick={() =>
                                                        setUploadData({
                                                            upload: upload,
                                                            requirementName: requirement.requirementName
                                                        })
                                                    }>
                                                    <FaRegFileImage />
                                                </Button>
                                            )}

                                            {labResult && (
                                                <Button variant='primary' onClick={() => setLabResultData(labResult)}>
                                                    <FaFilePrescription />
                                                </Button>
                                            )}
                                        </td>
                                    </>
                                )}
                            </tr>
                        );

                        // if (uploadHistory.length > 0) {
                        //     let upperIndex = index;
                        //     uploadHistory.reverse();

                        //     uploadHistory.forEach((history, index) => {
                        //         rows.push(
                        //             <tr key={upperIndex + '-' + index}>
                        //                 <td></td>
                        //                 <td className='text-center'>
                        //                     <span className={'failed history'}>{history.status}</span>
                        //                     <InfoTooltip message={history.remarks} />
                        //                 </td>
                        //                 <td className='text-center'>
                        //                     <Button
                        //                         variant='primary'
                        //                         onClick={() =>
                        //                             setUploadData({
                        //                                 upload: history,
                        //                                 requirementName: requirement.requirementName,
                        //                                 viewOnly: true
                        //                             })
                        //                         }>
                        //                         <FaRegFileImage />
                        //                     </Button>
                        //                 </td>
                        //             </tr>
                        //         );
                        //     })
                        // }
                    }
                });
            }
        }

        return rows;
    };

    const renderBulk = () => {
        if (bulkApplicationList && bulkApplicationList.length > 0) {
            let otherApplications = bulkApplicationList.filter((app) => app.applicationId !== parseInt(params.appId));

            if (bulkApplicationCount > 0) {
                let rows: JSX.Element[] = [];

                otherApplications.forEach((app, index) => {
                    if (index < bulkShowCount) {
                        rows.push(
                            <tr
                                key={index}
                                className='clickable'
                                onClick={() =>
                                    navigate(
                                        routes.APPLICATION.path
                                            .replace(':type', permitType)
                                            .replace(':appId', app.applicationId.toString())
                                    )
                                }>
                                <td>{common.parseFullname(app.formData)}</td>
                                <td className='status'>{app.status}</td>
                            </tr>
                        );
                    }
                });

                return (
                    <CollapsibleCard className='content-card'>
                        <CollapsibleCard.Header>
                            <span>
                                <b>{'Bulk Applied with ' + bulkApplicationCount + ' other' + (bulkApplicationCount > 1 ? 's' : '')}</b>
                            </span>
                        </CollapsibleCard.Header>
                        <CollapsibleCard.Body>
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th>{labels.FULL_NAME}</th>
                                        <th>{labels.STATUS}</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                            {bulkApplicationCount > bulkShowCount && (
                                <label
                                    className='action-text'
                                    onClick={() => {
                                        checkBulk();
                                    }}>
                                    {'Show ' +
                                        (bulkApplicationCount > bulkShowCount + config.SHOW_LIMIT ? config.SHOW_LIMIT : bulkApplicationCount - bulkShowCount) +
                                        ' more'}
                                </label>
                            )}
                        </CollapsibleCard.Body>
                    </CollapsibleCard>
                );
            }
        }
    };

    const renderExamResult = () => {
        if (application && application.examCompletedDate) {
            return (
                <Row>
                    <Col>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>{labels.HIV_EXAM_STATUS}</th>
                                    <th>{labels.SCORE}</th>
                                    <th>{labels.HIV_EXAM_DATE}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={application.examResult === 'PASS' ? 'passed' : 'failed'}>
                                        {application.examResult === 'PASS' ? labels.COMPLETED : labels.FAILED}
                                    </td>
                                    <td>{application.examScore + '%'}</td>
                                    <td>{application.examCompletedDate}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            );
        } else {
            return <></>;
        }
    };

    const renderStatusHistory = () => {
        if (application && application.statusHistory?.length > 0) {
            let history: JSX.Element[] = [];

            const sortedStatusHistory = sortBy(application.statusHistory, ['approvedDate'])

            sortedStatusHistory.forEach((status, index) => {
                history.push(
                    <tr key={index}>
                        <td
                            className={
                                status.status === HistoryStatus.REJECT || status.status === HistoryStatus.UPDATE || status.status === HistoryStatus.ON_HOLD
                                    ? 'failed'
                                    : 'passed'
                            }>
                            {status.status}
                            {status.remarks && <InfoTooltip message={status.remarks} />}
                        </td>
                        <td>{status.userId}</td>
                        <td>{status.lastName + ', ' + status.firstName}</td>
                        <td>{common.parseDateTimeAPIToDateTime(status.approvedDate)}</td>
                    </tr>
                );
            });

            return (
                <CollapsibleCard className='content-card' defaultCollapsed={true}>
                    <CollapsibleCard.Header>
                        <span>
                            <b>{labels.APP_HISTORY}</b>
                        </span>
                    </CollapsibleCard.Header>
                    <CollapsibleCard.Body>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th>{labels.ACTION}</th>
                                    <th>{labels.USER}</th>
                                    <th>{labels.NAME}</th>
                                    <th>{labels.DATE}</th>
                                </tr>
                            </thead>
                            <tbody>{history}</tbody>
                        </Table>
                    </CollapsibleCard.Body>
                </CollapsibleCard>
            );
        }
    };

    const renderMassagistTraining = () => {
        return (massagistTrainingDetails && trainingSessionDetails) ? (
            <CollapsibleCard className='content-card' defaultCollapsed={true}>
                <CollapsibleCard.Header>
                    <strong>{labels.MASSAGIST_TRAINING}</strong>
                </CollapsibleCard.Header>
                <CollapsibleCard.Body>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th>{labels.PARTICIPANT_TRAINING_STATUS}</th>
                                <th>{labels.PARTICIPANT_ID}</th>
                                <th>{labels.TRAINING_SESSION}</th>
                                <th>{labels.TRAINING_DATE}</th>
                                <th>{labels.COMPLETED_DATE}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={massagistTrainingDetails?.trainingSessionCompleted ? 'passed' : 'failed'}>
                                    {massagistTrainingDetails?.trainingSessionCompleted ? "COMPLETED" : "IN PROGRESS"}
                                </td>
                                <td>{massagistTrainingDetails?.trainingParticipantId}</td>
                                <td>{trainingSessionDetails?.trainingName}</td>
                                <td>{trainingSessionDetails?.startDate}&nbsp;{trainingSessionDetails?.startTime} TO {trainingSessionDetails?.endDate}&nbsp;{trainingSessionDetails?.endTime}</td>
                                <td>{massagistTrainingDetails?.trainingSessionCompletedDate}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CollapsibleCard.Body>
            </CollapsibleCard>
        )
            :
            <></>
    }

    const getLabMessage = ():String => {
        let message = '**Application is waiting for ';
        let added = false;
        if (application?.regularLabStatus) {
            message = message + labels.CLINICAL_LAB.toUpperCase();
            added  = true;
        }
        if (application?.socialHygieneStatus) {
            message = added ? message + ', ' : message;
            message = message + labels.SOCIAL_HYGIENE.toUpperCase();
            added  = true;
        }
        if (application?.waterAnalysisStatus) {
            message = added ? message + ', ' : message;
            message = message + labels.WATER_ANALYSIS.toUpperCase();
        }
        
        message = message + ' completion.'

        return message;
    }

    return (
        <div className='ApplicationDetails'>
            <PageHeader
                title={permitType === PermitType.IHC ? labels.HEALTH : labels.SANITARY}
                subTitle={application?.status}
                allowBack={true}
                backConfirm={_handleClose}
            />
            <PageBody>
                <Card>
                    <Card.Header>
                        <b>{'[' + params.appId + '] ' + labels.APP_INFORMATION}</b>
                    </Card.Header>
                    <Card.Body className='p-3'>
                        {isLoading && (
                            <div className='center-content'>
                                <Spinner animation='border' variant='primary' />
                            </div>
                        )}
                        {!isLoading && (
                            <>
                                {renderApplicationDetails()}
                                {renderSO()}
                                {renderBulk()}
                                <DuplicateApplicationCard application={application} permitType={permitType} />
                                {renderRequirements()}
                                {renderExamResult()}
                                {renderMassagistTraining()}
                                {<PaymentDetailsCard permitType={permitType} application={application} onUpdate={() => loadDetails()} />}
                                {renderStatusHistory()}
                            </>
                        )}
                    </Card.Body>
                    {application &&
                        !isLoading &&
                        !isRequirementsLoading &&
                        common.allowedValidator(permitType, user.authorities) && (
                            <Card.Footer>
                                <div className='footer-row'>
                                    <div className='reject-container'>
                                        {(application.status === Status.FOR_REVIEW || application.status === Status.HEAD_APPROVAL) && (
                                            <Button
                                                variant='outline-secondary'
                                                onClick={() => _handleOnHold(application.applicationId)}>
                                                {labels.ON_HOLD}
                                            </Button>
                                        )}
                                        {(application.status === Status.FOR_REVIEW ||
                                            application.status === Status.ON_HOLD ||
                                            application.status === Status.HEAD_APPROVAL ||
                                            application.status === Status.HIV_SEMINAR) && (
                                                <Button
                                                    variant='secondary'
                                                    onClick={() => _handleRejectApp(application.applicationId)}>
                                                    {labels.REJECT_APP}
                                                </Button>
                                            )}
                                    </div>
                                    <div className='button-group'>
                                        {permitType === PermitType.IHC &&
                                            (application.status === Status.FOR_REVIEW ||
                                                application.status === Status.ON_HOLD) && (
                                                <div className='mt-3 mt-lg-0'>
                                                    <Form.Group className='mb-0'>
                                                        <Form.Check
                                                            type='checkbox'
                                                            name='yellowCard'
                                                            label={labels.YELLOW_TAG}
                                                            checked={isYellow}
                                                            onChange={() => setIsYellow(!isYellow)}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            )}
                                        <div className='mt-3'>
                                            {(application.status === Status.FOR_REVIEW ||
                                                application.status === Status.ON_HOLD) && (
                                                    <Button
                                                        variant='outline-secondary'
                                                        disabled={checkReqUpdateDisabled()}
                                                        onClick={() => _handleRequireUpdateApp(application.applicationId)}>
                                                        {labels.REQUIRES_UPDATE}
                                                    </Button>
                                                )}

                                            {application.status === Status.HEAD_APPROVAL &&
                                                common.allowedApprover(permitType, user.authorities) && (
                                                    <Button
                                                        variant='outline-secondary'
                                                        onClick={() => _handleRequireUpdateHead()}>
                                                        {labels.REQUIRES_UPDATE}
                                                    </Button>
                                                )}

                                            {/* TODO: Check later */}
                                            {(application.status === Status.FOR_REVIEW ||
                                                application.status === Status.ON_HOLD ||
                                                application.status === Status.VALIDATED) &&
                                                !application.paymentDetailResponseDto?.[0]?.paymentDate && (
                                                    <Button
                                                        variant='primary'
                                                        disabled={checkApproveDisabled()}
                                                        onClick={() => setShowPayment(true)}>
                                                        {labels.PROCEED_PAYMENT}
                                                    </Button>
                                                )}

                                            {/* TODO: Check later */}
                                            {(application.status === Status.FOR_REVIEW ||
                                                application.status === Status.ON_HOLD) &&
                                                application.paymentDetailResponseDto?.[0]?.paymentDate && (
                                                    <Button
                                                        variant='primary'
                                                        disabled={checkApproveDisabled()}
                                                        onClick={() => _handleValidateApp(application.applicationId)}>
                                                        {labels.VALIDATE_APP}
                                                    </Button>
                                                )}

                                            {application.status === Status.HEAD_APPROVAL &&
                                                common.allowedApprover(permitType, user.authorities) &&
                                                common.allowedApprover(permitType, user.authorities) && (
                                                    <Button
                                                        variant='primary'
                                                        disabled={checkApproveDisabled()}
                                                        onClick={() => _handleApproveApp(application.applicationId)}>
                                                        {labels.APPROVE_APP}
                                                    </Button>
                                                )}

                                            {/* {application.status === Status.COMPLETED && (
                                                <Button
                                                    variant='primary'
                                                    onClick={() =>
                                                        _handleRelease(application.applicationId, application.pickUpDate)
                                                    }>
                                                    {labels.RELEASE_APP}
                                                </Button>
                                            )} */}

                                            {application.status === Status.DRAFT && (
                                                <Button
                                                    variant='secondary'
                                                    onClick={() => _handleDiscardApp(application.applicationId)}>
                                                    {labels.DISCARD_APP}
                                                </Button>
                                            )}



                                        </div>
                                    </div>
                                </div>
                                {application.status === Status.DRAFT && (
                                    <label className='update-caveat'>**Application is in <b>DRAFT</b> status and needs to be submitted by the applicant.</label>
                                )}
                                {application.status === Status.REQUIRES_UPDATE && (
                                    <label className='update-caveat'>**Application is in <b>REQUIRES UPDATE</b> status and is waiting to be resubmitted by the applicant.</label>
                                )}
                                {application.status === Status.PAYMENT_CREATED && (
                                    <label className='update-caveat'>**Application is in <b>PAYMENT CREATED</b> status and is waiting for payment to be submitted by the applicant.</label>
                                )}
                                {application.status === Status.PAYMENT_SUBMITTED && (
                                    <label className='update-caveat'>**Application is in <b>PAYMENT SUBMITTED</b> status. Please approve payment receipt.</label>
                                )}
                                {(application.status === Status.PENDING_SPECIMEN || application.status === Status.PENDING_RESULT || application.status === Status.PENDING_APPROVAL) && (
                                    <label className='update-caveat'>{getLabMessage()}</label>
                                )}
                            </Card.Footer>
                        )}
                </Card>
            </PageBody>
            {application && showPayment && (
                <CreatePaymentModal
                    show={showPayment}
                    requirements={requirements}
                    uploads={uploads}
                    application={application}
                    isYellow={isYellow}
                    permitType={permitType}
                    onClose={(paymentData) => _handlePaymentClose(paymentData)}
                />
            )}
            {uploadData && application && (
                <ViewRequirementModal
                    application={application}
                    permitType={permitType}
                    upload={uploadData.upload}
                    requirementName={uploadData.requirementName}
                    viewOnly={uploadData.viewOnly}
                    onClose={(updated) => _handleUploadClose(updated)}
                />
            )}
            {labResultData && application && (
                <LabFormModal
                    show={labResultData ? true : false}
                    status={Status.COMPLETED_RESULT}
                    application={application}
                    labForm={labResultData}
                    labType={labResultData.formData.hasOwnProperty('koh') ? LabType.SOCIAL_HYGIENE : 
                        labResultData.formData.formType === LabFormType.MICRO_WATER ? LabType.WATER_ANALYSIS : LabType.REGULAR_LABORATORY}
                    onClose={() => setLabResultData(undefined)}
                />
            )}
            {showBulkHC && application && (
                <EmployeeRequirementModal
                    show={showBulkHC}
                    application={application}
                    permitType={permitType}
                    onClose={(hasUpdate) => {
                        if (hasUpdate) {
                            checkHCBulk();
                        }

                        setShowBulkHC(false);
                    }}
                />
            )}
            {application && activeUploadHistory && (
                <UploadHistoryModal
                    application={application}
                    permitType={permitType}
                    requirementName={activeUploadHistory.requirementName}
                    uploadHistory={activeUploadHistory.uploadHistory}
                    onClose={() => setActiveUploadHistory(undefined)}
                />
            )}
            {application && application.certificateNumber && showCertificate && (
                <CertificateModal show={showCertificate} application={application} onClose={() => setShowCertificate(false)} />
            )}
        </div>
    );
};

export default ApplicationDetails;
