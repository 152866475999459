import React from 'react';
import { labels } from '../../../constants';
import { PaymentStatus, PermitType } from './../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import PaymentTable from '../../components/PaymentTable';

import './styles.scss';

type Props = {};

const HealthPaymentValidation: React.FunctionComponent<Props> = () => {
    return (
        <div className='HealthPaymentValidation'>
            <PageHeader title={labels.HEALTH} subTitle={labels.PAYMENT_VALIDATION} />
            <PageBody>
                <PaymentTable permitType={PermitType.IHC} status={PaymentStatus.SUBMITTED} />
            </PageBody>
        </div>
    );
};

export default HealthPaymentValidation;
