import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';

import { labels } from '../../../constants';
import { Application, HealthAppData } from '../../../models';

import HCDigitalCertificate from '../HCDigitalCertificate';

import './styles.scss';

type Props = {
    show: boolean;
    application: Application;
    viewOnly?: boolean;
    onClose: () => void;
};

const CertificateModal: React.FunctionComponent<Props> = (props) => {
    const { show, application, viewOnly, onClose } = props;
    const formData = application.formData as HealthAppData;
    const printRef = useRef(null);

    return (
        <Modal show={show} onHide={onClose} centered dialogClassName='CertificateModal'>
            <Modal.Header closeButton />
            <Modal.Body>
                <div className='cert-container' ref={printRef}>
                    <HCDigitalCertificate
                        formData={formData}
                        application={application}
                    />
                </div>
            </Modal.Body>
            {!viewOnly && (
                <Modal.Footer>
                    <ReactToPrint
                        bodyClass='CertificateModal'
                        trigger={() => <Button variant='primary'>{labels.PRINT}</Button>}
                        content={() => printRef.current}
                    />
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default CertificateModal;
