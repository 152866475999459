import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Table, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { usePage } from 'react-page-states';
import { IoMdAdd } from 'react-icons/io';

import { labels, routes } from './../../../constants';
import { modal, common, device, ApplicationService } from './../../../services';
import { TrainingSession, CreateTrainingSession, Roles } from '../../../models';
import PageHeader from './../../components/PageHeader';
import PageBody from './../../components/PageBody';
import Paginator from './../../components/Paginator';
import TrainingSessionModal from './../../components/TrainingSessionModal';

import './styles.scss';

type Props = {};

const TrainingSessions: React.FunctionComponent<Props> = () => {
    const isMobile = device.useMobileCheck();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user.user);
    const { pageNo, pageSize, total, ...page } = usePage();
    const [isLoading, setLoading] = useState(true);
    const [sessions, setSessions] = useState<TrainingSession[]>([]);
    const [showSession, setShowSession] = useState(false);

    const loadData = () => {
        setLoading(true);
        ApplicationService.getTrainingSession(pageNo - 1, pageSize).then((res) => {
            let data = res.body.data;
            setSessions(data.details ? data.details : []);
            page.setTotal(data.totalCount ? data.totalCount : 0);
            setLoading(false);
        });
    };

    useEffect(loadData, [pageSize, pageNo]);

    const _handleLimitChange = (limit: number) => {
        page.setPageNo(1);
        page.setPageSize(limit);
    };

    const _handleSessionClose = (session?: CreateTrainingSession) => {
        if (session) {
            modal.displayConfirmation(labels.dialog.message.SESSION_CONFIRM, () => {
                ApplicationService.createTrainingSession(session).then(() => {
                    modal.displaySuccess(labels.dialog.message.SUCCESS_NAME.replace('{name}', session.trainingName));
                    loadData();
                });
                setShowSession(false);
            });
        } else {
            setShowSession(false);
        }
    };

    const renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];

        sessions.forEach((session, index) => {
            rows.push(
                <tr
                    key={index}
                    onClick={() =>
                        navigate(
                            routes.dashboard.MASSAGIST.SESSION_DETAILS.path.replace(
                                ':sessionId',
                                session.trainingSessionId.toString()
                            )
                        )
                    }>
                    <td>{session.trainingName}</td>
                    <td>{session.startDate} {common.formatTimeToAMPM(session.startTime)}</td>
                    <td>{session.endDate} {common.formatTimeToAMPM(session.endTime)}</td>
                    <td>{session.trainer}</td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <div className='TrainingSessions'>
            <PageHeader title={labels.MASSAGIST_TRAINING} subTitle={labels.TRAINING_SESSIONS} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Row>
                            <Col xs={6}>
                                <span>Show </span>
                                <Form.Group className='count-select'>
                                    <Form.Control
                                        as='select'
                                        size='sm'
                                        value={pageSize}
                                        onChange={(e: any) => _handleLimitChange(e.target.value)}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                        <option>40</option>
                                        <option>50</option>
                                    </Form.Control>
                                </Form.Group>
                                {!isMobile && <span> entries</span>}
                            </Col>
                            {common.allowedRoute([Roles.MASSAGIST_TRAINER], user.authorities) && (
                                <Col xs={6}>
                                    <Button className='add' variant='primary' onClick={() => setShowSession(true)}>
                                        <IoMdAdd />
                                        {!isMobile && labels.CREATE_SESSION}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && total === 0 && (
                                    <div className='center'>
                                        <span>No data available</span>
                                    </div>
                                )}
                                {!isLoading && total > 0 && (
                                    <Table responsive striped bordered hover className='link'>
                                        <thead>
                                            <tr>
                                                <th>{labels.NAME}</th>
                                                <th>{labels.START_DATE}</th>
                                                <th>{labels.END_DATE}</th>
                                                <th>{labels.TRAINER}</th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderRows()}</tbody>
                                    </Table>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {total > 0 && (
                                    <span>
                                        Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                        {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                    </span>
                                )}
                            </Col>
                            <Col md='auto'>
                                <Paginator
                                    currentPage={pageNo}
                                    maxPage={page.maxPage}
                                    _handlePageChange={(pageNumber) => page.setPageNo(pageNumber)}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </PageBody>
            <TrainingSessionModal show={showSession} onClose={_handleSessionClose} />
        </div>
    );
};

export default TrainingSessions;
