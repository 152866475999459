import qc_logo from './../../assets/images/qc_logo.svg';
import qc_health_logo from './../../assets/images/qc_health_logo.png';
import lqc_logo from './../../assets/images/qc-assets/lungsod-qc.png';
import hc_back from './../../assets/images/qc-assets/qc-hc-back.png';
import hd_logo from './../../assets/images/qc-assets/qc-healthdep.png';
import qc_tower from './../../assets/images/qc-assets/qc-tower.png';
import qc_tree from './../../assets/images/qc-assets/qc-tree.png';

const images = {
    QC_LOGO: {
        src: qc_logo,
        alt: 'qc_logo'
    },
    QC_HEALTH_LOGO: {
        src: qc_health_logo,
        alt: 'qc_health_logo'
    },
    HC_BACK: {
        src: hc_back,
        alt: 'hc_back'
    },
    LQC_LOGO: {
        src: lqc_logo,
        alt: 'lqc_logo'
    },
    HD_LOGO: {
        src: hd_logo,
        alt: 'hd_logo'
    },
    QC_TOWER: {
        src: qc_tower,
        alt: 'qc_tower'
    },
    QC_TREE: {
        src: qc_tree,
        alt: 'qc_tree'
    }

};

export default images;
