import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePage } from 'react-page-states';
import { Card, Table, Row, Col, Spinner, Button } from 'react-bootstrap';
import { RiDownload2Line } from 'react-icons/ri';
import { find } from 'lodash';

import { labels, config } from '../../../constants';
import {
    Application,
    Status,
    Permit,
    PermitType,
    HealthAppData,
    SanitaryAppData,
    HistoryStatus,
    ApplicationFilter,
    SortBy
} from '../../../models';
import LabType from '../../../models/enums/LabType';
import { ApplicationService, common, modal } from '../../../services';
import Paginator from '../Paginator';
import SearchFilter from '../SearchFilter';

import './styles.scss';

interface Props {
    permitType: PermitType;
    labType: LabType | null;
    isLaboratory?: boolean;
}

type FormValues = {
    dateFrom?: string;
    dateTo?: string;
    applicationTypeId?: string;
    isPESO?: string;
    industryId?: string;
    occupation?: string;
    barangay?: string;
    status?: string;
    approvedDateFrom?: string;
    approvedDateTo?: string;
};

const ApplicationSearch: React.FunctionComponent<Props> = (props) => {
    const { permitType, isLaboratory, labType } = props;
    const { pageNo, pageSize, total, ...page } = usePage();
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const [searched, setSearched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [applicationList, setApplicationList] = useState<Application[]>([]);
    const [formValues, setFormValues] = useState<FormValues>();
    const permitId = permitList.length > 0 ? find(permitList, { type: permitType }).permitId : 0;

    const searchData = () => {
        if (permitId && formValues && searched) {
            let method: any;
            setLoading(true)

            let filter: ApplicationFilter = common.createFilter(formValues);

            if (formValues.status) {
                if (formValues.status === Status.COMPLETED_RESULT) {
                    filter.status = undefined;
                    method = ApplicationService.getCompletedLab(
                        pageSize,
                        pageNo,
                        permitId,
                        labType,
                        filter,
                        SortBy.CREATED_DATE,
                        true,
                        false
                    );
                } else {
                    method = ApplicationService.getApplicationByStatus(
                        pageSize,
                        pageNo,
                        permitId,
                        formValues.status,
                        filter,
                        SortBy.CREATED_DATE,
                        true,
                        false,
                        labType
                    );
                }
            } else {
                method = ApplicationService.getApplications(
                    pageSize,
                    pageNo,
                    permitId,
                    filter,
                    SortBy.CREATED_DATE,
                    true,
                    true,
                    false
                );
            }

            method
                .then((response: any) => {
                    let data = response.body.data;
                    page.setTotal(data.totalCount);
                    setApplicationList(data.details);
                })
                .catch((err: any) => console.error(err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(searchData, [pageNo, formValues, searched]);

    const  _handleExportCSV = async() => {
        if (!(permitId && formValues && searched)) {
            return;
        }

        let fileName = permitType + '_REPORT ' + common.parseDateTimeFile() + '.csv';
        let filter: ApplicationFilter = common.createFilter(formValues);

        let csvContent = generateExportHeader().join(',') + '\r\n';

        let maxItr = Math.ceil(total / config.EXPORT_MAX);
        let itr = 1;

        try {
            while (itr <= maxItr) {
                modal.displayPreloader(labels.GENERATE_REPORT + "..." + Math.ceil(((itr - 1)/maxItr) * 100)  + "%");
            
                let calls: any[] = [];
    
                while (calls.length < 5 && itr <= maxItr) {
                    let method;
                    
                    if (formValues.status) {
                        if (formValues.status === Status.COMPLETED_RESULT) {
                            filter.status = undefined;
                            method = ApplicationService.getCompletedLab(
                                config.EXPORT_MAX,
                                itr,
                                permitId,
                                labType,
                                filter,
                                SortBy.CREATED_DATE,
                                true
                            );
                        } else {
                            method = ApplicationService.getApplicationReportByStatus(
                                config.EXPORT_MAX,
                                itr,
                                permitId,
                                formValues.status,
                                filter,
                                SortBy.CREATED_DATE,
                                true
                            );
                        }
                    } else {
                        method = ApplicationService.getApplicationsReport(
                            config.EXPORT_MAX,
                            itr,
                            permitId,
                            filter,
                            SortBy.CREATED_DATE,
                            true,
                            true
                        );
                    }
    
                    calls.push(method);
                    itr++;
                }
    
                let responses = await Promise.all(calls)
                let content = '';
                
                responses.forEach((response) => {
                    content += generateExportContent(response.body.data.details);
                });

                csvContent += content;
            }
    
            common.downloadCSV(csvContent, fileName);
        } catch (error) {
            modal.displayError();
            console.error(error);
        } finally {
            modal.closePreloader();
        }

    };

    const _handleSearch = (values: FormValues) => {
        setFormValues(values);
        page.setPageNo(1);
        setSearched(true);
    };

    const generateExportHeader = () => {
        let headers;

        if (permitType === PermitType.IHC) {
            headers = [
                labels.EMAIL_ADDRESS,
                labels.APPLICANT,
                labels.APPLICATION_DATE,
                labels.TYPE,
                labels.INDUSTRY,
                labels.STATUS,
                labels.GENDER,
                labels.BIRTH_DATE,
                labels.NATIONALITY,
                labels.OCCUPATION,
                labels.COMPANY,
                labels.BARANGAY,
                labels.CONTACT_NUMBER,
                labels.HIV_EXAM_DATE,
                labels.YELLOW_CARD,
                labels.PESO_APPLICANT,
                labels.IN_HOUSE,
                labels.REFERENCE_NO,
                labels.OR_NO,
                labels.AMOUNT,
                labels.PAYMENT_DATE,
                labels.CERTIFICATE_NO,

            ];

            if (!isLaboratory) {
                headers.push(
                    labels.VALIDATOR,
                    labels.DATE_VALIDATED,
                    labels.PAYMENT_APPROVER,
                    labels.DATE_PAYMENT_APPROVED,
                    labels.APPROVER,
                    labels.DATE_APPROVED
                );
            }
        } else {
            headers = [
                labels.EMAIL_ADDRESS,
                labels.APPLICANT,
                labels.APPLICATION_DATE,
                labels.TYPE,
                labels.INDUSTRY,
                labels.STATUS,
                labels.ESTABLISHMENT,
                labels.BARANGAY,
                labels.ADDRESS,
                labels.REFERENCE_NO,
                labels.OR_NO,
                labels.AMOUNT,
                labels.PAYMENT_DATE,
                labels.CERTIFICATE_NO,
                labels.VALIDATOR,
                labels.DATE_VALIDATED,
                labels.PAYMENT_APPROVER,
                labels.DATE_PAYMENT_APPROVED,
                labels.APPROVER,
                labels.DATE_APPROVED
            ];
        }

        return headers;
    };

    const generateExportContent = (applicationList: Application[]): string => {
        let content = '';

        applicationList.forEach((application: Application) => {
            let row;
            let payment = application.paymentDetailResponseDto?.[0];
            let statusHistory = application.statusHistory;

            if (permitType === PermitType.IHC) {
                let formData = application.formData as HealthAppData;

                row = [
                    formData.email,
                    common.encloseQuote(common.parseFullname(formData)),
                    application.createdDate,
                    formData.type,
                    formData.industry,
                    application.status,
                    formData.gender,
                    formData.birthDate,
                    formData.nationality,
                    formData.occupation,
                    common.encloseQuote(formData.companyName),
                    common.encloseQuote(formData.barangay),
                    formData.contactNumber,
                    application.examCompletedDate,
                    common.parseBooleanLabel(application.yellowCard),
                    common.parseBooleanLabel(formData.isPESO),
                    common.parseBooleanLabel(formData.isInternal),
                    payment?.referenceNumber,
                    payment?.officialReceiptNumber,
                    payment?.total,
                    payment?.paymentDate,
                    application.certificateNumber

                ];

                if (!isLaboratory) {
                    row.push(
                        common.getHistoryApprover(HistoryStatus.REQUIREMENT, statusHistory),
                        common.getHistoryDate(HistoryStatus.REQUIREMENT, statusHistory),
                        common.getHistoryApprover(HistoryStatus.PAYMENT, statusHistory),
                        common.getHistoryDate(HistoryStatus.PAYMENT, statusHistory),
                        common.getHistoryApprover(HistoryStatus.HEAD, statusHistory),
                        common.getHistoryDate(HistoryStatus.HEAD, statusHistory)
                    );
                }
            } else {
                let formData = application.formData as SanitaryAppData;

                row = [
                    formData.email,
                    common.encloseQuote(common.parseFullname(formData)),
                    application.createdDate,
                    formData.type,
                    formData.industry,
                    application.status,
                    common.encloseQuote(formData.establishmentName),
                    common.encloseQuote(formData.barangay),
                    common.encloseQuote(formData.address),
                    payment?.referenceNumber,
                    payment?.officialReceiptNumber,
                    payment?.total,
                    payment?.paymentDate,
                    application.certificateNumber,
                    common.getHistoryApprover(HistoryStatus.REQUIREMENT, statusHistory),
                    common.getHistoryDate(HistoryStatus.REQUIREMENT, statusHistory),
                    common.getHistoryApprover(HistoryStatus.PAYMENT, statusHistory),
                    common.getHistoryDate(HistoryStatus.PAYMENT, statusHistory),
                    common.getHistoryApprover(HistoryStatus.HEAD, statusHistory),
                    common.getHistoryDate(HistoryStatus.HEAD, statusHistory)
                ];
            }

            content += row.join(',') + '\r\n';
        });

        return content;
    };

    const renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];

        if (applicationList.length > 0) {
            applicationList.forEach((application, index) => {
                let formData: any = application.formData;

                if (permitType === PermitType.IHC) {
                    rows.push(
                        <tr key={index}>
                            <td>{common.parseFullname(formData)}</td>
                            <td>{formData.type}</td>
                            <td>{formData.industry}</td>
                            <td>{application.status}</td>
                            <td>{application.createdDate}</td>
                        </tr>
                    );
                } else {
                    rows.push(
                        <tr key={index}>
                            <td>{formData.establishmentName}</td>
                            <td>{formData.type}</td>
                            <td>{formData.industry}</td>
                            <td>{application.status}</td>
                            <td>{application.createdDate}</td>
                        </tr>
                    );
                }
            });
        } else {
            rows.push(
                <tr key={1}>
                    <td colSpan={8}>
                        <div className='center-content'>
                            <span>{labels.NO_DATA}</span>
                        </div>
                    </td>
                </tr>
            );
        }

        return rows;
    };

    return (
        <div className='ApplicationSearch'>
            <SearchFilter defaultCollapsed={true} permitType={permitType} isLaboratory={isLaboratory} onSubmit={_handleSearch} />
            {searched && (
                <Card className='mt-4'>
                    {!isLoading && (
                        <div className='result-header'>
                            <label>{total + ' ' + (total > 1 ? labels.APPLICATIONS : labels.APPLICATION)}</label>
                            {total > 0 && (
                                <Button className='float-right' variant='primary' onClick={_handleExportCSV}>
                                    <RiDownload2Line />
                                    <span className='d-none d-sm-inline-block'>{labels.EXPORT_CSV}</span>
                                </Button>
                            )}
                        </div>
                    )}
                    <Card.Body className='p-3'>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && (
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{permitType === PermitType.IHC ? labels.APPLICANT : labels.ESTABLISHMENT}</th>
                                                <th>{labels.TYPE}</th>
                                                <th>{labels.INDUSTRY}</th>
                                                <th>{labels.STATUS}</th>
                                                <th>{labels.APPLICATION_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderRows()}</tbody>
                                    </Table>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {total > 0 && (
                                    <span>
                                        Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                        {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                    </span>
                                )}
                            </Col>
                            <Col md='auto'>
                                <Paginator
                                    currentPage={pageNo}
                                    maxPage={page.maxPage}
                                    _handlePageChange={(pageNo) => page.setPageNo(pageNo)}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
};

export default ApplicationSearch;
