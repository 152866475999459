import React from 'react';

import { labels } from './../../../constants';
import PageHeader from './../../components/PageHeader';
import PageBody from './../../components/PageBody';

import './styles.scss';
import UsersTable from '../../components/UsersTable';

type Props = {};

const UsersAll: React.FunctionComponent<Props> = () => {
    return (
        <div className='UsersAll'>
            <PageHeader title={labels.USER_MANAGEMENT} subTitle={labels.ALL_USERS} />
            <PageBody>
                <UsersTable />
            </PageBody>
        </div>
    );
};

export default UsersAll;
