import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Spinner, Row, Col } from 'react-bootstrap';

import { labels } from '../../../constants';
import { Application, SanitaryAppData, CreateSanitaryReport, SanitaryReport } from '../../../models';
import { ApplicationService, modal } from '../../../services';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import SanitaryReportForm from '../../components/SanitaryReportForm';

import './styles.scss';

type Props = {};

const CreateSanitaryReportPage: React.FunctionComponent<Props> = () => {
    const params: any = useParams();
    const navigate = useNavigate();
    const [sanitaryReportData, setSanitaryReportData] = useState<any>();
    const [isLoading, setLoading] = useState(true);

    const loadDetails = () => {
        const appId = params.appId;

        if (appId) {
            setLoading(true);
            ApplicationService.getApplicationById(appId)
                .then((response: any) => {
                    const data: Application = response.body.data;
                    const formData = data.formData as SanitaryAppData;
                    let orderData = {
                        establishmentName: formData.establishmentName,
                        industryId: formData.industryId,
                        permitNumber: formData.mayorPermitNo,
                        ownerName: formData.ownerName,
                        contactNumber: formData.contactNumber,
                        address: formData.address,
                        employeeNum: formData.employeeNum ? formData.employeeNum : 0,
                        employeeNumWithHC: formData.employeeNumWithHC ? formData.employeeNumWithHC : 0,
                        employeeNumWithoutHC: formData.employeeNumWithoutHC ? formData.employeeNumWithoutHC : 0,
                        ppePersonnel: formData.ppePersonnel ? formData.ppePersonnel : 0
                    };
                    setSanitaryReportData(orderData);
                    setLoading(false);
                })
                .catch((err: any) => console.error(err));
        } else {
            setLoading(false);
        }
    };

    useEffect(loadDetails, [params]);

    const _onSubmit = (values: any) => {
        modal.displayConfirmation(
            labels.dialog.message.SR_CONFIRM.replace('{name}', values.establishmentName).replace('{permit}', values.permitNumber),
            () => {
                const { establishmentName, industryId, permitNumber, sanitaryInspectionReportStatus, ...formData } = values;
                let request: CreateSanitaryReport = { establishmentName, industryId, permitNumber, sanitaryInspectionReportStatus, formData };

                ApplicationService.createSanitaryReport(request)
                    .then((response) => {
                        let data: SanitaryReport = response.body.data;
                        navigate(-1);
                        modal.displaySuccess(
                            labels.dialog.message.SR_SUCCESS.replace('{SR}', data.sanitaryInspectionReportNo).replace(
                                '{name}',
                                data.establishmentName
                            )
                        );
                    })
                    .catch((err: any) => console.error(err));
            }
        );
    };

    return (
        <div className='CreateSanitaryReport'>
            <PageHeader title={labels.CREATE_SANITARY_REPORT} allowBack={true} />
            <PageBody>
                {isLoading && (
                    <Card>
                        <Card.Body className='p-3'>
                            <Row>
                                <Col>
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                )}
                {!isLoading && <SanitaryReportForm sanitaryReportData={sanitaryReportData} onSubmit={_onSubmit} />}
            </PageBody>
        </div>
    );
};

export default CreateSanitaryReportPage;
