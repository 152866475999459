import { reject } from 'lodash';

import { actions } from '../constants';
import { Industry, Permit, ApplicationType, LookUp, LookUpInfo, Requirement } from '../models';

type State = {
    permitList?: Array<Permit>;
    industry: {
        loading: boolean;
        list: Array<Industry>;
    };
    requirement: {
        loading: boolean;
        list: Array<Requirement>;
    };
    applicationType: {
        loading: boolean;
        list: Array<ApplicationType>;
    };
    lookUps: {
        loading: boolean;
        list: Array<LookUp>;
    };
    lookUpInfos: {
        loading: boolean;
        list: Array<LookUpInfo>;
    };
};

const initialState: State = {
    permitList: [],
    industry: {
        loading: true,
        list: []
    },
    requirement: {
        loading: true,
        list: []
    },
    applicationType: {
        loading: true,
        list: []
    },
    lookUps: {
        loading: true,
        list: []
    },
    lookUpInfos: {
        loading: true,
        list: []
    }
};

const config = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case actions.config.SET_PERMITS: {
            return {
                ...state,
                permitList: action.payload
            };
        }
        case actions.config.SET_INDUSTRIES_LOADING: {
            return {
                ...state,
                industry: {
                    ...state.industry,
                    loading: true
                }
            };
        }
        case actions.config.SET_INDUSTRIES: {
            return {
                ...state,
                industry: {
                    loading: false,
                    list: action.payload
                }
            };
        }
        case actions.config.SET_REQUIREMENTS_LOADING: {
            return {
                ...state,
                requirement: {
                    ...state.requirement,
                    loading: true
                }
            };
        }
        case actions.config.SET_REQUIREMENTS: {
            return {
                ...state,
                requirement: {
                    loading: false,
                    list: action.payload
                }
            };
        }
        case actions.config.SET_APPLICATIONTYPES_LOADING: {
            return {
                ...state,
                applicationType: {
                    ...state.applicationType,
                    loading: true
                }
            };
        }
        case actions.config.SET_APPLICATIONTYPES: {
            return {
                ...state,
                applicationType: {
                    loading: false,
                    list: action.payload
                }
            };
        }
        case actions.config.SET_LOOKUPS_LOADING: {
            return {
                ...state,
                lookUps: {
                    ...state.lookUps,
                    loading: true
                }
            };
        }
        case actions.config.SET_LOOKUPS: {
            return {
                ...state,
                lookUps: {
                    loading: false,
                    list: action.payload
                }
            };
        }
        case actions.config.SET_LOOKUPINFOS_LOADING: {
            return {
                ...state,
                lookUpInfos: {
                    ...state.lookUpInfos,
                    loading: true
                }
            };
        }
        case actions.config.SET_LOOKUPINFOS: {
            let newList: Array<LookUpInfo> = action.payload;

            if (newList.length > 0) {
                let lookUpId = newList[0].lookUpId;
                let otherList = reject(state.lookUpInfos.list, { lookUpId: lookUpId });

                return {
                    ...state,
                    lookUpInfos: {
                        loading: false,
                        list: newList.concat(otherList)
                    }
                };
            } else {
                return {
                    ...state,
                    lookUpInfos: {
                        ...state.lookUpInfos,
                        loading: false
                    }
                };
            }
        }
    }

    return state;
};

export default config;
