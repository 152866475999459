const labels = {
    TITLE: 'Quezon City Health Department',
    QC_ADDRESS_LINE1: 'Quezon City, Metro Manila',
    QC_ADDRESS_LINE2: 'QC Hall Compound, Mayaman St., corner Kalayaan Ave., Gate 4, Quezon City',
    WATER_EMAIL: 'qchdwatertestinglaboratory@gmail.com',
    USERNAME: 'Username',
    USERNAME_REQUIRED: 'Username is required',
    PASSWORD: 'Password',
    PASSWORD_REQUIRED: 'Password is required',
    REMEMBER_ME: 'Remember me',
    FIELD_REQUIRED: 'This field is required',
    NOT_WEEKEND: 'Date must not be on a sunday',
    INVALID_AUTH: 'Invalid username or password',
    CURRENT_PASSWORD: 'Current Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    PASSWORD_MISMATCH: 'Passwords do not match',
    INVALID_PASSWORD: 'Invalid password',
    OK: 'OK',
    PERMIT: 'Permit',
    CANCEL: 'Cancel',
    CLEAR: 'Clear',
    LOGIN: 'Login',
    LOGGED_IN: 'Logged in as:',
    LOGOUT: 'Logout',
    CHANGE_PASSWORD: 'Change password',
    DASHBOARD: 'Dashboard',
    HEALTH: 'Health Certificate',
    SANITARY: 'Sanitary Permit',
    LABORATORY: 'Clinical Laboratory',
    ALL: 'All',
    ALL_APP: 'All Applications',
    ALL_IND: 'All Industries',
    ALL_SUBIND: 'All Sub-Industries',
    DRAFT: 'Draft',
    FOR_REVIEW: 'For Review',
    REQUIRES_UPDATE: 'Requires Update',
    REJECT_APP: 'Reject Application',
    VALIDATE_APP: 'Validate Application',
    VALIDATED: 'Validated',
    PAYMENT_CREATED: 'Payment Created',
    PAYMENT_VALIDATION: 'Payment Validation',
    WAITING_APPROVAL: 'Waiting Approval',
    APPROVED: 'Approved',
    APPROVED_BY: 'Approved by',
    RELEASED: 'Released',
    GENERATE_REPORTS: 'Generate Reports',
    APPLICANT: 'Applicant',
    APPLICATION: 'Application',
    APPLICATION_ID: 'Application ID',
    BULK_APP_ID: 'Bulk App ID',
    APPLICATIONS: 'Applications',
    REPRESENTATIVE: 'Representative',
    SIMILAR_APPLICATION: 'Similar Application',
    SIMILAR_APPLICATIONS: 'Similar Applications',
    ESTABLISHMENT: 'Establishment',
    ESTABLISHMENTS: 'Establishments',
    ESTABLISHMENT_NAME: 'Establishment Name',
    APP_TYPE: 'Application Type',
    TYPE: 'Type',
    DETAILS: 'Details',
    STATUS: 'Status',
    ACTION: 'Action',
    VIEW: 'View ',
    VIEW_DETAILS: 'View Details',
    VIEW_CERTIFICATES: 'View Certificates',
    SEARCH: 'Search',
    DATE: 'Date',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    APPLICATION_DATE: 'Date Applied',
    TIME: 'Time',
    APPROVE: 'Approve',
    APPROVE_APP: 'Approve Application',
    SUBMIT: 'Submit',
    USER_MANAGEMENT: 'User Management',
    CONFIG_MANAGEMENT: 'Config Management',
    REPORTS: 'Reports',
    ALL_USERS: 'All Users',
    CREATE_USER: 'Create User',
    ID: 'ID',
    BULK_ID: 'Bulk ID',
    FULL_NAME: 'Full Name',
    COMPANY: 'Company',
    USER: 'User',
    NAME: 'Name',
    POSITION: 'Position',
    DEPARTMENT: 'Department',
    DEGREE_SUFFIX: 'Degree Suffixes',
    LICENSE_NO: 'License No',
    LICENSE_NOS: 'License Nos',
    E_SIGNATURE: 'E-signature',
    DEACTIVATE: 'Deactivate',
    DEACTIVATED: 'Deactivated',
    ACTIVATE: 'Activate',
    IHC_REQUIREMENTS: 'IHC Requirements',
    IHC_REQUIREMENTS_FULL: 'Health Certificate Requirements',
    SP_REQUIREMENTS: 'SP Requirements',
    SP_REQUIREMENTS_FULL: 'Sanitary Permit Requirements',
    REQUIREMENT: 'Requirement',
    REQUIREMENTS: 'Requirements',
    REQUIREMENT_NAME: 'Requirement name',
    REQUIREMENT_DESC: 'Requirement description',
    CREATE_REQUIREMENT: 'Create requirement',
    ORDER_PAYMENT: 'Order of Payment',
    ORDER_PAYMENT_NAME: 'Order of Payment Name',
    ORDER_PAYMENT_CATEGORY: 'Order of Payment Category',
    HIV_SEMINAR: 'HIV Seminar',
    PRIVACY_POLICY: 'Privacy Policy',
    CATEGORY: 'Category',
    AMOUNT: 'Amount',
    REMARKS: 'Remarks',
    REMARKS_REQUIRED: 'Remarks is required',
    UPDATE: 'Update',
    SAVE: 'SAVE',
    SAVE_CHANGES: 'Save Changes',
    EMAIL_ADDRESS: 'Email',
    INVALID_FORMAT_EMAIL: 'Invalid email address format',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    MIDDLE_NAME: 'Middle Name',
    BIRTH_DATE: 'Birth Date',
    NATIONALITY: 'Nationality',
    USERNAME_FORMAT: 'Username must only contain small letters, numbers, underscore and period',
    PASSWORD_FORMAT: 'Password must contain at least 4 characters',
    INDUSTRIES: 'Industries',
    SUB_INDUSTRIES: 'Sub-Industries',
    BUSINESS_LINES: 'Business Lines',
    INDUSTRY: 'Industry',
    INDUSTRY_NAME: 'Industry Name',
    SUB_INDUSTRY: 'Sub-Industry',
    SUB_INDUSTRY_NAME: 'Sub-Industry Name',
    BUSINESS_LINE: 'Business line',
    BUSINESS_LINE_NAME: 'Business Line Name',
    REQUIRED: 'Required',
    MAND_REQ: 'Mandatory Requirement',
    YES: 'Yes',
    NO: 'No',
    TAGALOG_LINK: 'Tagalog Link',
    ENGLISH_LINK: 'English Link',
    DESCRIPTION: 'Description',
    OCCUPATION: 'Occupation',
    NEW_LOOKUP: 'New Lookup',
    LOOKUPS: 'Lookups',
    LOOKUP_VALUE: 'Lookup value',
    ACTIVE: 'Active',
    TOTAL: 'Total',
    APP_INFORMATION: 'Application Information',
    PROCEED_PAYMENT: 'Proceed order of payment',
    CREATE_PAYMENT: 'Create order of payment',
    COMPLETE_RESULT: 'Complete lab results',
    RESULT: 'Result',
    NONE: 'None',
    PENDING_SPECIMEN: 'Pending specimen',
    PENDING_RESULT: 'Pending result',
    PENDING_APPROVAL: 'Pending approval',
    COMPLETED_RESULT: 'Completed result',
    COMPLETED: 'Completed',
    VIEW_RESULT: 'View result',
    UPLOAD_RESULT: 'Upload result',
    UPDATE_RESULT: 'Update result',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    IN_HOUSE: 'In-house Laboratory',
    CLINICAL_LAB: 'Clinical Lab',
    SOCIAL_HYGIENE: 'Social Hygiene Lab',
    WATER_ANALYSIS: 'Water Analysis Lab',
    LAB_REQUIREMENT: 'Laboratory requirement',
    AVAILABLE_INHOUSE: 'Available on Clinical Lab',
    AVAILABLE_SOCIAL_HYGIENE: 'Available on Social Hygene Lab',
    AVAILABLE_WATER_ANALYSIS: 'Available on Water Analysis Lab',
    FOR_INHOUSE: 'For in-house laboratory',
    FOR_PICKUP: 'For pick-up',
    PICKUP_LOCATION: 'Pick-up location',
    PICKUP_SCHEDULE: 'Scheduled pick-up date',
    PENDING_LAB: 'Pending in-house laboratory',
    NO_RESULT: 'No result uploaded',
    PENDING: 'Pending',
    SELECT: 'Select',
    YELLOW_TAG: 'Tag as yellow card applicant',
    YELLOW_CARD: 'Yellow Card',
    PESO_APPLICANT: 'PESO Beneficiary Applicant',
    OR_NO: 'OR No.',
    REFERENCE_NO: 'Reference No.',
    CERTIFICATE_NO: 'Certificate No',
    RECEIPT: 'Receipt',
    VIEW_RECEIPT: 'View Receipt',
    RECEIVED: 'Received',
    NO_DATA: 'No data available',
    NO_REQUIREMENT: 'No upload',
    SPECIMEN: 'Specimen',
    SPECIMEN_RECEIVED: 'All specimens received',
    PICKUP_DATE: 'Pick-up Date',
    RELEASE_APP: 'Release Application',
    DISCARD: 'Discard',
    DISCARD_APP: 'Discard Application',
    HIV_EXAM_STATUS: 'HIV Exam Status',
    HIV_EXAM_DATE: 'HIV Exam Date',
    PASSED: 'Passed',
    FAILED: 'Failed',
    FROM_DATE_APPLIED: 'From Applied Date',
    TO_DATE_APPLIED: 'To Applied Date',
    FROM_DATE_APPROVED: 'From Approved Date',
    TO_DATE_APPROVED: 'To Approved Date',
    CREATED_DATE: 'Created Date',
    UPDATED_DATE: 'Updated Date',
    LAST_UPDATE: 'Last Update',
    FROM_DATE_CREATED: 'From Created Date',
    TO_DATE_CREATED: 'To Created Date',
    CREATED_BY: 'Created By',
    UPDATED_BY: 'Updated By',
    EXPORT_CSV: 'Export to CSV',
    PAYMENT_DATE: 'Payment Date',
    NUM_EMPLOYEES: 'Number of Employees',
    NUM_EMPLOYEES_HC: 'Employees w/ Health Certificate',
    NUM_EMPLOYEES_NOHC: 'Employees w/o Health Certificate',
    OWNER: 'Owner',
    MAYOR_PERMIT: 'Permit Number',
    CONTACT_NUMBER: 'Contact Number',
    REJECT: 'Reject',
    REJECTED: 'Rejected',
    ON_HOLD: 'On Hold',
    REQUIRE_UPDATE_FOR: 'Require Update For',
    SCORE: 'Score',
    ACCOUNT_DETAILS: 'Account Details',
    APP_HISTORY: 'Application History',
    PAYMENT_APPROVER: 'Payment Approver',
    DATE_PAYMENT_APPROVED: 'Date Payment Approved',
    VALIDATOR: 'Validator',
    DATE_VALIDATED: 'Date Validated',
    APPROVER: 'Approver',
    DATE_APPROVED: 'Date Approved',
    DATE_RELEASED: 'Date Released',
    DATE_EXPIRY: 'Date of expiry',
    DATE_UPLOADED: 'Uploaded Date',
    DATE_COMPLETED: 'Completed Date',
    UPLOADER: 'Uploader',
    BULK: 'Bulk',
    BULK_PART: 'Part of Bulk',
    PAYMENT_DETAILS: 'Payment Details',
    INDIVIDUAL: 'Individual',
    CHANNEL: 'Channel',
    EPAYMENT: 'ePayment',
    ON_SITE: 'On-site Payment',
    BACK_LIST: 'Back to list',
    OPTIONAL: 'Optional',
    PRINT: 'Print',
    OIC: 'OIC, Quezon City Health Department',
    ACCOUNT_NAME: 'Account Name',
    ACCOUNT_CODE: 'Account Code',

    dialog: {
        header: {
            SECURITY: 'Security',
            FAILED: 'Oops!',
            SORRY: 'Sorry!',
            CONFIRM: 'Confirm',
            SUCCESS: 'Success',
            CONFIRM_DISABLE: 'Confirm Disable',
            UPDATE_ACCOUNT: 'Account Update Required'
        },
        message: {
            SUCCESS: 'Successful',
            SUCCESS_NAME: '<b>{name}</b> created successfully.',
            FAILED: 'Something went wrong. Please try again later.',
            OFFLINE:
                'System is currently not available or you have no internet connection.\nPlease check your connection and try again.',
            UNAUTHORIZED: 'Unauthorized Access!',
            CHANGED:
                'Looks like updates has been made by other administrators on this application.\n\nPlease try again or check on other applications.',
            SURE: 'Are you sure?',
            LOGOUT: 'Are you sure you want to logout?',
            PASSWORD_SUCCCESS: 'Password changed successfully',
            APPROVE_APP: 'Are you sure you want to approve this application?',
            SAVE_CONFIRM: 'Are you sure you want to save your changes?',
            PRIVACY_SUCCCESS: 'Privacy policy saved successfully.',
            CLOSE_CONFIRM:
                'Are you sure you want to go back?\n\nOne or more requirement is in <b>REQUIRES UPDATE</b>.\nPlease ensure that the application status is also updated to <b>REQUIRES UPDATE</b> to notify and return the application to the applicant.',
            CREATE_CONFIRM: 'Are you sure you want to create this user?',
            CREATE_SUCCESS: 'User <b>{user}</b> has been created successfully.',
            UPDATE_CONFIRM: 'Are you sure you want to save changes of user <b>{user}</b>?',
            UPDATE_SUCCESS: 'Changes for <b>{user}</b> has been saved successfully.',
            ENABLE_REQ_CONFIRM: 'Are you sure you want to enable <b>{name}</b> requirement?',
            DISABLE_REQ_CONFIRM: 'Are you sure you want to disable <b>{name}</b> requirement?',
            DEL_LOOKUP_CONFIRM: 'Are you sure you want to delete <b>{name}</b> config?',
            APPROVE_REQ_CONFIRM: "Are you sure you want to <b>APPROVE</b> applicant's <b>{name}</b> requirement?",
            UPDATE_REQ_CONFIRM: "Are you sure you want to <b>REQUIRE AN UPDATE</b> to applicant's <b>{name}</b> requirement?",
            CREATE_PAYMENT_CONFIRM: 'Are you sure about the details of the order of payment?\n<b>Total:</b> {amount}',
            CREATE_PAYMENT_SUCCESS: 'Order of payment created successfully!\n<b>Reference Number:</b> {ref}',
            APPROVE_BULK_CONFIRM: 'You are approving receipt for <b>BULK</b>.\nApproving this will approve payment for all applications under this.\n\n',
            APPROVE_PAYMENT_CONFIRM: 'Are you sure you want to <b>APPROVE</b> the receipt of payment?',
            APPROVE_PAYMENT_SUCCESS: 'Payment receipt approved!\n<b>Reference Number:</b> {ref}',
            UPDATE_PAYMENT_CONFIRM: 'Are you sure you want to <b>REQUIRE AN UPDATE</b> to the payment receipt?',
            VALIDATE_APP_CONFIRM: 'Are you sure you want to <b>VALIDATE</b> the application?',
            VALIDATE_APP_SUCCESS: 'Application validated successfully.',
            APPROVE_APP_CONFIRM: 'Are you sure you want to <b>APPROVE</b> the application?',
            ON_HOLD_CONFIRM: 'Are you sure you want to put the application <b>ON HOLD</b>?',
            REJECT_APP_CONFIRM:
                'Are you sure you want to <b>REJECT</b> the application?\nRejecting will require the applicant to create a new application.',
            DISCARD_APP_CONFIRM:
                'Are you sure you want to <b>DISCARD</b> the application?\nApplicant can no longer update the application.',
            REQUIRE_UPDATE_CONFIRM: 'Are you sure you want to <b>REQUIRE AN UPDATE</b> to the application?',
            APPROVE_APP_SUCCESS: 'Application approved successfully',
            RELEASE_APP_CONFIRM: 'Are you sure you want to release the application?',
            RELEASE_APP_SUCCESS: 'Application set as released.',
            DEL_NAME: 'Are you sure you want to delete <b>{name}</b>?',
            DEL_SUB: 'Are you sure you want to disable <b>{name}</b>?',
            ENABLE_SUB: 'Are you sure you want to enable <b>{name}</b>?',
            DEL_IND:
                'Are you sure you want to delete <b>{name}</b>?\nDeleting this will also delete the other sub-industries under it.',
            DEAC_ACC: 'Are you sure you want to <b>DEACTIVATE</b> account <b>{name}</b>?',
            ACT_ACC: 'Are you sure you want to <b>ACTIVATE</b> account <b>{name}</b>?',
            DETAILS_REQUIRED: 'Your account currently has missing details.\n\nPlease update your account.',

            LAB_RECEIVED_CONFIRM: 'Are you sure that all specimens have been received?',
            LAB_RESULTS_CONFIRM: 'Are you sure that the details of the results are correct?',
            LAB_UPDATE_SUCCESS: 'Results has been updated.',
            LAB_CONFIRM_SUCCESS: 'Laboratory results has been saved.',
            LAB_COMPLETE_CONFIRM: 'Are you sure you that all lab results are correct and complete?',
            LAB_COMPLETE_SUCCESS: 'Laboratory results sent for approval.',
            LAB_APPROVE_CONFIRM: 'Are you sure you want to <b>APPROVE</b> results for <b>{type}</b>?',
            LAB_REJECT_CONFIRM:
                'Are you sure you want to <b>REJECT</b> results for <b>{type}</b>?\nLaboratory results will go back to Pending Results.',
            LAB_APPROVE_ALL_SUCCESS: 'Laboratory for <b>{name}</b> has been completed.',

            SESSION_CONFIRM: 'Are you sure you want to create this training session?',
            SESSION_DEL_PARTICIPANTS:
                '<b>NOTICE!</b>\n<b>{name}</b> has 1 or more participants.\n\nAre you sure you want to delete this?\nThis will also delete the participants of this session.',
            SESSION_DEL_CONFIRM: 'Are you sure you want to delete <b>{name}</b>?',
            SESSION_UPD_CONFIRM: 'Are you sure you want to update details of this training session?',
            PARTICIPANT_ADD_CONFIRM: 'Are you sure you want to add <b>{name}</b> to this training session?',
            PARTICIPANT_UPD_CONFIRM: "Are you sure you want to update this participant's details?",
            PARTICIPANT_DEL_CONFIRM: 'Are you sure you want to remove <b>{name}</b> to this training session?',
            PARTICIPANT_COMPLETED_CONFIRM: 'Are you sure you want to tag <b>{name}</b> as COMPLETED on this training session?',
            PARTICIPANT_NO_SHOW_CONFIRM: 'Are you sure you want to tag <b>{name}</b> as NO SHOW on this training session?',
            PARTICIPANT_CONFIRM_CONFIRM: 'Are you sure you want to CONFIRM <b>{name}</b> to be a participant on this training session?',

            SR_CONFIRM:
                'Are you sure you want to create a sanitary report for this establishment?\n\nName: <b>{name}</b>\nPermit Number: <b>{permit}</b>',
            SR_SUCCESS: 'Sanitary report successfully created.\n\nSR number: <b>{SR}</b>\nEstablishment: <b>{name}</b>',
            SR_UPDATE_CONFIRM:
                'Are you sure you want to update the sanitary report for this establishment?\n\nSR number: <b>{SR}</b>\nName: <b>{name}</b>\nPermit Number: <b>{permit}</b>',
            SR_UPDATE_SUCCESS: 'Sanitary report <b>{SR}</b> updated successfully.',
            SR_DELETE_CONFIRM: '<b>IRREVERSIBLE ACTION!</b>\n\nAre you sure you want to delete sanitary report\n<b>{SR}</b>?',
            DEL_ORDER_PAYMENT: '<b>IRREVERSIBLE ACTION!</b>\n\nAre you sure you want to delete order of payment\n<b>{name}</b>?',
            MAINTENANCE_DESC: 'This page controls when the applicant app will be shown Under Maintenance. Turning this ON/OFF will control the access to the applicant application.',
            MAINTENANCE_WARNING: 'This page controls when the applicant app will be shown Under Maintenance. Turning this ON will block access to the application depending on the schedule set. Proceed?',
            MAINTENANCE_WARNING_OFF: 'Turning this OFF will allow access to the application. Applicants will be able to post applications again. Proceed?',
        }
    },

    preloader: {
        GENERATE_REPORT: 'Generating Report...'
    },

    caveats: {
        PROBLEM: 'Having problems? Forgot your password?\nPlease contact your system administrator.',
        SECURITY:
            'Access to this system is provided by Quezon City administration solely for business purposes only. Penalties may be enforced for unauthorized use of this system. By logging in, you are signifying your consent to these conditions and your actions may be monitored for regulatory purposes.'
    },

    tooltip: {
        COMMA_MULTIPLE: 'Comma seperated on multiple'
    },

    REPUBLIC_OF_THE_PHILIPPINES: 'Republic of the Philippines',
    CITY: 'Quezon City',
    AGE: 'Age',
    SEX: 'Sex',
    GENDER: 'Gender',
    FACILITY: 'Facility',
    FACILITY_DETAILS: 'Facility Details',
    LAB_NO: 'Lab. No.',
    LAB_DATE_SUBMIT: 'Date Submitted',
    LAB_DATE_RELEASE: 'Date Released',
    LAB_DATE_CREATED: 'Date Created',
    FECALYSIS: 'Fecalysis',
    PHYSICAL: 'Physical',
    COLOR: 'Color',
    CONSISTENCY: 'Consistency',
    MICROSCOPIC: 'Microscopic',
    PUS_CELLS: 'Pus Cells',
    RED_CELLS: 'Red Cells',
    CYST: 'Cyst',
    TROPHOZIOTE: 'Trophozoite',
    OVA_PARASITE: 'Ova/Parasite',
    BACTERIA: 'Bacteria',
    HPF: '/hpf',
    SPUTUM: 'Sputum AFB',
    ADD_FINDINGS: 'Additional findings',
    MED_TECH: 'Medical Technologist',
    MICROSCOPIST: 'Microscopist',
    PATHOLOGIST: 'Pathologist',
    GENERATE_REPORT: 'Generate Report',
    APPLICATION_TYPE: 'Application Type',
    BARANGAY: 'Barangay',
    DISTRICT: 'District',
    ADDRESS: 'Address',
    HOME_ADDRESS: 'Home Address',
    APPLICATION_STATUS: 'Application Status',
    SEARCH_FILTER: 'Search Filter',
    LABORATORY_STATUS: 'Laboratory Status',
    LAB_USERS: 'Lab Users',

    SAMPLER: 'Sampler',
    SAMPLE: 'Sample',
    DISPATCH_SAMPLER: 'Dispatch Sampler',
    PRINT_LABEL: 'Print Label',
    COLLECTED_BY: 'Collected By',
    LAB_DATE_COLLECTED: 'Date Collected',
    LAB_DATE_RECEIVED: 'Date Received',
    LAB_DATE_EXAMINED: 'Date Examined',
    LAB_DATE_REPORTED: 'Date Reported',
    MICRO_WATER_RESULT: 'Microbiological Water Analysis Result',
    TEST: 'Test',
    UNIT: 'Unit',
    METHOD: 'Method',
    PNSDW_STANDARD: 'PNSDW Standard',
    RESULTS: 'Results',
    TOTAL_COLIFORM: 'Total Coliform',
    THERMO_COLIFORM: 'Thermotolerant (FECAL) Coliform',
    HETERO_COUNT: 'Heterotrophic Plate Count',
    METHOD_ENZYME: 'Enzyome Substrate Coliform Test',
    METHOD_MPN: 'Multiple Tube Fermentation Technique',
    METHOD_PLATE: 'Pour plate method',
    UNIT_ENZYME: 'Per 100mL',
    UNIT_TUBE: 'MPN/100mL',
    UNIT_PLATE: 'CFU/mL',
    STANDARD_ENZYME: 'ABSENT',
    STANDARD_TUBE: '<1.1 MPN/100mL',
    STANDARD_PLATE: 'Less than 500 CFU/mL',
    TABLE_END: 'Xxxxxxxxxxx NOTHING FOLLOWS xxxxxxxxxxX',

    MASSAGIST_TRAINERS: 'Massagist Trainers',
    MASSAGIST_TRAINING: 'Massagist Training',
    TRAINING_SESSIONS: 'Training Sessions',
    TRAINING_SESSION: 'Training Session',
    PARTICIPANT_RECORDS: 'Participant Records',
    TRAINING_DETAILS: 'Training Details',
    CREATE_SESSION: 'Create session',
    TRAINING_NAME: 'Training Name',
    TRAINING_DESC: 'Training Description',
    TRAINER: 'Trainer',
    TRAINING_UPDATE: 'Update Details',
    ADD_PARTICIPANT: 'Add Participant',
    TRAINING_DATE: 'Training Date',
    TRAINING_COMPLETED: 'Training Completed',
    PARTICIPANT_LIMIT: 'Participant Limit',
    LOCATION: 'Location',
    PARTICIPANT_TRAINING_STATUS: 'Training Status',
    COMPLETED_DATE: 'Date Completed',
    PARTICIPANT_ID: 'Participant ID',
    
    seminar: {
        CONFIRM: 'Confirm Participant',
        COMPLETED: 'Tag as Completed',
        NO_SHOW: 'Tag as No Show',
        REMOVE: 'Remove Participant',
    },

    SANITARY_INSPECTION: 'Sanitary Inspection',
    SANITARY_INSPECTOR: 'Sanitary Inspector',
    SANITARY_INSPECTORS: 'Sanitary Inspectors',
    CREATE_REPORT: 'Create Report',
    CREATE_SANITARY_REPORT: 'Create New Sanitary Report',
    SANITARY_REPORT_NO: 'Sanitary Report No',
    SANITARY_REPORT: 'Sanitary Report',
    SANITARY_REPORTS: 'Sanitary Reports',
    SEARCH_ESTABLISHMENT: 'Search for establishment',
    NONE_ESTABLISHMENT: 'No establishments found. Please refine your search.',
    CREATE_STANDALONE_REPORT: 'Create a standalone Sanitary Report',
    CLASSIFICATION: 'Classification',
    EMPLOYEES: 'Employees',
    TOTAL_EMPLOYEES: 'Total Employees',
    WITH_HC: 'w/ HC',
    WO_HC: 'w/o HC',
    USING_PPE: 'Using PPE',
    WATER_SUPPLY: 'Water Supply',
    TAP: 'Tap',
    FILTERED: 'Filtered',
    ICE: 'Ice',
    MICROBIOLOGICAL: 'Microbiological',
    PHYSICAL_CHEMICAL: 'Physical Chemical',
    QUALITY: 'Quality',
    DATE_EXAMINATION: 'Examination Date',
    TOILET_FACILITIES: 'Toilet Facilities',
    TOILET: 'Toilet',
    LAVATORY: 'Lavatory',
    NUM_FACILITY: 'No. of facility',
    SOAP: 'Soap',
    TOILET_PAPER: 'Toilet Paper',
    HAND_DRYER: 'Hand Dryer',
    W_ACCESS: 'w/ Access',
    DISTANCE_M: 'Distance(m)',
    GARBAGE: 'w/ Garbage Receptacles',
    CLEANLINESS: 'Cleanliness/Good Maintenance',
    FOOD: 'Food',
    STORAGE: 'Storage',
    EQUIPMENT: 'Equipment & Utensils',
    EQUIPMENT_MAINTENANCE: 'Provision, Cleaning & Maintenance',
    WASTE_DISPOSAL: 'Waste Disposal',
    SOLID: 'Solid',
    LIQUID: 'Liquid',
    INSECTS: 'Insects & Vermins',
    PRESENCE: 'Presence',
    SPECIFY_LOCATION: 'Specify Location',
    SERVICE_PROVIDER: 'Service Provider',
    GENERAL_MAINTENANCE: 'General Maintenance',
    KITCHEN: 'Kitchen',
    DINING: 'Dining',
    OUTSIDE_PREMISES: 'Outside Premises',
    OTHER_LOCATION: 'Other Location',
    NEEDS_REPAIR: 'Needs Repair',
    UNTIDY: 'Untidy',
    POOR_LIGHTING: 'Poor Lighting',
    POOR_VENTILATION: 'Poor Ventilation',
    FIRST_ORDER: 'First Sanitary Order',
    SECOND_ORDER: 'Second Sanitary Order',
    NOTICE_HEARING: 'Notice of Hearing',
    SANITARY_ORDER_NO: 'Sanitary Order No',
    COMMENTS_RECOM: 'Comments',
    SANITARY_ORDER: 'Sanitary Order',
    digitalHealthCert: {
        LABEL: 'DIGITAL HEALTH CERTIFICATE',
        SIGNATURE: 'Signature',
        QC_ORDINANCE: 'Pursuant to P.D. 856 and QC Ordinance SP 2503 S-2016, this certificate is issued to',
        OIC: 'OIC QUEZON CITY HEALTH DEPARTMENT',
        COMP_GEN_MSSG: 'This is computer generated document.',
        NO_SIGNATURE_REQ: 'No signature is required.',
        ONLINE_VALIDATION: 'Online Validation',
        VALID_UNTIL: 'VALID UNTIL',
        NEGATIVE_RES: 'NEGATIVE',
        SPUTUM_CXR: 'SPUTUM/CXR',
        STOOL: 'Stool',
        REGISTRATION_NO: 'Registration No.',
        OCCUPATIONAL_PERMIT: 'Occupational Permit No.'
    },
    MAINTENANCE: 'Maintenance'
};

export default labels;
