import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { images, labels } from './../../../constants';

import './styles.scss';

interface Props {}

const Header: React.FunctionComponent<Props> = (props) => {
    return (
        <Container fluid>
            <Row className='Header shadow-lg'>
                <Col xs={3}>
                    <Image src={images.QC_LOGO.src} alt={images.QC_LOGO.alt} className='logo' />
                </Col>
                <Col>
                    <span className='title'>{labels.TITLE}</span>
                </Col>
                <Col xs={3}>
                    <Image src={images.QC_HEALTH_LOGO.src} alt={images.QC_HEALTH_LOGO.alt} className='logo' />
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
