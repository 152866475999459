import React from 'react';

import { labels } from '../../../constants';
import { Status } from '../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import './styles.scss';
import LabTable from '../../components/LabTable';
import LabType from '../../../models/enums/LabType';

type Props = {};

const WaterAnalysisLabPendingResult: React.FunctionComponent<Props> = () => {
    return (
        <div className='PendingResult'>
            <PageHeader title={labels.WATER_ANALYSIS} subTitle={labels.PENDING_RESULT} />
            <PageBody>
                <LabTable status={Status.PENDING_RESULT} labType={LabType.WATER_ANALYSIS} />
            </PageBody>
        </div>
    );
};

export default WaterAnalysisLabPendingResult;
