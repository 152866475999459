import React from 'react';

import { labels } from '../../../constants';
import { PermitType } from './../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import Requirements from './../../components/Requirements';

import './styles.scss';

type Props = {};

const IHCRequirements: React.FunctionComponent<Props> = () => {
    return (
        <div className='IHCRequirements'>
            <PageHeader title={labels.IHC_REQUIREMENTS_FULL} />
            <PageBody>
                <Requirements permitType={PermitType.IHC} />
            </PageBody>
        </div>
    );
};

export default IHCRequirements;
