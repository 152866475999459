import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoWarningOutline } from 'react-icons/io5';

import { labels, routes } from '../../../constants';
import { Application, PermitType } from '../../../models';
import { ApplicationService } from '../../../services';
import CollapsibleCard from '../CollapsibleCard';

import './styles.scss';

interface Props {
    application?: Application;
    permitType: PermitType;
}

const DuplicateApplicationCard: React.FunctionComponent<Props> = (props) => {
    const { application, permitType } = props;
    const navigate = useNavigate();
    const [duplicateList, setDuplicateList] = useState<Application[]>([]);
    const total = duplicateList.length;

    const checkDuplicates = () => {
        if (application) {
            ApplicationService.getDuplicateApplications(application.applicationId)
                .then((response) => {
                    setDuplicateList(response.body.data);
                })
                .catch((err) => console.error(err));
        }
    };

    useEffect(checkDuplicates, [application]);

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        duplicateList.forEach((application, index) => {
            rows.push(
                <tr
                    key={index}
                    onClick={() =>
                        navigate(
                            routes.APPLICATION.path
                                .replace(':type', permitType)
                                .replace(':appId', application.applicationId.toString())
                        )
                    }>
                    <td>{application.formData.email}</td>
                    <td className='status'>{application.status}</td>
                    <td>{application.createdDate}</td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <>
            {total > 0 && (
                <CollapsibleCard className='DuplicateApplicationCard' defaultCollapsed={true}>
                    <CollapsibleCard.Header>
                        <IoWarningOutline />
                        <span>{total + ' ' + (total > 1 ? labels.SIMILAR_APPLICATIONS : labels.SIMILAR_APPLICATION)}</span>
                    </CollapsibleCard.Header>
                    <CollapsibleCard.Body>
                        <Table responsive bordered hover className='link'>
                            <thead>
                                <tr>
                                    <th>{labels.EMAIL_ADDRESS}</th>
                                    <th>{labels.STATUS}</th>
                                    <th>{labels.APPLICATION_DATE}</th>
                                </tr>
                            </thead>
                            <tbody>{renderRows()}</tbody>
                        </Table>
                    </CollapsibleCard.Body>
                </CollapsibleCard>
            )}
        </>
    );
};

export default DuplicateApplicationCard;
