import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

import { labels } from '../../../constants';
import { OrderOfPayment, Requirement, Permit, CreateOrderOfPayment } from '../../../models';

import './styles.scss';
import { ConfigService } from '../../../services';

type Props = {
    show: boolean;
    permitId: number;
    orderOfPayment?: OrderOfPayment;
    orderOfPayments?: OrderOfPayment[];
    isParent: boolean;
    isNew: boolean;
    onClose: (orderOfPayment: OrderOfPayment | CreateOrderOfPayment | undefined) => void;
};

type FormValues = {
    orderOfPaymentName: string;
    parentId?: any;
    accountName?: string;
    accountCode?: string;
    amount?: string;
    requirementId: string;
    requirementIds: string[];
};

const OrderOfPaymentModal: React.FunctionComponent<Props> = (props) => {
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const { show, permitId, orderOfPayment, orderOfPayments, isParent, isNew, onClose } = props;
    const [requirements, setRequirements] = useState<Requirement[]>([]);

    const setFormData = () => {
        if (orderOfPayment) {
            let requirementIds: number[] = [];

            if (orderOfPayment.requirementIds && orderOfPayment.requirementIds.length > 0) {
                requirementIds = orderOfPayment.requirementIds;
            } else if (orderOfPayment.requirementId) {
                requirementIds = [orderOfPayment.requirementId];
            }

            setValue('orderOfPaymentName', orderOfPayment.orderOfPaymentName);
            setValue('parentId', orderOfPayment.parentId);
            setValue('accountName', orderOfPayment.accountName);
            setValue('accountCode', orderOfPayment.accountCode);
            setValue('amount', String(orderOfPayment.amount));
            setValue('requirementIds', requirementIds.map(String));
        }
    };

    const { handleSubmit, register, setValue, watch, errors } = useForm<FormValues>({
        mode: 'onBlur'
    });

    const watchIsCategory = watch('isCategory', orderOfPayment && orderOfPayment.amount === null && orderOfPayment.parentId === null);

    const getRequirements = () => {
        ConfigService.getRequirements(permitId)
            .then((response: any) => {
                setRequirements(response.body.data);
                setFormData();
            })
            .catch((err) => {
                console.error(err);
            });
    };


    useEffect(getRequirements, [orderOfPayment]);

    const _onSubmit = (values: FormValues) => {
        let requirementIds = values.requirementIds.filter((req) => req !== "").map(parseInt);
        let requirementId = requirementIds.length > 0 ? parseInt(values.requirementIds[0]) : null;

        if (orderOfPayment) {
            orderOfPayment.orderOfPaymentName = values.orderOfPaymentName;
            orderOfPayment.orderOfPaymentDesc = values.orderOfPaymentName;
            orderOfPayment.parentId = values.parentId ? parseInt(values.parentId) : null;
            orderOfPayment.accountName = values.accountName;
            orderOfPayment.accountCode = values.accountCode;
            orderOfPayment.amount = values.amount && !isParent ? parseInt(values.amount) : null;
            orderOfPayment.requirementId = requirementId;
            orderOfPayment.requirementIds = requirementIds;

            onClose(orderOfPayment);
        } else {
            let newOrderOfPayment: CreateOrderOfPayment = {
                permitId: permitId,
                orderOfPaymentName: values.orderOfPaymentName,
                orderOfPaymentDesc: values.orderOfPaymentName,
                parentId: values.parentId ? parseInt(values.parentId) : null,
                accountName: values.accountName,
                accountCode: values.accountCode,
                amount: values.amount ? parseInt(values.amount) : null,
                requirementId: requirementId,
                requirementIds: requirementIds,
                inactive: false,
            }

            onClose(newOrderOfPayment);
        }

    };

    const renderRequirements = (): JSX.Element[] => {
        let options: JSX.Element[] = [];
        options.push(
            <option key={0} value={""}>
                {'None'}
            </option>
        );

        Object.values(requirements).forEach((requirement, index) => {
            options.push(
                <option key={index + 1} value={requirement.requirementId}>
                    {requirement.requirementName}
                </option>
            );
        });

        return options;
    };

    const renderPermitList = () => {
        let options: JSX.Element[] = [];

        permitList.forEach((item, index) => {
            options.push(
                <option key={index} value={item.permitId}>
                    {item.permitDesc}
                </option>
            );
        });

        return options;
    };

    const renderCategories = () => {
        let options: JSX.Element[] = [];

        options.push(
            <option key={0} value={""}>
                {'None'}
            </option>
        );

        if (orderOfPayments && orderOfPayments.length > 0) {
            orderOfPayments.forEach((order, index) => {
                if (order.permitId === permitId && order.amount === null && order.parentId === null) {
                    options.push(
                        <option
                            key={index + 1}
                            value={order.orderOfPaymentId}>
                            {order.orderOfPaymentName}
                        </option>
                    );
                }
            });
        }

        return options;
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='OrderOfPaymentModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.ORDER_PAYMENT}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{labels.PERMIT}</Form.Label>
                        <Form.Control
                            as='select'
                            value={permitId}
                            disabled>
                            {renderPermitList()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.ORDER_PAYMENT_NAME}</Form.Label>
                        <Form.Control
                            type='text'
                            name='orderOfPaymentName'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.orderOfPaymentName}
                        />
                        {!!errors.orderOfPaymentName && (
                            <Form.Control.Feedback type='invalid'>{errors.orderOfPaymentName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {isNew &&
                        <Form.Group>
                            <Form.Check
                                type='checkbox'
                                name='isCategory'
                                label={'Is this a payment category?'}
                                defaultChecked={false}
                                ref={register()}>
                            </Form.Check>
                        </Form.Group>
                    }
                    {!isParent && !watchIsCategory && (
                        <>
                            <Form.Group>
                                <Form.Label>{labels.ORDER_PAYMENT_CATEGORY}</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='parentId'
                                    ref={register}>
                                    {renderCategories()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{labels.ACCOUNT_NAME}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='accountName'
                                    ref={register()}
                                    isInvalid={!!errors.accountName}
                                />
                                {!!errors.accountName && (
                                    <Form.Control.Feedback type='invalid'>{errors.accountName.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{labels.ACCOUNT_CODE}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='accountCode'
                                    ref={register()}
                                    isInvalid={!!errors.accountCode}
                                />
                                {!!errors.accountCode && (
                                    <Form.Control.Feedback type='invalid'>{errors.accountCode.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{labels.AMOUNT}</Form.Label>
                                <Form.Control
                                    type='number'
                                    min={0}
                                    name='amount'
                                    ref={register({
                                        required: labels.FIELD_REQUIRED
                                    })}
                                    isInvalid={!!errors.amount}
                                />
                                {!!errors.amount && (
                                    <Form.Control.Feedback type='invalid'>{errors.amount.message}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{labels.REQUIREMENT}</Form.Label>
                                <Form.Control
                                    as='select'
                                    multiple
                                    name='requirementIds'
                                    ref={register()}>
                                    {renderRequirements()}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default OrderOfPaymentModal;
