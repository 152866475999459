export const isDev = (): boolean => {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
};

export const serverURL = (): string => {
    return process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : '';
};

export const serverPort = (): string => {
    return process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : '';
};

export const contextPath = (): string => {
    return process.env.REACT_APP_CONTEXT_PATH ? process.env.REACT_APP_CONTEXT_PATH : '';
};

export const apiURL = (): string => {
    return process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';
};

export const legacyApiURL = (): string => {
    return process.env.REACT_APP_LEGACY_API_URL ? process.env.REACT_APP_LEGACY_API_URL : '';
};

export const epaymentURL = (): string => {
    return process.env.REACT_APP_EPAYMENT_URL ? process.env.REACT_APP_EPAYMENT_URL : '';
};

export default {
    isDev,
    serverURL,
    serverPort,
    contextPath,
    apiURL,
    legacyApiURL,
    epaymentURL
};
