import React from 'react';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import { PermitType } from '../../../models';
import ApplicationSearch from '../../components/ApplicationSearch';
import LabType from '../../../models/enums/LabType';

import './styles.scss';

type Props = {};

const LabGenerateReport: React.FunctionComponent<Props> = () => {
    return (
        <div className='LabGenerateReport'>
            <PageHeader title={labels.LABORATORY} subTitle={labels.GENERATE_REPORT} />
            <PageBody>
                <ApplicationSearch permitType={PermitType.IHC} isLaboratory={true} labType={LabType.REGULAR_LABORATORY} />
            </PageBody>
        </div>
    );
};

export default LabGenerateReport;
