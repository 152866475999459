import React, { useState, useEffect } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import './styles.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    defaultCollapsed?: boolean;
    collapsed?: boolean;
    onClick?: () => void;
}

interface SubComponents {
    Header: React.FC<ChildProps>;
    Body: React.FC<ChildProps>;
}

interface ChildProps extends React.HTMLAttributes<HTMLDivElement> {
    collapsed?: boolean;
    onClick?: () => void;
}

const Header: React.FC<ChildProps> = (props) => {
    const { collapsed, onClick, children, ...otherProps } = props;

    return (
        <Card.Header {...otherProps}>
            {props.children}
            <button
                className='btn btn-icon btn-transparent-dark'
                onClick={(e) => {
                    e.preventDefault();
                    if (onClick) onClick();
                }}>
                {collapsed && <RiArrowUpSLine className='sidebar-toggle' />}
                {!collapsed && <RiArrowDownSLine className='sidebar-toggle' />}
            </button>
        </Card.Header>
    );
};

const Body: React.FC<ChildProps> = (props) => {
    const { collapsed, children, onClick, ...otherProps } = props;

    return (
        <Collapse in={collapsed}>
            <Card.Body {...otherProps}>{children}</Card.Body>
        </Collapse>
    );
};

const CollapsibleCard: React.FC<Props> & SubComponents = (props) => {
    const { defaultCollapsed, collapsed: collapsedState, onClick, children, ...otherProps } = props;

    const [collapsed, setCollapsed] = useState(defaultCollapsed);

    useEffect(() => {
        if (collapsedState !== undefined) {
            setCollapsed(collapsedState);
        }
    }, [collapsedState]);

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            let props = {
                collapsed,
                onClick: () => (onClick ? onClick() : setCollapsed(!collapsed)),
                ...child.props
            };
            return React.cloneElement(child, props);
        }

        return child;
    });

    otherProps.className = otherProps.className ? otherProps.className + ' CollapsibleCard' : 'CollapsibleCard';

    return <Card {...otherProps}>{childrenWithProps}</Card>;
};

CollapsibleCard.Header = Header;
CollapsibleCard.Body = Body;

export default CollapsibleCard;
