import React from 'react';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import ApplicationSearch from '../../components/ApplicationSearch';
import { PermitType } from '../../../models';

import './styles.scss';

type Props = {};

const SanitaryGenerateReport: React.FunctionComponent<Props> = () => {
    return (
        <div className='SanitaryGenerateReport'>
            <PageHeader title={labels.SANITARY} subTitle={labels.GENERATE_REPORT} />
            <PageBody>
                <ApplicationSearch permitType={PermitType.SP} labType={null} />
            </PageBody>
        </div>
    );
};

export default SanitaryGenerateReport;
