import React from 'react';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import './styles.scss';
import UsersTable from '../../components/UsersTable';
import { Roles } from '../../../models';

type Props = {};

const UsersMassagistTrainers: React.FunctionComponent<Props> = () => {
    return (
        <div className='UsersMassagistTrainers'>
            <PageHeader title={labels.USER_MANAGEMENT} subTitle={labels.MASSAGIST_TRAINERS} />
            <PageBody>
                <UsersTable roles={[Roles.MASSAGIST_TRAINER]} />
            </PageBody>
        </div>
    );
};

export default UsersMassagistTrainers;
