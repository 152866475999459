import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { usePage } from 'react-page-states';
import { Card, Table, Row, Col, Form, Spinner } from 'react-bootstrap';
import { find } from 'lodash';

import { labels } from '../../../constants';
import { Application, HealthAppData, Status, Permit, PermitType, ApplicationFilter, SortBy, SanitaryAppData } from '../../../models';
import LabType from '../../../models/enums/LabType';
import { ApplicationService, common } from '../../../services';
import Paginator from '../Paginator';
import LabModal from '../LabModal';
import SearchFilter, { SearchRef } from '../SearchFilter';
import Sorter from '../Sorter';

import './styles.scss';

interface Props {
    status: Status;
    labType: LabType;
}

const LabTable: React.FunctionComponent<Props> = (props) => {
    const { status, labType } = props;
    const searchRef = useRef<SearchRef>(null);
    const { pageNo, pageSize, total, ...page } = usePage();
    const permitList: Permit[] = useSelector((state: any) => state.config.permitList);
    const [isLoading, setLoading] = useState(true);
    const [applicationList, setApplications] = useState<Application[]>([]);
    const [activeApp, setActiveApp] = useState<Application>();
    const isCompleted = status === Status.COMPLETED_RESULT;
    const [sortBy, setSortBy] = useState(SortBy.UPDATED_DATE);
    const [sortAsc, setSortAsc] = useState(!isCompleted);
    const currentDate = new Date();
    const searchColumns: string[] = [isCompleted ? 'dateFrom' : '', 'applicationId', 'contactNumber', 'officialReceipt'];
    if (labType === LabType.WATER_ANALYSIS) {
        searchColumns.push('establishmentName');
    } else {
        searchColumns.push('lastName');
        searchColumns.push('firstName');
    }

    const initForm = {};
    if (isCompleted)
        initForm['dateFrom'] = common.parseDateToString(
            new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate())
        );

    const loadData = () => {
        setLoading(true);

        if (permitList.length > 0) {
            let method;
            let permitId;

            if (labType === LabType.WATER_ANALYSIS) {
                permitId = find(permitList, { type: PermitType.SP }).permitId;
            } else {
                permitId = find(permitList, { type: PermitType.IHC }).permitId;
            }

            let filter: ApplicationFilter = common.createFilter(searchRef?.current?.getValues());

            if (status === Status.COMPLETED_RESULT) {
                method = ApplicationService.getCompletedLab(pageSize, pageNo, permitId, labType, filter, sortBy, sortAsc);
            } else {
                method = ApplicationService.getApplicationByStatus(pageSize, pageNo, permitId, status, filter, sortBy, sortAsc, false, labType);
            }

            method
                .then((response: any) => {
                    let data = response.body.data;
                    page.setTotal(data.totalCount);
                    setApplications(data.details);
                })
                .catch((err: any) => console.error(err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(loadData, [pageSize, pageNo, permitList, sortBy, sortAsc]);

    const _handleFormChange = () => {
        page.setPageNo(1);
        loadData();
    };

    const _handleLimitChange = (limit: number) => {
        page.setPageSize(limit);
        page.setPageNo(1);
    };

    const _handleLabModalClose = (updated: boolean) => {
        setActiveApp(undefined);
        if (updated) loadData();
    };

    const renderName = () => {
        if (labType === LabType.WATER_ANALYSIS) {
            return (
                <>
                    {labels.ESTABLISHMENT}
                    <Sorter
                        sortBy={SortBy.ESTABLISHMENT}
                        currentSortBy={sortBy}
                        currentSortAsc={sortAsc}
                        setSortBy={setSortBy}
                        setSortAsc={setSortAsc}
                    />
                </>
            );
        } else {
            return (
                <>
                    {labels.APPLICANT}
                    <Sorter
                        sortBy={SortBy.LAST_NAME}
                        currentSortBy={sortBy}
                        currentSortAsc={sortAsc}
                        setSortBy={setSortBy}
                        setSortAsc={setSortAsc}
                    />
                </>
            );
        }
    };

    const renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];

        applicationList.forEach((application, index) => {
            let name;

            if (labType === LabType.WATER_ANALYSIS) {
                let formData = application.formData as SanitaryAppData;
                name = formData.establishmentName
            } else {
                let formData = application.formData as HealthAppData;
                name = common.parseFullname(formData)
            }

            rows.push(
                <tr key={index} className='clickable' onClick={() => setActiveApp(application)}>
                    <td>{application.applicationId}</td>
                    <td>{name}</td>
                    <td>{common.parseNullable(application.paymentDetailResponseDto?.[0]?.officialReceiptNumber)}</td>
                    <td>{application.updatedDate}</td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <div className='LabTable'>
            <SearchFilter ref={searchRef} columns={searchColumns} initForm={initForm} onSubmit={_handleFormChange} />
            <Card className='mt-3'>
                <Card.Body className='px-3 py-4'>
                    <Row>
                        <Col xs={{ span: 12, order: 'last' }} md={{ span: 4, order: 'first' }} className='mt-3 mt-md-0'>
                            <span>Show </span>
                            <Form.Group className='count-select'>
                                <Form.Control
                                    as='select'
                                    size='sm'
                                    value={pageSize}
                                    onChange={(e: any) => _handleLimitChange(e.target.value)}>
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>40</option>
                                    <option>50</option>
                                </Form.Control>
                            </Form.Group>
                            <span> entries</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isLoading && (
                                <div className='center-content'>
                                    <Spinner animation='border' variant='primary' />
                                </div>
                            )}
                            {!isLoading && total === 0 && (
                                <div className='center-content'>
                                    <span>No data available</span>
                                </div>
                            )}
                            {!isLoading && total > 0 && (
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>{labels.ID}</th>
                                            <th>{renderName()}</th>
                                            <th>{labels.OR_NO}</th>
                                            <th>
                                                {labels.LAST_UPDATE}
                                                <Sorter
                                                    sortBy={SortBy.UPDATED_DATE}
                                                    currentSortBy={sortBy}
                                                    currentSortAsc={sortAsc}
                                                    setSortBy={setSortBy}
                                                    setSortAsc={setSortAsc}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderRows()}</tbody>
                                </Table>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {total > 0 && (
                                <span>
                                    Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                    {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                </span>
                            )}
                        </Col>
                        <Col md='auto'>
                            <Paginator
                                currentPage={pageNo}
                                maxPage={page.maxPage}
                                _handlePageChange={(pageNo) => page.setPageNo(pageNo)}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {activeApp && (
                <LabModal
                    application={activeApp}
                    status={status}
                    onClose={(updated) => _handleLabModalClose(updated)}
                    labType={labType}
                />
            )}
        </div>
    );
};

export default LabTable;
