import React from 'react';

import { labels } from '../../../constants';
import { Status } from '../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import LabTable from '../../components/LabTable';
import LabType from '../../../models/enums/LabType';

import './styles.scss';

type Props = {};

const CompletedResult: React.FunctionComponent<Props> = () => {
    return (
        <div className='CompletedResult'>
            <PageHeader title={labels.LABORATORY} subTitle={labels.COMPLETED_RESULT} />
            <PageBody>
                <LabTable status={Status.COMPLETED_RESULT} labType={LabType.REGULAR_LABORATORY}/>
            </PageBody>
        </div>
    );
};

export default CompletedResult;
