import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';

import './styles.scss';

type Props = {};

const Preloader: React.FunctionComponent<Props> = () => {
    const { show, message } = useSelector((state: any) => state.modal.preloader);
    return (
        <Modal show={show} centered animation={false} keyboard={false} dialogClassName='Preloader'>
            <Modal.Body>
                <div className='loader'>
                    <Spinner animation='border' variant='primary' />
                    {message && <label>{message}</label>}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Preloader;
