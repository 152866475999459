enum Status {
    DRAFT = 'Draft',
    FOR_REVIEW = 'For Review',
    REQUIRES_UPDATE = 'Requires Update',
    REJECTED = 'Rejected',
    ON_HOLD = 'On Hold',
    VALIDATED = 'Validated',
    PAYMENT_CREATED = 'Payment Created',
    PAYMENT_SUBMITTED = 'Payment Submitted',
    PENDING_SPECIMEN = 'Pending Specimen',
    PENDING_RESULT = 'Pending Result',
    PENDING_APPROVAL = 'Pending Approval Lab',
    COMPLETED_RESULT = 'Completed Result',
    HIV_SEMINAR = 'HIV Seminar',
    HEAD_APPROVAL = 'Head Approval',
    COMPLETED = 'Completed'
}

export default Status;
