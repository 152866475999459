import React, { useState } from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { Button } from 'react-bootstrap';

import { config } from '../../../constants';
import { api, common } from '../../../services/utils';

import './styles.scss';

interface Props {
    fileURL: string;
    alt?: string;
}

const FileRenderer: React.FunctionComponent<Props> = (props) => {
    const { fileURL, alt } = props;
    const [isViewDoc, setViewDoc] = useState(false);

    const _handleFileDownload = () => {
        let fileSplit = fileURL.split('/');
        let fileName = fileSplit[fileSplit.length - 1];

        api.getExternalAuthorized(fileURL)
            .then((response) => {
                common.downloadCSV(response.body, fileName);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const renderFile = () => {
        let file: JSX.Element = <></>;
        let fileSplit = fileURL.split('.');
        let fileType = fileSplit[fileSplit.length - 1];

        if (config.DOWNLOAD_FILE.indexOf(fileType) !== -1) {
            if (fileType === 'csv') {
                file = (
                    <Button variant='primary' onClick={_handleFileDownload}>
                        Download File
                    </Button>
                );
            } else {
                file = (
                    <a href={fileURL} download>
                        <Button variant='primary'>Download File</Button>
                    </a>
                );
            }
        } else if (config.DOC_FILE.indexOf(fileType) !== -1) {
            file = (
                <>
                    {isViewDoc &&
                        <DocViewer
                            pluginRenderers={DocViewerRenderers}
                            documents={[{ uri: fileURL, fileType: fileType }]}
                            config={{
                                header: {
                                    disableHeader: true
                                }
                            }}
                        />
                    }
                    {!isViewDoc &&
                        <Button variant='primary' onClick={() => setViewDoc(true)}>
                            View PDF
                        </Button>
                    }
                </>
            );
        } else {
            file = <ReactPanZoom image={fileURL} alt={alt} />;
        }

        return file;
    };

    return <div className='FileRenderer'>{renderFile()}</div>;
};

export default FileRenderer;
