import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import { labels } from '../../../constants';
import InfoTooltip from '../InfoTooltip';

import './styles.scss';

type Props = {
    show: boolean;
    onClose: (data?: string) => void;
};

const SignatureModal: React.FunctionComponent<Props> = (props) => {
    const { show, onClose } = props;
    const [disableSave, setDisableSave] = useState(true);
    const sigCanvas: any = useRef({});

    return (
        <Modal show={show} onHide={() => onClose()} centered dialogClassName='SignatureModal'>
            <Modal.Header closeButton>
                <Modal.Title>
                    {labels.E_SIGNATURE}
                    <InfoTooltip message={'You can login thru a touch enabled device for easier signing'} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{ width: 500, height: 150 }}
                    onEnd={() => setDisableSave(sigCanvas.current.isEmpty())}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    block
                    variant='outline-primary'
                    onClick={() => {
                        sigCanvas.current.clear();
                        setDisableSave(true);
                    }}>
                    {labels.CLEAR}
                </Button>
                <Button
                    block
                    disabled={disableSave}
                    onClick={() => {
                        setDisableSave(true);
                        onClose(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
                    }}>
                    {labels.SAVE}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SignatureModal;
