import React from 'react';

import { labels } from '../../../constants';
import { PermitType } from '../../../models';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import Requirements from '../../components/Requirements';

import './styles.scss';

type Props = {};

const SPRequirements: React.FunctionComponent<Props> = () => {
    return (
        <div className='SPRequirements'>
            <PageHeader title={labels.SP_REQUIREMENTS_FULL} />
            <PageBody>
                <Requirements permitType={PermitType.SP} />
            </PageBody>
        </div>
    );
};

export default SPRequirements;
