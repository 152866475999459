import { api, env } from '../utils';
import {
    CreatePayment,
    CreateLabForm,
    LabForm,
    SortBy,
    SortType,
    CreateTrainingSession,
    TrainingSession,
    CreateTrainingParticipant,
    TrainingParticipant,
    CreateSanitaryReport,
    UpdateSanitaryReport,
    PaymentStatus,
    ApplicationFilter,
    Application
} from '../../models';
import LabType from '../../models/enums/LabType';
import CheckMassagistHistory from '../../models/applications/CheckMassagistHistory';

class ApplicationServiceClass {
    private serviceURL: string;
    private uploadsService: string;
    private fileService: string;
    private paymentsService: string;
    private laboratoryService: string;
    private reportService: string;
    private trainingService: string;
    private sanitaryReportService: string;
    private apiUrl: string = env.apiURL();
    private serverUrl: string = env.serverURL();

    constructor() {
        this.serviceURL = this.apiUrl + '/qch/application';
        this.uploadsService = this.apiUrl + '/qch/uploads';
        this.fileService = this.serverUrl + '/files';
        this.paymentsService = this.apiUrl + '/qch/payments';
        this.laboratoryService = this.apiUrl + '/qch/laboratory';
        this.reportService = this.apiUrl + '/qch/report';
        this.trainingService = this.apiUrl + '/qch/training';
        this.sanitaryReportService = this.apiUrl + '/qch/sanitaryInspectionReport';
    }

    addRequestFilter = (requestString: string, filter?: ApplicationFilter): string => {
        if (filter) {
            Object.keys(filter).forEach((key) => {
                if (filter[key]) requestString = requestString + '&' + key + '=' + filter[key];
            });
        }

        return requestString;
    };

    getApplicationById(id: number) {
        return api.get(this.serviceURL + '/' + id, false);
    }

    getApplicationByCertificateNo(certificateNo: string) {
        return api.get(this.serviceURL + '/certificate-number/' + certificateNo);
    }

    getApplications(
        limit: number,
        page: number,
        permitId: number,
        filter?: ApplicationFilter,
        sortBy: SortBy = SortBy.UPDATED_DATE,
        sortAsc: boolean = true,
        isReport: boolean = false,
        preLoaderMessage: string | boolean = false
    ) {
        let requestString = '/all?limit=' + limit + '&page=' + page + '&permitId=' + permitId;
        requestString = this.addRequestFilter(requestString, filter);
        requestString = requestString + '&sortBy=' + sortBy + '&sortType=' + (sortAsc ? SortType.ASCENDING : SortType.DESCENDING);
        if (isReport) requestString = requestString + '&isReport=' + isReport;

        return api.get(this.serviceURL + requestString, preLoaderMessage);
    }

    getApplicationByStatus(
        limit: number,
        page: number,
        permitId: number,
        status: string,
        filter?: ApplicationFilter,
        sortBy: SortBy = SortBy.UPDATED_DATE,
        sortAsc: boolean = true,
        preLoaderMessage: string | boolean = false,
        labType: LabType | null = null,
    ) {
        let requestString = '?limit=' + limit + '&page=' + page + '&permitId=' + permitId;
        let labTypeFilter = '';
        
        if (labType === LabType.SOCIAL_HYGIENE) {
            labTypeFilter = '&socialHygiene=true';
        } else if (labType === LabType.WATER_ANALYSIS) {
            labTypeFilter = '&waterAnalysis=true';
        } else if (labType === LabType.REGULAR_LABORATORY) {
            labTypeFilter = '&regularLab=true';
        }

        if (filter) {
            filter.status = status;
        } else {
            requestString = requestString + '&status=' + status;
        }

        requestString = this.addRequestFilter(requestString, filter);
        requestString = requestString + '&sortBy=' + sortBy + '&sortType=' + (sortAsc ? SortType.ASCENDING : SortType.DESCENDING)
            + labTypeFilter;

        return api.get(this.serviceURL + requestString, preLoaderMessage);
    }

    getApplicationsReport(
        limit: number,
        page: number,
        permitId: number,
        filter?: ApplicationFilter,
        sortBy: SortBy = SortBy.UPDATED_DATE,
        sortAsc: boolean = true,
        isReport: boolean = false,
        preLoaderMessage: string | boolean = false
    ) {
        let requestString = '/all/report?limit=' + limit + '&page=' + page + '&permitId=' + permitId;
        requestString = this.addRequestFilter(requestString, filter);
        requestString = requestString + '&sortBy=' + sortBy + '&sortType=' + (sortAsc ? SortType.ASCENDING : SortType.DESCENDING);
        if (isReport) requestString = requestString + '&isReport=' + isReport;

        return api.get(this.serviceURL + requestString, preLoaderMessage);
    }

    getApplicationReportByStatus(
        limit: number,
        page: number,
        permitId: number,
        status: string,
        filter?: ApplicationFilter,
        sortBy: SortBy = SortBy.UPDATED_DATE,
        sortAsc: boolean = true,
        preLoaderMessage: string | boolean = false
    ) {
        let requestString = '/report?limit=' + limit + '&page=' + page + '&permitId=' + permitId;

        if (filter) {
            filter.status = status;
        } else {
            requestString = requestString + '&status=' + status;
        }

        requestString = this.addRequestFilter(requestString, filter);
        requestString = requestString + '&sortBy=' + sortBy + '&sortType=' + (sortAsc ? SortType.ASCENDING : SortType.DESCENDING);

        return api.get(this.serviceURL + requestString, preLoaderMessage);
    }

    getMassagistByApplicationId(id: number) {
        return api.get(this.serviceURL + '/massagist/' + id, false);
    }

    getCompletedLab(
        limit: number,
        page: number,
        permitId: number,
        labType: LabType | null,
        filter?: ApplicationFilter,
        sortBy: SortBy = SortBy.UPDATED_DATE,
        sortAsc: boolean = true,
        preLoaderMessage: string | boolean = false
    ) {
        let labTypeFilter = '';
        
        if (labType === LabType.SOCIAL_HYGIENE) {
            labTypeFilter = '&socialHygieneCompleted=true';
        } else if (labType === LabType.WATER_ANALYSIS) {
            labTypeFilter = '&waterAnalysisCompleted=true';
        } else if (labType === LabType.REGULAR_LABORATORY) {
            labTypeFilter = '&labCompleted=true';
        }

        let requestString = '?limit=' + limit + '&page=' + page + '&permitId=' + permitId + labTypeFilter;
        requestString = this.addRequestFilter(requestString, filter);
        requestString = requestString + '&sortBy=' + sortBy + '&sortType=' + (sortAsc ? SortType.ASCENDING : SortType.DESCENDING);

        return api.get(this.serviceURL + requestString, preLoaderMessage);
    }

    discardApplication(applicationId: number) {
        return api.post(this.serviceURL + '/discard/' + applicationId, {});
    }

    validateApplication(applicationId: number, yellowCard: boolean) {
        return api.post(this.serviceURL + '/validate', { applicationId, yellowCard });
    }

    approveApplication(applicationId: number, remarks: string, pickUpDate: string) {
        return api.post(this.serviceURL + '/head/approve', { applicationId, remarks, pickUpDate });
    }

    requireUpdateApplication(applicationId: number, remarks: string, yellowCard: boolean) {
        return api.post(this.serviceURL + '/requires-update', { applicationId, remarks, yellowCard });
    }

    requireUpdateHead(applicationId: number, remarks: string) {
        return api.post(this.serviceURL + '/head/requires-update', { applicationId, remarks });
    }

    onHoldApplication(applicationId: number, remarks: string) {
        return api.post(this.serviceURL + '/on-hold', { applicationId, remarks });
    }

    rejectApplication(applicationId: number, remarks: string) {
        return api.post(this.serviceURL + '/reject', { applicationId, remarks });
    }

    releaseApplication(applicationId: number, pickUpDate: string) {
        return api.post(this.serviceURL + '/release', { applicationId, pickUpDate });
    }

    getDuplicateApplications(applicationId: number) {
        return api.get(this.serviceURL + '/duplicates/' + applicationId, false);
    }

    getBulkApplications(bulkApplicationId: number, page: number, limit: number) {
        let requestString = '?limit=' + limit + '&page=' + page + '&bulkApplicationId=' + bulkApplicationId;

        return api.get(this.serviceURL + '/bulk-upload' + requestString, false);
    }

    getBulkEmployeesHC(applicationId: number, page: number, limit: number) {
        let requestString = '?limit=' + limit + '&page=' + page + '&applicationId=' + applicationId;
        return api.get(this.serviceURL + '/sp/employees' + requestString, false);
    }

    getBulkEmployeesUploads(employeeIds: number[]) {
        return api.post(this.serviceURL + '/sp/upload/health-cert/employees', employeeIds, false);
    }

    updateApplication(updatedApplication: Application) {
        return api.put(this.serviceURL, updatedApplication);
    }

    getUploads(applicationId: number) {
        let requestString = '?applicationId=' + applicationId;

        return api.get(this.uploadsService + requestString, false);
    }

    getUploadHistory(applicationId: number) {
        let requestString = '/history/' + applicationId;

        return api.get(this.uploadsService + requestString, false);
    }

    getEmployeeUploadHistory(employeeId: number) {
        return api.get(this.uploadsService + '/history/employee/' + employeeId, true);
    }

    completeUpload(uploadId: number, remarks?: string) {
        return api.post(this.uploadsService + '/complete', { uploadId, remarks });
    }

    rejectUpload(uploadId: number, remarks?: string) {
        return api.post(this.uploadsService + '/reject', { uploadId, remarks });
    }

    getFileDetails(id: number) {
        return api.get(this.fileService + '/details/by/' + id, false);
    }

    uploadFile(file: File, refNo: number | string, uploader: string) {
        let requestString = '/upload?refNo=' + refNo;
        requestString += '&uploader=' + uploader;
        requestString += '&requirementsType=NA';

        return api.postMultiForm(this.fileService + requestString, { file });
    }

    getPayments(
        limit: number,
        page: number,
        permitId?: number,
        status?: PaymentStatus,
        filter?: ApplicationFilter,
        sortBy: SortBy = SortBy.PAYMENT_DATE,
        sortAsc: boolean = true
    ) {
        let requestString = '?limit=' + limit + '&page=' + page;
        if (permitId) requestString = requestString + '&permitId=' + permitId;
        if (status) requestString = requestString + '&status=' + status;
        requestString = this.addRequestFilter(requestString, filter);
        requestString = requestString + '&sortBy=' + sortBy + '&sortType=' + (sortAsc ? SortType.ASCENDING : SortType.DESCENDING);

        return api.get(this.paymentsService + requestString, false);
    }

    createPayment(data: CreatePayment) {
        return api.post(this.paymentsService, data);
    }

    getPayment(applicationId: number) {
        return api.get(this.paymentsService + '/' + applicationId, false);
    }

    getBulkPaymentParts(bulkApplicationId: number) {
        return api.get(this.paymentsService + '/bulk?bulkApplicationId=' + bulkApplicationId, false);
    }

    getBulkPaymentDetails(bulkApplicationId: number) {
        return api.get(this.paymentsService + '/bulk/' + bulkApplicationId, false);
    }

    completePayment(applicationId: number, remarks?: string) {
        return api.post(this.paymentsService + '/complete', { applicationId, remarks });
    }

    completeBulkPayment(bulkApplicationId: number, remarks?: string) {
        return api.post(this.paymentsService + '/bulk/complete', { bulkApplicationId, remarks });
    }

    rejectPayment(applicationId: number, remarks?: string) {
        return api.post(this.paymentsService + '/reject', { applicationId, remarks });
    }

    rejectBulkPayment(bulkApplicationId: number, remarks?: string) {
        return api.post(this.paymentsService + '/bulk/reject', { bulkApplicationId, remarks });
    }

    getAllLaboratories(id: number) {
        return api.get(this.laboratoryService + '/' + id);
    }

    getLaboratoryPerType(id: number, type: LabType | null) {
        return api.get(this.laboratoryService + '/' + id + '/type/' + type, false);
    }

    submitLaboratoryPerType(id: number, type: LabType) {
        return api.post(this.laboratoryService + '/submit/' + id + '/' + type, {});
    }

    createLaboratory(data: CreateLabForm) {
        return api.post(this.laboratoryService, data);
    }

    updateLaboratory(data: LabForm) {
        return api.put(this.laboratoryService, data);
    }

    pendingApprovalLaboratory(applicationId: number, type: LabType) {
        return api.post(this.laboratoryService + '/pending-approval/all/' + applicationId + '/' + type, {});
    }

    approveLaboratoryResult(laboratoryId: number) {
        return api.post(this.laboratoryService + '/approve/' + laboratoryId, {});
    }

    rejectLaboratoryResult(laboratoryId: number, remarks: string) {
        return api.post(this.laboratoryService + '/reject', { laboratoryId, remarks });
    }

    approveLaboratory(applicationId: number, type: LabType) {
        return api.post(this.laboratoryService + '/approved/all/' + applicationId + '/' + type, {});
    }

    rejectLaboratoryByType(applicationId: number, type: LabType, remarks?: string) {
        return api.post(this.laboratoryService + '/reject/all/' + type, { applicationId, remarks });
    }

    getReportCount() {
        return api.get(this.reportService + '/count', false);
    }

    getReportStat(permitType: string, statuses: string[]) {
        let requestString = '/stat?permitType=' + permitType + '&statuses=' + statuses.join(",");
        return api.get(this.reportService + requestString, false);
    }

    getTrainingSession(page: number, size: number) {
        let requestString = '/session?page=' + page + '&size=' + size + "&sort=createdDate,desc";
        return api.get(this.trainingService + requestString, false);
    }

    getTrainingSessionDetails(trainingSessionId: number) {
        return api.get(this.trainingService + '/session/' + trainingSessionId, false);
    }

    createTrainingSession(data: CreateTrainingSession) {
        return api.post(this.trainingService + '/session', data);
    }

    updateTrainingSession(data: TrainingSession) {
        return api.put(this.trainingService + '/session', data);
    }

    deleteTrainingSession(id: number) {
        return api.delete(this.trainingService + '/session/' + id);
    }

    getTrainingParticipants(page: number, size: number, firstName?: string, lastName?: string) {
        let requestString = '/participant?page=' + page + '&size=' + size;
        if (firstName) requestString = requestString + '&firstName=' + firstName;
        if (lastName) requestString = requestString + '&lastName=' + lastName;
        return api.get(this.trainingService + requestString, false);
    }

    getTrainingSessionParticipants(trainingSessionId: number) {
        return api.get(this.trainingService + '/participant/session/' + trainingSessionId, false);
    }

    createTrainingParticipant(data: CreateTrainingParticipant) {
        return api.post(this.trainingService + '/participant', data);
    }

    updateTrainingParticipant(data: TrainingParticipant) {
        return api.put(this.trainingService + '/participant', data);
    }

    deleteTrainingParticipant(trainingParticipantId: number) {
        return api.delete(this.trainingService + '/participant/' + trainingParticipantId);
    }

    updateParticipantSeminarAttendance(participantId: number | string, action: 'complete' | 'no-show' | 'session-confirmed') {
        return api.post(this.trainingService + `/participant/${participantId}/${action}`, {});
    }

    createSanitaryReport(data: CreateSanitaryReport) {
        return api.post(this.sanitaryReportService, data);
    }

    updateSanitaryReport(data: UpdateSanitaryReport) {
        return api.put(this.sanitaryReportService, data);
    }

    getSanitaryReports(
        pageNo: number,
        pageSize: number,
        filter?: ApplicationFilter,
        preLoaderMessage: string | boolean = false
    ) {
        let requestString = '/all?limit=' + pageSize + '&page=' + pageNo;
        requestString = this.addRequestFilter(requestString, filter);

        return api.get(this.sanitaryReportService + requestString, preLoaderMessage);
    }

    getSanitaryReport(sanitaryInspectionReportId: number) {
        return api.get(this.sanitaryReportService + '/' + sanitaryInspectionReportId, false);
    }

    deleteSanitaryReport(sanitaryInspectionReportId: number) {
        return api.delete(this.sanitaryReportService + '/' + sanitaryInspectionReportId);
    }

    checkMassagistTraining(detailParams: CheckMassagistHistory) {
        const { firstName, middleName, lastName, dob } = detailParams;
        const apiEndpoint = this.trainingService + '/participant/attendance'
            + `?firstName=${firstName}`
            + `${middleName ? ('&middleName=' + middleName) : ''}`
            + `&lastName=${lastName}`
            + `&dob=${dob}`

        return api.get(apiEndpoint, false, false, false);
    }
}

const ApplicationService = new ApplicationServiceClass();

export default ApplicationService;
