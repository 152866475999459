import React from 'react'
import QRCode from 'react-qr-code';
import { images, labels } from '../../../constants'
import { Application, HealthAppData } from '../../../models';
import { generateCertificateLink, generateNewDateFromString } from '../../../services/utils/common.util';

import './styles.scss'

export const OFFICER_IN_CHARGE = "RAMONA ASUNCION DG. ABARQUEZ M.D. MPH";

interface IHCDigitalCertificate {
  formData: HealthAppData;
  application: Application;
}

const HCDigitalCertificate: React.FC<IHCDigitalCertificate> = ({ formData, application }) => {
  const generateAddress = () => {
    const { houseNo, street, barangay, city } = formData;

    return `${houseNo !== "" ? `${houseNo} ` : ''}${street !== "" ? `${street} ` : ''}${barangay !== "" ? `${barangay} ` : ''}${city !== "" ? `${city} ` : ''}`
  }

  const generateAge = () => {
    const birthDate = generateNewDateFromString(formData.birthDate)
    const completedDate = generateNewDateFromString(formData.updatedDate)

    if (birthDate && completedDate) {
      const birthYear = birthDate?.getFullYear()
      const birthMonth = birthDate?.getMonth()
      const birthWeekday = birthDate?.getDate()

      const completedYear = completedDate?.getFullYear()
      const completedMonth = completedDate?.getMonth()
      const completedWeekday = completedDate?.getDate()

      if (birthMonth > completedMonth || (birthMonth === completedMonth && birthWeekday > completedWeekday)) {
        return (completedYear - 1) - birthYear
      } else {
        return completedYear - birthYear
      }
    }

    return ''
  }

  const generateFullName = () => {
    const { firstName, middleName, lastName, suffix } = formData;

    return `${lastName}, ${firstName}${suffix ? ` ${suffix},` : ""}${middleName ? ` ${middleName}` : ""}`
  }

  return <div id="digital-cert-full">
    <div id="cert-front" className="base-holder">
      <div className="content-holder">
        <div id="front-header" className="qc-blue-bg">
          <img src={images.QC_LOGO.src} alt={images.QC_LOGO.alt} id="qc-logo" />
          <div id="header-text">
            <span>{labels.REPUBLIC_OF_THE_PHILIPPINES}</span>
            <span>{labels.TITLE}</span>
          </div>
          <img src={images.HD_LOGO.src} alt={images.HD_LOGO.alt} id="hd-logo" />
        </div>
        <div id="front-image" className="qc-green-bg">
          <div className="front-bg-image" id="qc-tree-bg">
            <img id="back-img-tree" alt={images.QC_TREE.alt} src={images.QC_TREE.src} />
          </div>
          <div className="front-bg-image" id="qc-tower-bg">
            <img id="back-img-tower" alt={images.QC_TOWER.alt} src={images.QC_TOWER.src} />
          </div>
          <div id="front-image-title">{labels.digitalHealthCert.LABEL}</div>
          <div id="front-image-container">
            <div id="front-image-placeholder" />
            <div id="front-signature">
              <span>{labels.digitalHealthCert.SIGNATURE}</span>
            </div>
          </div>
          <div id="front-image-warning">
            {labels.digitalHealthCert.QC_ORDINANCE}
          </div>
        </div>
        <div id="front-details" className="white-bg">
          <div id="front-details-bg"></div>
          <div id="front-details-content">
            <div className="detail-row">
              <span className="all-caps">{labels.NAME}:</span>
              &nbsp;
              <div className="hc-user-detail">
                <span>{generateFullName()}</span>
              </div>
            </div>
            <div className="detail-row">
              <div className="row-section auto">
                <span className="all-caps">{labels.AGE}:</span>
                &nbsp;
                <div className="hc-user-detail" style={{ width: "20px" }}>
                  <span>{generateAge()}</span>
                </div>
              </div>&nbsp;&nbsp;
              <div className="row-section auto">
                <span className="all-caps">{labels.SEX}:</span>
                &nbsp;
                <div className="hc-user-detail" style={{ width: "20px" }}>
                  <span>{formData.gender === "Female" ? 'F' : 'M'}</span>
                </div>
              </div>&nbsp;&nbsp;&nbsp;
              <div className="row-section fw">
                <span className="all-caps">{labels.NATIONALITY}:</span>
                &nbsp;
                <div className="hc-user-detail">
                  <span>{formData.nationality}</span>
                </div>
              </div>
            </div>
            <div className="detail-row">
              <div className="row-section fw"><span id="hm-ad-field" className="all-caps">{labels.HOME_ADDRESS}:</span>&nbsp;<div className="hc-user-detail"><span>{generateAddress()}</span></div></div>
            </div>
            <div className="detail-row">
              <div className="row-section fw">
                <span id="occ-field" className="all-caps">{labels.OCCUPATION}:</span>&nbsp;
                <div className="hc-user-detail">
                  <span>{formData.occupation}</span>
                </div>
              </div>&nbsp;&nbsp;
              <div className="row-section auto">
                <span id="ind-field" className="all-caps">{labels.INDUSTRY}:</span>&nbsp;
                <div className="hc-user-detail">
                  <span>{formData.industry}</span>
                </div>
              </div>
            </div>
            <div className="detail-row">
              <div className="row-section fw">
                <span className="all-caps">{labels.digitalHealthCert.SPUTUM_CXR}:</span>
                &nbsp;
                <div className="hc-user-detail">
                  <span className="all-caps">{labels.digitalHealthCert.NEGATIVE_RES}</span>
                </div>
              </div>&nbsp;
              <div className="row-section fw">
                <span className="all-caps">{labels.digitalHealthCert.STOOL}:</span>
                &nbsp;
                <div className="hc-user-detail">
                  <span className="all-caps">{labels.digitalHealthCert.NEGATIVE_RES}</span>
                </div>
              </div>
            </div>
            <div className="detail-row">
              <div className="row-section fw">
                <span id="ind-field" className="all-caps">{labels.digitalHealthCert.REGISTRATION_NO}:</span>&nbsp;
                <div className="hc-user-detail">
                  <span>{application.certificateNumber}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="front-approval" className="qc-green-bg">
          <div id="front-approval-bg"></div>
          <div id="front-approval-contents">
            <div id="front-approval-details">
              <div id="front-approve-label"><strong className="all-caps">{labels.APPROVED}:</strong></div>
              <div id="front-approver">
                <span id="approver-name"><strong>{OFFICER_IN_CHARGE}</strong></span>
                <span id="approver-title">{labels.digitalHealthCert.OIC}</span>
              </div>
              <div id="e-gen-notice">
                <div>{labels.digitalHealthCert.COMP_GEN_MSSG}</div>
                <div>{labels.digitalHealthCert.NO_SIGNATURE_REQ}</div>
              </div>
            </div>
            <div id="front-qr-parts">
              <div className="qr-part ta-center">
                <div className="qr-part-label">{labels.digitalHealthCert.ONLINE_VALIDATION}</div>
                <div className="qr-part-mark"><QRCode size={75} value={generateCertificateLink(application.applicationId)} /></div>
              </div>
              <div id="front-valid-until">
                <span>{labels.digitalHealthCert.VALID_UNTIL}</span>&nbsp;<div id="valid-until-value">{application.expirationDate}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="cert-back" className="base-holder">
      <div className="content-holder">
        <img id="back-img" alt={images.HC_BACK.alt} src={images.HC_BACK.src} />
      </div>
    </div>
  </div>
}

export default HCDigitalCertificate;