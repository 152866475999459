import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Table, Form, Spinner } from 'react-bootstrap';
import { FaRegFileImage } from 'react-icons/fa';
import { FaPrint } from 'react-icons/fa';
import { filter, find } from 'lodash';

import { contentValues, labels } from '../../../constants';
import { Application, Requirement, Status, LabForm, LabFormStatus, Upload, PermitType, OrderOfPayment } from '../../../models';
import LabType from '../../../models/enums/LabType';
import { ConfigService, modal, common, ApplicationService } from '../../../services';
import InfoTooltip from './../InfoTooltip';
import LabFormModal from './../LabFormModal';
import ViewRequirementModal from './../ViewRequirementModal';
import LabSpecimenFormModal from '../LabSpecimenFormModal';
import DuplicateApplicationCard from '../DuplicateApplicationCard';
import PaymentDetailsCard from '../PaymentDetailsCard';

import './styles.scss';
import LabDispatchFormModal from '../LabDispatchFormModal';

type Props = {
    application?: Application;
    status: Status;
    labType: LabType;
    onClose: (updated: boolean) => void;
};

interface LabRequirement extends Requirement {
    orderOfPaymentId: number;
    disabled: boolean;
    selected: boolean;
    dispatched: boolean;
}

type UploadData = {
    upload: Upload;
    requirementName: string;
};

interface FacilityDetails {
    facility?: string;
    labNo?: string
};

let labForm: LabForm;
let requirement: Requirement;

const LabModal: React.FunctionComponent<Props> = (props) => {
    const { application, status, onClose, labType } = props;
    const formData = application?.formData as any;
    const user = useSelector((state: any) => state.user.user);
    const [isLoading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showDispatchForm, setShowDispatchForm] = useState(false);
    const [showSpecimenForm, setShowSpecimenForm] = useState(false);
    const [labRequirements, setLabRequirements] = useState<LabRequirement[]>([]);
    const [labResults, setLabResults] = useState<LabForm[]>([]);
    const [uploads, setUploads] = useState<Upload[]>([]);
    const [uploadData, setUploadData] = useState<UploadData>();
    const [facilityDetails, setFacilityDetails] = useState<FacilityDetails>({ facility: contentValues.FACILITY_LIST[0] });
    const permitType: PermitType = labType === LabType.WATER_ANALYSIS ? PermitType.SP : PermitType.IHC;

    const getLabData = () => {
        if (application) {
            let calls: any = [];
            calls.push(
                ConfigService.getRequirements(
                    application.permitId,
                    application.formData.applicationTypeId,
                    application.formData.industryId,
                    application.formData.subIndustryId
                )
            );
            calls.push(ConfigService.getOrderOfPayments(application.permitId));
            calls.push(ApplicationService.getUploads(application.applicationId));
            calls.push(ApplicationService.getLaboratoryPerType(application.applicationId, labType));

            setLoading(true);
            Promise.all(calls)
                .then((responses: any[]) => {
                    let labRequirements: LabRequirement[] = responses[0].body.data;
                    let orderOfPayments = responses[1].body.data;
                    let uploads = responses[2].body.data;
                    let labResults = responses[3].body.data;
                    let paymentDetails = application.paymentDetailResponseDto?.[0]?.paymentDetailDtos;

                    let labFilter;
                    if (labType === LabType.REGULAR_LABORATORY) {
                        labFilter = { internal: true, inactive: false };
                    } else if (labType === LabType.SOCIAL_HYGIENE) {
                        labFilter = { socialHygiene: true, inactive: false };
                    } else if (labType === LabType.WATER_ANALYSIS) {
                        labFilter = { waterAnalysis: true, inactive: false };
                    }

                    labRequirements = filter(labRequirements, labFilter);

                    labRequirements.forEach((requirement) => {
                        //Check if requirement has corresponding payment
                        requirement.disabled = true;

                        let orderOfPayment = find(orderOfPayments, (ord: OrderOfPayment)=> { return ord.requirementId === requirement.requirementId || ord.requirementIds?.includes(requirement.requirementId) });
                        if (orderOfPayment) {
                            requirement.disabled = find(paymentDetails, { orderOfPaymentId: orderOfPayment.orderOfPaymentId })
                                ? false
                                : true;
                        }

                        //Check if requirement has corresponding result
                        const labResult: LabForm = find(labResults, { requirementId: requirement.requirementId });

                        requirement.selected = labResult?.formData?.dateReceived ? true : false;
                        requirement.dispatched = labResult?.formData?.collectedBy ? true : false;
                    });

                    setLabRequirements(labRequirements);
                    setUploads(uploads);
                    setLabResults(labResults);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        }
    };

    const getLabResults = () => {
        if (application) {
            ApplicationService.getLaboratoryPerType(application.applicationId, labType)
                .then((response) => {

                    labRequirements.forEach((requirement) => {
                        //Check if requirement has corresponding result
                        const labResult: LabForm = find(response.body.data, { requirementId: requirement.requirementId });

                        requirement.selected = labResult?.formData?.dateReceived ? true : false;
                        requirement.dispatched = labResult?.formData?.collectedBy ? true : false;
                    });

                    setLabRequirements(labRequirements);
                    setLabResults(response.body.data);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    useEffect(getLabData, [application]);

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        if (isLoading) {
            rows.push(
                <tr key={1}>
                    <td colSpan={100}>
                        <div className='center-content'>
                            <Spinner animation='border' variant='primary' />
                        </div>
                    </td>
                </tr>
            );
        } else {
            labRequirements.forEach((req, index) => {
                let result = find(labResults, { requirementId: req.requirementId });
                let upload: Upload = find(uploads, { requirementId: req.requirementId });

                rows.push(
                    <tr key={index}>
                        <td>
                            <span>{req.requirementName}</span>
                            {labType === LabType.WATER_ANALYSIS && status === Status.PENDING_SPECIMEN && req.dispatched &&
                                <Button className='label-print' variant='outline-primary' onClick={() => _handleDispatchForm(result, req)}>
                                    <FaPrint />
                                </Button>
                            }
                        </td>
                        {status === Status.PENDING_SPECIMEN &&
                            !req.disabled &&
                            (labType !== LabType.WATER_ANALYSIS || req.dispatched) && (
                                <td className='text-center'>
                                    <Form.Group>
                                        <Form.Check
                                            type='checkbox'
                                            checked={req.selected}
                                            onChange={() => _handleSelect(result, req)}></Form.Check>
                                    </Form.Group>
                                </td>
                            )}
                        {status === Status.PENDING_SPECIMEN &&
                            !req.disabled &&
                            !req.dispatched &&
                            labType === LabType.WATER_ANALYSIS && (
                                <td className='text-center'>
                                    <Button variant='primary' onClick={() => _handleDispatchForm(result, req)}>
                                        {labels.DISPATCH_SAMPLER}
                                    </Button>
                                </td>
                            )}
                        {status === Status.PENDING_SPECIMEN && req.disabled && (
                            <td className='text-center'>No Order of Payment</td>
                        )}
                        {status !== Status.PENDING_SPECIMEN && !req.disabled && (
                            <>
                                <td className='text-center'>
                                    <span className={result?.status}>{result?.status}</span>
                                    {result && result.remarks && result.status === LabFormStatus.REJECTED && (
                                        <InfoTooltip message={result.remarks} />
                                    )}
                                </td>
                                <td className='text-center'>
                                    <Button variant='primary' onClick={() => _handleShowForm(result, req)}>
                                        {result && result.status !== LabFormStatus.PENDING ?
                                            labels.VIEW_RESULT : labels.UPLOAD_RESULT}
                                    </Button>
                                </td>
                            </>
                        )}
                        {status !== Status.PENDING_SPECIMEN && req.disabled && !upload && (
                            <>
                                <td className='text-center'>No Order of Payment</td>
                                <td></td>
                            </>
                        )}
                        {status !== Status.PENDING_SPECIMEN && req.disabled && upload && (
                            <>
                                <td className='text-center'>{upload.status}</td>
                                <td className='text-center'>
                                    <Button
                                        variant='primary'
                                        onClick={() =>
                                            setUploadData({
                                                upload: upload,
                                                requirementName: req.requirementName
                                            })
                                        }>
                                        <FaRegFileImage />
                                    </Button>
                                </td>
                            </>
                        )}
                    </tr>
                );
            });
        }

        return rows;
    };

    const renderFacilityDetailsInput = () => {
        return (
            <Row>
                <Col>
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>{labels.FACILITY_DETAILS}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.FACILITY}</Form.Label>
                                                <Form.Control
                                                    custom
                                                    name='facility'
                                                    as='select'
                                                    defaultValue={contentValues.FACILITY_LIST[0]}
                                                    onChange={(event) => {
                                                        event.persist()
                                                        setFacilityDetails((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                facility: event.target.value
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {contentValues.FACILITY_LIST.map(facName => <option value={facName}>{facName}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>{labels.LAB_NO}*</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='labNo'
                                                    onChange={(event) => {
                                                        event.persist()
                                                        setFacilityDetails((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                labNo: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const checkAllReceived = () => {
        //Check if 1 or more active input is not yet selected
        return find(labRequirements, { disabled: false, selected: false }) || (labType === LabType.REGULAR_LABORATORY && !facilityDetails.labNo);
    };

    const checkAllResult = () => {
        let disabled = true;

        if (!isLoading) {
            let uploadReqIds = uploads.map((a) => a.requirementId);

            //Get active requirements with no uploads
            let pendingRequirements: Requirement[] = labRequirements.filter(
                (req) => !req.disabled && !uploadReqIds.includes(req.requirementId)
            );

            if (pendingRequirements.length > 0) {
                //Check if 1 or more requirement has no result
                disabled = pendingRequirements.some((req) => {
                    return !(
                        find(labResults, { requirementId: req.requirementId, status: LabFormStatus.SUBMITTED }) ||
                        find(labResults, { requirementId: req.requirementId, status: LabFormStatus.APPROVED })
                    );
                });
            } else {
                disabled = false;
            }
        }

        return disabled;
    };

    const checkRejected = () => {
        let disabled = true;

        if (!isLoading) {
            labResults.every((result) => {
                disabled = result.status !== LabFormStatus.REJECTED;
                return disabled;
            });
        }

        return disabled;
    };

    const checkAllApproved = () => {
        let disabled = true;

        if (!isLoading) {
            if (labResults.length > 0) {
                labResults.every((result) => {
                    disabled = result.status !== LabFormStatus.APPROVED;
                    return !disabled;
                });
            } else {
                disabled = false;
            }
        }

        return disabled;
    };

    const _handleShowForm = (labResult: LabForm, req: Requirement) => {
        labForm = labResult;
        requirement = req;

        setShowForm(true);
    };

    const _handleDispatchForm = (labResult: LabForm, req: Requirement) => {
        labForm = labResult;
        requirement = req;

        setShowDispatchForm(true);
    };

    const _handleSelect = (labResult: LabForm, req: Requirement) => {
        labForm = labResult;
        requirement = req;

        setShowSpecimenForm(true);
    };

    const _handleReceived = () => {
        if (application) {
            modal.displayConfirmation(labels.dialog.message.LAB_RECEIVED_CONFIRM, () => {
                ApplicationService.submitLaboratoryPerType(application.applicationId, labType)
                    .then(() => {
                        // did this so that I can store the lab and facility details on the related application
                        // then re-use it on the lab modal form for proper data display
                        const { labNo, facility } = facilityDetails;
                        const updatedApplication = {
                            ...application,
                            formData: {
                                ...application.formData,
                                labNo,
                                facility
                            }
                        }

                        ApplicationService.updateApplication(updatedApplication);
                        onClose(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    const _handleFormClose = (updated?: boolean) => {
        setShowForm(false);
        if (updated) getLabResults();
    };

    const _handleDispatchFormClose = (updated?: boolean) => {
        setShowDispatchForm(false);
        if (updated) getLabResults();
    };

    const _handleSpecimenFormClose = (updated?: boolean) => {
        setShowSpecimenForm(false);
        if (updated) getLabResults();
    };

    const _handleComplete = () => {
        if (application) {
            modal.displayConfirmation(labels.dialog.message.LAB_COMPLETE_CONFIRM, () => {
                ApplicationService.pendingApprovalLaboratory(application.applicationId, labType)
                    .then(() => {
                        modal.displaySuccess(labels.dialog.message.LAB_COMPLETE_SUCCESS);
                        onClose(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    const _handleApprove = () => {
        if (application) {
            ApplicationService.approveLaboratory(application.applicationId, labType)
                .then(() => {
                    modal.displaySuccess(
                        labels.dialog.message.LAB_APPROVE_ALL_SUCCESS.replace('{name}', labType === LabType.WATER_ANALYSIS ? formData.establishmentName : common.parseFullname(formData))
                    );
                    onClose(true);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const _handleReject = () => {
        if (application) {
            ApplicationService.rejectLaboratoryByType(application.applicationId, labType!)
                .then(() => {
                    onClose(true);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    return (
        <Modal show={application ? true : false} onHide={() => onClose(false)} centered dialogClassName='LabModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.APP_INFORMATION}</Modal.Title>
            </Modal.Header>
            {application && (
                <>
                    <Modal.Body>
                        <Row className='detail-row'>
                            {(labType === LabType.REGULAR_LABORATORY || labType === LabType.SOCIAL_HYGIENE) &&
                                <>
                                    <Col xs={12} lg={6}>
                                        <span>
                                            {common.parseDetailLabel(labels.FULL_NAME)}
                                            <span>{common.parseFullname(formData)}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <span>
                                            {common.parseDetailLabel(labels.APPLICATION_DATE)}
                                            <span>{application.createdDate}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <span>
                                            {common.parseDetailLabel(labels.BIRTH_DATE)}
                                            <span>{formData.birthDate}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <span>
                                            {common.parseDetailLabel(labels.GENDER)}
                                            <span>{formData.gender}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12}>
                                        <span>
                                            {common.parseDetailLabel(labels.CONTACT_NUMBER)}
                                            <span>{formData.contactNumber}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12}>
                                        <span>
                                            {common.parseDetailLabel(labels.EMAIL_ADDRESS)}
                                            <span>{formData.email}</span>
                                        </span>
                                    </Col>
                                </>
                            }
                            {(labType === LabType.WATER_ANALYSIS) &&
                                <>
                                    <Col xs={12}>
                                        <span>
                                            {common.parseDetailLabel(labels.ESTABLISHMENT_NAME)}
                                            <span>{formData.establishmentName}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12}>
                                        <span>
                                            {common.parseDetailLabel(labels.ADDRESS)}
                                            <span>{formData.address}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <span>
                                            {common.parseDetailLabel(labels.OWNER)}
                                            <span>{formData.ownerName}</span>
                                        </span>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <span>
                                            {common.parseDetailLabel(labels.CONTACT_NUMBER)}
                                            <span>{formData.contactNumber}</span>
                                        </span>
                                    </Col>
                                </>
                            }
                        </Row>

                        <DuplicateApplicationCard application={application} permitType={permitType} />

                        <Row>
                            <Col>
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th>{labels.REQUIREMENTS}</th>
                                            {(status === Status.PENDING_RESULT ||
                                                status === Status.PENDING_APPROVAL ||
                                                status === Status.COMPLETED_RESULT) && (
                                                    <>
                                                        <th>{labels.STATUS}</th>
                                                        <th>{labels.RESULT}</th>
                                                    </>
                                                )}
                                            {status === Status.PENDING_SPECIMEN && <th>{labels.RECEIVED}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>{renderRows()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        {(status === Status.PENDING_SPECIMEN && labType === LabType.REGULAR_LABORATORY) && renderFacilityDetailsInput()}
                        <PaymentDetailsCard permitType={permitType} application={application} viewOnly={true} />
                    </Modal.Body>
                    <Modal.Footer>
                        {status === Status.PENDING_SPECIMEN &&
                            common.allowedDispatch(user.authorities) && (
                                <Button variant='primary' disabled={checkAllReceived()} onClick={() => _handleReceived()}>
                                    {labels.SPECIMEN_RECEIVED}
                                </Button>
                            )}
                        {status === Status.PENDING_RESULT &&
                            common.allowedLab(user.authorities) && (
                                <Button variant='primary' disabled={checkAllResult()} onClick={() => _handleComplete()}>
                                    {labels.COMPLETE_RESULT}
                                </Button>
                            )}
                        {status === Status.PENDING_APPROVAL &&
                            common.allowedLab(user.authorities) && (
                                <>
                                    <Button variant='outline-secondary' disabled={checkRejected()} onClick={_handleReject}>
                                        {labels.REJECT}
                                    </Button>
                                    <Button variant='primary' disabled={checkAllApproved()} onClick={_handleApprove}>
                                        {labels.APPROVE}
                                    </Button>
                                </>
                            )}
                    </Modal.Footer>
                    {showForm && (
                        <LabFormModal
                            show={showForm}
                            status={status}
                            requirement={requirement}
                            application={application}
                            labType={labType}
                            labForm={labForm}
                            onClose={(updated) => _handleFormClose(updated)}
                        />
                    )}
                    {showDispatchForm && (
                        <LabDispatchFormModal
                            show={showDispatchForm}
                            application={application}
                            requirement={requirement}
                            labForm={labForm}
                            onClose={(updated) => _handleDispatchFormClose(updated)}
                        />
                    )}
                    {showSpecimenForm && (
                        <LabSpecimenFormModal
                            show={showSpecimenForm}
                            application={application}
                            labType={labType}
                            requirement={requirement}
                            labForm={labForm}
                            onClose={(updated) => _handleSpecimenFormClose(updated)}
                        />
                    )}
                    <ViewRequirementModal
                        application={application}
                        permitType={PermitType.IHC}
                        upload={uploadData?.upload}
                        requirementName={uploadData?.requirementName}
                        viewOnly={true}
                        onClose={() => setUploadData(undefined)}
                    />
                </>
            )}
        </Modal>
    );
};

export default LabModal;
