import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';

import { labels } from '../../../constants';
import { common } from '../../../services';
import { CreateTrainingSession, TrainingSession } from '../../../models';

import './styles.scss';

type Props = {
    show: boolean;
    session?: TrainingSession;
    onClose: (session?: CreateTrainingSession | TrainingSession) => void;
};

const TrainingSessionModal: React.FunctionComponent<Props> = (props) => {
    const { show, session, onClose } = props;
    const user = useSelector((state: any) => state.user.user);
    const [startDateTime, setStartDateTime] = useState('');
    let formOptions: any = { mode: 'onBlur' };

    formOptions.defaultValues = session
        ? {
              trainingName: session.trainingName,
              trainingDesc: session.trainingDesc,
              startDate: common.formatDateTimeOutput(`${session.startDate} ${session.startTime}`),
              startTime: session.startTime,
              endDate: common.formatDateTimeOutput(`${session.endDate} ${session.endTime}`),
              endTime: session.endTime,
              trainer: session.trainer,
              maxAllowedParticipants: session.maxAllowedParticipants,
              location: session.location
          }
        : undefined;

    const { handleSubmit, register, errors } = useForm<CreateTrainingSession>(formOptions);

    const handleStartDateChange = (event) => {
        setStartDateTime(event.target.value);
    };

    const _onSubmit = (values: CreateTrainingSession) => {
        // format time first because original date picker values are overriden on next step
        values.startTime = common.formatDate(values.startDate).format("HH:mm:ss");
        values.endTime = common.formatDate(values.endDate).format("HH:mm:ss");

        values.startDate = common.parseDateTimeToDate(values.startDate);
        values.endDate = common.parseDateTimeToDate(values.endDate);

        if (session) {
            let updatedSession = {
                ...values,
                trainingSessionId: session.trainingSessionId
            };

            onClose(updatedSession);
        } else {
            onClose(values);
        }
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='TrainingSessionModal'>
            <Modal.Header closeButton>
                <Modal.Title>{session ? labels.TRAINING_UPDATE : labels.CREATE_SESSION}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.TRAINING_NAME)}</Form.Label>
                        <Form.Control
                            type='text'
                            name='trainingName'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.trainingName}
                        />
                        {!!errors.trainingName && (
                            <Form.Control.Feedback type='invalid'>{errors.trainingName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.TRAINING_DESC}</Form.Label>
                        <Form.Control type='text' name='trainingDesc' ref={register()} isInvalid={!!errors.trainingDesc} />
                        {!!errors.trainingDesc && (
                            <Form.Control.Feedback type='invalid'>{errors.trainingDesc.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.START_DATE)}</Form.Label>
                        <Form.Control
                            type='datetime-local'
                            name='startDate'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            onChange={handleStartDateChange}
                            isInvalid={!!errors.startDate}
                            min={common.parseDateTimeStringAPI(common.parseDateToString(new Date()))}
                        />
                        {!!errors.startDate && (
                            <Form.Control.Feedback type='invalid'>{errors.startDate.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.END_DATE)}</Form.Label>
                        <Form.Control
                            type='datetime-local'
                            name='endDate'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.endDate}
                            min={startDateTime}
                        />
                        {!!errors.endDate && (
                            <Form.Control.Feedback type='invalid'>{errors.endDate.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.TRAINER)}</Form.Label>
                        <Form.Control
                            type='text'
                            name='trainer'
                            defaultValue={common.parseUserFullname(user)}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.trainer}
                        />
                        {!!errors.trainer && (
                            <Form.Control.Feedback type='invalid'>{errors.trainer.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.PARTICIPANT_LIMIT}</Form.Label>
                        <Form.Control
                            type='number'
                            name='maxAllowedParticipants'
                            defaultValue={40}
                            ref={register({
                                    required: labels.FIELD_REQUIRED
                                })}
                            isInvalid={!!errors.maxAllowedParticipants}
                            min={0}
                        />
                        {!!errors.maxAllowedParticipants && (
                            <Form.Control.Feedback type='invalid'>{errors.maxAllowedParticipants.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{common.parseRequired(labels.LOCATION)}</Form.Label>
                        <Form.Control
                            type='text'
                            name='location'
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.location}
                        />
                        {!!errors.location && (
                            <Form.Control.Feedback type='invalid'>{errors.location.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {session ? labels.UPDATE : labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TrainingSessionModal;
