import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { IoLocationOutline, IoPersonCircleOutline } from 'react-icons/io5';
import { GrCertificate } from 'react-icons/gr';
import { BiCalendarEdit } from 'react-icons/bi';
import { MdOutlineBusinessCenter } from 'react-icons/md';

import { common } from '../../../services';
import { SanitaryReportStatus } from '../../../models';

import './styles.scss';

interface Props {
    establishmentName: string;
    ownerName: string;
    permitNumber: string;
    industry: string;
    createdDate: string;
    address: string;
    reportNo?: string;
    status?: SanitaryReportStatus;
    onClick?: () => void;
}

const SanitaryReportItem: React.FunctionComponent<Props> = (props) => {
    const { establishmentName, ownerName, permitNumber, industry, createdDate, address, reportNo, status, onClick } = props;

    const getStatusClass = (status: SanitaryReportStatus) => {
        let className = 'default';
        let statusKey = SanitaryReportStatus[status];

        if (statusKey === SanitaryReportStatus.RESOLVED) {
            className = 'done';
        } else if (statusKey === SanitaryReportStatus.FOR_ISSUANCE) {
            className = 'reject';
        }

        return className;
    };

    return (
        <Row className={'SanitaryReportItem' + (onClick ? ' clickable' : '')} onClick={onClick}>
            <Col>
                {(reportNo || status) && (
                    <Row className='header'>
                        <Col xs={5}>
                            <label className='order'>{reportNo}</label>
                        </Col>
                        {status && (
                            <Col xs={7} className='my-auto'>
                                <label className={'status ' + getStatusClass(status)}>{SanitaryReportStatus[status]}</label>
                            </Col>
                        )}
                    </Row>
                )}
                <Row>
                    <Col>
                        <label className='main-label'>{establishmentName}</label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3} className='sub-label'>
                        <label>Owner Name</label>
                        <div className='label-container'>
                            <IoPersonCircleOutline />
                            <label>{ownerName}</label>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className='sub-label'>
                        <label>Permit Number</label>
                        <div className='label-container'>
                            <GrCertificate />
                            <label>{common.parseNullable(permitNumber)}</label>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className='sub-label'>
                        <label>Industry</label>
                        <div className='label-container'>
                            <MdOutlineBusinessCenter />
                            <label>{industry}</label>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className='sub-label'>
                        <label>Updated Date</label>
                        <div className='label-container'>
                            <BiCalendarEdit />
                            <label>{createdDate}</label>
                        </div>
                    </Col>
                    <Col xs={12} className='sub-label'>
                        <label>Address</label>
                        <div className='label-container'>
                            <IoLocationOutline />
                            <label>{address}</label>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default SanitaryReportItem;
