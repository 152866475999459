import React, { useState, useEffect } from 'react';
import { Card, Table, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { usePage } from 'react-page-states';

import { labels } from './../../../constants';
import { TrainingParticipant } from './../../../models';
import { ApplicationService, common } from '../../../services';

import PageHeader from './../../components/PageHeader';
import PageBody from './../../components/PageBody';
import Paginator from './../../components/Paginator';

import './styles.scss';

type FormValues = {
    firstName: string;
    lastName: string;
};

type Props = {};

const ParticipantRecords: React.FunctionComponent<Props> = () => {
    const { pageNo, pageSize, total, ...page } = usePage();
    const [isLoading, setLoading] = useState(true);
    const [participantList, setParticipantList] = useState<TrainingParticipant[]>([]);
    const [formValues, setFormValues] = useState<FormValues>();

    const { handleSubmit, register } = useForm<FormValues>();

    const loadData = () => {
        setLoading(true);
        ApplicationService.getTrainingParticipants(pageNo - 1, pageSize, formValues?.firstName, formValues?.lastName).then(
            (res) => {
                let data = res.body.data;
                setParticipantList(data.details);
                page.setTotal(data.totalCount ? data.totalCount : 0);
                setLoading(false);
            }
        );
    };

    useEffect(loadData, [pageSize, pageNo, formValues]);

    const _handleLimitChange = (limit: number) => {
        page.setPageNo(1);
        page.setPageSize(limit);
    };

    const _handleFormChange = (values: FormValues) => {
        setFormValues(values);
        page.setPageNo(1);
    };

    const renderRows = (): JSX.Element[] => {
        let rows: JSX.Element[] = [];

        participantList.forEach((participant, index) => {
            rows.push(
                <tr key={index}>
                    <td>{common.parseFullname(participant)}</td>
                    <td>{participant.gender}</td>
                    <td>{participant.birthDate}</td>
                    <td>{participant.emailAddress}</td>
                    <td>
                        {participant.trainingSession
                            ? common.parseDateTimeAPIToDateTime(common.parseDateTimeToString(participant.trainingSession.startDate))
                            : ''}
                    </td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <div className='ParticipantRecords'>
            <PageHeader title={labels.MASSAGIST_TRAINING} subTitle={labels.PARTICIPANT_RECORDS} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Form onSubmit={handleSubmit((values) => _handleFormChange(values))}>
                            <Row>
                                <Col sm={12} md={2}>
                                    <span>Show </span>
                                    <Form.Group className='count-select'>
                                        <Form.Control
                                            as='select'
                                            size='sm'
                                            value={pageSize}
                                            onChange={(e: any) => _handleLimitChange(e.target.value)}>
                                            <option>10</option>
                                            <option>20</option>
                                            <option>30</option>
                                            <option>40</option>
                                            <option>50</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <span> entries</span>
                                </Col>
                                <Col sm={12} md={2} className='mb-4'>
                                    <Form.Control type='text' name='lastName' placeholder='Search lastname' ref={register()} />
                                </Col>
                                <Col sm={12} md={2} className='mb-4'>
                                    <Form.Control type='text' name='firstName' placeholder='Search firstname' ref={register()} />
                                </Col>
                                <Col sm={12} md={2} className='mb-4'>
                                    <Button variant='primary' type='submit'>
                                        {labels.SEARCH}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && total === 0 && (
                                    <div className='center'>
                                        <span>No data available</span>
                                    </div>
                                )}
                                {!isLoading && total > 0 && (
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{labels.NAME}</th>
                                                <th>{labels.GENDER}</th>
                                                <th>{labels.BIRTH_DATE}</th>
                                                <th>{labels.EMAIL_ADDRESS}</th>
                                                <th>{labels.TRAINING_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderRows()}</tbody>
                                    </Table>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {total > 0 && (
                                    <span>
                                        Showing {1 + (pageNo - 1) * pageSize} to{' '}
                                        {pageNo * pageSize > total ? total : pageNo * pageSize} of {total} entries
                                    </span>
                                )}
                            </Col>
                            <Col md='auto'>
                                <Paginator
                                    currentPage={pageNo}
                                    maxPage={page.maxPage}
                                    _handlePageChange={(pageNumber) => page.setPageNo(pageNumber)}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </PageBody>
        </div>
    );
};

export default ParticipantRecords;
