import { actions, config } from './../constants';
import { Credentials, User } from './../models';
import { AuthService } from './../services';

export const login = (form: Credentials) => {
    return (dispatch: any) => {
        return AuthService.login(form)
            .then((response: any) => {
                if (form.rememberMe) sessionStorage.setItem(config.TOKEN_KEY, response.body.id_token);

                dispatch(setToken(response.body.id_token));

                AuthService.getDetails().then((response: any) => {
                    dispatch(setLogin(response.body));
                    return Promise.resolve();
                });
            })
            .catch((error) => {
                dispatch(logout());
                return Promise.reject(error);
            });
    };
};

export const checkSession = () => {
    return (dispatch: any) => {
        if (sessionStorage.getItem(config.TOKEN_KEY)) {
            dispatch(setUserLoading(true));

            AuthService.getDetails()
                .then((response: any) => {
                    dispatch(setLogin(response.body));
                })
                .catch(() => {
                    dispatch(logout());
                });
        } else {
            dispatch(setUserLoading(false));
        }
    };
};

export const setLogin = (data: User) => {
    return {
        type: actions.user.LOGIN,
        payload: data
    };
};

export const logout = () => {
    sessionStorage.clear();
    return {
        type: actions.user.LOGOUT
    };
};

const setUserLoading = (isLoading: boolean) => {
    return {
        type: actions.user.LOADING,
        payload: isLoading
    };
};

const setToken = (token: string) => {
    return {
        type: actions.user.SET_TOKEN,
        payload: token
    };
};
