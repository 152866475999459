import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Row, Col, Spinner, Form, Button } from 'react-bootstrap';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import { ConfigService, modal } from './../../../services';
import './styles.scss';

type Props = {};

const Maintenance: React.FunctionComponent<Props> = () => {
    const [isLoading, setLoading] = useState(true);
    const [isMaintenance, setMaintenance] = useState(false);
    const [isScheduled, setScheduled] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const formatDateSetter = (dateString) => {
        if (!dateString.includes('/')) {
            const [datePart, timePart] = dateString.split('T');
            let [year, month, day] = datePart.split('-');
            let [hours, minutes] = (timePart || '00:00').split(':');

            year = year.padStart(4, '0');
            month = month.padStart(2, '0');
            day = day.padStart(2, '0');
            hours = hours.padStart(2, '0');
            minutes = minutes.padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
            return formattedDate;
        } else {
            const [datePart, timePart] = dateString.split(' ');
            const [month, day, year] = datePart.split('/');
            const [hours, minutes] = timePart.split(':');

            const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
            return formattedDate;
        }
    };

    const today = formatDateSetter(new Date().toISOString().split('T')[0]);

    const handleToggle = () => {
        setMaintenance(!isMaintenance);
    };

    const { handleSubmit } = useForm({
        mode: 'onBlur'
    });

    const loadData = () => {
        setLoading(true);

        ConfigService.getMaintenance()
            .then((response: any) => {
                setMaintenance(response.body.data.active)
                setScheduled(response.body.data.isScheduled)
                setStartDate(formatDateSetter(response.body.data.validFrom))
                setEndDate(formatDateSetter(response.body.data.validTo))
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                console.error(err);
            });
    };

    useEffect(loadData, []);
    useEffect(() => {
        setScheduled(isMaintenance ? isScheduled : false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMaintenance])

    const _onSubmit = (values: any) => {
        let request = {
            message: "Test",
            active: isMaintenance,
            validFrom: formatDate(startDate),
            validTo: formatDate(endDate)
        }
        modal.displayConfirmation(labels.dialog.message[isMaintenance ? "MAINTENANCE_WARNING" : "MAINTENANCE_WARNING_OFF"], () => {
            ConfigService.setMaintenance(request)
                .then(() => {
                    modal.displaySuccess(labels.dialog.message.PRIVACY_SUCCCESS);
                })
                .catch((err: any) => {
                    console.error(err);
                });
        });
    };

    const formatDate = (dateString) => {
        const [datePart, timePart] = dateString.split('T');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');
        const formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}:00`;
        return formattedDateTime;
    };

    return (
        <div className='PrivacyPolicy'>
            <PageHeader title={labels.MAINTENANCE} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && (
                                    <Form onSubmit={handleSubmit(_onSubmit)}>
                                        <Row>
                                            <div className='label'>
                                                {labels.dialog.message.MAINTENANCE_DESC}
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Check
                                                        type="switch"
                                                        id="maintenance-switch"
                                                        className="datepicker-switch"
                                                        label="Maintenance"
                                                        onChange={handleToggle}
                                                        defaultChecked={isMaintenance}
                                                    />
                                                    <Row className='date-picker'>
                                                        <Col>
                                                            <Row>
                                                                <label>Start Date</label>
                                                            </Row>
                                                            <Row className="date-input">
                                                                <input
                                                                    type='datetime-local'
                                                                    value={startDate}
                                                                    onChange={(e) => setStartDate(e.target.value)}
                                                                    disabled={!isMaintenance}
                                                                    min={today}
                                                                />
                                                            </Row>
                                                            <Row>
                                                                <label>End Date</label>
                                                            </Row>
                                                            <Row className="date-input">
                                                                <input
                                                                    type='datetime-local'
                                                                    value={endDate}
                                                                    onChange={(e) => setEndDate(e.target.value)}
                                                                    disabled={!isMaintenance}
                                                                    min={startDate ?? today}
                                                                />
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type='submit' variant='primary'>
                                                    {labels.SAVE}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </PageBody>
        </div>
    );
};

export default Maintenance;
