import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Row, Col, Spinner, Form, Button } from 'react-bootstrap';
import { find } from 'lodash';

import { labels } from '../../../constants';
import { Link, Language, CreateLink } from '../../../models';
import { ConfigService, modal } from './../../../services';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import './styles.scss';

type Props = {};

const HIVSeminar: React.FunctionComponent<Props> = () => {
    const [isLoading, setLoading] = useState(true);
    const [tagalogLink, setTagalogLink] = useState<Link>();
    const [englishLink, setEnglishLink] = useState<Link>();
    // const [isQLoading, setQLoading] = useState(true);

    const { handleSubmit, register, errors } = useForm({
        mode: 'onBlur'
    });

    const loadData = () => {
        setLoading(true);
        // setQLoading(true);

        ConfigService.getLinks()
            .then((response: any) => {
                const links: Link[] = response.body.data;
                setTagalogLink(find(links, { language: Language.TAGALOG }));
                setEnglishLink(find(links, { language: Language.ENGLISH }));
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                console.error(err);
            });
    };

    useEffect(loadData, []);

    const _onSubmit = (values: any) => {
        let tagalogChanged = (tagalogLink ? tagalogLink.link : undefined) !== values.tagalogLink;
        let englishChanged = (englishLink ? englishLink.link : undefined) !== values.englishLink;

        if (tagalogChanged || englishChanged) {
            modal.displayConfirmation(labels.dialog.message.SAVE_CONFIRM, () => {
                if (tagalogChanged) {
                    if (tagalogLink) {
                        tagalogLink.link = values.tagalogLink;
                        ConfigService.updateLink(tagalogLink);
                    } else {
                        let link: CreateLink = {
                            language: Language.TAGALOG,
                            link: values.tagalogLink
                        };
                        ConfigService.createLink(link);
                    }
                }

                if (englishChanged) {
                    if (englishLink) {
                        englishLink.link = values.englishLink;
                        ConfigService.updateLink(englishLink);
                    } else {
                        let link: CreateLink = {
                            language: Language.ENGLISH,
                            link: values.englishLink
                        };
                        ConfigService.createLink(link);
                    }
                }
            });
        }
    };

    return (
        <div className='HIVSeminar'>
            <PageHeader title={labels.HIV_SEMINAR} />
            <PageBody>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body className='p-3'>
                                <Row>
                                    <Col>
                                        {isLoading && (
                                            <div className='center-content'>
                                                <Spinner animation='border' variant='primary' />
                                            </div>
                                        )}
                                        {!isLoading && (
                                            <Form onSubmit={handleSubmit(_onSubmit)}>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Form.Group>
                                                            <Form.Label>{labels.TAGALOG_LINK}</Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                name='tagalogLink'
                                                                defaultValue={tagalogLink ? tagalogLink.link : undefined}
                                                                ref={register({
                                                                    required: labels.FIELD_REQUIRED
                                                                })}
                                                                isInvalid={!!errors.tagalogLink}
                                                            />
                                                            {!!errors.tagalogLink && (
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {errors.tagalogLink.message}
                                                                </Form.Control.Feedback>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <Form.Group>
                                                            <Form.Label>{labels.ENGLISH_LINK}</Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                name='englishLink'
                                                                defaultValue={englishLink ? englishLink.link : undefined}
                                                                ref={register({
                                                                    required: labels.FIELD_REQUIRED
                                                                })}
                                                                isInvalid={!!errors.englishLink}
                                                            />
                                                            {!!errors.englishLink && (
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {errors.englishLink.message}
                                                                </Form.Control.Feedback>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button type='submit' variant='primary'>
                                                            {labels.SAVE}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* <Row className='mt-5'>
                    <Col>
                        <Card>
                            <Card.Body className='p-3'>
                                <Row>
                                    <Col>
                                        {isQLoading && (
                                            <div className='center-content'>
                                                <Spinner animation='border' variant='primary' />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
            </PageBody>
        </div>
    );
};

export default HIVSeminar;
