enum HistoryStatus {
    REQUIREMENT = 'Requirement Approval',
    PAYMENT = 'Payment Approval',
    HEAD = 'Head Approval',
    LAB = 'Complete Laboratory',
    ON_HOLD = 'On Hold',
    REJECT = 'Reject',
    UPDATE = 'Requires Update'
}

export default HistoryStatus;
