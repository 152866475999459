import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { usePage } from 'react-page-states';
import { RiDownload2Line } from 'react-icons/ri';

import { labels } from '../../../constants';
import { ApplicationFilter, SanitaryReport, SanitaryReportStatus } from '../../../models';
import { getIndustries } from '../../../actions/config.action';
import { ApplicationService, common } from '../../../services';

import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import Paginator from '../../components/Paginator';
import SanitaryReportItem from '../../components/SanitaryReportItem';
import SearchFilter, { SearchRef } from '../../components/SearchFilter';

import './styles.scss';

type FormValues = {
    establishmentName: string;
    permitNumber: string;
    industryId: string;
    status: SanitaryReportStatus;
    dateFrom: string;
    dateTo: string;
};

type Props = {};

const SanitaryInspectionReport: React.FunctionComponent<Props> = () => {
    const dispatch = useDispatch();
    const searchRef = useRef<SearchRef>(null);
    const { pageNo, pageSize, total, ...page } = usePage();
    const [isLoading, setLoading] = useState(false);
    const [searched, setSearched] = useState(false);
    const [sanitaryReportList, setSanitaryReportList] = useState<SanitaryReport[]>([]);
    const searchColumns: string[] = ['establishmentName', 'permitNumber', 'sanitaryInspectionReportStatus', 'dateFrom', 'dateTo', 'industryId'];

    const searchData = (isExport: boolean = false) => {
        let filter: ApplicationFilter = common.createFilter(searchRef?.current?.getValues());
        if (searched) {
            if (!isExport) setLoading(true);
            ApplicationService.getSanitaryReports(
                isExport ? 1 : pageNo,
                isExport ? 99999999 : pageSize,
                filter,
                isExport ? labels.preloader.GENERATE_REPORT : false
            )
                .then((response) => {
                    let data = response.body.data;

                    if (isExport) {
                        _handleExport(data.details);
                    } else {
                        page.setTotal(data.totalCount);
                        setSanitaryReportList(data.details);
                    }
                })
                .catch((err) => console.error(err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        dispatch(getIndustries());
    }, [dispatch]);

    useEffect(searchData, [pageNo, searched]);

    const _handleFormChange = () => {
        page.setPageNo(1);
        setSearched(true);
        searchData();
    };

    const _handleExport = (data: SanitaryReport[]) => {
        const generateExportHeader = () => {
            let headers = [
                labels.SANITARY_REPORT_NO,
                labels.ESTABLISHMENT_NAME,
                labels.ADDRESS,
                labels.MAYOR_PERMIT,
                labels.INDUSTRY,
                labels.CREATED_DATE,
                labels.UPDATED_DATE,
                labels.CREATED_BY
            ];

            return headers;
        };

        const generateExportContent = (data: SanitaryReport[]) => {
            let content = '';

            data.forEach((sanitaryReport) => {
                let row = [
                    sanitaryReport.sanitaryInspectionReportNo,
                    sanitaryReport.establishmentName,
                    sanitaryReport.formData.address,
                    sanitaryReport.permitNumber,
                    sanitaryReport.industry.industryName,
                    sanitaryReport.createdDate,
                    sanitaryReport.updatedDate,
                    sanitaryReport.submittedBy.firstName + ' ' + sanitaryReport.submittedBy.lastName
                ];

                content += row.join(',') + '\r\n';
            });

            return content;
        };

        let fileName = 'SanitaryInspectionReport ' + common.parseDateTimeFile() + '.csv';

        let csvContent = generateExportHeader().join(',') + '\r\n';
        csvContent += generateExportContent(data);

        common.downloadCSV(csvContent, fileName);
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        sanitaryReportList.forEach((sanitaryReport, index) => {
            rows.push(
                <SanitaryReportItem
                    key={index}
                    establishmentName={sanitaryReport.establishmentName}
                    ownerName={sanitaryReport.formData.ownerName}
                    permitNumber={sanitaryReport.permitNumber}
                    industry={sanitaryReport.industry.industryName}
                    createdDate={sanitaryReport.updatedDate}
                    address={sanitaryReport.formData.address}
                    reportNo={sanitaryReport.sanitaryInspectionReportNo}
                    status={sanitaryReport.sanitaryInspectionReportStatus}
                />
            );
        });

        return rows;
    };

    return (
        <div className='SanitaryInspectionReport'>
            <PageHeader title={labels.SANITARY_REPORT} subTitle={labels.GENERATE_REPORT} />
            <PageBody>
                <SearchFilter ref={searchRef} columns={searchColumns} defaultCollapsed={true} onSubmit={_handleFormChange} />
                {searched && (
                    <Card className='mt-4'>
                        {!isLoading && (
                            <div className='result-header'>
                                <label>{total + ' ' + (total > 1 ? labels.SANITARY_REPORTS : labels.SANITARY_REPORT)}</label>
                                {total > 0 && (
                                    <Button variant='primary' onClick={() => searchData(true)}>
                                        <RiDownload2Line />
                                        <span className='d-none d-sm-inline-block'>{labels.EXPORT_CSV}</span>
                                    </Button>
                                )}
                            </div>
                        )}
                        <Card.Body className='p-3'>
                            {isLoading && (
                                <div className='center-content'>
                                    <Spinner animation='border' variant='primary' />
                                </div>
                            )}
                            {!isLoading && total === 0 && (
                                <div className='center-content'>
                                    <span>No data available</span>
                                </div>
                            )}
                            {!isLoading && total > 0 && (
                                <>
                                    <div>{renderRows()}</div>
                                    <Row className='page-row'>
                                        <Col md='auto'>
                                            <Paginator
                                                currentPage={pageNo}
                                                maxPage={page.maxPage}
                                                _handlePageChange={(pageNo) => page.setPageNo(pageNo)}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                )}
            </PageBody>
        </div>
    );
};

export default SanitaryInspectionReport;
