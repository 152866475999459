import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';

import { labels } from '../../../constants';
import { Upload, Status, File, Application, PermitType } from '../../../models';
import { ApplicationService, modal, common } from '../../../services';
import FileRenderer from './../FileRenderer';

import './styles.scss';

type Props = {
    application: Application;
    permitType: PermitType;
    upload?: Upload;
    requirementName?: string;
    viewOnly?: boolean;
    onClose: (updated: boolean) => void;
};

const ViewRequirementModal: React.FunctionComponent<Props> = (props) => {
    const { application, permitType, upload, requirementName, viewOnly, onClose } = props;
    const user = useSelector((state: any) => state.user.user);
    const [isLoading, setLoading] = useState(true);
    const [fileData, setFileData] = useState<File>();

    const loadData = () => {
        if (upload) {
            setLoading(true);

            ApplicationService.getFileDetails(upload.imageId).then((response) => {
                setLoading(false);
                setFileData(response.body);
            });
        }
    };

    useEffect(loadData, [upload]);

    const _handleApprove = () => {
        if (upload && requirementName) {
            modal.displayApproveConfirmation(
                labels.dialog.message.APPROVE_REQ_CONFIRM.replace('{name}', requirementName),
                (values) => {
                    ApplicationService.completeUpload(upload.uploadId, values.remarks).then(() => {
                        onClose(true);
                    });
                }
            );
        }
    };

    const _handleRequireUpdate = () => {
        if (upload && requirementName) {
            modal.displayReqUpdConfirmation(
                labels.dialog.message.UPDATE_REQ_CONFIRM.replace('{name}', requirementName),
                (values) => {
                    ApplicationService.rejectUpload(upload.uploadId, values.remarks).then(() => {
                        onClose(true);
                    });
                }
            );
        }
    };

    return (
        <Modal show={upload ? true : false} onHide={() => onClose(false)} centered dialogClassName='ViewRequirementModal'>
            <Modal.Header closeButton>
                <Modal.Title>{requirementName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='detail-row'>
                    {upload?.testCompletedDate && (
                        <Col xs={12} lg={6}>
                            <span>
                                {common.parseDetailLabel(labels.DATE_COMPLETED)}
                                <span>{upload?.testCompletedDate}</span>
                            </span>
                        </Col>
                    )}
                    <Col xs={12} lg={6}>
                        <span>
                            {common.parseDetailLabel(labels.DATE_UPLOADED)}
                            <span>{upload?.uploadDate}</span>
                        </span>
                    </Col>
                </Row>
                {upload?.remarks && (
                    <Row className='detail-row'>
                        <Col>
                            <span>
                                {common.parseDetailLabel(labels.REMARKS)}
                                <span>{upload?.remarks}</span>
                            </span>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        {isLoading && (
                            <div className='center-content'>
                                <Spinner animation='border' variant='primary' />
                            </div>
                        )}
                        {!isLoading && fileData && (
                            <div className='image-group'>
                                <FileRenderer fileURL={fileData.imageUrl} alt='requirement' />
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            {!viewOnly &&
                upload &&
                common.allowedValidator(permitType, user.authorities) &&
                (application.status === Status.FOR_REVIEW || application.status === Status.ON_HOLD || application.status === Status.VALIDATED || application.status === Status.HEAD_APPROVAL) && (
                    <Modal.Footer>
                        {upload.status !== Status.REQUIRES_UPDATE && (
                            <Button variant='outline-secondary' onClick={() => _handleRequireUpdate()}>
                                {labels.REQUIRES_UPDATE}
                            </Button>
                        )}
                        {upload.status !== Status.COMPLETED && (
                            <Button variant='primary' onClick={() => _handleApprove()}>
                                {labels.APPROVE}
                            </Button>
                        )}
                    </Modal.Footer>
                )}
        </Modal>
    );
};

export default ViewRequirementModal;
