import { filter, sortBy, findLast } from 'lodash';
import moment from 'moment';

import { labels, config } from '../../constants';
import {
    CommonAppData,
    NestedOrderOfPayment,
    OrderOfPayment,
    OrderOfPaymentData,
    PermitType,
    Roles,
    User,
    Payment,
    StatusHistory,
    HistoryStatus,
    LabFormUser,
    TrainingParticipant,
    Employee,
    ApplicationFilter,
    HealthAppData,
    Departments,
    LabFormType,
    Requirement
} from '../../models';

export const allowedRoute = (allowedAuth: string[], auths: string[], allowedDepartments?: string[], department?: string) => {
    return checkIfAuthAllowed(allowedAuth, auths) && checkIfDepartmentAllowed(auths, allowedDepartments, department);
};

export const checkIfAuthAllowed = (allowedAuth: string[], auths: string[]) => {
    let isAllowed = false;

    //Allow  access to Super Admin and empty auth routes
    if (auths.includes(Roles.SUPER_ADMIN) || allowedAuth.length === 0) {
        isAllowed = true;
    } else {
        auths.every((auth) => {
            isAllowed = allowedAuth.includes(auth);

            return !isAllowed;
        });
    }

    return isAllowed;
}

export const checkIfDepartmentAllowed = (auths: string[], allowedDepartments?: string[], department?: string) => {
    let isAllowed = false;

    //Allow  access to Super Admin and empty department routes
    if (auths.includes(Roles.SUPER_ADMIN) || !(allowedDepartments) || allowedDepartments.length === 0) {
        isAllowed = true;
    } else {
        //Backward compatibility for old lab accounts
        if (allowedDepartments.includes(Departments.CL) && (!(department) || department === "")) {
            isAllowed = true;
            //Check access if user has deparment
        } else if (department) {
            let departments = department.split(", ");

            departments.every((auth) => {
                isAllowed = allowedDepartments.includes(auth);

                return !isAllowed;
            });
        }
    }

    return isAllowed;
}

export const blockedRoute = (blockedAuth: string[], auths: string[]) => {
    let isBlocked = false;

    if (auths.includes(Roles.SUPER_ADMIN) || blockedAuth.length === 0) {
        isBlocked = false;
    } else {
        auths.every((auth) => {
            isBlocked = blockedAuth.includes(auth);

            return isBlocked;
        });
    }

    return isBlocked;
};

export const allowedAction = (allowedAuth: string[], auths: string[]) => {
    let isAllowed = false;

    if (auths.includes(Roles.SUPER_ADMIN) || auths.includes(Roles.ADMIN)) {
        isAllowed = true;
    } else {
        auths.every((auth) => {
            isAllowed = allowedAuth.includes(auth);

            return !isAllowed;
        });
    }

    return isAllowed;
};

export const allowedValidator = (permitType: PermitType, auths: string[]) => {
    let isAllowed = false;

    if (permitType === PermitType.IHC) {
        isAllowed = allowedAction([Roles.IHC_VALIDATOR, Roles.IHC_APPROVER], auths);
    } else if (permitType === PermitType.SP) {
        isAllowed = allowedAction([Roles.SP_VALIDATOR, Roles.SP_APPROVER], auths);
    }

    return isAllowed;
};

export const allowedApprover = (permitType: PermitType, auths: string[]) => {
    let isAllowed = false;

    if (permitType === PermitType.IHC) {
        isAllowed = allowedAction([Roles.IHC_APPROVER], auths);
    } else if (permitType === PermitType.SP) {
        isAllowed = allowedAction([Roles.SP_APPROVER], auths);
    }

    return isAllowed;
};

export const allowedDispatch = (auths: string[]) => {
    return allowedAction([Roles.LAB_AIDE, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST], auths);;
};

export const allowedLab = (auths: string[]) => {
    return allowedAction([Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST], auths);;
};

export const getNestedRoute = (route: any, results: any[] = []) => {
    const r: any[] = results;

    Object.keys(route).forEach((key) => {
        const value = route[key];
        if (value.path) {
            r.push(value);
        } else {
            getNestedRoute(value, r);
        }
    });

    return r;
};

export const getEnumKey = (enumObj: any, value: string | number) => {
    let keys = Object.keys(enumObj).filter((x) => enumObj[x] === value);

    return keys.length > 0 ? keys[0] : '';
};

export const createOrderOfPaymentData = (
    orderPayments: OrderOfPayment[],
    results?: OrderOfPaymentData,
    parent?: NestedOrderOfPayment,
    depth: number = 0
) => {
    let r: OrderOfPaymentData;

    if (results) {
        r = results;
    } else {
        r = {
            list: [],
            totalDepth: 0
        };
    }

    //Check if parent has children
    let children = filter(orderPayments, { parentId: parent ? parent.orderOfPaymentId : null });

    if (parent && children.length > 0) {
        //If parent exist and has children continue finding nested children
        r.totalDepth = depth + 1;
        children = sortBy(children, ['permitId', 'order']);
        parent.child = children;
        parent.depth = depth;

        //Continue finding children of the epic parent
        children.forEach((child) => {
            createOrderOfPaymentData(orderPayments, r, child, depth + 1);
        });
    } else if (parent && children.length === 0) {
        //Just set depth as final
        parent.depth = depth;
    } else if (children.length > 0) {
        //Else this is the epic parent and add children as parent
        r.totalDepth = depth + 1;
        children = sortBy(children, ['permitId', 'order']);
        r.list = r.list.concat(children);
        r.list = sortBy(r.list, ['permitId', 'order']);

        //Continue finding children of the epic parent
        children.forEach((child) => {
            createOrderOfPaymentData(orderPayments, r, child, depth + 1);
        });
    }

    return r;
};

export const getAge = (dateString: string) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const isWeekend = (dateString: string) => {
    let date = moment(dateString, config.DATE_FORMAT).toDate();
    let day = date.getDay();
    return day === 0;
};

export const parseUserFullname = (user: User) => {
    return user.lastName + ', ' + user.firstName;
};

export const parseFullname = (data: CommonAppData | TrainingParticipant | Payment | Employee): string => {
    return data.lastName + (data.suffix ? ' ' + data.suffix : '') + ', ' + data.firstName + (data.middleName ? ' ' + data.middleName : '');
};

export const parseRepName = (user: User | LabFormUser) => {
    let name = '';

    if (user) {
        name = user.firstName + ' ' + user.lastName + (user.degreeSuffix ? ', ' + user.degreeSuffix : '');
        name = name.toUpperCase();
    }

    return name;
};

export const parseRepLicense = (license: string) => {
    let licenses = license.split(',');
    let response = parseDetailLabel(licenses.length > 1 ? labels.LICENSE_NOS : labels.LICENSE_NO);

    return response + licenses;
};

export const parseBooleanString = (boolean: boolean | string) => {
    let result: boolean = false;

    if (typeof boolean === 'string') {
        result = boolean.toLowerCase() === 'true';
    } else {
        result = boolean;
    }

    return result;
};

export const parseBooleanLabel = (boolean: boolean | string) => {
    let result: boolean = false;

    if (typeof boolean === 'string') {
        result = boolean.toLowerCase() === 'true';
    } else {
        result = boolean;
    }

    return result ? labels.YES : labels.NO;
};

export const parseRequired = (label: string) => {
    return label + ' *';
};

export const parseOptional = (label: string) => {
    return label + ' (Optional)';
};

export const parseDetailLabel = (label: string) => {
    return label + ': ';
};

export const parseCurrency = (amount?: number | string) => {
    return config.CURRENCY + ' ' + (amount ? amount : '0');
};

export const parseNullable = (data?: string | number) => {
    return data ? data : '-';
};

export const isPastDate = (dateString?: string, dateStringReference?: string) => {
    let date = moment(dateString, config.DATE_FORMAT);
    let dateReference = moment(dateStringReference, config.DATE_FORMAT);

    return date.isBefore(dateReference);
};

export const parseDateToString = (date?: Date) => {
    return moment(date ? date : new Date()).format(config.DATE_FORMAT);
};

export const parseDateToStringAPI = (date?: Date) => {
    return moment(date ? date : new Date()).format(config.DATE_API_FORMAT);
};

export const parseDateTimeToString = (date?: Date) => {
    return moment(date ? date : new Date()).format(config.DATETIME_FORMAT);
};

export const parseDateTimeFile = (date?: Date): string => {
    return moment(date ? date : new Date()).format(config.DATETIME_FILE_FORMAT);
};

export const parseDateStringAPI = (dateString: string) => {
    return moment(dateString, config.DATE_FORMAT).format(config.DATE_API_FORMAT);
};

export const parseDateString = (dateString: string) => {
    return moment(dateString, config.DATE_API_FORMAT).format(config.DATE_FORMAT);
};

export const parseDateTimeStringAPI = (dateTimeString: string) => {
    return moment(dateTimeString, config.DATETIME_FORMAT).format(config.DATETIME_API_FORMAT);
};

export const parseDateTimeToDate = (dateTimeString: string) => {
    return moment(dateTimeString, config.DATETIME_API_FORMAT).format(config.DATE_API_FORMAT);
};

export const parseDateTimeToTime = (dateTimeString: string) => {
    return moment(dateTimeString, config.DATETIME_API_FORMAT).format(config.TIME_FORMAT);
};

export const parseDateTimeAPIToDateTime = (dateTimeString: string) => {
    return moment(dateTimeString, config.DATETIME_API_FORMAT).format(config.DATETIME_DISPLAY_FORMAT);
};

export const removeChar = (data?: string, char: string = ',') => {
    return data ? data.replaceAll(char, '') : '';
};

export const encloseQuote = (data: string) => {
    return '"' + data + '"';
};

export const getHistoryApprover = (status: HistoryStatus, history?: StatusHistory[]) => {
    let approver = '';

    if (history) {
        let historyApprover: StatusHistory = findLast(history, { status: status });
        approver = historyApprover ? historyApprover.userId : '';
    }

    return approver;
};

export const getHistoryDate = (status: HistoryStatus, history?: StatusHistory[]) => {
    let date = '';

    if (history) {
        let historyApprover: StatusHistory = findLast(history, { status: status });
        date = historyApprover ? historyApprover.approvedDate : '';
    }

    return date;
};

export const downloadCSV = (data: string, fileName: string) => {
    const nav = window.navigator as any;
    let file = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    if (nav.msSaveOrOpenBlob)
        // IE10+
        nav.msSaveOrOpenBlob(file, fileName);
    else {
        // Others
        var a = document.createElement('a'),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
};

const dataToFile = (data: string, filename: string) => {
    let arr: any = data.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n),
        ext = mime.split('/')[1];

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename + '.' + ext, { type: mime });
};

const createFilter = (form?: Object): ApplicationFilter => {
    let filter: ApplicationFilter = {};

    if (form) {
        Object.keys(form).forEach((key) => {
            if (form[key]) {
                filter[key] = form[key];
            }
        });
    }

    return filter;
};

export const generateNewDateFromString = (dateString) => {
    if (dateString) {
        var t = dateString.split(/[- : /]/);
        var d = new Date(t[2], t[0] - 1, t[1]);

        return new Date(d);
    }
};

export const generateCertificateLink = (appId) => {
    const linkOrigin = process.env.REACT_APP_PORTAL_URL;

    return `${linkOrigin}/qce/documents/${appId}`;
}

export const generateAddress = (formData: HealthAppData) => {
    if (!formData) return null;

    const { houseNo, street, barangay, city } = formData;
    return `
        ${houseNo ? houseNo : ''}
        ${street ? ` ${street},` : ''}
        ${barangay ? ` ${barangay},` : ''}
        ${city ? ` ${city}` : ''}
    `
}

export const formatDate = (dateTimeString: Date) => {
    return moment(dateTimeString, config.DATETIME_FORMAT);
}

export const formatToDate = (dateTimeString: Date) => {
    return moment(dateTimeString, config.DATETIME_FORMAT).format(config.DATE_FORMAT);
}

export const formatDateTimeOutput = (dateTimeString: string) => {
    return moment(dateTimeString, config.DATETIME_OUTPUT).format(config.DATETIME_API_FORMAT);
}

export const formatTimeToAMPM = (dateString: string) => {
    return moment(dateString, config.TIME_API).format(config.TIME_FORMAT);
}

export const getFormType = (requirement?: Requirement) => {
    let type = LabFormType.STOOL;

    if (requirement) {
        if (requirement.requirementName.toLowerCase().includes(LabFormType.STOOL.toLowerCase())) {
            type = LabFormType.STOOL;
        } else if (requirement.requirementName.toLowerCase().includes(LabFormType.SPUTUM.toLowerCase())) {
            type = LabFormType.SPUTUM;
        } else if (requirement.requirementName.toLowerCase() === LabFormType.VD.toLowerCase()) {
            type = LabFormType.VD;
        } else if (requirement.requirementName.toLowerCase().includes(LabFormType.MICRO_WATER.toLowerCase())) {
            type = LabFormType.MICRO_WATER;
        }
    }

    return type;
}

export default {
    allowedRoute,
    blockedRoute,
    allowedAction,
    allowedApprover,
    allowedValidator,
    allowedDispatch,
    allowedLab,
    getNestedRoute,
    getEnumKey,
    createOrderOfPaymentData,
    checkIfDepartmentAllowed,
    getAge,
    isWeekend,
    parseUserFullname,
    parseFullname,
    parseRepName,
    parseRepLicense,
    parseBooleanString,
    parseBooleanLabel,
    parseRequired,
    parseOptional,
    parseDetailLabel,
    parseCurrency,
    parseNullable,
    isPastDate,
    parseDateToString,
    parseDateToStringAPI,
    parseDateStringAPI,
    parseDateString,
    parseDateTimeFile,
    parseDateTimeToString,
    parseDateTimeStringAPI,
    parseDateTimeToDate,
    parseDateTimeToTime,
    parseDateTimeAPIToDateTime,
    removeChar,
    encloseQuote,
    getHistoryApprover,
    getHistoryDate,
    downloadCSV,
    dataToFile,
    createFilter,
    generateNewDateFromString,
    generateCertificateLink,
    formatDate,
    formatToDate,
    formatDateTimeOutput,
    formatTimeToAMPM,
    getFormType
};
