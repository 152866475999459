import { combineReducers } from 'redux';
import user from './user.reducer';
import config from './config.reducer';
import content from './content.reducer';

import modal from './modal.reducer';

export default combineReducers({
    user,
    config,
    content,
    modal
});
