import { api, env } from './../utils';
import { ChangePassword, CreateUser, Credentials, Roles, User } from './../../models';

class AuthServiceClass {
    private serviceURL: string;
    private apiUrl: string = env.apiURL();

    constructor() {
        this.serviceURL = this.apiUrl + '/uaa';
    }

    login(data: Credentials) {
        return api.post(this.serviceURL + '/authenticate', data, true, false);
    }

    getDetails() {
        return api.get(this.serviceURL + '/account');
    }

    changePassword(data: ChangePassword) {
        return api.post(this.serviceURL + '/account/change-password', data, true, false);
    }


    getUsers(size: number, page: number, roles?: Roles[], login?: string, firstname?: string, lastname?: string) {
        let request = this.serviceURL + '/users?size=' + size + '&page=' + page;

        if (roles) request = request + '&roles=' + roles.join();
        if (login) request = request + '&login=' + login;
        if (firstname) request = request + '&firstname=' + firstname;
        if (lastname) request = request + '&lastname=' + lastname;

        return api.get(request, false);
    }

    activateUser(id: number, active: boolean) {
        let request = this.serviceURL + '/users/activate';

        return api.put(request, { id, active });
    }

    createUser(user: CreateUser) {
        return api.post(this.serviceURL + '/register', user);
    }

    getUserDetail(login: string) {
        return api.get(this.serviceURL + '/users/' + login, false);
    }

    updateUser(user: User) {
        return api.put(this.serviceURL + '/users', user);
    }
}

const AuthService = new AuthServiceClass();

export default AuthService;
