import React from 'react';
import { useLocation } from 'react-router-dom';

import { labels } from '../../constants';
import PageHeader from '../components/PageHeader';
import PageBody from '../components/PageBody';
import AccountDetails from '../components/AccountDetails';

import './styles.scss';

type Props = {};

const UsersCreate: React.FunctionComponent<Props> = () => {
    const location: any = useLocation();

    const roles = location?.state?.roles;

    return (
        <div className='UsersCreate'>
            <PageHeader title={labels.CREATE_USER} allowBack />
            <PageBody>
                <AccountDetails createMode={true} roles={roles}/>
            </PageBody>
        </div>
    );
};

export default UsersCreate;
