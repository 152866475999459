import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Col, Spinner, Table, Button, Form } from 'react-bootstrap';
import { FiEdit3 } from 'react-icons/fi';
import { FaRegTrashAlt } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { find, filter } from 'lodash';

import { labels } from '../../../constants';
import { LookUp, LookUpInfo } from '../../../models';
import { ConfigService, modal } from '../../../services';
import { getLookUps, getLookUpInfos } from '../../../actions/config.action';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import LookUpModal from '../../components/LookUpModal';
import LookUpInfoModal from '../../components/LookUpInfoModal';

import './styles.scss';

type Props = {};

let lookUp: LookUp | undefined;
let lookUpInfo: LookUpInfo | undefined;

const LookUps: React.FunctionComponent<Props> = () => {
    const lookUps = useSelector((state: any) => state.config.lookUps);
    const lookUpInfos = useSelector((state: any) => state.config.lookUpInfos);

    const dispatch = useDispatch();
    const [lookUpId, setLookUpId] = useState<number>();
    const [showModal, setShowModal] = useState(false);
    const [showModalInfo, setShowModalInfo] = useState(false);

    useEffect(() => {
        dispatch(getLookUps());
    }, [dispatch]);

    const loadLookUp = () => {
        if (!lookUps.loading && lookUps.list.length > 0) {
            let lookUp: LookUp = find(lookUps.list, { lookUpId: lookUpId });
            if (!lookUp) {
                setLookUpId(lookUps.list[0].lookUpId);
            }
        }
    };

    const loadLookUpInfo = () => {
        if (lookUpId !== undefined) dispatch(getLookUpInfos(lookUpId, true));
    };

    useEffect(loadLookUp, [lookUps]);
    useEffect(loadLookUpInfo, [lookUpId]);

    const setEditing = (data: LookUpInfo) => {
        lookUpInfo = data;
        setShowModalInfo(true);
    };

    const setAddingInfo = () => {
        lookUpInfo = undefined;
        setShowModalInfo(true);
    };

    const setAdding = () => {
        lookUp = undefined;
        setShowModal(true);
    };

    const deleteLookupInfo = (id: number, name: string) => {
        modal.displayConfirmation(labels.dialog.message.DEL_LOOKUP_CONFIRM.replace('{name}', name), () => {
            ConfigService.deleteLookupInfo(id).then(() => loadLookUpInfo());
        });
    };

    const _handleModalClose = (data: any) => {
        lookUp = undefined;
        setShowModal(false);

        if (data) {
            let method;

            if (data.lookUpId) {
                method = ConfigService.updateLookUp(data);
            } else {
                method = ConfigService.createLookUp(data);
            }

            method.then(() => {
                dispatch(getLookUps(true));
            });
        }
    };

    const _handleModalInfoClose = (data: any) => {
        lookUpInfo = undefined;
        setShowModalInfo(false);

        if (data) {
            let method;

            if (data.lookUpInfoId) {
                method = ConfigService.updateLookUpInfo(data);
            } else {
                data.lookUpId = lookUpId;
                method = ConfigService.createLookUpInfo(data);
            }

            method.then(() => {
                loadLookUpInfo();
            });
        }
    };

    const renderLookups = () => {
        let options: JSX.Element[] = [];

        lookUps.list.forEach((lookUp, index) => {
            options.push(
                <option key={index} value={lookUp.lookUpId}>
                    {lookUp.desc}
                </option>
            );
        });

        return options;
    };

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        if (lookUpId === undefined || lookUps.loading || lookUpInfos.loading) {
            rows.push(
                <tr key={1}>
                    <td colSpan={3}>
                        <div className='center-content'>
                            <Spinner animation='border' variant='primary' />
                        </div>
                    </td>
                </tr>
            );
        } else if (filter(lookUpInfos.list, { lookUpId: lookUpId }).length === 0) {
            rows.push(
                <tr key={1}>
                    <td colSpan={3}>
                        <div className='center-content'>
                            <span>{labels.NO_DATA}</span>
                        </div>
                    </td>
                </tr>
            );
        } else {
            filter(lookUpInfos.list, { lookUpId: lookUpId }).forEach((lookup, index) => {
                rows.push(
                    <tr key={index}>
                        <td>{lookup.value}</td>
                        <td>{lookup.desc}</td>
                        <td className='action-group'>
                            <Button variant='outline-primary' onClick={() => setEditing(lookup)}>
                                <FiEdit3 />
                            </Button>
                            <Button
                                variant='outline-secondary'
                                onClick={() => deleteLookupInfo(lookup.lookUpInfoId, lookup.value)}>
                                <FaRegTrashAlt />
                            </Button>
                        </td>
                    </tr>
                );
            });
        }

        return rows;
    };

    return (
        <div className='LookUps'>
            <PageHeader title={labels.LOOKUPS} />
            <PageBody>
                <Card>
                    <Card.Body className='py-4 px-5'>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Group className='count-select'>
                                            <Form.Control
                                                as='select'
                                                onChange={(e: any) => setLookUpId(parseInt(e.target.value))}>
                                                {renderLookups()}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button variant='outline-primary' onClick={() => setAdding()}>
                                            <IoMdAdd />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Button className='add' variant='primary' onClick={() => setAddingInfo()}>
                                    {labels.NEW_LOOKUP}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th>{labels.NAME}</th>
                                            <th>{labels.DESCRIPTION}</th>
                                            <th>
                                                <span>{labels.ACTION}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderRows()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <LookUpModal show={showModal} onClose={(data) => _handleModalClose(data)} lookUp={lookUp} />
                <LookUpInfoModal show={showModalInfo} onClose={(data) => _handleModalInfoClose(data)} lookUpInfo={lookUpInfo} />
            </PageBody>
        </div>
    );
};

export default LookUps;
