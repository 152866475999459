import { Roles, Departments } from './../models';
import { labels } from './../constants';

import Login from './../pages/Login';
import Dashboard from './../pages/Dashboard';
import Summary from './../pages/Summary';
import HealthAll from './../pages/Health/All';
import HealthDraft from '../pages/Health/Draft';
import HealthForReview from '../pages/Health/ForReview';
import HealthRequiresUpdate from '../pages/Health/RequiresUpdate';
import HealthOnHold from '../pages/Health/OnHold';
import HealthRejected from '../pages/Health/Rejected';
import HealthValidated from '../pages/Health/Validated';
import HealthPaymentCreated from '../pages/Health/PaymentCreated';
import HealthPaymentValidation from '../pages/Health/PaymentValidation';
import HealthWaitingApproval from '../pages/Health/WaitingApproval';
import HealthGenerateReport from '../pages/Health/GenerateReport';
import HealthApproved from '../pages/Health/Approved';
import SanitaryAll from './../pages/Sanitary/All';
import SanitaryDraft from '../pages/Sanitary/Draft';
import SanitaryForReview from '../pages/Sanitary/ForReview';
import SanitaryRequiresUpdate from '../pages/Sanitary/RequiresUpdate';
import SanitaryOnHold from '../pages/Sanitary/OnHold';
import SanitaryRejected from '../pages/Sanitary/Rejected';
import SanitaryValidated from '../pages/Sanitary/Validated';
import SanitaryPaymentCreated from '../pages/Sanitary/PaymentCreated';
import SanitaryPaymentValidation from '../pages/Sanitary/PaymentValidation';
import SanitaryWaitingApproval from '../pages/Sanitary/WaitingApproval';
import SanitaryGenerateReport from '../pages/Sanitary/GenerateReport';
import SanitaryApproved from '../pages/Sanitary/Approved';
import PendingSpecimen from '../pages/Laboratory/PendingSpecimen';
import PendingResult from '../pages/Laboratory/PendingResult';
import PendingApproval from '../pages/Laboratory/PendingApproval';
import CompletedResult from '../pages/Laboratory/CompletedResult';
import LabGenerateReport from '../pages/Laboratory/GenerateReport';
import SearchApplication from '../pages/SanitaryInspection/SearchApplication';
import CreateSanitaryReport from '../pages/SanitaryInspection/CreateSanitaryReport';
import SanitaryReports from '../pages/SanitaryInspection/SanitaryReports';
import SanitaryInspectionReport from '../pages/SanitaryInspection/SanitaryInspectionReport';
import SanitaryReportDetails from '../pages/SanitaryInspection/SanitaryReportDetails';
import TrainingSessions from '../pages/Massagist/TrainingSessions';
import ParticipantRecords from '../pages/Massagist/ParticipantRecords';
import TrainingDetails from '../pages/Massagist/TrainingDetails';
import UsersAll from './../pages/Users/All';
import UsersLab from './../pages/Users/Lab';
import UsersInspectors from './../pages/Users/Inspectors';
import UsersMassagistTrainers from './../pages/Users/MassagistTrainers';
import IHCRequirements from './../pages/Config/IHCRequirements';
import SPRequirements from './../pages/Config/SPRequirements';
import OrderOfPayment from './../pages/Config/OrderOfPayment';
import HIVSeminar from './../pages/Config/HIVSeminar';
import Industries from './../pages/Config/Industries';
import LookUps from '../pages/Config/LookUps';
import PrivacyPolicy from './../pages/Config/PrivacyPolicy';
import User from './../pages/User';
import CreateUser from './../pages/CreateUser';
import ApplicationDetails from './../pages/ApplicationDetails';
import SocialPendingSpecimen from '../pages/SocialHygene/PendingSpecimen';
import SocialPendingResult from '../pages/SocialHygene/PendingResult';
import SocialPendingApproval from '../pages/SocialHygene/PendingApproval';
import SocialCompletedResult from '../pages/SocialHygene/CompletedResult';
import SocialLabGenerateReport from '../pages/SocialHygene/GenerateReport';
import Maintenance from '../pages/Config/Maintenance';
import WaterAnalysisLabSpecimen from '../pages/WaterAnalysisLab/PendingSpecimen';
import WaterAnalysisLabResult from '../pages/WaterAnalysisLab/CompletedResult';
import WaterAnalysisLabPendingApproval from '../pages/WaterAnalysisLab/PendingApproval';
import WaterAnalysisLabPendingResult from '../pages/WaterAnalysisLab/PendingResult';
import WaterAnalysisLabGenerateReport from '../pages/WaterAnalysisLab/GenerateReport';

const routes = {
    LOGIN: {
        path: '/login',
        component: Login
    },
    DASHBOARD: {
        path: '/dashboard',
        component: Dashboard
    },
    USER: {
        path: '/dashboard/user/:userId',
        component: User
    },
    CREATE_USER: {
        auth: [Roles.ADMIN],
        path: '/dashboard/user/create',
        component: CreateUser
    },
    APPLICATION: {
        path: '/dashboard/application/:type/:appId',
        component: ApplicationDetails
    },

    dashboard: {
        SUMMARY: {
            //leave auth empty to be accesible by all
            auth: [],
            blockedAuth: [Roles.MASSAGIST_TRAINER, Roles.INSPECTOR, Roles.SP_VIEWER, Roles.LAB_VIEWER],
            path: '/dashboard/summary',
            component: Summary
        },
        HEALTH: {
            DRAFT: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/draft',
                component: HealthDraft,
                label: labels.DRAFT
            },
            FOR_REVIEW: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/for-review',
                component: HealthForReview,
                label: labels.FOR_REVIEW
            },
            REQUIRES_UPDATE: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/requires-update',
                component: HealthRequiresUpdate,
                label: labels.REQUIRES_UPDATE
            },
            ON_HOLD: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/on-hold',
                component: HealthOnHold,
                label: labels.ON_HOLD
            },
            REJECTED: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/rejected',
                component: HealthRejected,
                label: labels.REJECTED
            },
            VALIDATED: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/validated',
                component: HealthValidated,
                label: labels.VALIDATED
            },
            PAYMENT_CREATED: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/payment-created',
                component: HealthPaymentCreated,
                label: labels.PAYMENT_CREATED
            },
            PAYMENT_VALIDATION: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/payment-approval',
                component: HealthPaymentValidation,
                label: labels.PAYMENT_VALIDATION
            },
            WAITING_APPROVAL: {
                auth: [Roles.IHC_APPROVER],
                path: '/dashboard/health/waiting-approval',
                component: HealthWaitingApproval,
                label: labels.WAITING_APPROVAL
            },
            APPROVED: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/completed',
                component: HealthApproved,
                label: labels.COMPLETED
            },
            ALL: {
                auth: [Roles.IHC_VALIDATOR, Roles.IHC_APPROVER, Roles.INSPECTOR],
                path: '/dashboard/health/all',
                component: HealthAll,
                label: labels.ALL_APP
            },
            GENERATE_REPORT: {
                auth: [Roles.ADMIN, Roles.IHC_VALIDATOR, Roles.IHC_APPROVER],
                path: '/dashboard/health/reports',
                component: HealthGenerateReport,
                label: labels.GENERATE_REPORTS
            }
        },
        SANITARY: {
            DRAFT: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/draft',
                component: SanitaryDraft,
                label: labels.DRAFT
            },
            FOR_REVIEW: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/for-review',
                component: SanitaryForReview,
                label: labels.FOR_REVIEW
            },
            REQUIRES_UPDATE: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/requires-update',
                component: SanitaryRequiresUpdate,
                label: labels.REQUIRES_UPDATE
            },
            ON_HOLD: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/on-hold',
                component: SanitaryOnHold,
                label: labels.ON_HOLD
            },
            REJECTED: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/rejected',
                component: SanitaryRejected,
                label: labels.REJECTED
            },
            VALIDATED: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/validated',
                component: SanitaryValidated,
                label: labels.VALIDATED
            },
            PAYMENT_CREATED: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/payment-created',
                component: SanitaryPaymentCreated,
                label: labels.PAYMENT_CREATED
            },
            PAYMENT_VALIDATION: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/payment-approval',
                component: SanitaryPaymentValidation,
                label: labels.PAYMENT_VALIDATION
            },
            WAITING_APPROVAL: {
                auth: [Roles.SP_APPROVER],
                path: '/dashboard/sanitary/waiting-approval',
                component: SanitaryWaitingApproval,
                label: labels.WAITING_APPROVAL
            },
            APPROVED: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/completed',
                component: SanitaryApproved,
                label: labels.COMPLETED
            },
            ALL: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER, Roles.INSPECTOR, Roles.SP_VIEWER],
                path: '/dashboard/sanitary/all',
                component: SanitaryAll,
                label: labels.ALL_APP
            },
            GENERATE_REPORT: {
                auth: [Roles.ADMIN, Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/reports',
                component: SanitaryGenerateReport,
                label: labels.GENERATE_REPORTS
            },
            SR_VIEW: {
                auth: [Roles.SP_VALIDATOR, Roles.SP_APPROVER],
                path: '/dashboard/sanitary/order/:type/:id',
                component: SanitaryReportDetails,
                hideSidebar: true
            }
        },
        LABORATORY: {
            PENDING_SPECIMEN: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST, Roles.LAB_AIDE],
                department: [Departments.CL],
                path: '/dashboard/laboratory/specimen',
                component: PendingSpecimen,
                label: labels.PENDING_SPECIMEN
            },
            PENDING_RESULT: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.CL],
                path: '/dashboard/laboratory/result',
                component: PendingResult,
                label: labels.PENDING_RESULT
            },
            PENDING_APPROVAL: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.CL],
                path: '/dashboard/laboratory/approval',
                component: PendingApproval,
                label: labels.PENDING_APPROVAL
            },
            COMPLETED_RESULT: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.CL],
                path: '/dashboard/laboratory/completed',
                component: CompletedResult,
                label: labels.COMPLETED
            },
            GENERATE_REPORT: {
                auth: [Roles.ADMIN, Roles.LAB_REP, Roles.PATHOLOGIST],
                department: [Departments.CL],
                path: '/dashboard/laboratory/reports',
                component: LabGenerateReport,
                label: labels.GENERATE_REPORTS
            }
        },
        SOCIAL_HYGIENE: {
            PENDING_SPECIMEN: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST, Roles.LAB_AIDE],
                department: [Departments.SHL],
                path: '/dashboard/shc/specimen',
                component: SocialPendingSpecimen,
                label: labels.PENDING_SPECIMEN
            },
            PENDING_RESULT: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.SHL],
                path: '/dashboard/shc/result',
                component: SocialPendingResult,
                label: labels.PENDING_RESULT
            },
            PENDING_APPROVAL: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.SHL],
                path: '/dashboard/shc/approval',
                component: SocialPendingApproval,
                label: labels.PENDING_APPROVAL
            },
            COMPLETED_RESULT: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.SHL],
                path: '/dashboard/shc/completed',
                component: SocialCompletedResult,
                label: labels.COMPLETED
            },
            GENERATE_REPORT: {
                auth: [Roles.ADMIN, Roles.LAB_REP, Roles.PATHOLOGIST],
                department: [Departments.SHL],
                path: '/dashboard/shc/reports',
                component: SocialLabGenerateReport,
                label: labels.GENERATE_REPORTS
            }
        },
        WATER_ANALYSIS: {
            PENDING_SPECIMEN: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST, Roles.LAB_AIDE],
                department: [Departments.WAL],
                path: '/dashboard/water-analysis-lab/specimen',
                component: WaterAnalysisLabSpecimen,
                label: labels.PENDING_SPECIMEN
            },
            PENDING_RESULT: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.WAL],
                path: '/dashboard/water-analysis-lab/result',
                component: WaterAnalysisLabPendingResult,
                label: labels.PENDING_RESULT
            },
            PENDING_APPROVAL: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.WAL],
                path: '/dashboard/water-analysis-lab/approval',
                component: WaterAnalysisLabPendingApproval,
                label: labels.PENDING_APPROVAL
            },
            COMPLETED_RESULT: {
                auth: [Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST],
                department: [Departments.WAL],
                path: '/dashboard/water-analysis-lab/completed',
                component: WaterAnalysisLabResult,
                label: labels.COMPLETED
            },
            GENERATE_REPORT: {
                auth: [Roles.ADMIN, Roles.LAB_REP, Roles.PATHOLOGIST],
                department: [Departments.WAL],
                path: '/dashboard/water-analysis-lab/reports',
                component: WaterAnalysisLabGenerateReport,
                label: labels.GENERATE_REPORTS
            }
        },
        SANITARY_INSPECTION: {
            CREATE_SANITARY_SEARCH: {
                auth: [Roles.INSPECTOR],
                path: '/dashboard/inspection/create/search',
                component: SearchApplication,
                label: labels.CREATE_REPORT
            },
            SANITARY_REPORTS: {
                auth: [Roles.INSPECTOR],
                path: '/dashboard/inspection/reports',
                component: SanitaryReports,
                label: labels.SANITARY_REPORTS
            },
            GENERATE_REPORT: {
                auth: [Roles.ADMIN, Roles.INSPECTOR],
                path: '/dashboard/inspection/report',
                component: SanitaryInspectionReport,
                label: labels.GENERATE_REPORT
            },
            SANITARY_DETAILS: {
                auth: [Roles.INSPECTOR],
                path: '/dashboard/inspection/report/details/:id',
                component: SanitaryReportDetails,
                hideSidebar: true
            },
            SANITARY_REPORT_FORM: {
                auth: [Roles.INSPECTOR],
                path: '/dashboard/inspection/create/:appId',
                component: CreateSanitaryReport,
                hideSidebar: true
            },
            SANITARY_REPORT_STANDALONE: {
                auth: [Roles.INSPECTOR],
                path: '/dashboard/inspection/create/standalone',
                component: CreateSanitaryReport,
                hideSidebar: true
            }
        },
        MASSAGIST: {
            SESSIONS: {
                auth: [Roles.MASSAGIST_TRAINER],
                path: '/dashboard/massagist/sessions',
                component: TrainingSessions,
                label: labels.TRAINING_SESSIONS
            },
            RECORDS: {
                auth: [Roles.ADMIN, Roles.MASSAGIST_TRAINER],
                path: '/dashboard/massagist/records',
                component: ParticipantRecords,
                label: labels.PARTICIPANT_RECORDS
            },
            SESSION_DETAILS: {
                auth: [Roles.MASSAGIST_TRAINER],
                path: '/dashboard/massagist/session/:sessionId',
                component: TrainingDetails,
                hideSidebar: true
            }
        },
        USERS: {
            ALL: {
                auth: [Roles.ADMIN],
                path: '/dashboard/users/all',
                component: UsersAll,
                label: labels.ALL_USERS
            },
            LABORATORY_USERS: {
                auth: [Roles.ADMIN],
                path: '/dashboard/users/lab',
                component: UsersLab,
                label: labels.LAB_USERS
            },
            SANITARY_INSPECTORS: {
                auth: [Roles.ADMIN],
                path: '/dashboard/users/inspectors',
                component: UsersInspectors,
                label: labels.SANITARY_INSPECTORS
            },
            MASSAGIST_TRAINERS: {
                auth: [Roles.ADMIN],
                path: '/dashboard/users/trainers',
                component: UsersMassagistTrainers,
                label: labels.MASSAGIST_TRAINERS
            }
        },
        CONFIG: {
            IHC_REQUIREMENTS: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/ihc',
                component: IHCRequirements,
                label: labels.IHC_REQUIREMENTS
            },
            SP_REQUIREMENTS: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/sp',
                component: SPRequirements,
                label: labels.SP_REQUIREMENTS
            },
            PAYMENT: {
                auth: [Roles.SUPER_ADMIN, Roles.IHC_APPROVER, Roles.SP_APPROVER],
                path: '/dashboard/config/payment',
                component: OrderOfPayment,
                label: labels.ORDER_PAYMENT
            },
            SEMINAR: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/seminar',
                component: HIVSeminar,
                label: labels.HIV_SEMINAR
            },
            INDUSTRIES: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/industries',
                component: Industries,
                label: labels.INDUSTRIES
            },
            OCCUPATIONS: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/lookups',
                component: LookUps,
                label: labels.LOOKUPS
            },
            PRIVACY: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/privacy',
                component: PrivacyPolicy,
                label: labels.PRIVACY_POLICY
            },
            MAINTENANCE: {
                auth: [Roles.SUPER_ADMIN],
                path: '/dashboard/config/maintenance',
                component: Maintenance,
                label: labels.MAINTENANCE
            }
        }
    }
};

export default routes;
