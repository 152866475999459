import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Row, Col, Spinner, Form, Button } from 'react-bootstrap';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import { ConfigService, modal } from './../../../services';

import './styles.scss';

type Props = {};

const PrivacyPolicy: React.FunctionComponent<Props> = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [isLoading, setLoading] = useState(true);

    const { handleSubmit, register, errors } = useForm({
        mode: 'onBlur'
    });

    const loadData = () => {
        setLoading(true);

        ConfigService.getPrivacy()
            .then((response: any) => {
                setLoading(false);
                setPrivacyPolicy(response.body.data.content);
            })
            .catch((err: any) => {
                setLoading(false);
                console.error(err);
            });
    };

    useEffect(loadData, []);

    const _onSubmit = (values: any) => {
        let newPrivacyPolicy = values.privacyPolicy;

        if (privacyPolicy !== newPrivacyPolicy) {
            modal.displayConfirmation(labels.dialog.message.SAVE_CONFIRM, () => {
                ConfigService.savePrivacy(newPrivacyPolicy)
                    .then(() => {
                        setPrivacyPolicy(newPrivacyPolicy);
                        modal.displaySuccess(labels.dialog.message.PRIVACY_SUCCCESS);
                    })
                    .catch((err: any) => {
                        console.error(err);
                    });
            });
        }
    };

    return (
        <div className='PrivacyPolicy'>
            <PageHeader title={labels.PRIVACY_POLICY} />
            <PageBody>
                <Card>
                    <Card.Body className='p-3'>
                        <Row>
                            <Col>
                                {isLoading && (
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                )}
                                {!isLoading && (
                                    <Form onSubmit={handleSubmit(_onSubmit)}>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Control
                                                        as='textarea'
                                                        name='privacyPolicy'
                                                        defaultValue={privacyPolicy}
                                                        ref={register({
                                                            required: labels.FIELD_REQUIRED
                                                        })}
                                                        isInvalid={!!errors.privacyPolicy}
                                                    />
                                                    {!!errors.privacyPolicy && (
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.privacyPolicy.message}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type='submit' variant='primary'>
                                                    {labels.SAVE}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </PageBody>
        </div>
    );
};

export default PrivacyPolicy;
