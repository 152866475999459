import React from 'react';

import { labels } from '../../../constants';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';

import './styles.scss';
import UsersTable from '../../components/UsersTable';
import { Roles } from '../../../models';

type Props = {};

const UsersLab: React.FunctionComponent<Props> = () => {
    return (
        <div className='UsersLab'>
            <PageHeader title={labels.USER_MANAGEMENT} subTitle={labels.LAB_USERS} />
            <PageBody>
                <UsersTable roles={[Roles.LAB_VIEWER, Roles.LAB_REP, Roles.MICROSCOPIST, Roles.PATHOLOGIST]} />
            </PageBody>
        </div>
    );
};

export default UsersLab;
