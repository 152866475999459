import { actions } from '../constants';
import { ApplicationService } from '../services';
import { File } from './../models';

export const getImageDetails = (id: number) => {
    return (dispatch: any) => {
        ApplicationService.getFileDetails(id).then((res) => {
            dispatch(addImage(res.body));
        });
    };
};

export const addImage = (file: File) => {
    return {
        type: actions.content.ADD_IMAGE,
        payload: file
    };
};
