import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';

import { labels } from '../../../constants';
import { Payment, PaymentStatus, File, PermitType, EPaymentInfo } from '../../../models';
import { ApplicationService, modal, common, EPaymentService } from '../../../services';
import FileRenderer from './../FileRenderer';

import './styles.scss';

type Props = {
    permitType: PermitType;
    payment?: Payment;
    viewOnly?: boolean;
    onClose: (updated: boolean) => void;
};

const PaymentModal: React.FunctionComponent<Props> = (props) => {
    const { permitType, payment, viewOnly, onClose } = props;
    const user = useSelector((state: any) => state.user.user);
    const [isLoading, setLoading] = useState(true);
    const [fileData, setFileData] = useState<File>();
    const [epaymentInfo, setEPaymentInfo] = useState<EPaymentInfo>();
    const isBulk = payment?.bulkApplicationId ? true : false;

    const loadData = () => {
        if (payment) {
            setLoading(true);

            if (payment.epayment) {
                EPaymentService.getEPaymentData(payment.officialReceiptNumber).then((response) => {
                    setLoading(false);
                    setEPaymentInfo(response.body);
                });
            } else {
                ApplicationService.getFileDetails(payment.imageId).then((response) => {
                    setLoading(false);
                    setFileData(response.body);
                });
            }
        }
    };

    useEffect(loadData, [payment]);

    // const getBase64 = () => {
    //     return 'data:image/jpeg;base64,' + fileData?.image;
    // };

    const _handleApprove = () => {
        if (payment) {
            let message = '';

            if (isBulk) {
                message = labels.dialog.message.APPROVE_BULK_CONFIRM;
            }

            message = message + labels.dialog.message.APPROVE_PAYMENT_CONFIRM;

            modal.displayApproveConfirmation(message, (values) => {
                let method;

                if (payment.bulkApplicationId) {
                    method = ApplicationService.completeBulkPayment(payment.bulkApplicationId, values.remarks);
                } else {
                    method = ApplicationService.completePayment(payment.applicationId, values.remarks);
                }

                method
                    .then(() => {
                        onClose(true);
                        modal.displaySuccess(
                            labels.dialog.message.APPROVE_PAYMENT_SUCCESS.replace('{ref}', payment.referenceNumber)
                        );
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    const _handleRequireUpdate = () => {
        if (payment) {
            modal.displayReqUpdConfirmation(labels.dialog.message.UPDATE_PAYMENT_CONFIRM, (values) => {
                let method;

                if (payment.bulkApplicationId) {
                    method = ApplicationService.rejectBulkPayment(payment.bulkApplicationId, values.remarks);
                } else {
                    method = ApplicationService.rejectPayment(payment.applicationId, values.remarks);
                }

                method
                    .then(() => {
                        onClose(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    };

    return (
        <Modal show={payment ? true : false} onHide={() => onClose(false)} centered dialogClassName='PaymentModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.RECEIPT}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.OR_NO)}
                            <span>{payment?.officialReceiptNumber}</span>
                        </span>
                    </Col>
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.REFERENCE_NO)}
                            <span>{payment?.referenceNumber}</span>
                        </span>
                    </Col>
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.PAYMENT_DATE)}
                            <span>{payment?.paymentDate}</span>
                        </span>
                    </Col>
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.DATE_UPLOADED)}
                            <span>{payment?.updatedDate}</span>
                        </span>
                    </Col>
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.TOTAL)}
                            <span>{common.parseCurrency(payment?.total)}</span>
                        </span>
                    </Col>
                    {permitType === PermitType.IHC && (
                        <Col xs={12} md={6}>
                            <span>
                                {common.parseDetailLabel(labels.BULK)}
                                <span>{common.parseBooleanLabel(isBulk)}</span>
                            </span>
                        </Col>
                    )}
                    <Col xs={12} md={6}>
                        <span>
                            {common.parseDetailLabel(labels.CHANNEL)}
                            <span>{payment?.epayment ? labels.EPAYMENT : labels.ON_SITE}</span>
                        </span>
                    </Col>
                    {payment?.remarks && (
                        <Col xs={12}>
                            <span>
                                {common.parseDetailLabel(labels.REMARKS)}
                                <span>{payment?.remarks}</span>
                            </span>
                        </Col>
                    )}
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {isLoading && (
                            <div className='center-content'>
                                <Spinner animation='border' variant='primary' />
                            </div>
                        )}
                        {!isLoading && fileData && (
                            <div className='image-group'>
                                <FileRenderer fileURL={fileData.imageUrl} alt='receipt' />
                            </div>
                        )}
                        {!isLoading && epaymentInfo && (
                            <Row>
                                <Col xs={12} md={6}>
                                    <span>
                                        {common.parseDetailLabel('Payment Option')}
                                        <span>{epaymentInfo.payment_option}</span>
                                    </span>
                                </Col>
                                <Col xs={12} md={6}>
                                    <span>
                                        {common.parseDetailLabel('Merchant')}
                                        <span>{epaymentInfo.payment_provider_details.option_name}</span>
                                    </span>
                                </Col>
                                <Col xs={12} md={6}>
                                    <span>
                                        {common.parseDetailLabel('Paid Amount')}
                                        <span>{epaymentInfo.epayment_amount}</span>
                                    </span>
                                </Col>
                                <Col xs={12} md={6}>
                                    <span>
                                        {common.parseDetailLabel('Payment Time')}
                                        <span>{epaymentInfo.transaction_timestamp}</span>
                                    </span>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            {!viewOnly &&
                payment &&
                payment.status === PaymentStatus.SUBMITTED &&
                common.allowedValidator(permitType, user.authorities) && (
                    <Modal.Footer>
                        <Button variant='outline-secondary' onClick={() => _handleRequireUpdate()}>
                            {labels.REQUIRES_UPDATE}
                        </Button>
                        <Button variant='primary' onClick={() => _handleApprove()}>
                            {labels.APPROVE}
                        </Button>
                    </Modal.Footer>
                )}
        </Modal>
    );
};

export default PaymentModal;
