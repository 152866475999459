import React from 'react';

import { labels } from './../../../constants';
import { PermitType } from './../../../models';
import PageHeader from './../../components/PageHeader';
import PageBody from './../../components/PageBody';
import ApplicationTable from '../../components/ApplicationTable';

import './styles.scss';

type Props = {};

const HealthAll: React.FunctionComponent<Props> = () => {
    return (
        <div className='HealthAll'>
            <PageHeader title={labels.HEALTH} subTitle={labels.ALL_APP} />
            <PageBody>
                <ApplicationTable permitType={PermitType.IHC} />
            </PageBody>
        </div>
    );
};

export default HealthAll;
