import { actions, config } from './../constants';
import { env } from './../services';
import { User } from '../models';

type State = {
    isAuthenticated: boolean;
    isLoading: boolean;
    token: string | null;
    user?: User;
};

const initialDevState: State = {
    isAuthenticated: false,
    isLoading: true,
    token: sessionStorage.getItem(config.TOKEN_KEY)
};

const initialProdState: State = {
    isAuthenticated: false,
    isLoading: true,
    token: sessionStorage.getItem(config.TOKEN_KEY)
};

const initialState = env.isDev() ? initialDevState : initialProdState;

const user = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case actions.user.LOGIN: {
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            };
        }
        case actions.user.LOGOUT: {
            return {
                ...initialState,
                isLoading: false
            };
        }
        case actions.user.LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }
        case actions.user.SET_TOKEN: {
            return {
                ...state,
                token: action.payload
            };
        }
    }

    return state;
};

export default user;
