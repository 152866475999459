import { remove, cloneDeep } from 'lodash';

import { actions } from '../constants';
import { File } from '../models';

interface State {
    imageList: File[];
}

const initialState: State = {
    imageList: []
};

const content = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case actions.content.ADD_IMAGE: {
            let newImageList = cloneDeep(state.imageList);
            remove(newImageList, { id: action.payload.id });
            newImageList.push(action.payload);

            return {
                ...state,
                imageList: newImageList
            };
        }
    }

    return state;
};

export default content;
