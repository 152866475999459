import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Spinner, Row, Col } from 'react-bootstrap';

import { labels } from '../../../constants';
import { UpdateSanitaryReport, SanitaryReport } from '../../../models';
import { ApplicationService, modal } from '../../../services';
import PageHeader from '../../components/PageHeader';
import PageBody from '../../components/PageBody';
import SanitaryReportForm from '../../components/SanitaryReportForm';

import './styles.scss';

type Props = {};

const SanitaryReportDetails: React.FunctionComponent<Props> = () => {
    const params: any = useParams();
    const isView = params.type ? true : false;
    const navigate = useNavigate();
    const [sanitaryReportData, setSanitaryReportData] = useState<any>();
    const [isLoading, setLoading] = useState(true);

    const loadDetails = () => {
        const sanitaryInspectionReportId = params.id;
        setLoading(true);
        ApplicationService.getSanitaryReport(sanitaryInspectionReportId)
            .then((response: any) => {
                const data: SanitaryReport = response.body.data;
                const { formData, ...details } = data;

                setSanitaryReportData({
                    ...formData,
                    ...details
                });
            })
            .catch((err: any) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(loadDetails, [params]);

    const _onSubmit = (values: any) => {
        modal.displayConfirmation(
            labels.dialog.message.SR_UPDATE_CONFIRM.replace('{SR}', sanitaryReportData.sanitaryInspectionReportNo)
                .replace('{name}', values.establishmentName)
                .replace('{permit}', values.permitNumber),
            () => {
                const { establishmentName, industryId, permitNumber, sanitaryInspectionReportStatus, ...formData } = values;
                let request: UpdateSanitaryReport = {
                    sanitaryInspectionReportId: sanitaryReportData.sanitaryInspectionReportId,
                    establishmentName,
                    industryId,
                    permitNumber,
                    sanitaryInspectionReportStatus,
                    formData
                };

                ApplicationService.updateSanitaryReport(request)
                    .then((response) => {
                        navigate(-1);
                        modal.displaySuccess(
                            labels.dialog.message.SR_UPDATE_SUCCESS.replace('{SR}', response.body.data.sanitaryInspectionReportNo)
                        );
                    })
                    .catch((err: any) => console.error(err));
            }
        );
    };

    return (
        <div className='SanitaryReportDetails'>
            <PageHeader title={labels.SANITARY_REPORT} allowBack={true} subTitle={sanitaryReportData?.sanitaryInspectionReportNo} />
            <PageBody>
                {isLoading && (
                    <Card>
                        <Card.Body className='p-3'>
                            <Row>
                                <Col>
                                    <div className='center-content'>
                                        <Spinner animation='border' variant='primary' />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                )}
                {!isLoading && (
                    <SanitaryReportForm sanitaryReportData={sanitaryReportData} readOnly={isView} onSubmit={_onSubmit} />
                )}
            </PageBody>
        </div>
    );
};

export default SanitaryReportDetails;
