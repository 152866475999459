const config = {
    TOKEN_KEY: 'QC_JWT',
    MOBILE_WIDTH: 768,
    CURRENCY: 'PHP',
    DATE_FORMAT: 'YYYY-MM-DD',
    TIME_FORMAT: 'h:mm a',
    DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm',
    DATE_API_FORMAT: 'MM/DD/YYYY',
    DATETIME_FILE_FORMAT: 'MM-DD-YYYY h.mm a',
    DATETIME_DISPLAY_FORMAT: 'MM/DD/YYYY h:mm a',
    DATETIME_API_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    REFRESH_SECONDS: 300,
    EXPORT_MAX: 300,
    SHOW_LIMIT: 10,
    VALID_FILE: 'image/png, image/jpeg, image/jpg, application/pdf',
    DOWNLOAD_FILE: ['csv', 'xlsx', 'xslx', 'xls', 'xsl', 'docx'],
    DOC_FILE: ['pdf'],
    OIC_NAME: 'RAMONA ASUNCION D.G. ABARQUEZ, MD, MPH',
    DATETIME_OUTPUT: 'MM/DD/YYYY HH:mm:ss',
    TIME_API: 'HH:mm:ss'
};

export default config;
