import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

import { labels } from '../../../constants';
import { Upload, Application, PermitType } from '../../../models';
import ViewRequirementModal from '../ViewRequirementModal';
import InfoTooltip from '../InfoTooltip';
import { FaRegFileImage } from 'react-icons/fa';

import './styles.scss';

type Props = {
    application: Application;
    permitType: PermitType;
    requirementName: string;
    uploadHistory: Upload[];
    onClose: () => void;
};

const UploadHistoryModal: React.FunctionComponent<Props> = (props) => {
    const { application, permitType, requirementName, uploadHistory, onClose } = props;
    const [activeUpload, setActiveUpload] = useState<Upload>();

    const renderRows = () => {
        let rows: JSX.Element[] = [];

        if (uploadHistory.length > 0) {
            uploadHistory.forEach((upload, index) => {
                rows.push(
                    <tr key={index}>
                        <td>{upload.uploadDate}</td>
                        <td className='failed'>
                            {upload.status}
                            {upload.remarks && <InfoTooltip message={upload.remarks} />}
                        </td>
                        <td className='text-center'>
                            <Button variant='primary' onClick={() => setActiveUpload(upload)}>
                                <FaRegFileImage />
                            </Button>
                        </td>
                    </tr>
                );
            });
        } else {
            rows.push(
                <tr>
                    <td colSpan={100} className='text-center'>
                        <span>No data available</span>
                    </td>
                </tr>
            );
        }

        return rows;
    };

    return (
        <Modal show={uploadHistory} onHide={() => onClose()} centered dialogClassName='UploadHistoryModal'>
            <Modal.Header closeButton>
                <Modal.Title>{requirementName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive bordered hover>
                    <thead>
                        <tr>
                            <th>{labels.DATE}</th>
                            <th>{labels.STATUS}</th>
                            <th>{labels.VIEW}</th>
                        </tr>
                    </thead>
                    <tbody>{renderRows()}</tbody>
                </Table>
            </Modal.Body>
            {activeUpload && application && (
                <ViewRequirementModal
                    application={application}
                    permitType={permitType}
                    upload={activeUpload}
                    requirementName={requirementName}
                    viewOnly={true}
                    onClose={() => setActiveUpload(undefined)}
                />
            )}
        </Modal>
    );
};

export default UploadHistoryModal;
