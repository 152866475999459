import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

import { labels } from '../../../constants';
import { UpdateIndustry } from '../../../models';

import './styles.scss';

type FormValues = {
    industryName: string;
};

type Props = {
    show: boolean;
    industry?: UpdateIndustry;
    onClose: (industry?: any) => void;
};

const IndustryModal: React.FunctionComponent<Props> = (props) => {
    const { show, industry, onClose } = props;

    const { handleSubmit, register, errors } = useForm({
        mode: 'onBlur'
    });

    const _onSubmit = (values: FormValues) => {
        if (industry) {
            industry.industryName = values.industryName;
            industry.industryDesc = values.industryName;

            onClose(industry);
        } else {
            let newIndustry = {
                industryName: values.industryName,
                industryDesc: values.industryName
            };

            onClose(newIndustry);
        }
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='IndustryModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.INDUSTRY}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{labels.INDUSTRY_NAME}</Form.Label>
                        <Form.Control
                            type='text'
                            name='industryName'
                            defaultValue={industry ? industry.industryDesc : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.industryName}
                        />
                        {!!errors.industryName && (
                            <Form.Control.Feedback type='invalid'>{errors.industryName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default IndustryModal;
