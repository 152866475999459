const actions = {
    modal: {
        preloader: {
            OPEN: 'PRELOADER_OPEN',
            CLOSE: 'PRELOADER_CLOSE'
        },
        dialog: {
            OPEN: 'DIALOG_OPEN',
            CLOSE: 'DIALOG_CLOSE'
        }
    },
    user: {
        LOGIN: 'LOGIN',
        LOGOUT: 'LOGOUT',
        LOADING: 'LOADING',
        SET_TOKEN: 'SET_TOKEN'
    },
    config: {
        SET_PERMITS: 'SET_PERMITS',
        SET_INDUSTRIES: 'SET_INDUSTRIES',
        SET_INDUSTRIES_LOADING: 'SET_INDUSTRIES_LOADING',
        SET_REQUIREMENTS: 'SET_REQUIREMENTS',
        SET_REQUIREMENTS_LOADING: 'SET_REQUIREMENTS_LOADING',
        SET_APPLICATIONTYPES: 'SET_APPLICATIONTYPES',
        SET_APPLICATIONTYPES_LOADING: 'SET_APPLICATIONTYPES_LOADING',
        SET_LOOKUPS: 'SET_LOOKUPS',
        SET_LOOKUPS_LOADING: 'SET_LOOKUPS_LOADING',
        SET_LOOKUPINFOS: 'SET_LOOKUPINFOS',
        SET_LOOKUPINFOS_LOADING: 'SET_LOOKUPINFOS_LOADING'
    },
    content: {
        ADD_IMAGE: 'ADD_IMAGE'
    }
};

export default actions;
