import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Image, Dropdown } from 'react-bootstrap';
import { RiMenuLine, RiArrowDownSLine } from 'react-icons/ri';

import { images, routes, labels } from './../../constants';
import { logout } from './../../actions/user.action';
import { getPermits, getRequirements } from '../../actions/config.action';
import { device, modal, common } from './../../services';
import Sidebar from './../Sidebar';
import ChangePassword from './../components/ChangePassword';

import './styles.scss';

type Props = {};

const CustomToggle = React.forwardRef((props: any, ref: any) => (
    <button
        ref={ref}
        className='btn btn-icon btn-transparent-dark'
        onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
        }}>
        <RiArrowDownSLine className='sidebar-toggle' />
    </button>
));

const Dashboard: React.FunctionComponent<Props> = () => {
    const isMobile = device.useMobileCheck();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: any) => state.user.user);
    const dispatch = useDispatch();

    const [toggled, setToggle] = useState<boolean>(!isMobile);
    const [isChangePassword, setChangePassword] = useState<boolean>(!isMobile);

    const checkAccount = () => {
        if (!user.signatureId && (common.allowedLab(user.authorities))) {
            modal.displayDialog(
                labels.dialog.message.DETAILS_REQUIRED,
                labels.dialog.header.UPDATE_ACCOUNT,
                labels.UPDATE.toUpperCase(),
                undefined,
                () => {
                    navigate(routes.USER.path.replace(':userId', user.login));
                }
            );
        }
    };

    useEffect(() => {
        if (isMobile) {
            setToggle(false);
        } else {
            setToggle(true);
        }
    }, [isMobile, location]);

    useEffect(() => {
        dispatch(getPermits());
        dispatch(getRequirements());
    }, [dispatch]);

    useEffect(checkAccount, []);

    const _handleLogout = () => {
        modal.displayConfirmation(labels.dialog.message.LOGOUT, () => {
            dispatch(logout());
        });
    };

    return (
        <div className='Dashboard'>
            <Navbar sticky='top' bg='light' variant='light' className='shadow nav-content'>
                <div>
                    <button
                        className={'btn btn-icon btn-transparent-dark' + (toggled ? ' focus' : '')}
                        onClick={() => setToggle(!toggled)}>
                        <RiMenuLine className='sidebar-toggle' />
                    </button>
                    <Image src={images.QC_HEALTH_LOGO.src} alt={images.QC_HEALTH_LOGO.alt} className='brand-logo' />
                    <Navbar.Brand className='d-none d-sm-inline-block'>{labels.TITLE}</Navbar.Brand>
                </div>
                <div className='account-group'>
                    <span className='d-none d-md-inline-block'>{labels.LOGGED_IN}</span>
                    <span className='name'>{user.firstName + ' ' + user.lastName}</span>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} />
                        <Dropdown.Menu className='dropdown-menu-right shadow'>
                            <Dropdown.Item onClick={() => navigate(routes.USER.path.replace(':userId', user.login))}>
                                {labels.ACCOUNT_DETAILS}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => setChangePassword(true)}>{labels.CHANGE_PASSWORD}</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => _handleLogout()}>{labels.LOGOUT}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Navbar>
            <div className='dashboard-body'>
                <Sidebar toggled={toggled} />
                <div className={'content-wrapper' + (toggled && isMobile ? ' hidden' : '')}>
                    <Outlet />
                </div>
            </div>
            <ChangePassword show={isChangePassword} onClose={() => setChangePassword(false)} />
        </div>
    );
};

export default Dashboard;
