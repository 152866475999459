import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import { routes } from './constants';
import { Roles } from './models';
import { common } from './services';

import { checkSession } from './actions/user.action';

import './styles/custom-bootstrap.scss';
import './App.scss';

type Props = {};

const Navigator: React.FunctionComponent<Props> = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state: any) => state.user.isAuthenticated);
    const isLoading = useSelector((state: any) => state.user.isLoading);
    const user = useSelector((state: any) => state.user.user);

    useEffect(() => {
        dispatch(checkSession());
    }, [dispatch]);

    const getIndexPath = () => {
        let path = routes.dashboard.SUMMARY.path;
        let auths = user.authorities;

        if (auths && auths.length === 1) {
            if (auths.includes(Roles.MASSAGIST_TRAINER)) {
                path = routes.dashboard.MASSAGIST.SESSIONS.path;
            } else if (auths.includes(Roles.LAB_VIEWER)) {
                path = routes.dashboard.LABORATORY.COMPLETED_RESULT.path;
            } else if (auths.includes(Roles.SP_VIEWER)) {
                path = routes.dashboard.SANITARY.ALL.path;
            }
        }

        return path;
    };

    const renderDashboardRoutes = (): JSX.Element[] => {
        let dashboardRoutes: JSX.Element[] = [];
        let allRoutes = common.getNestedRoute(routes.dashboard);

        allRoutes.forEach((route, index) => {
            if (common.allowedRoute(route.auth, user.authorities)) {
                dashboardRoutes.push(<Route key={index} path={route.path} element={<route.component />} />);
            }
        });

        return dashboardRoutes;
    };

    return (
        <HashRouter>
            {!isLoading && (
                <Routes>
                    {!isAuthenticated && <Route path={routes.LOGIN.path} element={<routes.LOGIN.component />} />}
                    {isAuthenticated && (
                        <Route path={routes.DASHBOARD.path} element={<routes.DASHBOARD.component />}>
                            <Route index element={<Navigate to={getIndexPath()} replace />} />
                            <Route path={routes.dashboard.SUMMARY.path} element={<routes.dashboard.SUMMARY.component />} />
                            <Route key={routes.USER.path} path={routes.USER.path} element={<routes.USER.component />} />
                            <Route key={routes.CREATE_USER.path} path={routes.CREATE_USER.path} element={<routes.CREATE_USER.component />} />
                            <Route
                                key={routes.APPLICATION.path}
                                path={routes.APPLICATION.path}
                                element={<routes.APPLICATION.component />}
                            />
                            {renderDashboardRoutes()}
                            <Route path='*' element={<Navigate to={getIndexPath()} replace />} />
                        </Route>
                    )}
                    <Route
                        path='*'
                        element={<Navigate to={isAuthenticated ? routes.DASHBOARD.path : routes.LOGIN.path} replace />}
                    />
                </Routes>
            )}
        </HashRouter>
    );
};

export default Navigator;
