import { actions } from './../constants';
import { PreloaderOptions, DialogOptions } from './../models';

interface State {
    preloader: PreloaderOptions;
    dialog: DialogOptions;
}

const initialState: State = {
    preloader: {
        show: false
    },
    dialog: {
        show: false
    }
};

const modal = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case actions.modal.preloader.OPEN: {
            return {
                ...state,
                preloader: {
                    show: true,
                    message: action.payload.message
                }
            };
        }
        case actions.modal.preloader.CLOSE: {
            return {
                ...state,
                preloader: {
                    show: false,
                    message: undefined
                }
            };
        }
        case actions.modal.dialog.OPEN: {
            const { header, body, positiveText, negativeText, secondaryText, inputs, negativeModal } = action.payload;

            return {
                ...state,
                dialog: {
                    show: true,
                    header: header,
                    body: body,
                    positiveText: positiveText,
                    negativeText: negativeText,
                    secondaryText: secondaryText,
                    response: undefined,
                    inputs: inputs,
                    negativeModal: negativeModal
                }
            };
        }
        case actions.modal.dialog.CLOSE: {
            return {
                ...state,
                dialog: {
                    show: false,
                    header: undefined,
                    body: undefined,
                    positiveText: undefined,
                    negativeText: undefined,
                    response: action.payload.response,
                    values: action.payload.values
                }
            };
        }
    }

    return state;
};

export default modal;
