import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

import { labels } from '../../../constants';
import { UpdateSubIndustry, BusinessLine } from '../../../models';

import './styles.scss';

type FormValues = {
    subIndustryName: string;
    businessLineName: string;
};

type Props = {
    show: boolean;
    subIndustry: UpdateSubIndustry;
    businessLine?: BusinessLine;
    onClose: (subIndustry?: any) => void;
};

const BusinessLineModal: React.FunctionComponent<Props> = (props) => {
    const { show, subIndustry, businessLine, onClose } = props;

    const { handleSubmit, register, errors } = useForm({
        mode: 'onBlur'
    });

    const _onSubmit = (values: FormValues) => {
        if (businessLine) {
            businessLine.businessLineName = values.businessLineName;
            businessLine.businessLineDesc = values.businessLineName;

            onClose(businessLine);
        } else {
            let newBusinessLine = {
                subIndustryId: subIndustry.subIndustryId,
                businessLineName: values.businessLineName,
                businessLineDesc: values.businessLineName
            };

            onClose(newBusinessLine);
        }
    };

    return (
        <Modal show={show} onHide={() => onClose(undefined)} centered dialogClassName='BusinessLineModal'>
            <Modal.Header closeButton>
                <Modal.Title>{labels.BUSINESS_LINE}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{labels.SUB_INDUSTRY_NAME}</Form.Label>
                        <Form.Control
                            type='text'
                            name='subIndustryName'
                            disabled
                            defaultValue={subIndustry ? subIndustry.subIndustryDesc : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.subIndustryName}
                        />
                        {!!errors.subIndustryName && (
                            <Form.Control.Feedback type='invalid'>{errors.subIndustryName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{labels.BUSINESS_LINE_NAME}</Form.Label>
                        <Form.Control
                            type='text'
                            name='businessLineName'
                            defaultValue={businessLine ? businessLine.businessLineDesc : undefined}
                            ref={register({
                                required: labels.FIELD_REQUIRED
                            })}
                            isInvalid={!!errors.businessLineName}
                        />
                        {!!errors.businessLineName && (
                            <Form.Control.Feedback type='invalid'>{errors.businessLineName.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button block type='submit'>
                        {labels.SAVE}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default BusinessLineModal;
